import { AbilityBuilder, Ability, AbilityClass } from "@casl/ability";
import { Agent } from "../models/prisma/agent.model";

export type Actions =
  | "view"
  | "manage"
  | "create"
  | "read"
  | "update"
  | "delete";
type Subjects =
  "Prospect" |
  "SalesFairQuiz" |
  "User" |
  "Agent" |
  "Lobby" |
  "Attendance" |
  "RECEPÇÃO" |
  "RELATÓRIOS" |
  "all";

export type AppAbility = Ability<[Actions, string]>;
export const appAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(user: Agent) {
  const { can, rules } = new AbilityBuilder(appAbility);
  console.log("CALL");

  if (user?.role?.description == 'ADMIN') {
    can("manage", "all");
  }
  else {
    if (user?.role?.description) {
      can("read", user?.role?.description as Subjects);
    }

    for (let policy of user?.role?.AgentRolePolicyMap || []) {

      const access = (policy.policy.service || policy.policy.serviceMap?.description);

      if (access) {
        if (policy.policy.canManage) {
          can("manage", access as Subjects);
        }

        if (policy.policy.canUpdate || policy.policy.canManage) {
          can("update", access as Subjects);
        }

        if (policy.policy.canCreate || policy.policy.canManage) {
          can("create", access as Subjects);
        }

        if (policy.policy.canDelete || policy.policy.canManage) {
          can("delete", access as Subjects);
        }

        if (policy.policy.canRead || policy.policy.canManage) {
          can("read", access as Subjects);
        }
      }
    }

  }


  // const routePermissionsFromApi: string[] = [];
  // can(routePermissionsFromApi, "route");

  return rules;
}

export function buildAbilityFor(user: Agent): AppAbility {
  return new appAbility(defineRulesFor(user));
}
