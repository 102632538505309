//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { CustodyWalletAsset } from '../../../models/prisma/custodyWalletAsset.model';
        import { GET_LIST, startedFilter } from './custodyWalletAsset.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import CustodyWalletDetails from '../custodyWallet/custodyWallet.details';
import CustodyCurrenciesDetails from '../custodyCurrencies/custodyCurrencies.details';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            custodyWalletAssetCms: CustodyWalletAsset
        }>`
        query CustodyWalletAssetCms($id: String)
        {
            custodyWalletAssetCms(id: $id)
          {
            id
                    walletId
                    wallet {
                        id
                        address
                    }
                    currencyId
                    currency {
                        id
                        name
                    }
                    balance
                    createdAt
                    updatedAt
                    deleted
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<CustodyWalletAsset>`
        mutation CustodyWalletAssetSave(
            $id: String,
                        $walletId: String,
                        $wallet: CustodyWalletCreateInputs,
                    $currencyId: String,
                        $currency: CustodyCurrenciesCreateInputs,
                    $balance: String,
                        
        )
        {
            custodyWalletAssetCreateCms(data: {
                id: $id,
                    walletId: $walletId,
                    wallet: $wallet,
                    currencyId: $currencyId,
                    currency: $currency,
                    balance: $balance,
                    
            }) {
                id
                    walletId
                    wallet {
                        id
                        address
                    }
                    currencyId
                    currency {
                        id
                        name
                    }
                    balance
                    createdAt
                    updatedAt
                    deleted
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<CustodyWalletAsset>`
        mutation CustodyWalletAssetSave(
            $id: String,
                        $walletId: String,
                        $wallet: CustodyWalletCreateInputs,
                    $currencyId: String,
                        $currency: CustodyCurrenciesCreateInputs,
                    $balance: String,
                        
        )
        {
            custodyWalletAssetUpdateCms(data: {
                id: $id,
                    walletId: $walletId,
                    wallet: $wallet,
                    currencyId: $currencyId,
                    currency: $currency,
                    balance: $balance,
                    
            }) {
                id
                    walletId
                    wallet {
                        id
                        address
                    }
                    currencyId
                    currency {
                        id
                        name
                    }
                    balance
                    createdAt
                    updatedAt
                    deleted
                    
            }
        }
        `;

        
                    const CUSTODYWALLET_QUERY = `
                    query custodyWalletListCms($search: Search) {
                        custodyWalletListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    address
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const CUSTODYCURRENCIES_QUERY = `
                    query custodyCurrenciesListCms($search: Search) {
                        custodyCurrenciesListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const CustodyWalletAssetDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ custodyWalletAssetCms: CustodyWalletAsset }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.custodyWalletAssetCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("CustodyWalletAsset has been saved");
                    const model = entity?.data?.custodyWalletAssetUpdateCms || entity?.data?.custodyWalletAssetCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/custodyWalletAsset-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('CustodyWalletAsset')} breadcrumbItems={[
                                { title: t("CustodyWalletAsset"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('CustodyWalletAsset')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('CustodyWalletAsset')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        { (!props.relation || props.relation.field != "walletId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Wallet')}</>}>
                                <Form.Item noStyle id="wallet_id" name="walletId"
                                rules={[{ required: (true && !(dataSources.dependencies?.wallet?.address || databaseEntity?.wallet?.address)), message: 'Please input the CustodyWallet!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                value={ dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.walletId = newValue.key;
                                            dataSources.dependencies["wallet"] = {
                                                id: newValue.key,
                                                address: newValue.value
                                            };
                                            if(dataSources.dependencies["wallet"]) {
                                                delete dataSources.dependencies["wallet"].__typename;
                                            }                                            
                                            setSelects({ ...selects, walletId: newValue.key });
                                            setUncheck({ ...uncheck, walletId: false });
                                        }
                                        else if (databaseEntity?.wallet) {
                                            dataSources.dependencies.walletId = undefined;
                                            dataSources.dependencies["wallet"] = {
                                                id: undefined,
                                                address: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["wallet"] = {
                                            id: undefined,
                                            address: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.walletId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyWallet", query: CUSTODYWALLET_QUERY, idField: "id", textField: "address" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyWallet", query: CUSTODYWALLET_QUERY, idField: "id", textField: "address" });
                                    }}>
                                    {(dataSources["CustodyWallet"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.address}>
                                            {item.address}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyWalletwalletIdEditVisible: !modals.CustodyWalletwalletIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletwalletIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletwalletIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyWallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyWalletwalletIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    walletId: entity?.id,
                                                    wallet: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyWalletAddVisible: !modals.CustodyWalletAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyWallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyWalletAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        walletId: entity?.id,
                                                        wallet: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id) && !uncheck['walletId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, walletId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            walletId: "",
                                            wallet: {
                                                address: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "currencyId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Currency')}</>}>
                                <Form.Item noStyle id="currency_id" name="currencyId"
                                rules={[{ required: (true && !(dataSources.dependencies?.currency?.name || databaseEntity?.currency?.name)), message: 'Please input the CustodyCurrencies!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.currency ? dataSources.dependencies?.currency?.name : databaseEntity?.currency?.name}
                                value={ dataSources.dependencies?.currency ? dataSources.dependencies?.currency?.name : databaseEntity?.currency?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.currencyId = newValue.key;
                                            dataSources.dependencies["currency"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["currency"]) {
                                                delete dataSources.dependencies["currency"].__typename;
                                            }                                            
                                            setSelects({ ...selects, currencyId: newValue.key });
                                            setUncheck({ ...uncheck, currencyId: false });
                                        }
                                        else if (databaseEntity?.currency) {
                                            dataSources.dependencies.currencyId = undefined;
                                            dataSources.dependencies["currency"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["currency"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.currencyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyCurrencies", query: CUSTODYCURRENCIES_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyCurrencies", query: CUSTODYCURRENCIES_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["CustodyCurrencies"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyCurrenciescurrencyIdEditVisible: !modals.CustodyCurrenciescurrencyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyCurrenciescurrencyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyCurrenciescurrencyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyCurrencies
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyCurrenciesDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.currency?.id || databaseEntity?.currency?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyCurrenciescurrencyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    currencyId: entity?.id,
                                                    currency: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyCurrenciesAddVisible: !modals.CustodyCurrenciesAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyCurrenciesAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyCurrenciesAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyCurrencies
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyCurrenciesDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyCurrenciesAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        currencyId: entity?.id,
                                                        currency: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.currency?.id || databaseEntity?.currency?.id) && !uncheck['currencyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, currencyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            currencyId: "",
                                            currency: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Balance")} name="balance"
                            rules={[{ required: true, message: 'Please input the ' + t("Balance") + '!' }]}>
                                <Input />
                            </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (CustodyWalletAssetDetails);
  