//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { CustodyBlockchainTransaction } from '../../../models/prisma/custodyBlockchainTransaction.model';
        import { GET_LIST, startedFilter } from './custodyBlockchainTransaction.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import CustodyWalletDetails from '../custodyWallet/custodyWallet.details';
import CompanyDetails from '../company/company.details';
import BlockchainTransactionOperationDetails from '../blockchainTransactionOperation/blockchainTransactionOperation.details';
import BlockchainTaxDetails from '../blockchainTax/blockchainTax.details';
import BlockchainTransactionStatusDetails from '../blockchainTransactionStatus/blockchainTransactionStatus.details';
import CustodyTransactionTypeDetails from '../custodyTransactionType/custodyTransactionType.details';
import CustodyCurrenciesDetails from '../custodyCurrencies/custodyCurrencies.details';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            custodyBlockchainTransactionCms: CustodyBlockchainTransaction
        }>`
        query CustodyBlockchainTransactionCms($id: String)
        {
            custodyBlockchainTransactionCms(id: $id)
          {
            id
                    hash
                    confirmations
                    blockNumber
                    vout
                    wallet {
                        id
                        address
                    }
                    walletId
                    address
                    amount
                    dataSign
                    company {
                        id
                        companyName
                    }
                    companyId
                    operation {
                        id
                        description
                    }
                    operationId
                    tax {
                        id
                        hash
                    }
                    taxId
                    cashout
                    status {
                        id
                        description
                    }
                    statusId
                    isSend
                    isStepSend
                    lastConfirmations
                    deleted
                    error
                    tries
                    createdAt
                    updatedAt
                    externalCode
                    type {
                        id
                        title
                    }
                    typeId
                    withdrawId
                    currency {
                        id
                        name
                    }
                    currencyId
                    nftId
                    fee
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<CustodyBlockchainTransaction>`
        mutation CustodyBlockchainTransactionSave(
            $id: String,
                        $hash: String,
                        $confirmations: Int,
                        $blockNumber: Int,
                        $vout: Int,
                        $wallet: CustodyWalletCreateInputs,
                    $walletId: String,
                        $address: String,
                        $amount: String,
                        $dataSign: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $operation: BlockchainTransactionOperationCreateInputs,
                    $operationId: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $cashout: Boolean,
                        $status: BlockchainTransactionStatusCreateInputs,
                    $statusId: String,
                        $isSend: Boolean,
                        $isStepSend: Boolean,
                        $lastConfirmations: Int,
                        $error: String,
                        $tries: Int,
                        $externalCode: String,
                        $type: CustodyTransactionTypeCreateInputs,
                    $typeId: String,
                        $withdrawId: String,
                        $currency: CustodyCurrenciesCreateInputs,
                    $currencyId: String,
                        $nftId: String,
                        $fee: String,
                        
        )
        {
            custodyBlockchainTransactionCreateCms(data: {
                id: $id,
                    hash: $hash,
                    confirmations: $confirmations,
                    blockNumber: $blockNumber,
                    vout: $vout,
                    wallet: $wallet,
                    walletId: $walletId,
                    address: $address,
                    amount: $amount,
                    dataSign: $dataSign,
                    company: $company,
                    companyId: $companyId,
                    operation: $operation,
                    operationId: $operationId,
                    tax: $tax,
                    taxId: $taxId,
                    cashout: $cashout,
                    status: $status,
                    statusId: $statusId,
                    isSend: $isSend,
                    isStepSend: $isStepSend,
                    lastConfirmations: $lastConfirmations,
                    error: $error,
                    tries: $tries,
                    externalCode: $externalCode,
                    type: $type,
                    typeId: $typeId,
                    withdrawId: $withdrawId,
                    currency: $currency,
                    currencyId: $currencyId,
                    nftId: $nftId,
                    fee: $fee,
                    
            }) {
                id
                    hash
                    confirmations
                    blockNumber
                    vout
                    wallet {
                        id
                        address
                    }
                    walletId
                    address
                    amount
                    dataSign
                    company {
                        id
                        companyName
                    }
                    companyId
                    operation {
                        id
                        description
                    }
                    operationId
                    tax {
                        id
                        hash
                    }
                    taxId
                    cashout
                    status {
                        id
                        description
                    }
                    statusId
                    isSend
                    isStepSend
                    lastConfirmations
                    deleted
                    error
                    tries
                    createdAt
                    updatedAt
                    externalCode
                    type {
                        id
                        title
                    }
                    typeId
                    withdrawId
                    currency {
                        id
                        name
                    }
                    currencyId
                    nftId
                    fee
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<CustodyBlockchainTransaction>`
        mutation CustodyBlockchainTransactionSave(
            $id: String,
                        $hash: String,
                        $confirmations: Int,
                        $blockNumber: Int,
                        $vout: Int,
                        $wallet: CustodyWalletCreateInputs,
                    $walletId: String,
                        $address: String,
                        $amount: String,
                        $dataSign: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $operation: BlockchainTransactionOperationCreateInputs,
                    $operationId: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $cashout: Boolean,
                        $status: BlockchainTransactionStatusCreateInputs,
                    $statusId: String,
                        $isSend: Boolean,
                        $isStepSend: Boolean,
                        $lastConfirmations: Int,
                        $error: String,
                        $tries: Int,
                        $externalCode: String,
                        $type: CustodyTransactionTypeCreateInputs,
                    $typeId: String,
                        $withdrawId: String,
                        $currency: CustodyCurrenciesCreateInputs,
                    $currencyId: String,
                        $nftId: String,
                        $fee: String,
                        
        )
        {
            custodyBlockchainTransactionUpdateCms(data: {
                id: $id,
                    hash: $hash,
                    confirmations: $confirmations,
                    blockNumber: $blockNumber,
                    vout: $vout,
                    wallet: $wallet,
                    walletId: $walletId,
                    address: $address,
                    amount: $amount,
                    dataSign: $dataSign,
                    company: $company,
                    companyId: $companyId,
                    operation: $operation,
                    operationId: $operationId,
                    tax: $tax,
                    taxId: $taxId,
                    cashout: $cashout,
                    status: $status,
                    statusId: $statusId,
                    isSend: $isSend,
                    isStepSend: $isStepSend,
                    lastConfirmations: $lastConfirmations,
                    error: $error,
                    tries: $tries,
                    externalCode: $externalCode,
                    type: $type,
                    typeId: $typeId,
                    withdrawId: $withdrawId,
                    currency: $currency,
                    currencyId: $currencyId,
                    nftId: $nftId,
                    fee: $fee,
                    
            }) {
                id
                    hash
                    confirmations
                    blockNumber
                    vout
                    wallet {
                        id
                        address
                    }
                    walletId
                    address
                    amount
                    dataSign
                    company {
                        id
                        companyName
                    }
                    companyId
                    operation {
                        id
                        description
                    }
                    operationId
                    tax {
                        id
                        hash
                    }
                    taxId
                    cashout
                    status {
                        id
                        description
                    }
                    statusId
                    isSend
                    isStepSend
                    lastConfirmations
                    deleted
                    error
                    tries
                    createdAt
                    updatedAt
                    externalCode
                    type {
                        id
                        title
                    }
                    typeId
                    withdrawId
                    currency {
                        id
                        name
                    }
                    currencyId
                    nftId
                    fee
                    
            }
        }
        `;

        
                    const CUSTODYWALLET_QUERY = `
                    query custodyWalletListCms($search: Search) {
                        custodyWalletListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    address
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const COMPANY_QUERY = `
                    query companyListCms($search: Search) {
                        companyListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyName
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTRANSACTIONOPERATION_QUERY = `
                    query blockchainTransactionOperationListCms($search: Search) {
                        blockchainTransactionOperationListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTAX_QUERY = `
                    query blockchainTaxListCms($search: Search) {
                        blockchainTaxListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    hash
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTRANSACTIONSTATUS_QUERY = `
                    query blockchainTransactionStatusListCms($search: Search) {
                        blockchainTransactionStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const CUSTODYTRANSACTIONTYPE_QUERY = `
                    query custodyTransactionTypeListCms($search: Search) {
                        custodyTransactionTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const CUSTODYCURRENCIES_QUERY = `
                    query custodyCurrenciesListCms($search: Search) {
                        custodyCurrenciesListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const CustodyBlockchainTransactionDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ custodyBlockchainTransactionCms: CustodyBlockchainTransaction }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.custodyBlockchainTransactionCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("CustodyBlockchainTransaction has been saved");
                    const model = entity?.data?.custodyBlockchainTransactionUpdateCms || entity?.data?.custodyBlockchainTransactionCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/custodyBlockchainTransaction-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('CustodyBlockchainTransaction')} breadcrumbItems={[
                                { title: t("CustodyBlockchainTransaction"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('CustodyBlockchainTransaction')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('CustodyBlockchainTransaction')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Hash")} name="hash"
                            rules={[{ required: true, message: 'Please input the ' + t("Hash") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Confirmations")} name="confirmations"
                            rules={[{ required: true, message: 'Please input the ' + t("Confirmations") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("BlockNumber")} name="blockNumber"
                            rules={[{ required: true, message: 'Please input the ' + t("BlockNumber") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Vout")} name="vout"
                            rules={[{ required: true, message: 'Please input the ' + t("Vout") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "walletId") && <Form.Item label={<> {t('Wallet')}</>}>
                                <Form.Item noStyle id="wallet_id" name="walletId"
                                rules={[{ required: (false && !(dataSources.dependencies?.wallet?.address || databaseEntity?.wallet?.address)), message: 'Please input the CustodyWallet!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                value={ dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.walletId = newValue.key;
                                            dataSources.dependencies["wallet"] = {
                                                id: newValue.key,
                                                address: newValue.value
                                            };
                                            if(dataSources.dependencies["wallet"]) {
                                                delete dataSources.dependencies["wallet"].__typename;
                                            }                                            
                                            setSelects({ ...selects, walletId: newValue.key });
                                            setUncheck({ ...uncheck, walletId: false });
                                        }
                                        else if (databaseEntity?.wallet) {
                                            dataSources.dependencies.walletId = undefined;
                                            dataSources.dependencies["wallet"] = {
                                                id: undefined,
                                                address: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["wallet"] = {
                                            id: undefined,
                                            address: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.walletId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyWallet", query: CUSTODYWALLET_QUERY, idField: "id", textField: "address" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyWallet", query: CUSTODYWALLET_QUERY, idField: "id", textField: "address" });
                                    }}>
                                    {(dataSources["CustodyWallet"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.address}>
                                            {item.address}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyWalletwalletIdEditVisible: !modals.CustodyWalletwalletIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletwalletIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletwalletIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyWallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyWalletwalletIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    walletId: entity?.id,
                                                    wallet: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyWalletAddVisible: !modals.CustodyWalletAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyWallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyWalletAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        walletId: entity?.id,
                                                        wallet: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id) && !uncheck['walletId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, walletId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            walletId: "",
                                            wallet: {
                                                address: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Address")} name="address"
                            rules={[{ required: false, message: 'Please input the ' + t("Address") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Amount")} name="amount"
                            rules={[{ required: true, message: 'Please input the ' + t("Amount") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("DataSign")} name="dataSign"
                            rules={[{ required: false, message: 'Please input the ' + t("DataSign") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "companyId") && <Form.Item label={<> {t('Company')}</>}>
                                <Form.Item noStyle id="company_id" name="companyId"
                                rules={[{ required: (false && !(dataSources.dependencies?.company?.companyName || databaseEntity?.company?.companyName)), message: 'Please input the Company!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                value={ dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyId = newValue.key;
                                            dataSources.dependencies["company"] = {
                                                id: newValue.key,
                                                companyName: newValue.value
                                            };
                                            if(dataSources.dependencies["company"]) {
                                                delete dataSources.dependencies["company"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyId: newValue.key });
                                            setUncheck({ ...uncheck, companyId: false });
                                        }
                                        else if (databaseEntity?.company) {
                                            dataSources.dependencies.companyId = undefined;
                                            dataSources.dependencies["company"] = {
                                                id: undefined,
                                                companyName: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["company"] = {
                                            id: undefined,
                                            companyName: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}>
                                    {(dataSources["Company"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyName}>
                                            {item.companyName}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CompanycompanyIdEditVisible: !modals.CompanycompanyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanycompanyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CompanycompanyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.company?.id || databaseEntity?.company?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CompanycompanyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyId: entity?.id,
                                                    company: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CompanyAddVisible: !modals.CompanyAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanyAddVisible"]}
                                    onHide={() => setModals({ ...modals, CompanyAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CompanyAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyId: entity?.id,
                                                        company: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.company?.id || databaseEntity?.company?.id) && !uncheck['companyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyId: "",
                                            company: {
                                                companyName: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "operationId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Operation')}</>}>
                                <Form.Item noStyle id="operation_id" name="operationId"
                                rules={[{ required: (true && !(dataSources.dependencies?.operation?.description || databaseEntity?.operation?.description)), message: 'Please input the BlockchainTransactionOperation!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.operation ? dataSources.dependencies?.operation?.description : databaseEntity?.operation?.description}
                                value={ dataSources.dependencies?.operation ? dataSources.dependencies?.operation?.description : databaseEntity?.operation?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.operationId = newValue.key;
                                            dataSources.dependencies["operation"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["operation"]) {
                                                delete dataSources.dependencies["operation"].__typename;
                                            }                                            
                                            setSelects({ ...selects, operationId: newValue.key });
                                            setUncheck({ ...uncheck, operationId: false });
                                        }
                                        else if (databaseEntity?.operation) {
                                            dataSources.dependencies.operationId = undefined;
                                            dataSources.dependencies["operation"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["operation"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.operationId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTransactionOperation", query: BLOCKCHAINTRANSACTIONOPERATION_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTransactionOperation", query: BLOCKCHAINTRANSACTIONOPERATION_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["BlockchainTransactionOperation"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTransactionOperationoperationIdEditVisible: !modals.BlockchainTransactionOperationoperationIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTransactionOperationoperationIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTransactionOperationoperationIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTransactionOperation
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTransactionOperationDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.operation?.id || databaseEntity?.operation?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTransactionOperationoperationIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    operationId: entity?.id,
                                                    operation: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTransactionOperationAddVisible: !modals.BlockchainTransactionOperationAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTransactionOperationAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTransactionOperationAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTransactionOperation
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTransactionOperationDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTransactionOperationAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        operationId: entity?.id,
                                                        operation: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.operation?.id || databaseEntity?.operation?.id) && !uncheck['operationId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, operationId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            operationId: "",
                                            operation: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "taxId") && <Form.Item label={<> {t('Tax')}</>}>
                                <Form.Item noStyle id="tax_id" name="taxId"
                                rules={[{ required: (false && !(dataSources.dependencies?.tax?.hash || databaseEntity?.tax?.hash)), message: 'Please input the BlockchainTax!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                value={ dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.taxId = newValue.key;
                                            dataSources.dependencies["tax"] = {
                                                id: newValue.key,
                                                hash: newValue.value
                                            };
                                            if(dataSources.dependencies["tax"]) {
                                                delete dataSources.dependencies["tax"].__typename;
                                            }                                            
                                            setSelects({ ...selects, taxId: newValue.key });
                                            setUncheck({ ...uncheck, taxId: false });
                                        }
                                        else if (databaseEntity?.tax) {
                                            dataSources.dependencies.taxId = undefined;
                                            dataSources.dependencies["tax"] = {
                                                id: undefined,
                                                hash: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["tax"] = {
                                            id: undefined,
                                            hash: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.taxId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}>
                                    {(dataSources["BlockchainTax"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.hash}>
                                            {item.hash}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxtaxIdEditVisible: !modals.BlockchainTaxtaxIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxtaxIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    taxId: entity?.id,
                                                    tax: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxAddVisible: !modals.BlockchainTaxAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTaxAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        taxId: entity?.id,
                                                        tax: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id) && !uncheck['taxId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, taxId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            taxId: "",
                                            tax: {
                                                hash: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Cashout")} name="cashout" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "statusId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Status')}</>}>
                                <Form.Item noStyle id="status_id" name="statusId"
                                rules={[{ required: (true && !(dataSources.dependencies?.status?.description || databaseEntity?.status?.description)), message: 'Please input the BlockchainTransactionStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                value={ dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.statusId = newValue.key;
                                            dataSources.dependencies["status"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["status"]) {
                                                delete dataSources.dependencies["status"].__typename;
                                            }                                            
                                            setSelects({ ...selects, statusId: newValue.key });
                                            setUncheck({ ...uncheck, statusId: false });
                                        }
                                        else if (databaseEntity?.status) {
                                            dataSources.dependencies.statusId = undefined;
                                            dataSources.dependencies["status"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["status"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.statusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTransactionStatus", query: BLOCKCHAINTRANSACTIONSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTransactionStatus", query: BLOCKCHAINTRANSACTIONSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["BlockchainTransactionStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTransactionStatusstatusIdEditVisible: !modals.BlockchainTransactionStatusstatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTransactionStatusstatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTransactionStatusstatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTransactionStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTransactionStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.status?.id || databaseEntity?.status?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTransactionStatusstatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    statusId: entity?.id,
                                                    status: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTransactionStatusAddVisible: !modals.BlockchainTransactionStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTransactionStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTransactionStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTransactionStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTransactionStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTransactionStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        statusId: entity?.id,
                                                        status: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.status?.id || databaseEntity?.status?.id) && !uncheck['statusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, statusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            statusId: "",
                                            status: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("IsSend")} name="isSend" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("IsStepSend")} name="isStepSend" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("LastConfirmations")} name="lastConfirmations"
                            rules={[{ required: true, message: 'Please input the ' + t("LastConfirmations") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Error")} name="error"
                            rules={[{ required: false, message: 'Please input the ' + t("Error") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Tries")} name="tries"
                            rules={[{ required: true, message: 'Please input the ' + t("Tries") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("ExternalCode")} name="externalCode"
                            rules={[{ required: false, message: 'Please input the ' + t("ExternalCode") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "typeId") && <Form.Item label={<> {t('Type')}</>}>
                                <Form.Item noStyle id="type_id" name="typeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.type?.title || databaseEntity?.type?.title)), message: 'Please input the CustodyTransactionType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.type ? dataSources.dependencies?.type?.title : databaseEntity?.type?.title}
                                value={ dataSources.dependencies?.type ? dataSources.dependencies?.type?.title : databaseEntity?.type?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.typeId = newValue.key;
                                            dataSources.dependencies["type"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["type"]) {
                                                delete dataSources.dependencies["type"].__typename;
                                            }                                            
                                            setSelects({ ...selects, typeId: newValue.key });
                                            setUncheck({ ...uncheck, typeId: false });
                                        }
                                        else if (databaseEntity?.type) {
                                            dataSources.dependencies.typeId = undefined;
                                            dataSources.dependencies["type"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["type"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.typeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyTransactionType", query: CUSTODYTRANSACTIONTYPE_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyTransactionType", query: CUSTODYTRANSACTIONTYPE_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["CustodyTransactionType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyTransactionTypetypeIdEditVisible: !modals.CustodyTransactionTypetypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyTransactionTypetypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyTransactionTypetypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyTransactionType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyTransactionTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.type?.id || databaseEntity?.type?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyTransactionTypetypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    typeId: entity?.id,
                                                    type: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyTransactionTypeAddVisible: !modals.CustodyTransactionTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyTransactionTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyTransactionTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyTransactionType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyTransactionTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyTransactionTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        typeId: entity?.id,
                                                        type: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.type?.id || databaseEntity?.type?.id) && !uncheck['typeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, typeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            typeId: "",
                                            type: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("WithdrawId")} name="withdrawId"
                            rules={[{ required: false, message: 'Please input the ' + t("WithdrawId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "currencyId") && <Form.Item label={<> {t('Currency')}</>}>
                                <Form.Item noStyle id="currency_id" name="currencyId"
                                rules={[{ required: (false && !(dataSources.dependencies?.currency?.name || databaseEntity?.currency?.name)), message: 'Please input the CustodyCurrencies!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.currency ? dataSources.dependencies?.currency?.name : databaseEntity?.currency?.name}
                                value={ dataSources.dependencies?.currency ? dataSources.dependencies?.currency?.name : databaseEntity?.currency?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.currencyId = newValue.key;
                                            dataSources.dependencies["currency"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["currency"]) {
                                                delete dataSources.dependencies["currency"].__typename;
                                            }                                            
                                            setSelects({ ...selects, currencyId: newValue.key });
                                            setUncheck({ ...uncheck, currencyId: false });
                                        }
                                        else if (databaseEntity?.currency) {
                                            dataSources.dependencies.currencyId = undefined;
                                            dataSources.dependencies["currency"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["currency"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.currencyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyCurrencies", query: CUSTODYCURRENCIES_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyCurrencies", query: CUSTODYCURRENCIES_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["CustodyCurrencies"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyCurrenciescurrencyIdEditVisible: !modals.CustodyCurrenciescurrencyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyCurrenciescurrencyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyCurrenciescurrencyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyCurrencies
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyCurrenciesDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.currency?.id || databaseEntity?.currency?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyCurrenciescurrencyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    currencyId: entity?.id,
                                                    currency: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyCurrenciesAddVisible: !modals.CustodyCurrenciesAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyCurrenciesAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyCurrenciesAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyCurrencies
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyCurrenciesDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyCurrenciesAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        currencyId: entity?.id,
                                                        currency: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.currency?.id || databaseEntity?.currency?.id) && !uncheck['currencyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, currencyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            currencyId: "",
                                            currency: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("NftId")} name="nftId"
                            rules={[{ required: false, message: 'Please input the ' + t("NftId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Fee")} name="fee"
                            rules={[{ required: true, message: 'Please input the ' + t("Fee") + '!' }]}>
                                <Input />
                            </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (CustodyBlockchainTransactionDetails);
  