//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { OfferInfo } from '../../../models/prisma/offerInfo.model';
        import { GET_LIST, startedFilter } from './offerInfo.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import OfferKeyDetails from '../offerKey/offerKey.details';
import PublicFileDetails from '../publicFile/publicFile.details';
import OfferDetails from '../offer/offer.details';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            offerInfoCms: OfferInfo
        }>`
        query OfferInfoCms($id: String)
        {
            offerInfoCms(id: $id)
          {
            id
                    key {
                        id
                        description
                    }
                    keyId
                    title
                    fullDescription
                    imgPubFileUrl
                    imgPubFileId
                    imgPubFile {
                        id
                        url
                    }
                    pubFileUrl
                    pubFileId
                    pubFile {
                        id
                        url
                    }
                    order
                    slug
                    link
                    tag
                    offer {
                        id
                        title
                    }
                    offerId
                    principal
                    deleted
                    createdAt
                    updatedAt
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<OfferInfo>`
        mutation OfferInfoSave(
            $id: String,
                        $key: OfferKeyCreateInputs,
                    $keyId: String,
                        $title: String,
                        $fullDescription: String,
                        $imgPubFileUrl: String,
                        $imgPubFileId: String,
                        $imgPubFile: PublicFileCreateInputs,
                    $pubFileUrl: String,
                        $pubFileId: String,
                        $pubFile: PublicFileCreateInputs,
                    $order: Int,
                        $slug: String,
                        $link: String,
                        $tag: String,
                        $offer: OfferCreateInputs,
                    $offerId: String,
                        $principal: Boolean,
                        
        )
        {
            offerInfoCreateCms(data: {
                id: $id,
                    key: $key,
                    keyId: $keyId,
                    title: $title,
                    fullDescription: $fullDescription,
                    imgPubFileUrl: $imgPubFileUrl,
                    imgPubFileId: $imgPubFileId,
                    imgPubFile: $imgPubFile,
                    pubFileUrl: $pubFileUrl,
                    pubFileId: $pubFileId,
                    pubFile: $pubFile,
                    order: $order,
                    slug: $slug,
                    link: $link,
                    tag: $tag,
                    offer: $offer,
                    offerId: $offerId,
                    principal: $principal,
                    
            }) {
                id
                    key {
                        id
                        description
                    }
                    keyId
                    title
                    fullDescription
                    imgPubFileUrl
                    imgPubFileId
                    imgPubFile {
                        id
                        url
                    }
                    pubFileUrl
                    pubFileId
                    pubFile {
                        id
                        url
                    }
                    order
                    slug
                    link
                    tag
                    offer {
                        id
                        title
                    }
                    offerId
                    principal
                    deleted
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<OfferInfo>`
        mutation OfferInfoSave(
            $id: String,
                        $key: OfferKeyCreateInputs,
                    $keyId: String,
                        $title: String,
                        $fullDescription: String,
                        $imgPubFileUrl: String,
                        $imgPubFileId: String,
                        $imgPubFile: PublicFileCreateInputs,
                    $pubFileUrl: String,
                        $pubFileId: String,
                        $pubFile: PublicFileCreateInputs,
                    $order: Int,
                        $slug: String,
                        $link: String,
                        $tag: String,
                        $offer: OfferCreateInputs,
                    $offerId: String,
                        $principal: Boolean,
                        
        )
        {
            offerInfoUpdateCms(data: {
                id: $id,
                    key: $key,
                    keyId: $keyId,
                    title: $title,
                    fullDescription: $fullDescription,
                    imgPubFileUrl: $imgPubFileUrl,
                    imgPubFileId: $imgPubFileId,
                    imgPubFile: $imgPubFile,
                    pubFileUrl: $pubFileUrl,
                    pubFileId: $pubFileId,
                    pubFile: $pubFile,
                    order: $order,
                    slug: $slug,
                    link: $link,
                    tag: $tag,
                    offer: $offer,
                    offerId: $offerId,
                    principal: $principal,
                    
            }) {
                id
                    key {
                        id
                        description
                    }
                    keyId
                    title
                    fullDescription
                    imgPubFileUrl
                    imgPubFileId
                    imgPubFile {
                        id
                        url
                    }
                    pubFileUrl
                    pubFileId
                    pubFile {
                        id
                        url
                    }
                    order
                    slug
                    link
                    tag
                    offer {
                        id
                        title
                    }
                    offerId
                    principal
                    deleted
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        
                    const OFFERKEY_QUERY = `
                    query offerKeyListCms($search: Search) {
                        offerKeyListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const PUBLICFILE_QUERY = `
                    query publicFileListCms($search: Search) {
                        publicFileListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    url
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const OFFER_QUERY = `
                    query offerListCms($search: Search) {
                        offerListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const OfferInfoDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ offerInfoCms: OfferInfo }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.offerInfoCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("OfferInfo has been saved");
                    const model = entity?.data?.offerInfoUpdateCms || entity?.data?.offerInfoCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/offerInfo-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('OfferInfo')} breadcrumbItems={[
                                { title: t("OfferInfo"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('OfferInfo')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('OfferInfo')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        { (!props.relation || props.relation.field != "keyId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Key')}</>}>
                                <Form.Item noStyle id="key_id" name="keyId"
                                rules={[{ required: (true && !(dataSources.dependencies?.key?.description || databaseEntity?.key?.description)), message: 'Please input the OfferKey!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.key ? dataSources.dependencies?.key?.description : databaseEntity?.key?.description}
                                value={ dataSources.dependencies?.key ? dataSources.dependencies?.key?.description : databaseEntity?.key?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.keyId = newValue.key;
                                            dataSources.dependencies["key"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["key"]) {
                                                delete dataSources.dependencies["key"].__typename;
                                            }                                            
                                            setSelects({ ...selects, keyId: newValue.key });
                                            setUncheck({ ...uncheck, keyId: false });
                                        }
                                        else if (databaseEntity?.key) {
                                            dataSources.dependencies.keyId = undefined;
                                            dataSources.dependencies["key"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["key"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.keyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "OfferKey", query: OFFERKEY_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "OfferKey", query: OFFERKEY_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["OfferKey"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OfferKeykeyIdEditVisible: !modals.OfferKeykeyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferKeykeyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OfferKeykeyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        OfferKey
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferKeyDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.key?.id || databaseEntity?.key?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OfferKeykeyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    keyId: entity?.id,
                                                    key: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OfferKeyAddVisible: !modals.OfferKeyAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferKeyAddVisible"]}
                                    onHide={() => setModals({ ...modals, OfferKeyAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            OfferKey
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferKeyDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OfferKeyAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        keyId: entity?.id,
                                                        key: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.key?.id || databaseEntity?.key?.id) && !uncheck['keyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, keyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            keyId: "",
                                            key: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Title")} name="title"
                            rules={[{ required: true, message: 'Please input the ' + t("Title") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("FullDescription")}
                    rules={[{ required: false, message: 'Please input the ' + t("fullDescription") + '!' }]}>
                    <ReactQuill modules={{
                      toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                      ],
                      clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                      }
                  }} theme="snow" value={descriptions['fullDescription'] || databaseEntity?.fullDescription ||''} onChange={(value) => {
                      setDescriptions({ ...descriptions, ['fullDescription']: value });
                  }} />
                </Form.Item><Form.Item label={t("ImgPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("imgPubFileId") + '!' }]}>
                    {!!databaseEntity?.imgPubFileId && !dropUpload['imgPubFileId'] && !files['imgPubFileId'] &&
                        <>
                            { !!download[databaseEntity?.imgPubFileId]  && <>
                        { !download[databaseEntity?.imgPubFileId + 'Error'] && <> 
                        
                            <a title="imgPubFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.imgPubFileId, "public") 
                            }}>
                            
                            <img alt="imgPubFileId" 
                            onError={() => { 
                                download[databaseEntity?.imgPubFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.imgPubFileId + 'Error']: true });
                            }} src={download[databaseEntity?.imgPubFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.imgPubFileId, "public") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, imgPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.imgPubFileId || dropUpload['imgPubFileId'] || files['imgPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, imgPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("PubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("pubFileId") + '!' }]}>
                    {!!databaseEntity?.pubFileId && !dropUpload['pubFileId'] && !files['pubFileId'] &&
                        <>
                            <a title="pubFileId" target="_blank"  onClick={() => { StorageService.forceDownload(databaseEntity?.pubFileId, "private") }}>{databaseEntity?.pubFileId}</a>
                            
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, pubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.pubFileId || dropUpload['pubFileId'] || files['pubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, pubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("Order")} name="order"
                            rules={[{ required: true, message: 'Please input the ' + t("Order") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Slug")} name="slug"
                            rules={[{ required: false, message: 'Please input the ' + t("Slug") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Link")} name="link"
                            rules={[{ required: false, message: 'Please input the ' + t("Link") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Tag")} name="tag"
                            rules={[{ required: false, message: 'Please input the ' + t("Tag") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "offerId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Offer')}</>}>
                                <Form.Item noStyle id="offer_id" name="offerId"
                                rules={[{ required: (true && !(dataSources.dependencies?.offer?.title || databaseEntity?.offer?.title)), message: 'Please input the Offer!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.offer ? dataSources.dependencies?.offer?.title : databaseEntity?.offer?.title}
                                value={ dataSources.dependencies?.offer ? dataSources.dependencies?.offer?.title : databaseEntity?.offer?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.offerId = newValue.key;
                                            dataSources.dependencies["offer"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["offer"]) {
                                                delete dataSources.dependencies["offer"].__typename;
                                            }                                            
                                            setSelects({ ...selects, offerId: newValue.key });
                                            setUncheck({ ...uncheck, offerId: false });
                                        }
                                        else if (databaseEntity?.offer) {
                                            dataSources.dependencies.offerId = undefined;
                                            dataSources.dependencies["offer"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["offer"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.offerId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Offer", query: OFFER_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Offer", query: OFFER_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["Offer"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OfferofferIdEditVisible: !modals.OfferofferIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferofferIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OfferofferIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Offer
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.offer?.id || databaseEntity?.offer?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OfferofferIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    offerId: entity?.id,
                                                    offer: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OfferAddVisible: !modals.OfferAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferAddVisible"]}
                                    onHide={() => setModals({ ...modals, OfferAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Offer
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OfferAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        offerId: entity?.id,
                                                        offer: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.offer?.id || databaseEntity?.offer?.id) && !uncheck['offerId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, offerId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            offerId: "",
                                            offer: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Principal")} name="principal" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (OfferInfoDetails);
  