import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AbilityContext } from "../hooks/Ability";

const SideBarCustom = (props: any) => {
  const ability = useContext(AbilityContext);

  return (
    <>

      {ability.can('read', 'Company') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-exchange-dollar-line"></i>
            <span className="ml-1">{props.t("Company")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/company-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'Company') && <li>
              <Link to="/company-details">
                <i className=" ri-add-circle-line"></i>
                {props.t("Add")}
              </Link>
            </li>}
            {ability.can('read', 'CompanyKyc') && <li>
              <Link to="/companyKyc-list">
                <i className="ri-list-check"></i>
                {props.t("Listing KYC")}
              </Link>
            </li>}
            {ability.can('read', 'CompanyDeposit') && <li>
              <Link to="/companyDeposit-list">
                <i className="ri-list-check"></i>
                {props.t("Listing Deposits")}
              </Link>
            </li>}
            {ability.can('read', 'ApiKeyWallet') && <li>
              <Link to="/apiKeyWallet-list">
                <i className="ri-list-check"></i>
                {props.t("Listing Api Key Wallt")}
              </Link>
            </li>}
          </ul>
        </li>}
      {ability.can('read', 'BlockchainToken') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-exchange-dollar-line"></i>
            <span className="ml-1">{props.t("Tokens")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/blockchainToken-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'BlockchainToken') && <li>
              <Link to="/blockchainToken-details">
                <i className=" ri-add-circle-line"></i>
                {props.t("Add")}
              </Link>
            </li>}
          </ul>
        </li>}
      {ability.can('read', 'Offer') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-price-tag-2-line"></i>
            <span className="ml-1">{props.t("Ofertas")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/offer-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'Offer') &&
              <li>
                <Link to="/offer-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
          </ul>
        </li>}
      {ability.can('read', 'Bank') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-bank-line"></i>
            <span className="ml-1">{props.t("Bancos")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/bank-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'Bank') &&
              <li>
                <Link to="/bank-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
          </ul>
        </li>}
      {ability.can('read', 'BankReconciliation') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-exchange-dollar-line"></i>
            <span className="ml-1">{props.t("BankReconciliation")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/bankReconciliation-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'BankReconciliation') &&
              <li>
                <Link to="/bankReconciliation-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
          </ul>
        </li>}
      {ability.can('read', 'BlockchainTax') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-user-3-line"></i>
            <span className="ml-1">{props.t("Taxas")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/blockchainTax-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'BlockchainTax') &&
              <li>
                <Link to="/blockchainTax-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
          </ul>
        </li>}
      {ability.can('read', 'ApprovalManagerUser') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-user-3-line"></i>
            <span className="ml-1">{props.t("Aprovadores")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/approvalManagerUser-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'ApprovalManagerUser') &&
              <li>
                <Link to="/approvalManagerUser-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
          </ul>
        </li>}
      {ability.can('read', 'Account') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-user-3-line"></i>
            <span className="ml-1">{props.t("Users")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/account-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'User') &&
              <li>
                <Link to="/account-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
            {ability.can('read', 'AccountKyc') && <li>
              <Link to="/accountKyc-list">
                <i className="ri-list-check"></i>
                {props.t("Listing KYC")}
              </Link>
            </li>}
          </ul>
        </li>}
      {ability.can('read', 'Agent') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-user-3-line"></i>
            <span className="ml-1">{props.t("Agent")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/agent-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'Agent') &&
              <li>
                <Link to="/agent-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
          </ul>
        </li>}
      {ability.can('read', 'Investor') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-user-3-line"></i>
            <span className="ml-1">{props.t("Investors")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/investor-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'Investor') &&
              <li>
                <Link to="/investor-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
            {ability.can('read', 'InvestorDeposit') && <li>
              <Link to="/investorDeposit-list">
                <i className="ri-list-check"></i>
                {props.t("Listing Deposits")}
              </Link>
            </li>}
          </ul>
        </li>}

      {ability.can('read', 'LiqiFile') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-user-3-line"></i>
            <span className="ml-1">{props.t("Public Files")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/liqiFile-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'LiqiFile') &&
              <li>
                <Link to="/liqiFile-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
          </ul>
        </li>}

      {ability.can('read', 'Faq') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-user-3-line"></i>
            <span className="ml-1">{props.t("Faq")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/faq-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'Faq') &&
              <li>
                <Link to="/faq-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
            {ability.can('read', 'FaqItem') &&
              <li>
                <Link to="/faqItem-list">
                  <i className="ri-list-check"></i>
                  {props.t("Listing Faq Item")}
                </Link>
              </li>}
            {ability.can('create', 'FaqItem') &&
              <li>
                <Link to="/faqItem-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add Faq Item")}
                </Link>
              </li>}
          </ul>
        </li>}

      {ability.can('read', 'AgentRole') &&
        <li>
          <Link to="/#" className="has-arrow waves-effect">
            <i className="ri-user-3-line"></i>
            <span className="ml-1">{props.t("Funções")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/agentRole-list">
                <i className="ri-list-check"></i>
                {props.t("Listing")}
              </Link>
            </li>
            {ability.can('create', 'AgentRole') &&
              <li>
                <Link to="/agentRole-details">
                  <i className=" ri-add-circle-line"></i>
                  {props.t("Add")}
                </Link>
              </li>}
          </ul>
        </li>}


    </>
  );
};

export default SideBarCustom;
