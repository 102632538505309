//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { OracleRegister } from '../../../models/prisma/oracleRegister.model';
        import { GET_LIST, startedFilter } from './oracleRegister.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import OracleDataDetails from '../oracleData/oracleData.details';
import OracleAddressDetails from '../oracleAddress/oracleAddress.details';
import BlockchainTaxDetails from '../blockchainTax/blockchainTax.details';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            oracleRegisterCms: OracleRegister
        }>`
        query OracleRegisterCms($id: String)
        {
            oracleRegisterCms(id: $id)
          {
            id
                    oracleData {
                        id
                        oracleIndexTypeId
                    }
                    oracleDataId
                    oracleAddress {
                        id
                        address
                    }
                    oracleAddressId
                    tax {
                        id
                        hash
                    }
                    taxId
                    isRequirdApproved
                    isSendNetwork
                    deleted
                    createdAt
                    updatedAt
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<OracleRegister>`
        mutation OracleRegisterSave(
            $id: String,
                        $oracleData: OracleDataCreateInputs,
                    $oracleDataId: String,
                        $oracleAddress: OracleAddressCreateInputs,
                    $oracleAddressId: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $isRequirdApproved: Boolean,
                        $isSendNetwork: Boolean,
                        
        )
        {
            oracleRegisterCreateCms(data: {
                id: $id,
                    oracleData: $oracleData,
                    oracleDataId: $oracleDataId,
                    oracleAddress: $oracleAddress,
                    oracleAddressId: $oracleAddressId,
                    tax: $tax,
                    taxId: $taxId,
                    isRequirdApproved: $isRequirdApproved,
                    isSendNetwork: $isSendNetwork,
                    
            }) {
                id
                    oracleData {
                        id
                        oracleIndexTypeId
                    }
                    oracleDataId
                    oracleAddress {
                        id
                        address
                    }
                    oracleAddressId
                    tax {
                        id
                        hash
                    }
                    taxId
                    isRequirdApproved
                    isSendNetwork
                    deleted
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<OracleRegister>`
        mutation OracleRegisterSave(
            $id: String,
                        $oracleData: OracleDataCreateInputs,
                    $oracleDataId: String,
                        $oracleAddress: OracleAddressCreateInputs,
                    $oracleAddressId: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $isRequirdApproved: Boolean,
                        $isSendNetwork: Boolean,
                        
        )
        {
            oracleRegisterUpdateCms(data: {
                id: $id,
                    oracleData: $oracleData,
                    oracleDataId: $oracleDataId,
                    oracleAddress: $oracleAddress,
                    oracleAddressId: $oracleAddressId,
                    tax: $tax,
                    taxId: $taxId,
                    isRequirdApproved: $isRequirdApproved,
                    isSendNetwork: $isSendNetwork,
                    
            }) {
                id
                    oracleData {
                        id
                        oracleIndexTypeId
                    }
                    oracleDataId
                    oracleAddress {
                        id
                        address
                    }
                    oracleAddressId
                    tax {
                        id
                        hash
                    }
                    taxId
                    isRequirdApproved
                    isSendNetwork
                    deleted
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        
                    const ORACLEDATA_QUERY = `
                    query oracleDataListCms($search: Search) {
                        oracleDataListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    oracleIndexTypeId
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const ORACLEADDRESS_QUERY = `
                    query oracleAddressListCms($search: Search) {
                        oracleAddressListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    address
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTAX_QUERY = `
                    query blockchainTaxListCms($search: Search) {
                        blockchainTaxListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    hash
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const OracleRegisterDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ oracleRegisterCms: OracleRegister }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.oracleRegisterCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("OracleRegister has been saved");
                    const model = entity?.data?.oracleRegisterUpdateCms || entity?.data?.oracleRegisterCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/oracleRegister-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('OracleRegister')} breadcrumbItems={[
                                { title: t("OracleRegister"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('OracleRegister')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('OracleRegister')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        { (!props.relation || props.relation.field != "oracleDataId") && <Form.Item label={<> {t('OracleData')}</>}>
                                <Form.Item noStyle id="oracleData_id" name="oracleDataId"
                                rules={[{ required: (false && !(dataSources.dependencies?.oracleData?.oracleIndexTypeId || databaseEntity?.oracleData?.oracleIndexTypeId)), message: 'Please input the OracleData!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.oracleData ? dataSources.dependencies?.oracleData?.oracleIndexTypeId : databaseEntity?.oracleData?.oracleIndexTypeId}
                                value={ dataSources.dependencies?.oracleData ? dataSources.dependencies?.oracleData?.oracleIndexTypeId : databaseEntity?.oracleData?.oracleIndexTypeId}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.oracleDataId = newValue.key;
                                            dataSources.dependencies["oracleData"] = {
                                                id: newValue.key,
                                                oracleIndexTypeId: newValue.value
                                            };
                                            if(dataSources.dependencies["oracleData"]) {
                                                delete dataSources.dependencies["oracleData"].__typename;
                                            }                                            
                                            setSelects({ ...selects, oracleDataId: newValue.key });
                                            setUncheck({ ...uncheck, oracleDataId: false });
                                        }
                                        else if (databaseEntity?.oracleData) {
                                            dataSources.dependencies.oracleDataId = undefined;
                                            dataSources.dependencies["oracleData"] = {
                                                id: undefined,
                                                oracleIndexTypeId: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["oracleData"] = {
                                            id: undefined,
                                            oracleIndexTypeId: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.oracleDataId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "OracleData", query: ORACLEDATA_QUERY, idField: "id", textField: "oracleIndexTypeId" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "OracleData", query: ORACLEDATA_QUERY, idField: "id", textField: "oracleIndexTypeId" });
                                    }}>
                                    {(dataSources["OracleData"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.oracleIndexTypeId}>
                                            {item.oracleIndexTypeId}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OracleDataoracleDataIdEditVisible: !modals.OracleDataoracleDataIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OracleDataoracleDataIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OracleDataoracleDataIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        OracleData
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OracleDataDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.oracleData?.id || databaseEntity?.oracleData?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OracleDataoracleDataIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    oracleDataId: entity?.id,
                                                    oracleData: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OracleDataAddVisible: !modals.OracleDataAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OracleDataAddVisible"]}
                                    onHide={() => setModals({ ...modals, OracleDataAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            OracleData
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OracleDataDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OracleDataAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        oracleDataId: entity?.id,
                                                        oracleData: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.oracleData?.id || databaseEntity?.oracleData?.id) && !uncheck['oracleDataId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, oracleDataId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            oracleDataId: "",
                                            oracleData: {
                                                oracleIndexTypeId: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "oracleAddressId") && <Form.Item label={<> {t('OracleAddress')}</>}>
                                <Form.Item noStyle id="oracleAddress_id" name="oracleAddressId"
                                rules={[{ required: (false && !(dataSources.dependencies?.oracleAddress?.address || databaseEntity?.oracleAddress?.address)), message: 'Please input the OracleAddress!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.oracleAddress ? dataSources.dependencies?.oracleAddress?.address : databaseEntity?.oracleAddress?.address}
                                value={ dataSources.dependencies?.oracleAddress ? dataSources.dependencies?.oracleAddress?.address : databaseEntity?.oracleAddress?.address}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.oracleAddressId = newValue.key;
                                            dataSources.dependencies["oracleAddress"] = {
                                                id: newValue.key,
                                                address: newValue.value
                                            };
                                            if(dataSources.dependencies["oracleAddress"]) {
                                                delete dataSources.dependencies["oracleAddress"].__typename;
                                            }                                            
                                            setSelects({ ...selects, oracleAddressId: newValue.key });
                                            setUncheck({ ...uncheck, oracleAddressId: false });
                                        }
                                        else if (databaseEntity?.oracleAddress) {
                                            dataSources.dependencies.oracleAddressId = undefined;
                                            dataSources.dependencies["oracleAddress"] = {
                                                id: undefined,
                                                address: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["oracleAddress"] = {
                                            id: undefined,
                                            address: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.oracleAddressId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "OracleAddress", query: ORACLEADDRESS_QUERY, idField: "id", textField: "address" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "OracleAddress", query: ORACLEADDRESS_QUERY, idField: "id", textField: "address" });
                                    }}>
                                    {(dataSources["OracleAddress"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.address}>
                                            {item.address}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OracleAddressoracleAddressIdEditVisible: !modals.OracleAddressoracleAddressIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OracleAddressoracleAddressIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OracleAddressoracleAddressIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        OracleAddress
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OracleAddressDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.oracleAddress?.id || databaseEntity?.oracleAddress?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OracleAddressoracleAddressIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    oracleAddressId: entity?.id,
                                                    oracleAddress: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OracleAddressAddVisible: !modals.OracleAddressAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OracleAddressAddVisible"]}
                                    onHide={() => setModals({ ...modals, OracleAddressAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            OracleAddress
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OracleAddressDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OracleAddressAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        oracleAddressId: entity?.id,
                                                        oracleAddress: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.oracleAddress?.id || databaseEntity?.oracleAddress?.id) && !uncheck['oracleAddressId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, oracleAddressId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            oracleAddressId: "",
                                            oracleAddress: {
                                                address: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "taxId") && <Form.Item label={<> {t('Tax')}</>}>
                                <Form.Item noStyle id="tax_id" name="taxId"
                                rules={[{ required: (false && !(dataSources.dependencies?.tax?.hash || databaseEntity?.tax?.hash)), message: 'Please input the BlockchainTax!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                value={ dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.taxId = newValue.key;
                                            dataSources.dependencies["tax"] = {
                                                id: newValue.key,
                                                hash: newValue.value
                                            };
                                            if(dataSources.dependencies["tax"]) {
                                                delete dataSources.dependencies["tax"].__typename;
                                            }                                            
                                            setSelects({ ...selects, taxId: newValue.key });
                                            setUncheck({ ...uncheck, taxId: false });
                                        }
                                        else if (databaseEntity?.tax) {
                                            dataSources.dependencies.taxId = undefined;
                                            dataSources.dependencies["tax"] = {
                                                id: undefined,
                                                hash: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["tax"] = {
                                            id: undefined,
                                            hash: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.taxId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}>
                                    {(dataSources["BlockchainTax"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.hash}>
                                            {item.hash}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxtaxIdEditVisible: !modals.BlockchainTaxtaxIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxtaxIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    taxId: entity?.id,
                                                    tax: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxAddVisible: !modals.BlockchainTaxAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTaxAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        taxId: entity?.id,
                                                        tax: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id) && !uncheck['taxId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, taxId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            taxId: "",
                                            tax: {
                                                hash: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("IsRequirdApproved")} name="isRequirdApproved" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("IsSendNetwork")} name="isSendNetwork" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (OracleRegisterDetails);
  