//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { BlockchainToken } from '../../../models/prisma/blockchainToken.model';
        import { GET_LIST, startedFilter } from './blockchainToken.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import TokenCategoryDetails from '../tokenCategory/tokenCategory.details';
import TokenSubCategoryDetails from '../tokenSubCategory/tokenSubCategory.details';
import BlockchainTokenTypeDetails from '../blockchainTokenType/blockchainTokenType.details';
import TypeSupplyTokenDetails from '../typeSupplyToken/typeSupplyToken.details';
import BlockchainTokenRateTypeDetails from '../blockchainTokenRateType/blockchainTokenRateType.details';
import PublicFileDetails from '../publicFile/publicFile.details';
import TokenKindDetails from '../tokenKind/tokenKind.details';
import CompanyDetails from '../company/company.details';
import WalletDetails from '../wallet/wallet.details';
import BlockchainDetails from '../blockchain/blockchain.details';
import PartnerDetails from '../partner/partner.details';
import TokenStatusDetails from '../tokenStatus/tokenStatus.details';
import TokenRemunerationTypeDetails from '../tokenRemunerationType/tokenRemunerationType.details';
import TokenSettlementTypeDetails from '../tokenSettlementType/tokenSettlementType.details';
import TokenRateTypeDetails from '../tokenRateType/tokenRateType.details';
import ApprovalManagerTokenMapList from '../approvalManagerTokenMap/approvalManagerTokenMap.list';
import TokenDeployVarsMapList from '../tokenDeployVarsMap/tokenDeployVarsMap.list';
import BlockchainTokenConstMapList from '../blockchainTokenConstMap/blockchainTokenConstMap.list';
import BlockchainTokenRuleMapList from '../blockchainTokenRuleMap/blockchainTokenRuleMap.list';
import TokenResourceMapList from '../tokenResourceMap/tokenResourceMap.list';
import TokenRemunerationScheduleList from '../tokenRemunerationSchedule/tokenRemunerationSchedule.list';
import TokenContractInfoList from '../tokenContractInfo/tokenContractInfo.list';
import TokenNewsList from '../tokenNews/tokenNews.list';
import DividendList from '../dividend/dividend.list';
import TokenInfoList from '../tokenInfo/tokenInfo.list';
import OfferList from '../offer/offer.list';
import InvestorDepositList from '../investorDeposit/investorDeposit.list';
import CompanyDepositList from '../companyDeposit/companyDeposit.list';
import WalletAssetList from '../walletAsset/walletAsset.list';
import BlockchainTransactionList from '../blockchainTransaction/blockchainTransaction.list';
import BankAccountList from '../bankAccount/bankAccount.list';
import BankTransactionList from '../bankTransaction/bankTransaction.list';
import BlockchainTokenFileList from '../blockchainTokenFile/blockchainTokenFile.list';
import TokenConstVarsList from '../tokenConstVars/tokenConstVars.list';
import StatementList from '../statement/statement.list';
import AirdropCampaignList from '../airdropCampaign/airdropCampaign.list';
import CampaignList from '../campaign/campaign.list';
import OfferInvestList from '../offerInvest/offerInvest.list';
import BlockchainTokenUserMapList from '../blockchainTokenUserMap/blockchainTokenUserMap.list';
import CompanyContactsTokenMapList from '../companyContactsTokenMap/companyContactsTokenMap.list';
import VirtualAssetMapList from '../virtualAssetMap/virtualAssetMap.list';
import DocumentSignerContractList from '../documentSignerContract/documentSignerContract.list';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            blockchainTokenCms: BlockchainToken
        }>`
        query BlockchainTokenCms($id: String)
        {
            blockchainTokenCms(id: $id)
          {
            id
                    name
                    category {
                        id
                        description
                    }
                    tokenCategoryId
                    subCategory {
                        id
                        description
                    }
                    subCategoryId
                    type {
                        id
                        description
                    }
                    typeId
                    maxSupply
                    typeSupply {
                        id
                        title
                    }
                    typeSupplyId
                    tokensEntitlementPercent
                    estimatedRevenue
                    initialPrice
                    royaltyInternal
                    royaltyIssuer
                    feeInternal
                    rateType {
                        id
                        description
                    }
                    rateTypeId
                    interestRate
                    interestAnnualRate
                    interestStart
                    interestEnd
                    campaignStart
                    campaignEnd
                    interestPayments
                    fullDescription
                    collectionName
                    collectionTitle
                    collectionDescription
                    collectionItensPath_cid
                    collectionMetadata_cid
                    isStandardCollection
                    symbol
                    decimals
                    iconImgPubFileUrl
                    iconImgPubFileId
                    iconImgPubFile {
                        id
                        url
                    }
                    dividendsTokenAddress
                    kind {
                        id
                        description
                    }
                    kindId
                    address
                    abi
                    company {
                        id
                        companyName
                    }
                    companyId
                    isLockup
                    lockup
                    lockupExpiration
                    tokenExpiration
                    istokenExpiration
                    wallet {
                        id
                        address
                    }
                    walletId
                    deleted
                    createdAt
                    updatedAt
                    network {
                        id
                        title
                    }
                    networkId
                    custody {
                        id
                        partnerName
                    }
                    custodyId
                    status {
                        id
                        description
                    }
                    statusId
                    error
                    step
                    isDocument
                    remunerationType {
                        id
                        title
                    }
                    remunerationTypeId
                    tokenSettlementType {
                        id
                        title
                    }
                    tokenSettlementTypeId
                    tokenRateType {
                        id
                        title
                    }
                    tokenRateTypeId
                    isTrashed
                    transactionHashDeploy
                    isAutoBurn
                    isPaymentToken
                    sourceCode
                    tests
                    addressWalletOwner
                    syncMonitor
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<BlockchainToken>`
        mutation BlockchainTokenSave(
            $id: String,
                        $name: String,
                        $category: TokenCategoryCreateInputs,
                    $tokenCategoryId: String,
                        $subCategory: TokenSubCategoryCreateInputs,
                    $subCategoryId: String,
                        $type: BlockchainTokenTypeCreateInputs,
                    $typeId: String,
                        $maxSupply: Float,
                        $typeSupply: TypeSupplyTokenCreateInputs,
                    $typeSupplyId: String,
                        $tokensEntitlementPercent: Float,
                        $estimatedRevenue: Float,
                        $initialPrice: Float,
                        $royaltyInternal: Float,
                        $royaltyIssuer: Float,
                        $feeInternal: Float,
                        $rateType: BlockchainTokenRateTypeCreateInputs,
                    $rateTypeId: String,
                        $interestRate: Float,
                        $interestAnnualRate: Float,
                        $interestStart: String,
                        $interestEnd: String,
                        $campaignStart: String,
                        $campaignEnd: String,
                        $interestPayments: Int,
                        $fullDescription: String,
                        $collectionName: String,
                        $collectionTitle: String,
                        $collectionDescription: String,
                        $collectionItensPath_cid: String,
                        $collectionMetadata_cid: String,
                        $isStandardCollection: Boolean,
                        $symbol: String,
                        $decimals: Int,
                        $iconImgPubFileUrl: String,
                        $iconImgPubFileId: String,
                        $iconImgPubFile: PublicFileCreateInputs,
                    $dividendsTokenAddress: String,
                        $kind: TokenKindCreateInputs,
                    $kindId: String,
                        $address: String,
                        $abi: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $isLockup: Boolean,
                        $lockup: Float,
                        $lockupExpiration: String,
                        $tokenExpiration: String,
                        $istokenExpiration: Boolean,
                        $wallet: WalletCreateInputs,
                    $walletId: String,
                        $network: BlockchainCreateInputs,
                    $networkId: String,
                        $custody: PartnerCreateInputs,
                    $custodyId: String,
                        $status: TokenStatusCreateInputs,
                    $statusId: String,
                        $error: String,
                        $step: Int,
                        $isDocument: Boolean,
                        $remunerationType: TokenRemunerationTypeCreateInputs,
                    $remunerationTypeId: String,
                        $tokenSettlementType: TokenSettlementTypeCreateInputs,
                    $tokenSettlementTypeId: String,
                        $tokenRateType: TokenRateTypeCreateInputs,
                    $tokenRateTypeId: String,
                        $isTrashed: Boolean,
                        $transactionHashDeploy: String,
                        $isAutoBurn: Boolean,
                        $isPaymentToken: Boolean,
                        $sourceCode: String,
                        $tests: String,
                        $addressWalletOwner: String,
                        $syncMonitor: Boolean,
                        
        )
        {
            blockchainTokenCreateCms(data: {
                id: $id,
                    name: $name,
                    category: $category,
                    tokenCategoryId: $tokenCategoryId,
                    subCategory: $subCategory,
                    subCategoryId: $subCategoryId,
                    type: $type,
                    typeId: $typeId,
                    maxSupply: $maxSupply,
                    typeSupply: $typeSupply,
                    typeSupplyId: $typeSupplyId,
                    tokensEntitlementPercent: $tokensEntitlementPercent,
                    estimatedRevenue: $estimatedRevenue,
                    initialPrice: $initialPrice,
                    royaltyInternal: $royaltyInternal,
                    royaltyIssuer: $royaltyIssuer,
                    feeInternal: $feeInternal,
                    rateType: $rateType,
                    rateTypeId: $rateTypeId,
                    interestRate: $interestRate,
                    interestAnnualRate: $interestAnnualRate,
                    interestStart: $interestStart,
                    interestEnd: $interestEnd,
                    campaignStart: $campaignStart,
                    campaignEnd: $campaignEnd,
                    interestPayments: $interestPayments,
                    fullDescription: $fullDescription,
                    collectionName: $collectionName,
                    collectionTitle: $collectionTitle,
                    collectionDescription: $collectionDescription,
                    collectionItensPath_cid: $collectionItensPath_cid,
                    collectionMetadata_cid: $collectionMetadata_cid,
                    isStandardCollection: $isStandardCollection,
                    symbol: $symbol,
                    decimals: $decimals,
                    iconImgPubFileUrl: $iconImgPubFileUrl,
                    iconImgPubFileId: $iconImgPubFileId,
                    iconImgPubFile: $iconImgPubFile,
                    dividendsTokenAddress: $dividendsTokenAddress,
                    kind: $kind,
                    kindId: $kindId,
                    address: $address,
                    abi: $abi,
                    company: $company,
                    companyId: $companyId,
                    isLockup: $isLockup,
                    lockup: $lockup,
                    lockupExpiration: $lockupExpiration,
                    tokenExpiration: $tokenExpiration,
                    istokenExpiration: $istokenExpiration,
                    wallet: $wallet,
                    walletId: $walletId,
                    network: $network,
                    networkId: $networkId,
                    custody: $custody,
                    custodyId: $custodyId,
                    status: $status,
                    statusId: $statusId,
                    error: $error,
                    step: $step,
                    isDocument: $isDocument,
                    remunerationType: $remunerationType,
                    remunerationTypeId: $remunerationTypeId,
                    tokenSettlementType: $tokenSettlementType,
                    tokenSettlementTypeId: $tokenSettlementTypeId,
                    tokenRateType: $tokenRateType,
                    tokenRateTypeId: $tokenRateTypeId,
                    isTrashed: $isTrashed,
                    transactionHashDeploy: $transactionHashDeploy,
                    isAutoBurn: $isAutoBurn,
                    isPaymentToken: $isPaymentToken,
                    sourceCode: $sourceCode,
                    tests: $tests,
                    addressWalletOwner: $addressWalletOwner,
                    syncMonitor: $syncMonitor,
                    
            }) {
                id
                    name
                    category {
                        id
                        description
                    }
                    tokenCategoryId
                    subCategory {
                        id
                        description
                    }
                    subCategoryId
                    type {
                        id
                        description
                    }
                    typeId
                    maxSupply
                    typeSupply {
                        id
                        title
                    }
                    typeSupplyId
                    tokensEntitlementPercent
                    estimatedRevenue
                    initialPrice
                    royaltyInternal
                    royaltyIssuer
                    feeInternal
                    rateType {
                        id
                        description
                    }
                    rateTypeId
                    interestRate
                    interestAnnualRate
                    interestStart
                    interestEnd
                    campaignStart
                    campaignEnd
                    interestPayments
                    fullDescription
                    collectionName
                    collectionTitle
                    collectionDescription
                    collectionItensPath_cid
                    collectionMetadata_cid
                    isStandardCollection
                    symbol
                    decimals
                    iconImgPubFileUrl
                    iconImgPubFileId
                    iconImgPubFile {
                        id
                        url
                    }
                    dividendsTokenAddress
                    kind {
                        id
                        description
                    }
                    kindId
                    address
                    abi
                    company {
                        id
                        companyName
                    }
                    companyId
                    isLockup
                    lockup
                    lockupExpiration
                    tokenExpiration
                    istokenExpiration
                    wallet {
                        id
                        address
                    }
                    walletId
                    deleted
                    createdAt
                    updatedAt
                    network {
                        id
                        title
                    }
                    networkId
                    custody {
                        id
                        partnerName
                    }
                    custodyId
                    status {
                        id
                        description
                    }
                    statusId
                    error
                    step
                    isDocument
                    remunerationType {
                        id
                        title
                    }
                    remunerationTypeId
                    tokenSettlementType {
                        id
                        title
                    }
                    tokenSettlementTypeId
                    tokenRateType {
                        id
                        title
                    }
                    tokenRateTypeId
                    isTrashed
                    transactionHashDeploy
                    isAutoBurn
                    isPaymentToken
                    sourceCode
                    tests
                    addressWalletOwner
                    syncMonitor
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<BlockchainToken>`
        mutation BlockchainTokenSave(
            $id: String,
                        $name: String,
                        $category: TokenCategoryCreateInputs,
                    $tokenCategoryId: String,
                        $subCategory: TokenSubCategoryCreateInputs,
                    $subCategoryId: String,
                        $type: BlockchainTokenTypeCreateInputs,
                    $typeId: String,
                        $maxSupply: Float,
                        $typeSupply: TypeSupplyTokenCreateInputs,
                    $typeSupplyId: String,
                        $tokensEntitlementPercent: Float,
                        $estimatedRevenue: Float,
                        $initialPrice: Float,
                        $royaltyInternal: Float,
                        $royaltyIssuer: Float,
                        $feeInternal: Float,
                        $rateType: BlockchainTokenRateTypeCreateInputs,
                    $rateTypeId: String,
                        $interestRate: Float,
                        $interestAnnualRate: Float,
                        $interestStart: String,
                        $interestEnd: String,
                        $campaignStart: String,
                        $campaignEnd: String,
                        $interestPayments: Int,
                        $fullDescription: String,
                        $collectionName: String,
                        $collectionTitle: String,
                        $collectionDescription: String,
                        $collectionItensPath_cid: String,
                        $collectionMetadata_cid: String,
                        $isStandardCollection: Boolean,
                        $symbol: String,
                        $decimals: Int,
                        $iconImgPubFileUrl: String,
                        $iconImgPubFileId: String,
                        $iconImgPubFile: PublicFileCreateInputs,
                    $dividendsTokenAddress: String,
                        $kind: TokenKindCreateInputs,
                    $kindId: String,
                        $address: String,
                        $abi: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $isLockup: Boolean,
                        $lockup: Float,
                        $lockupExpiration: String,
                        $tokenExpiration: String,
                        $istokenExpiration: Boolean,
                        $wallet: WalletCreateInputs,
                    $walletId: String,
                        $network: BlockchainCreateInputs,
                    $networkId: String,
                        $custody: PartnerCreateInputs,
                    $custodyId: String,
                        $status: TokenStatusCreateInputs,
                    $statusId: String,
                        $error: String,
                        $step: Int,
                        $isDocument: Boolean,
                        $remunerationType: TokenRemunerationTypeCreateInputs,
                    $remunerationTypeId: String,
                        $tokenSettlementType: TokenSettlementTypeCreateInputs,
                    $tokenSettlementTypeId: String,
                        $tokenRateType: TokenRateTypeCreateInputs,
                    $tokenRateTypeId: String,
                        $isTrashed: Boolean,
                        $transactionHashDeploy: String,
                        $isAutoBurn: Boolean,
                        $isPaymentToken: Boolean,
                        $sourceCode: String,
                        $tests: String,
                        $addressWalletOwner: String,
                        $syncMonitor: Boolean,
                        
        )
        {
            blockchainTokenUpdateCms(data: {
                id: $id,
                    name: $name,
                    category: $category,
                    tokenCategoryId: $tokenCategoryId,
                    subCategory: $subCategory,
                    subCategoryId: $subCategoryId,
                    type: $type,
                    typeId: $typeId,
                    maxSupply: $maxSupply,
                    typeSupply: $typeSupply,
                    typeSupplyId: $typeSupplyId,
                    tokensEntitlementPercent: $tokensEntitlementPercent,
                    estimatedRevenue: $estimatedRevenue,
                    initialPrice: $initialPrice,
                    royaltyInternal: $royaltyInternal,
                    royaltyIssuer: $royaltyIssuer,
                    feeInternal: $feeInternal,
                    rateType: $rateType,
                    rateTypeId: $rateTypeId,
                    interestRate: $interestRate,
                    interestAnnualRate: $interestAnnualRate,
                    interestStart: $interestStart,
                    interestEnd: $interestEnd,
                    campaignStart: $campaignStart,
                    campaignEnd: $campaignEnd,
                    interestPayments: $interestPayments,
                    fullDescription: $fullDescription,
                    collectionName: $collectionName,
                    collectionTitle: $collectionTitle,
                    collectionDescription: $collectionDescription,
                    collectionItensPath_cid: $collectionItensPath_cid,
                    collectionMetadata_cid: $collectionMetadata_cid,
                    isStandardCollection: $isStandardCollection,
                    symbol: $symbol,
                    decimals: $decimals,
                    iconImgPubFileUrl: $iconImgPubFileUrl,
                    iconImgPubFileId: $iconImgPubFileId,
                    iconImgPubFile: $iconImgPubFile,
                    dividendsTokenAddress: $dividendsTokenAddress,
                    kind: $kind,
                    kindId: $kindId,
                    address: $address,
                    abi: $abi,
                    company: $company,
                    companyId: $companyId,
                    isLockup: $isLockup,
                    lockup: $lockup,
                    lockupExpiration: $lockupExpiration,
                    tokenExpiration: $tokenExpiration,
                    istokenExpiration: $istokenExpiration,
                    wallet: $wallet,
                    walletId: $walletId,
                    network: $network,
                    networkId: $networkId,
                    custody: $custody,
                    custodyId: $custodyId,
                    status: $status,
                    statusId: $statusId,
                    error: $error,
                    step: $step,
                    isDocument: $isDocument,
                    remunerationType: $remunerationType,
                    remunerationTypeId: $remunerationTypeId,
                    tokenSettlementType: $tokenSettlementType,
                    tokenSettlementTypeId: $tokenSettlementTypeId,
                    tokenRateType: $tokenRateType,
                    tokenRateTypeId: $tokenRateTypeId,
                    isTrashed: $isTrashed,
                    transactionHashDeploy: $transactionHashDeploy,
                    isAutoBurn: $isAutoBurn,
                    isPaymentToken: $isPaymentToken,
                    sourceCode: $sourceCode,
                    tests: $tests,
                    addressWalletOwner: $addressWalletOwner,
                    syncMonitor: $syncMonitor,
                    
            }) {
                id
                    name
                    category {
                        id
                        description
                    }
                    tokenCategoryId
                    subCategory {
                        id
                        description
                    }
                    subCategoryId
                    type {
                        id
                        description
                    }
                    typeId
                    maxSupply
                    typeSupply {
                        id
                        title
                    }
                    typeSupplyId
                    tokensEntitlementPercent
                    estimatedRevenue
                    initialPrice
                    royaltyInternal
                    royaltyIssuer
                    feeInternal
                    rateType {
                        id
                        description
                    }
                    rateTypeId
                    interestRate
                    interestAnnualRate
                    interestStart
                    interestEnd
                    campaignStart
                    campaignEnd
                    interestPayments
                    fullDescription
                    collectionName
                    collectionTitle
                    collectionDescription
                    collectionItensPath_cid
                    collectionMetadata_cid
                    isStandardCollection
                    symbol
                    decimals
                    iconImgPubFileUrl
                    iconImgPubFileId
                    iconImgPubFile {
                        id
                        url
                    }
                    dividendsTokenAddress
                    kind {
                        id
                        description
                    }
                    kindId
                    address
                    abi
                    company {
                        id
                        companyName
                    }
                    companyId
                    isLockup
                    lockup
                    lockupExpiration
                    tokenExpiration
                    istokenExpiration
                    wallet {
                        id
                        address
                    }
                    walletId
                    deleted
                    createdAt
                    updatedAt
                    network {
                        id
                        title
                    }
                    networkId
                    custody {
                        id
                        partnerName
                    }
                    custodyId
                    status {
                        id
                        description
                    }
                    statusId
                    error
                    step
                    isDocument
                    remunerationType {
                        id
                        title
                    }
                    remunerationTypeId
                    tokenSettlementType {
                        id
                        title
                    }
                    tokenSettlementTypeId
                    tokenRateType {
                        id
                        title
                    }
                    tokenRateTypeId
                    isTrashed
                    transactionHashDeploy
                    isAutoBurn
                    isPaymentToken
                    sourceCode
                    tests
                    addressWalletOwner
                    syncMonitor
                    
            }
        }
        `;

        
                    const TOKENCATEGORY_QUERY = `
                    query tokenCategoryListCms($search: Search) {
                        tokenCategoryListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const TOKENSUBCATEGORY_QUERY = `
                    query tokenSubCategoryListCms($search: Search) {
                        tokenSubCategoryListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTOKENTYPE_QUERY = `
                    query blockchainTokenTypeListCms($search: Search) {
                        blockchainTokenTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const TYPESUPPLYTOKEN_QUERY = `
                    query typeSupplyTokenListCms($search: Search) {
                        typeSupplyTokenListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTOKENRATETYPE_QUERY = `
                    query blockchainTokenRateTypeListCms($search: Search) {
                        blockchainTokenRateTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const PUBLICFILE_QUERY = `
                    query publicFileListCms($search: Search) {
                        publicFileListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    url
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const TOKENKIND_QUERY = `
                    query tokenKindListCms($search: Search) {
                        tokenKindListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const COMPANY_QUERY = `
                    query companyListCms($search: Search) {
                        companyListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyName
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const WALLET_QUERY = `
                    query walletListCms($search: Search) {
                        walletListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    address
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAIN_QUERY = `
                    query blockchainListCms($search: Search) {
                        blockchainListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const PARTNER_QUERY = `
                    query partnerListCms($search: Search) {
                        partnerListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    partnerName
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const TOKENSTATUS_QUERY = `
                    query tokenStatusListCms($search: Search) {
                        tokenStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const TOKENREMUNERATIONTYPE_QUERY = `
                    query tokenRemunerationTypeListCms($search: Search) {
                        tokenRemunerationTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const TOKENSETTLEMENTTYPE_QUERY = `
                    query tokenSettlementTypeListCms($search: Search) {
                        tokenSettlementTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const TOKENRATETYPE_QUERY = `
                    query tokenRateTypeListCms($search: Search) {
                        tokenRateTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const BlockchainTokenDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ blockchainTokenCms: BlockchainToken }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.blockchainTokenCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("BlockchainToken has been saved");
                    const model = entity?.data?.blockchainTokenUpdateCms || entity?.data?.blockchainTokenCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/blockchainToken-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('BlockchainToken')} breadcrumbItems={[
                                { title: t("BlockchainToken"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('BlockchainToken')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('BlockchainToken')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Name")} name="name"
                            rules={[{ required: true, message: 'Please input the ' + t("Name") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "tokenCategoryId") && <Form.Item label={<> {t('Category')}</>}>
                                <Form.Item noStyle id="category_id" name="tokenCategoryId"
                                rules={[{ required: (false && !(dataSources.dependencies?.category?.description || databaseEntity?.category?.description)), message: 'Please input the TokenCategory!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.category ? dataSources.dependencies?.category?.description : databaseEntity?.category?.description}
                                value={ dataSources.dependencies?.category ? dataSources.dependencies?.category?.description : databaseEntity?.category?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.tokenCategoryId = newValue.key;
                                            dataSources.dependencies["category"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["category"]) {
                                                delete dataSources.dependencies["category"].__typename;
                                            }                                            
                                            setSelects({ ...selects, tokenCategoryId: newValue.key });
                                            setUncheck({ ...uncheck, tokenCategoryId: false });
                                        }
                                        else if (databaseEntity?.category) {
                                            dataSources.dependencies.tokenCategoryId = undefined;
                                            dataSources.dependencies["category"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["category"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.tokenCategoryId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "TokenCategory", query: TOKENCATEGORY_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "TokenCategory", query: TOKENCATEGORY_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["TokenCategory"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, TokenCategorytokenCategoryIdEditVisible: !modals.TokenCategorytokenCategoryIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenCategorytokenCategoryIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, TokenCategorytokenCategoryIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        TokenCategory
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenCategoryDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.category?.id || databaseEntity?.category?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, TokenCategorytokenCategoryIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    tokenCategoryId: entity?.id,
                                                    category: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, TokenCategoryAddVisible: !modals.TokenCategoryAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenCategoryAddVisible"]}
                                    onHide={() => setModals({ ...modals, TokenCategoryAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TokenCategory
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenCategoryDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, TokenCategoryAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        tokenCategoryId: entity?.id,
                                                        category: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.category?.id || databaseEntity?.category?.id) && !uncheck['tokenCategoryId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, tokenCategoryId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            tokenCategoryId: "",
                                            category: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "subCategoryId") && <Form.Item label={<> {t('SubCategory')}</>}>
                                <Form.Item noStyle id="subCategory_id" name="subCategoryId"
                                rules={[{ required: (false && !(dataSources.dependencies?.subCategory?.description || databaseEntity?.subCategory?.description)), message: 'Please input the TokenSubCategory!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.subCategory ? dataSources.dependencies?.subCategory?.description : databaseEntity?.subCategory?.description}
                                value={ dataSources.dependencies?.subCategory ? dataSources.dependencies?.subCategory?.description : databaseEntity?.subCategory?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.subCategoryId = newValue.key;
                                            dataSources.dependencies["subCategory"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["subCategory"]) {
                                                delete dataSources.dependencies["subCategory"].__typename;
                                            }                                            
                                            setSelects({ ...selects, subCategoryId: newValue.key });
                                            setUncheck({ ...uncheck, subCategoryId: false });
                                        }
                                        else if (databaseEntity?.subCategory) {
                                            dataSources.dependencies.subCategoryId = undefined;
                                            dataSources.dependencies["subCategory"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["subCategory"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.subCategoryId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "TokenSubCategory", query: TOKENSUBCATEGORY_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "TokenSubCategory", query: TOKENSUBCATEGORY_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["TokenSubCategory"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, TokenSubCategorysubCategoryIdEditVisible: !modals.TokenSubCategorysubCategoryIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenSubCategorysubCategoryIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, TokenSubCategorysubCategoryIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        TokenSubCategory
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenSubCategoryDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.subCategory?.id || databaseEntity?.subCategory?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, TokenSubCategorysubCategoryIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    subCategoryId: entity?.id,
                                                    subCategory: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, TokenSubCategoryAddVisible: !modals.TokenSubCategoryAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenSubCategoryAddVisible"]}
                                    onHide={() => setModals({ ...modals, TokenSubCategoryAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TokenSubCategory
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenSubCategoryDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, TokenSubCategoryAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        subCategoryId: entity?.id,
                                                        subCategory: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.subCategory?.id || databaseEntity?.subCategory?.id) && !uncheck['subCategoryId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, subCategoryId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            subCategoryId: "",
                                            subCategory: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "typeId") && <Form.Item label={<> {t('Type')}</>}>
                                <Form.Item noStyle id="type_id" name="typeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.type?.description || databaseEntity?.type?.description)), message: 'Please input the BlockchainTokenType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.type ? dataSources.dependencies?.type?.description : databaseEntity?.type?.description}
                                value={ dataSources.dependencies?.type ? dataSources.dependencies?.type?.description : databaseEntity?.type?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.typeId = newValue.key;
                                            dataSources.dependencies["type"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["type"]) {
                                                delete dataSources.dependencies["type"].__typename;
                                            }                                            
                                            setSelects({ ...selects, typeId: newValue.key });
                                            setUncheck({ ...uncheck, typeId: false });
                                        }
                                        else if (databaseEntity?.type) {
                                            dataSources.dependencies.typeId = undefined;
                                            dataSources.dependencies["type"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["type"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.typeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTokenType", query: BLOCKCHAINTOKENTYPE_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTokenType", query: BLOCKCHAINTOKENTYPE_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["BlockchainTokenType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenTypetypeIdEditVisible: !modals.BlockchainTokenTypetypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenTypetypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenTypetypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTokenType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.type?.id || databaseEntity?.type?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTokenTypetypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    typeId: entity?.id,
                                                    type: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenTypeAddVisible: !modals.BlockchainTokenTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTokenType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTokenTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        typeId: entity?.id,
                                                        type: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.type?.id || databaseEntity?.type?.id) && !uncheck['typeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, typeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            typeId: "",
                                            type: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("MaxSupply")} name="maxSupply"
                            rules={[{ required: false, message: 'Please input the ' + t("MaxSupply") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "typeSupplyId") && <Form.Item label={<> {t('TypeSupply')}</>}>
                                <Form.Item noStyle id="typeSupply_id" name="typeSupplyId"
                                rules={[{ required: (false && !(dataSources.dependencies?.typeSupply?.title || databaseEntity?.typeSupply?.title)), message: 'Please input the TypeSupplyToken!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.typeSupply ? dataSources.dependencies?.typeSupply?.title : databaseEntity?.typeSupply?.title}
                                value={ dataSources.dependencies?.typeSupply ? dataSources.dependencies?.typeSupply?.title : databaseEntity?.typeSupply?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.typeSupplyId = newValue.key;
                                            dataSources.dependencies["typeSupply"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["typeSupply"]) {
                                                delete dataSources.dependencies["typeSupply"].__typename;
                                            }                                            
                                            setSelects({ ...selects, typeSupplyId: newValue.key });
                                            setUncheck({ ...uncheck, typeSupplyId: false });
                                        }
                                        else if (databaseEntity?.typeSupply) {
                                            dataSources.dependencies.typeSupplyId = undefined;
                                            dataSources.dependencies["typeSupply"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["typeSupply"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.typeSupplyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "TypeSupplyToken", query: TYPESUPPLYTOKEN_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "TypeSupplyToken", query: TYPESUPPLYTOKEN_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["TypeSupplyToken"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, TypeSupplyTokentypeSupplyIdEditVisible: !modals.TypeSupplyTokentypeSupplyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TypeSupplyTokentypeSupplyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, TypeSupplyTokentypeSupplyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        TypeSupplyToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TypeSupplyTokenDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.typeSupply?.id || databaseEntity?.typeSupply?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, TypeSupplyTokentypeSupplyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    typeSupplyId: entity?.id,
                                                    typeSupply: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, TypeSupplyTokenAddVisible: !modals.TypeSupplyTokenAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TypeSupplyTokenAddVisible"]}
                                    onHide={() => setModals({ ...modals, TypeSupplyTokenAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TypeSupplyToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TypeSupplyTokenDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, TypeSupplyTokenAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        typeSupplyId: entity?.id,
                                                        typeSupply: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.typeSupply?.id || databaseEntity?.typeSupply?.id) && !uncheck['typeSupplyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, typeSupplyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            typeSupplyId: "",
                                            typeSupply: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("TokensEntitlementPercent")} name="tokensEntitlementPercent"
                            rules={[{ required: false, message: 'Please input the ' + t("TokensEntitlementPercent") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("EstimatedRevenue")} name="estimatedRevenue"
                            rules={[{ required: false, message: 'Please input the ' + t("EstimatedRevenue") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("InitialPrice")} name="initialPrice"
                            rules={[{ required: false, message: 'Please input the ' + t("InitialPrice") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("RoyaltyInternal")} name="royaltyInternal"
                            rules={[{ required: false, message: 'Please input the ' + t("RoyaltyInternal") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("RoyaltyIssuer")} name="royaltyIssuer"
                            rules={[{ required: false, message: 'Please input the ' + t("RoyaltyIssuer") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("FeeInternal")} name="feeInternal"
                            rules={[{ required: false, message: 'Please input the ' + t("FeeInternal") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "rateTypeId") && <Form.Item label={<> {t('RateType')}</>}>
                                <Form.Item noStyle id="rateType_id" name="rateTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.rateType?.description || databaseEntity?.rateType?.description)), message: 'Please input the BlockchainTokenRateType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.rateType ? dataSources.dependencies?.rateType?.description : databaseEntity?.rateType?.description}
                                value={ dataSources.dependencies?.rateType ? dataSources.dependencies?.rateType?.description : databaseEntity?.rateType?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.rateTypeId = newValue.key;
                                            dataSources.dependencies["rateType"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["rateType"]) {
                                                delete dataSources.dependencies["rateType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, rateTypeId: newValue.key });
                                            setUncheck({ ...uncheck, rateTypeId: false });
                                        }
                                        else if (databaseEntity?.rateType) {
                                            dataSources.dependencies.rateTypeId = undefined;
                                            dataSources.dependencies["rateType"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["rateType"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.rateTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTokenRateType", query: BLOCKCHAINTOKENRATETYPE_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTokenRateType", query: BLOCKCHAINTOKENRATETYPE_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["BlockchainTokenRateType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenRateTyperateTypeIdEditVisible: !modals.BlockchainTokenRateTyperateTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenRateTyperateTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenRateTyperateTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTokenRateType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenRateTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.rateType?.id || databaseEntity?.rateType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTokenRateTyperateTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    rateTypeId: entity?.id,
                                                    rateType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenRateTypeAddVisible: !modals.BlockchainTokenRateTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenRateTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenRateTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTokenRateType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenRateTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTokenRateTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        rateTypeId: entity?.id,
                                                        rateType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.rateType?.id || databaseEntity?.rateType?.id) && !uncheck['rateTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, rateTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            rateTypeId: "",
                                            rateType: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("InterestRate")} name="interestRate"
                            rules={[{ required: false, message: 'Please input the ' + t("InterestRate") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("InterestAnnualRate")} name="interestAnnualRate"
                            rules={[{ required: false, message: 'Please input the ' + t("InterestAnnualRate") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("InterestStart")} name="interestStart"
                            rules={[{ required: false, message: 'Please input the ' + t("InterestStart") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.interestStart ? moment(databaseEntity?.interestStart, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['interestStart'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("InterestEnd")} name="interestEnd"
                            rules={[{ required: false, message: 'Please input the ' + t("InterestEnd") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.interestEnd ? moment(databaseEntity?.interestEnd, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['interestEnd'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("CampaignStart")} name="campaignStart"
                            rules={[{ required: false, message: 'Please input the ' + t("CampaignStart") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.campaignStart ? moment(databaseEntity?.campaignStart, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['campaignStart'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("CampaignEnd")} name="campaignEnd"
                            rules={[{ required: false, message: 'Please input the ' + t("CampaignEnd") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.campaignEnd ? moment(databaseEntity?.campaignEnd, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['campaignEnd'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("InterestPayments")} name="interestPayments"
                            rules={[{ required: false, message: 'Please input the ' + t("InterestPayments") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("FullDescription")}
                    rules={[{ required: false, message: 'Please input the ' + t("fullDescription") + '!' }]}>
                    <ReactQuill modules={{
                      toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                      ],
                      clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                      }
                  }} theme="snow" value={descriptions['fullDescription'] || databaseEntity?.fullDescription ||''} onChange={(value) => {
                      setDescriptions({ ...descriptions, ['fullDescription']: value });
                  }} />
                </Form.Item><Form.Item label={t("CollectionName")} name="collectionName"
                            rules={[{ required: false, message: 'Please input the ' + t("CollectionName") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CollectionTitle")} name="collectionTitle"
                            rules={[{ required: false, message: 'Please input the ' + t("CollectionTitle") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CollectionDescription")} name="collectionDescription"
                            rules={[{ required: false, message: 'Please input the ' + t("CollectionDescription") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CollectionItensPath_cid")} name="collectionItensPath_cid"
                            rules={[{ required: false, message: 'Please input the ' + t("CollectionItensPath_cid") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CollectionMetadata_cid")} name="collectionMetadata_cid"
                            rules={[{ required: false, message: 'Please input the ' + t("CollectionMetadata_cid") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("IsStandardCollection")} name="isStandardCollection" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("Symbol")} name="symbol"
                            rules={[{ required: true, message: 'Please input the ' + t("Symbol") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Decimals")} name="decimals"
                            rules={[{ required: true, message: 'Please input the ' + t("Decimals") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("IconImgPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("iconImgPubFileId") + '!' }]}>
                    {!!databaseEntity?.iconImgPubFileId && !dropUpload['iconImgPubFileId'] && !files['iconImgPubFileId'] &&
                        <>
                            { !!download[databaseEntity?.iconImgPubFileId]  && <>
                        { !download[databaseEntity?.iconImgPubFileId + 'Error'] && <> 
                        
                            <a title="iconImgPubFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.iconImgPubFileId, "public") 
                            }}>
                            
                            <img alt="iconImgPubFileId" 
                            onError={() => { 
                                download[databaseEntity?.iconImgPubFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.iconImgPubFileId + 'Error']: true });
                            }} src={download[databaseEntity?.iconImgPubFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.iconImgPubFileId, "public") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, iconImgPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.iconImgPubFileId || dropUpload['iconImgPubFileId'] || files['iconImgPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, iconImgPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("DividendsTokenAddress")} name="dividendsTokenAddress"
                            rules={[{ required: false, message: 'Please input the ' + t("DividendsTokenAddress") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "kindId") && <Form.Item label={<> {t('Kind')}</>}>
                                <Form.Item noStyle id="kind_id" name="kindId"
                                rules={[{ required: (false && !(dataSources.dependencies?.kind?.description || databaseEntity?.kind?.description)), message: 'Please input the TokenKind!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.kind ? dataSources.dependencies?.kind?.description : databaseEntity?.kind?.description}
                                value={ dataSources.dependencies?.kind ? dataSources.dependencies?.kind?.description : databaseEntity?.kind?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.kindId = newValue.key;
                                            dataSources.dependencies["kind"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["kind"]) {
                                                delete dataSources.dependencies["kind"].__typename;
                                            }                                            
                                            setSelects({ ...selects, kindId: newValue.key });
                                            setUncheck({ ...uncheck, kindId: false });
                                        }
                                        else if (databaseEntity?.kind) {
                                            dataSources.dependencies.kindId = undefined;
                                            dataSources.dependencies["kind"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["kind"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.kindId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "TokenKind", query: TOKENKIND_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "TokenKind", query: TOKENKIND_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["TokenKind"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, TokenKindkindIdEditVisible: !modals.TokenKindkindIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenKindkindIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, TokenKindkindIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        TokenKind
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenKindDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.kind?.id || databaseEntity?.kind?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, TokenKindkindIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    kindId: entity?.id,
                                                    kind: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, TokenKindAddVisible: !modals.TokenKindAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenKindAddVisible"]}
                                    onHide={() => setModals({ ...modals, TokenKindAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TokenKind
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenKindDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, TokenKindAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        kindId: entity?.id,
                                                        kind: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.kind?.id || databaseEntity?.kind?.id) && !uncheck['kindId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, kindId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            kindId: "",
                                            kind: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Address")} name="address"
                            rules={[{ required: false, message: 'Please input the ' + t("Address") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Abi")} name="abi"
                            rules={[{ required: false, message: 'Please input the ' + t("Abi") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "companyId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Company')}</>}>
                                <Form.Item noStyle id="company_id" name="companyId"
                                rules={[{ required: (true && !(dataSources.dependencies?.company?.companyName || databaseEntity?.company?.companyName)), message: 'Please input the Company!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                value={ dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyId = newValue.key;
                                            dataSources.dependencies["company"] = {
                                                id: newValue.key,
                                                companyName: newValue.value
                                            };
                                            if(dataSources.dependencies["company"]) {
                                                delete dataSources.dependencies["company"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyId: newValue.key });
                                            setUncheck({ ...uncheck, companyId: false });
                                        }
                                        else if (databaseEntity?.company) {
                                            dataSources.dependencies.companyId = undefined;
                                            dataSources.dependencies["company"] = {
                                                id: undefined,
                                                companyName: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["company"] = {
                                            id: undefined,
                                            companyName: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}>
                                    {(dataSources["Company"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyName}>
                                            {item.companyName}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CompanycompanyIdEditVisible: !modals.CompanycompanyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanycompanyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CompanycompanyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.company?.id || databaseEntity?.company?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CompanycompanyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyId: entity?.id,
                                                    company: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CompanyAddVisible: !modals.CompanyAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanyAddVisible"]}
                                    onHide={() => setModals({ ...modals, CompanyAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CompanyAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyId: entity?.id,
                                                        company: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.company?.id || databaseEntity?.company?.id) && !uncheck['companyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyId: "",
                                            company: {
                                                companyName: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("IsLockup")} name="isLockup" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("Lockup")} name="lockup"
                            rules={[{ required: false, message: 'Please input the ' + t("Lockup") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("LockupExpiration")} name="lockupExpiration"
                            rules={[{ required: false, message: 'Please input the ' + t("LockupExpiration") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.lockupExpiration ? moment(databaseEntity?.lockupExpiration, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['lockupExpiration'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("TokenExpiration")} name="tokenExpiration"
                            rules={[{ required: false, message: 'Please input the ' + t("TokenExpiration") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.tokenExpiration ? moment(databaseEntity?.tokenExpiration, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['tokenExpiration'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("IstokenExpiration")} name="istokenExpiration" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "walletId") && <Form.Item label={<> {t('Wallet')}</>}>
                                <Form.Item noStyle id="wallet_id" name="walletId"
                                rules={[{ required: (false && !(dataSources.dependencies?.wallet?.address || databaseEntity?.wallet?.address)), message: 'Please input the Wallet!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                value={ dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.walletId = newValue.key;
                                            dataSources.dependencies["wallet"] = {
                                                id: newValue.key,
                                                address: newValue.value
                                            };
                                            if(dataSources.dependencies["wallet"]) {
                                                delete dataSources.dependencies["wallet"].__typename;
                                            }                                            
                                            setSelects({ ...selects, walletId: newValue.key });
                                            setUncheck({ ...uncheck, walletId: false });
                                        }
                                        else if (databaseEntity?.wallet) {
                                            dataSources.dependencies.walletId = undefined;
                                            dataSources.dependencies["wallet"] = {
                                                id: undefined,
                                                address: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["wallet"] = {
                                            id: undefined,
                                            address: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.walletId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Wallet", query: WALLET_QUERY, idField: "id", textField: "address" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Wallet", query: WALLET_QUERY, idField: "id", textField: "address" });
                                    }}>
                                    {(dataSources["Wallet"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.address}>
                                            {item.address}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, WalletwalletIdEditVisible: !modals.WalletwalletIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["WalletwalletIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, WalletwalletIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Wallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <WalletDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, WalletwalletIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    walletId: entity?.id,
                                                    wallet: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, WalletAddVisible: !modals.WalletAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["WalletAddVisible"]}
                                    onHide={() => setModals({ ...modals, WalletAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Wallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <WalletDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, WalletAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        walletId: entity?.id,
                                                        wallet: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id) && !uncheck['walletId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, walletId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            walletId: "",
                                            wallet: {
                                                address: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "networkId") && <Form.Item label={<> {t('Network')}</>}>
                                <Form.Item noStyle id="network_id" name="networkId"
                                rules={[{ required: (false && !(dataSources.dependencies?.network?.title || databaseEntity?.network?.title)), message: 'Please input the Blockchain!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.network ? dataSources.dependencies?.network?.title : databaseEntity?.network?.title}
                                value={ dataSources.dependencies?.network ? dataSources.dependencies?.network?.title : databaseEntity?.network?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.networkId = newValue.key;
                                            dataSources.dependencies["network"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["network"]) {
                                                delete dataSources.dependencies["network"].__typename;
                                            }                                            
                                            setSelects({ ...selects, networkId: newValue.key });
                                            setUncheck({ ...uncheck, networkId: false });
                                        }
                                        else if (databaseEntity?.network) {
                                            dataSources.dependencies.networkId = undefined;
                                            dataSources.dependencies["network"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["network"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.networkId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Blockchain", query: BLOCKCHAIN_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Blockchain", query: BLOCKCHAIN_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["Blockchain"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainnetworkIdEditVisible: !modals.BlockchainnetworkIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainnetworkIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainnetworkIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Blockchain
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.network?.id || databaseEntity?.network?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainnetworkIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    networkId: entity?.id,
                                                    network: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainAddVisible: !modals.BlockchainAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Blockchain
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        networkId: entity?.id,
                                                        network: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.network?.id || databaseEntity?.network?.id) && !uncheck['networkId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, networkId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            networkId: "",
                                            network: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "custodyId") && <Form.Item label={<> {t('Custody')}</>}>
                                <Form.Item noStyle id="custody_id" name="custodyId"
                                rules={[{ required: (false && !(dataSources.dependencies?.custody?.partnerName || databaseEntity?.custody?.partnerName)), message: 'Please input the Partner!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.custody ? dataSources.dependencies?.custody?.partnerName : databaseEntity?.custody?.partnerName}
                                value={ dataSources.dependencies?.custody ? dataSources.dependencies?.custody?.partnerName : databaseEntity?.custody?.partnerName}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.custodyId = newValue.key;
                                            dataSources.dependencies["custody"] = {
                                                id: newValue.key,
                                                partnerName: newValue.value
                                            };
                                            if(dataSources.dependencies["custody"]) {
                                                delete dataSources.dependencies["custody"].__typename;
                                            }                                            
                                            setSelects({ ...selects, custodyId: newValue.key });
                                            setUncheck({ ...uncheck, custodyId: false });
                                        }
                                        else if (databaseEntity?.custody) {
                                            dataSources.dependencies.custodyId = undefined;
                                            dataSources.dependencies["custody"] = {
                                                id: undefined,
                                                partnerName: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["custody"] = {
                                            id: undefined,
                                            partnerName: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.custodyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Partner", query: PARTNER_QUERY, idField: "id", textField: "partnerName" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Partner", query: PARTNER_QUERY, idField: "id", textField: "partnerName" });
                                    }}>
                                    {(dataSources["Partner"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.partnerName}>
                                            {item.partnerName}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, PartnercustodyIdEditVisible: !modals.PartnercustodyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["PartnercustodyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, PartnercustodyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Partner
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <PartnerDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.custody?.id || databaseEntity?.custody?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, PartnercustodyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    custodyId: entity?.id,
                                                    custody: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, PartnerAddVisible: !modals.PartnerAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["PartnerAddVisible"]}
                                    onHide={() => setModals({ ...modals, PartnerAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Partner
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <PartnerDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, PartnerAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        custodyId: entity?.id,
                                                        custody: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.custody?.id || databaseEntity?.custody?.id) && !uncheck['custodyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, custodyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            custodyId: "",
                                            custody: {
                                                partnerName: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "statusId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Status')}</>}>
                                <Form.Item noStyle id="status_id" name="statusId"
                                rules={[{ required: (true && !(dataSources.dependencies?.status?.description || databaseEntity?.status?.description)), message: 'Please input the TokenStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                value={ dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.statusId = newValue.key;
                                            dataSources.dependencies["status"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["status"]) {
                                                delete dataSources.dependencies["status"].__typename;
                                            }                                            
                                            setSelects({ ...selects, statusId: newValue.key });
                                            setUncheck({ ...uncheck, statusId: false });
                                        }
                                        else if (databaseEntity?.status) {
                                            dataSources.dependencies.statusId = undefined;
                                            dataSources.dependencies["status"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["status"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.statusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "TokenStatus", query: TOKENSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "TokenStatus", query: TOKENSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["TokenStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, TokenStatusstatusIdEditVisible: !modals.TokenStatusstatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenStatusstatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, TokenStatusstatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        TokenStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.status?.id || databaseEntity?.status?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, TokenStatusstatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    statusId: entity?.id,
                                                    status: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, TokenStatusAddVisible: !modals.TokenStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, TokenStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TokenStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, TokenStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        statusId: entity?.id,
                                                        status: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.status?.id || databaseEntity?.status?.id) && !uncheck['statusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, statusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            statusId: "",
                                            status: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Error")} name="error"
                            rules={[{ required: false, message: 'Please input the ' + t("Error") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Step")} name="step"
                            rules={[{ required: true, message: 'Please input the ' + t("Step") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("IsDocument")} name="isDocument" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "remunerationTypeId") && <Form.Item label={<> {t('RemunerationType')}</>}>
                                <Form.Item noStyle id="remunerationType_id" name="remunerationTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.remunerationType?.title || databaseEntity?.remunerationType?.title)), message: 'Please input the TokenRemunerationType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.remunerationType ? dataSources.dependencies?.remunerationType?.title : databaseEntity?.remunerationType?.title}
                                value={ dataSources.dependencies?.remunerationType ? dataSources.dependencies?.remunerationType?.title : databaseEntity?.remunerationType?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.remunerationTypeId = newValue.key;
                                            dataSources.dependencies["remunerationType"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["remunerationType"]) {
                                                delete dataSources.dependencies["remunerationType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, remunerationTypeId: newValue.key });
                                            setUncheck({ ...uncheck, remunerationTypeId: false });
                                        }
                                        else if (databaseEntity?.remunerationType) {
                                            dataSources.dependencies.remunerationTypeId = undefined;
                                            dataSources.dependencies["remunerationType"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["remunerationType"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.remunerationTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "TokenRemunerationType", query: TOKENREMUNERATIONTYPE_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "TokenRemunerationType", query: TOKENREMUNERATIONTYPE_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["TokenRemunerationType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, TokenRemunerationTyperemunerationTypeIdEditVisible: !modals.TokenRemunerationTyperemunerationTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenRemunerationTyperemunerationTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, TokenRemunerationTyperemunerationTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        TokenRemunerationType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenRemunerationTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.remunerationType?.id || databaseEntity?.remunerationType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, TokenRemunerationTyperemunerationTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    remunerationTypeId: entity?.id,
                                                    remunerationType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, TokenRemunerationTypeAddVisible: !modals.TokenRemunerationTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenRemunerationTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, TokenRemunerationTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TokenRemunerationType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenRemunerationTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, TokenRemunerationTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        remunerationTypeId: entity?.id,
                                                        remunerationType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.remunerationType?.id || databaseEntity?.remunerationType?.id) && !uncheck['remunerationTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, remunerationTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            remunerationTypeId: "",
                                            remunerationType: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "tokenSettlementTypeId") && <Form.Item label={<> {t('TokenSettlementType')}</>}>
                                <Form.Item noStyle id="tokenSettlementType_id" name="tokenSettlementTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.tokenSettlementType?.title || databaseEntity?.tokenSettlementType?.title)), message: 'Please input the TokenSettlementType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.tokenSettlementType ? dataSources.dependencies?.tokenSettlementType?.title : databaseEntity?.tokenSettlementType?.title}
                                value={ dataSources.dependencies?.tokenSettlementType ? dataSources.dependencies?.tokenSettlementType?.title : databaseEntity?.tokenSettlementType?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.tokenSettlementTypeId = newValue.key;
                                            dataSources.dependencies["tokenSettlementType"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["tokenSettlementType"]) {
                                                delete dataSources.dependencies["tokenSettlementType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, tokenSettlementTypeId: newValue.key });
                                            setUncheck({ ...uncheck, tokenSettlementTypeId: false });
                                        }
                                        else if (databaseEntity?.tokenSettlementType) {
                                            dataSources.dependencies.tokenSettlementTypeId = undefined;
                                            dataSources.dependencies["tokenSettlementType"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["tokenSettlementType"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.tokenSettlementTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "TokenSettlementType", query: TOKENSETTLEMENTTYPE_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "TokenSettlementType", query: TOKENSETTLEMENTTYPE_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["TokenSettlementType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, TokenSettlementTypetokenSettlementTypeIdEditVisible: !modals.TokenSettlementTypetokenSettlementTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenSettlementTypetokenSettlementTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, TokenSettlementTypetokenSettlementTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        TokenSettlementType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenSettlementTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.tokenSettlementType?.id || databaseEntity?.tokenSettlementType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, TokenSettlementTypetokenSettlementTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    tokenSettlementTypeId: entity?.id,
                                                    tokenSettlementType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, TokenSettlementTypeAddVisible: !modals.TokenSettlementTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenSettlementTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, TokenSettlementTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TokenSettlementType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenSettlementTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, TokenSettlementTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        tokenSettlementTypeId: entity?.id,
                                                        tokenSettlementType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.tokenSettlementType?.id || databaseEntity?.tokenSettlementType?.id) && !uncheck['tokenSettlementTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, tokenSettlementTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            tokenSettlementTypeId: "",
                                            tokenSettlementType: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "tokenRateTypeId") && <Form.Item label={<> {t('TokenRateType')}</>}>
                                <Form.Item noStyle id="tokenRateType_id" name="tokenRateTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.tokenRateType?.title || databaseEntity?.tokenRateType?.title)), message: 'Please input the TokenRateType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.tokenRateType ? dataSources.dependencies?.tokenRateType?.title : databaseEntity?.tokenRateType?.title}
                                value={ dataSources.dependencies?.tokenRateType ? dataSources.dependencies?.tokenRateType?.title : databaseEntity?.tokenRateType?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.tokenRateTypeId = newValue.key;
                                            dataSources.dependencies["tokenRateType"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["tokenRateType"]) {
                                                delete dataSources.dependencies["tokenRateType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, tokenRateTypeId: newValue.key });
                                            setUncheck({ ...uncheck, tokenRateTypeId: false });
                                        }
                                        else if (databaseEntity?.tokenRateType) {
                                            dataSources.dependencies.tokenRateTypeId = undefined;
                                            dataSources.dependencies["tokenRateType"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["tokenRateType"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.tokenRateTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "TokenRateType", query: TOKENRATETYPE_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "TokenRateType", query: TOKENRATETYPE_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["TokenRateType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, TokenRateTypetokenRateTypeIdEditVisible: !modals.TokenRateTypetokenRateTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenRateTypetokenRateTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, TokenRateTypetokenRateTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        TokenRateType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenRateTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.tokenRateType?.id || databaseEntity?.tokenRateType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, TokenRateTypetokenRateTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    tokenRateTypeId: entity?.id,
                                                    tokenRateType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, TokenRateTypeAddVisible: !modals.TokenRateTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenRateTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, TokenRateTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TokenRateType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenRateTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, TokenRateTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        tokenRateTypeId: entity?.id,
                                                        tokenRateType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.tokenRateType?.id || databaseEntity?.tokenRateType?.id) && !uncheck['tokenRateTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, tokenRateTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            tokenRateTypeId: "",
                                            tokenRateType: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("IsTrashed")} name="isTrashed" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("TransactionHashDeploy")} name="transactionHashDeploy"
                            rules={[{ required: false, message: 'Please input the ' + t("TransactionHashDeploy") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("IsAutoBurn")} name="isAutoBurn" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("IsPaymentToken")} name="isPaymentToken" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("SourceCode")} name="sourceCode"
                            rules={[{ required: false, message: 'Please input the ' + t("SourceCode") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Tests")} name="tests"
                            rules={[{ required: false, message: 'Please input the ' + t("Tests") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("AddressWalletOwner")} name="addressWalletOwner"
                            rules={[{ required: false, message: 'Please input the ' + t("AddressWalletOwner") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("SyncMonitor")} name="syncMonitor" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`ApprovalManagerTokenMap`)}>
                            <ApprovalManagerTokenMapList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></ApprovalManagerTokenMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={2} tab={t(`TokenDeployVarsMap`)}>
                            <TokenDeployVarsMapList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></TokenDeployVarsMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={3} tab={t(`BlockchainTokenConstMap`)}>
                            <BlockchainTokenConstMapList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></BlockchainTokenConstMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={4} tab={t(`BlockchainTokenRuleMap`)}>
                            <BlockchainTokenRuleMapList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></BlockchainTokenRuleMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={5} tab={t(`TokenResourceMap`)}>
                            <TokenResourceMapList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></TokenResourceMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={6} tab={t(`TokenRemunerationSchedule`)}>
                            <TokenRemunerationScheduleList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></TokenRemunerationScheduleList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={7} tab={t(`TokenContractInfo`)}>
                            <TokenContractInfoList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></TokenContractInfoList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={8} tab={t(`TokenNews`)}>
                            <TokenNewsList relation={{model: "token", field: "blockchainTokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></TokenNewsList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={9} tab={t(`Dividend`)}>
                            <DividendList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></DividendList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={10} tab={t(`TokenInfo`)}>
                            <TokenInfoList relation={{model: "token", field: "blockchainTokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></TokenInfoList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={11} tab={t(`Offer`)}>
                            <OfferList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></OfferList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={12} tab={t(`Offer`)}>
                            <OfferList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></OfferList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={13} tab={t(`InvestorDeposit`)}>
                            <InvestorDepositList relation={{model: "currencyToken", field: "currencyTokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></InvestorDepositList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={14} tab={t(`CompanyDeposit`)}>
                            <CompanyDepositList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></CompanyDepositList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={15} tab={t(`WalletAsset`)}>
                            <WalletAssetList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></WalletAssetList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={16} tab={t(`BlockchainTransaction`)}>
                            <BlockchainTransactionList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></BlockchainTransactionList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={17} tab={t(`BankAccount`)}>
                            <BankAccountList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></BankAccountList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={18} tab={t(`BankTransaction`)}>
                            <BankTransactionList relation={{model: "stableCoin", field: "stableCoinId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></BankTransactionList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={19} tab={t(`BankTransaction`)}>
                            <BankTransactionList relation={{model: "stableCoin", field: "stableCoinId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></BankTransactionList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={20} tab={t(`BlockchainTokenFile`)}>
                            <BlockchainTokenFileList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></BlockchainTokenFileList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={21} tab={t(`TokenConstVars`)}>
                            <TokenConstVarsList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></TokenConstVarsList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={22} tab={t(`Statement`)}>
                            <StatementList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></StatementList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={23} tab={t(`AirdropCampaign`)}>
                            <AirdropCampaignList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></AirdropCampaignList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={24} tab={t(`Campaign`)}>
                            <CampaignList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></CampaignList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={25} tab={t(`OfferInvest`)}>
                            <OfferInvestList relation={{model: "currencyToken", field: "currencyTokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></OfferInvestList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={26} tab={t(`BlockchainTokenUserMap`)}>
                            <BlockchainTokenUserMapList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></BlockchainTokenUserMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={27} tab={t(`CompanyContactsTokenMap`)}>
                            <CompanyContactsTokenMapList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></CompanyContactsTokenMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={28} tab={t(`VirtualAssetMap`)}>
                            <VirtualAssetMapList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></VirtualAssetMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={29} tab={t(`DocumentSignerContract`)}>
                            <DocumentSignerContractList relation={{model: "token", field: "tokenId", textField: "name", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.name}}></DocumentSignerContractList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (BlockchainTokenDetails);
  