//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { ScheduleCommunication } from '../../../models/prisma/scheduleCommunication.model';
        import { GET_LIST, startedFilter } from './scheduleCommunication.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import ScheduleCommunicationEmailsList from '../scheduleCommunicationEmails/scheduleCommunicationEmails.list';
import ScheduleCommunicationFieldsList from '../scheduleCommunicationFields/scheduleCommunicationFields.list';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            scheduleCommunicationCms: ScheduleCommunication
        }>`
        query ScheduleCommunicationCms($id: String)
        {
            scheduleCommunicationCms(id: $id)
          {
            id
                    key
                    source
                    title
                    description
                    link
                    imageUrl
                    documentUrl
                    offerId
                    step
                    blockchainTokenId
                    bankName
                    agencyNumber
                    accountNumber
                    accountOwner
                    document
                    kind
                    tokenName
                    investorsQty
                    parcel
                    totalParcel
                    paymentDate
                    value
                    status
                    createdAt
                    updatedAt
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<ScheduleCommunication>`
        mutation ScheduleCommunicationSave(
            $id: String,
                        $key: String,
                        $source: String,
                        $title: String,
                        $description: String,
                        $link: String,
                        $imageUrl: String,
                        $documentUrl: String,
                        $offerId: String,
                        $step: String,
                        $blockchainTokenId: String,
                        $bankName: String,
                        $agencyNumber: String,
                        $accountNumber: String,
                        $accountOwner: String,
                        $document: String,
                        $kind: String,
                        $tokenName: String,
                        $investorsQty: String,
                        $parcel: String,
                        $totalParcel: String,
                        $paymentDate: String,
                        $value: String,
                        $status: String,
                        
        )
        {
            scheduleCommunicationCreateCms(data: {
                id: $id,
                    key: $key,
                    source: $source,
                    title: $title,
                    description: $description,
                    link: $link,
                    imageUrl: $imageUrl,
                    documentUrl: $documentUrl,
                    offerId: $offerId,
                    step: $step,
                    blockchainTokenId: $blockchainTokenId,
                    bankName: $bankName,
                    agencyNumber: $agencyNumber,
                    accountNumber: $accountNumber,
                    accountOwner: $accountOwner,
                    document: $document,
                    kind: $kind,
                    tokenName: $tokenName,
                    investorsQty: $investorsQty,
                    parcel: $parcel,
                    totalParcel: $totalParcel,
                    paymentDate: $paymentDate,
                    value: $value,
                    status: $status,
                    
            }) {
                id
                    key
                    source
                    title
                    description
                    link
                    imageUrl
                    documentUrl
                    offerId
                    step
                    blockchainTokenId
                    bankName
                    agencyNumber
                    accountNumber
                    accountOwner
                    document
                    kind
                    tokenName
                    investorsQty
                    parcel
                    totalParcel
                    paymentDate
                    value
                    status
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<ScheduleCommunication>`
        mutation ScheduleCommunicationSave(
            $id: String,
                        $key: String,
                        $source: String,
                        $title: String,
                        $description: String,
                        $link: String,
                        $imageUrl: String,
                        $documentUrl: String,
                        $offerId: String,
                        $step: String,
                        $blockchainTokenId: String,
                        $bankName: String,
                        $agencyNumber: String,
                        $accountNumber: String,
                        $accountOwner: String,
                        $document: String,
                        $kind: String,
                        $tokenName: String,
                        $investorsQty: String,
                        $parcel: String,
                        $totalParcel: String,
                        $paymentDate: String,
                        $value: String,
                        $status: String,
                        
        )
        {
            scheduleCommunicationUpdateCms(data: {
                id: $id,
                    key: $key,
                    source: $source,
                    title: $title,
                    description: $description,
                    link: $link,
                    imageUrl: $imageUrl,
                    documentUrl: $documentUrl,
                    offerId: $offerId,
                    step: $step,
                    blockchainTokenId: $blockchainTokenId,
                    bankName: $bankName,
                    agencyNumber: $agencyNumber,
                    accountNumber: $accountNumber,
                    accountOwner: $accountOwner,
                    document: $document,
                    kind: $kind,
                    tokenName: $tokenName,
                    investorsQty: $investorsQty,
                    parcel: $parcel,
                    totalParcel: $totalParcel,
                    paymentDate: $paymentDate,
                    value: $value,
                    status: $status,
                    
            }) {
                id
                    key
                    source
                    title
                    description
                    link
                    imageUrl
                    documentUrl
                    offerId
                    step
                    blockchainTokenId
                    bankName
                    agencyNumber
                    accountNumber
                    accountOwner
                    document
                    kind
                    tokenName
                    investorsQty
                    parcel
                    totalParcel
                    paymentDate
                    value
                    status
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        

        const ScheduleCommunicationDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ scheduleCommunicationCms: ScheduleCommunication }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.scheduleCommunicationCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("ScheduleCommunication has been saved");
                    const model = entity?.data?.scheduleCommunicationUpdateCms || entity?.data?.scheduleCommunicationCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/scheduleCommunication-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('ScheduleCommunication')} breadcrumbItems={[
                                { title: t("ScheduleCommunication"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('ScheduleCommunication')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('ScheduleCommunication')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Key")} name="key"
                            rules={[{ required: false, message: 'Please input the ' + t("Key") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Source")} name="source"
                            rules={[{ required: false, message: 'Please input the ' + t("Source") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Title")} name="title"
                            rules={[{ required: false, message: 'Please input the ' + t("Title") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Description")} name="description"
                            rules={[{ required: false, message: 'Please input the ' + t("Description") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Link")} name="link"
                            rules={[{ required: false, message: 'Please input the ' + t("Link") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("ImageUrl")} name="imageUrl"
                            rules={[{ required: false, message: 'Please input the ' + t("ImageUrl") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("DocumentUrl")} name="documentUrl"
                            rules={[{ required: false, message: 'Please input the ' + t("DocumentUrl") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("OfferId")} name="offerId"
                            rules={[{ required: false, message: 'Please input the ' + t("OfferId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Step")} name="step"
                            rules={[{ required: false, message: 'Please input the ' + t("Step") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("BlockchainTokenId")} name="blockchainTokenId"
                            rules={[{ required: false, message: 'Please input the ' + t("BlockchainTokenId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("BankName")} name="bankName"
                            rules={[{ required: false, message: 'Please input the ' + t("BankName") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("AgencyNumber")} name="agencyNumber"
                            rules={[{ required: false, message: 'Please input the ' + t("AgencyNumber") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("AccountNumber")} name="accountNumber"
                            rules={[{ required: false, message: 'Please input the ' + t("AccountNumber") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("AccountOwner")} name="accountOwner"
                            rules={[{ required: false, message: 'Please input the ' + t("AccountOwner") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Document")} name="document"
                            rules={[{ required: false, message: 'Please input the ' + t("Document") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Kind")} name="kind"
                            rules={[{ required: false, message: 'Please input the ' + t("Kind") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("TokenName")} name="tokenName"
                            rules={[{ required: false, message: 'Please input the ' + t("TokenName") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("InvestorsQty")} name="investorsQty"
                            rules={[{ required: false, message: 'Please input the ' + t("InvestorsQty") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Parcel")} name="parcel"
                            rules={[{ required: false, message: 'Please input the ' + t("Parcel") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("TotalParcel")} name="totalParcel"
                            rules={[{ required: false, message: 'Please input the ' + t("TotalParcel") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("PaymentDate")} name="paymentDate"
                            rules={[{ required: false, message: 'Please input the ' + t("PaymentDate") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Value")} name="value"
                            rules={[{ required: false, message: 'Please input the ' + t("Value") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Status")} name="status"
                            rules={[{ required: true, message: 'Please input the ' + t("Status") + '!' }]}>
                                <Input />
                            </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`ScheduleCommunicationEmails`)}>
                            <ScheduleCommunicationEmailsList relation={{model: "scheduleCommunication", field: "scheduleCommunicationId", textField: "key", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.key}}></ScheduleCommunicationEmailsList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={2} tab={t(`ScheduleCommunicationFields`)}>
                            <ScheduleCommunicationFieldsList relation={{model: "scheduleCommunication", field: "scheduleCommunicationId", textField: "key", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.key}}></ScheduleCommunicationFieldsList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (ScheduleCommunicationDetails);
  