//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { TokenCategory } from '../../../models/prisma/tokenCategory.model';
        import { GET_LIST, startedFilter } from './tokenCategory.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import PublicFileDetails from '../publicFile/publicFile.details';
import BlockchainTokenList from '../blockchainToken/blockchainToken.list';
import UserRoleTokenCategoryMapList from '../userRoleTokenCategoryMap/userRoleTokenCategoryMap.list';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            tokenCategoryCms: TokenCategory
        }>`
        query TokenCategoryCms($id: String)
        {
            tokenCategoryCms(id: $id)
          {
            id
                    description
                    imgPubFileUrl
                    imgPubFileId
                    imgPubFile {
                        id
                        url
                    }
                    isPublic
                    title
                    subtitle
                    fullDescription
                    categoryImgPubFileUrl
                    categoryImgPubFileId
                    iconImgPubFileUrl
                    iconImgPubFileId
                    logoImgPubFileUrl
                    logoImgPubFileId
                    createdAt
                    updatedAt
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<TokenCategory>`
        mutation TokenCategorySave(
            $id: String,
                        $description: String,
                        $imgPubFileUrl: String,
                        $imgPubFileId: String,
                        $imgPubFile: PublicFileCreateInputs,
                    $isPublic: Boolean,
                        $title: String,
                        $subtitle: String,
                        $fullDescription: String,
                        $categoryImgPubFileUrl: String,
                        $categoryImgPubFileId: String,
                        $iconImgPubFileUrl: String,
                        $iconImgPubFileId: String,
                        $logoImgPubFileUrl: String,
                        $logoImgPubFileId: String,
                        
        )
        {
            tokenCategoryCreateCms(data: {
                id: $id,
                    description: $description,
                    imgPubFileUrl: $imgPubFileUrl,
                    imgPubFileId: $imgPubFileId,
                    imgPubFile: $imgPubFile,
                    isPublic: $isPublic,
                    title: $title,
                    subtitle: $subtitle,
                    fullDescription: $fullDescription,
                    categoryImgPubFileUrl: $categoryImgPubFileUrl,
                    categoryImgPubFileId: $categoryImgPubFileId,
                    iconImgPubFileUrl: $iconImgPubFileUrl,
                    iconImgPubFileId: $iconImgPubFileId,
                    logoImgPubFileUrl: $logoImgPubFileUrl,
                    logoImgPubFileId: $logoImgPubFileId,
                    
            }) {
                id
                    description
                    imgPubFileUrl
                    imgPubFileId
                    imgPubFile {
                        id
                        url
                    }
                    isPublic
                    title
                    subtitle
                    fullDescription
                    categoryImgPubFileUrl
                    categoryImgPubFileId
                    iconImgPubFileUrl
                    iconImgPubFileId
                    logoImgPubFileUrl
                    logoImgPubFileId
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<TokenCategory>`
        mutation TokenCategorySave(
            $id: String,
                        $description: String,
                        $imgPubFileUrl: String,
                        $imgPubFileId: String,
                        $imgPubFile: PublicFileCreateInputs,
                    $isPublic: Boolean,
                        $title: String,
                        $subtitle: String,
                        $fullDescription: String,
                        $categoryImgPubFileUrl: String,
                        $categoryImgPubFileId: String,
                        $iconImgPubFileUrl: String,
                        $iconImgPubFileId: String,
                        $logoImgPubFileUrl: String,
                        $logoImgPubFileId: String,
                        
        )
        {
            tokenCategoryUpdateCms(data: {
                id: $id,
                    description: $description,
                    imgPubFileUrl: $imgPubFileUrl,
                    imgPubFileId: $imgPubFileId,
                    imgPubFile: $imgPubFile,
                    isPublic: $isPublic,
                    title: $title,
                    subtitle: $subtitle,
                    fullDescription: $fullDescription,
                    categoryImgPubFileUrl: $categoryImgPubFileUrl,
                    categoryImgPubFileId: $categoryImgPubFileId,
                    iconImgPubFileUrl: $iconImgPubFileUrl,
                    iconImgPubFileId: $iconImgPubFileId,
                    logoImgPubFileUrl: $logoImgPubFileUrl,
                    logoImgPubFileId: $logoImgPubFileId,
                    
            }) {
                id
                    description
                    imgPubFileUrl
                    imgPubFileId
                    imgPubFile {
                        id
                        url
                    }
                    isPublic
                    title
                    subtitle
                    fullDescription
                    categoryImgPubFileUrl
                    categoryImgPubFileId
                    iconImgPubFileUrl
                    iconImgPubFileId
                    logoImgPubFileUrl
                    logoImgPubFileId
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        
                    const PUBLICFILE_QUERY = `
                    query publicFileListCms($search: Search) {
                        publicFileListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    url
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const TokenCategoryDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ tokenCategoryCms: TokenCategory }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.tokenCategoryCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("TokenCategory has been saved");
                    const model = entity?.data?.tokenCategoryUpdateCms || entity?.data?.tokenCategoryCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/tokenCategory-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('TokenCategory')} breadcrumbItems={[
                                { title: t("TokenCategory"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('TokenCategory')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('TokenCategory')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Description")} name="description"
                            rules={[{ required: true, message: 'Please input the ' + t("Description") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("ImgPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("imgPubFileId") + '!' }]}>
                    {!!databaseEntity?.imgPubFileId && !dropUpload['imgPubFileId'] && !files['imgPubFileId'] &&
                        <>
                            { !!download[databaseEntity?.imgPubFileId]  && <>
                        { !download[databaseEntity?.imgPubFileId + 'Error'] && <> 
                        
                            <a title="imgPubFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.imgPubFileId, "public") 
                            }}>
                            
                            <img alt="imgPubFileId" 
                            onError={() => { 
                                download[databaseEntity?.imgPubFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.imgPubFileId + 'Error']: true });
                            }} src={download[databaseEntity?.imgPubFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.imgPubFileId, "public") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, imgPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.imgPubFileId || dropUpload['imgPubFileId'] || files['imgPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, imgPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("IsPublic")} name="isPublic" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("Title")} name="title"
                            rules={[{ required: false, message: 'Please input the ' + t("Title") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Subtitle")} name="subtitle"
                            rules={[{ required: false, message: 'Please input the ' + t("Subtitle") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("FullDescription")}
                    rules={[{ required: false, message: 'Please input the ' + t("fullDescription") + '!' }]}>
                    <ReactQuill modules={{
                      toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                      ],
                      clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                      }
                  }} theme="snow" value={descriptions['fullDescription'] || databaseEntity?.fullDescription ||''} onChange={(value) => {
                      setDescriptions({ ...descriptions, ['fullDescription']: value });
                  }} />
                </Form.Item><Form.Item label={t("CategoryImgPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("categoryImgPubFileId") + '!' }]}>
                    {!!databaseEntity?.categoryImgPubFileId && !dropUpload['categoryImgPubFileId'] && !files['categoryImgPubFileId'] &&
                        <>
                            { !!download[databaseEntity?.categoryImgPubFileId]  && <>
                        { !download[databaseEntity?.categoryImgPubFileId + 'Error'] && <> 
                        
                            <a title="categoryImgPubFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.categoryImgPubFileId, "public") 
                            }}>
                            
                            <img alt="categoryImgPubFileId" 
                            onError={() => { 
                                download[databaseEntity?.categoryImgPubFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.categoryImgPubFileId + 'Error']: true });
                            }} src={download[databaseEntity?.categoryImgPubFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.categoryImgPubFileId, "public") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, categoryImgPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.categoryImgPubFileId || dropUpload['categoryImgPubFileId'] || files['categoryImgPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, categoryImgPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("IconImgPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("iconImgPubFileId") + '!' }]}>
                    {!!databaseEntity?.iconImgPubFileId && !dropUpload['iconImgPubFileId'] && !files['iconImgPubFileId'] &&
                        <>
                            { !!download[databaseEntity?.iconImgPubFileId]  && <>
                        { !download[databaseEntity?.iconImgPubFileId + 'Error'] && <> 
                        
                            <a title="iconImgPubFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.iconImgPubFileId, "public") 
                            }}>
                            
                            <img alt="iconImgPubFileId" 
                            onError={() => { 
                                download[databaseEntity?.iconImgPubFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.iconImgPubFileId + 'Error']: true });
                            }} src={download[databaseEntity?.iconImgPubFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.iconImgPubFileId, "public") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, iconImgPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.iconImgPubFileId || dropUpload['iconImgPubFileId'] || files['iconImgPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, iconImgPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("LogoImgPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("logoImgPubFileId") + '!' }]}>
                    {!!databaseEntity?.logoImgPubFileId && !dropUpload['logoImgPubFileId'] && !files['logoImgPubFileId'] &&
                        <>
                            { !!download[databaseEntity?.logoImgPubFileId]  && <>
                        { !download[databaseEntity?.logoImgPubFileId + 'Error'] && <> 
                        
                            <a title="logoImgPubFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.logoImgPubFileId, "public") 
                            }}>
                            
                            <img alt="logoImgPubFileId" 
                            onError={() => { 
                                download[databaseEntity?.logoImgPubFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.logoImgPubFileId + 'Error']: true });
                            }} src={download[databaseEntity?.logoImgPubFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.logoImgPubFileId, "public") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, logoImgPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.logoImgPubFileId || dropUpload['logoImgPubFileId'] || files['logoImgPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, logoImgPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item>
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`BlockchainToken`)}>
                            <BlockchainTokenList relation={{model: "category", field: "tokenCategoryId", textField: "description", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.description}}></BlockchainTokenList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={2} tab={t(`UserRoleTokenCategoryMap`)}>
                            <UserRoleTokenCategoryMapList relation={{model: "tokenCategory", field: "tokenCategoryId", textField: "description", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.description}}></UserRoleTokenCategoryMapList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (TokenCategoryDetails);
  