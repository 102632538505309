//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { CompanyKYC } from '../../../models/prisma/companyKYC.model';
        import { GET_LIST, startedFilter } from './companyKYC.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import CompanyDetails from '../company/company.details';
import StateDetails from '../state/state.details';
import CountryDetails from '../country/country.details';
import DocumentStatusDetails from '../documentStatus/documentStatus.details';
import KycStatusDetails from '../kycStatus/kycStatus.details';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            companyKYCCms: CompanyKYC
        }>`
        query CompanyKYCCms($id: String)
        {
            companyKYCCms(id: $id)
          {
            id
                    step
                    company {
                        id
                        companyName
                    }
                    companyId
                    foundedAt
                    termsAccepted
                    termsAcceptedDate
                    companyDocument
                    companyName
                    tradeName
                    companyActivity
                    companyPostalcode
                    companyAddress
                    companyAddressNumber
                    companyAddressComplement
                    companyNeighborhood
                    companyCity
                    companyState {
                        id
                        description
                    }
                    companyStateId
                    companyCountry {
                        id
                        description
                    }
                    companyCountryId
                    documentStatus {
                        id
                        description
                    }
                    documentStatusId
                    deleted
                    createdAt
                    updatedAt
                    status {
                        id
                        description
                    }
                    statusId
                    invalidReason
                    fullMotiveRefused
                    fullDocumentResume
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<CompanyKYC>`
        mutation CompanyKYCSave(
            $id: String,
                        $step: Int,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $foundedAt: String,
                        $termsAccepted: Boolean,
                        $termsAcceptedDate: String,
                        $companyDocument: String,
                        $companyName: String,
                        $tradeName: String,
                        $companyActivity: String,
                        $companyPostalcode: String,
                        $companyAddress: String,
                        $companyAddressNumber: String,
                        $companyAddressComplement: String,
                        $companyNeighborhood: String,
                        $companyCity: String,
                        $companyState: StateCreateInputs,
                    $companyStateId: String,
                        $companyCountry: CountryCreateInputs,
                    $companyCountryId: String,
                        $documentStatus: DocumentStatusCreateInputs,
                    $documentStatusId: String,
                        $status: KycStatusCreateInputs,
                    $statusId: String,
                        $invalidReason: String,
                        $fullMotiveRefused: String,
                        $fullDocumentResume: String,
                        
        )
        {
            companyKYCCreateCms(data: {
                id: $id,
                    step: $step,
                    company: $company,
                    companyId: $companyId,
                    foundedAt: $foundedAt,
                    termsAccepted: $termsAccepted,
                    termsAcceptedDate: $termsAcceptedDate,
                    companyDocument: $companyDocument,
                    companyName: $companyName,
                    tradeName: $tradeName,
                    companyActivity: $companyActivity,
                    companyPostalcode: $companyPostalcode,
                    companyAddress: $companyAddress,
                    companyAddressNumber: $companyAddressNumber,
                    companyAddressComplement: $companyAddressComplement,
                    companyNeighborhood: $companyNeighborhood,
                    companyCity: $companyCity,
                    companyState: $companyState,
                    companyStateId: $companyStateId,
                    companyCountry: $companyCountry,
                    companyCountryId: $companyCountryId,
                    documentStatus: $documentStatus,
                    documentStatusId: $documentStatusId,
                    status: $status,
                    statusId: $statusId,
                    invalidReason: $invalidReason,
                    fullMotiveRefused: $fullMotiveRefused,
                    fullDocumentResume: $fullDocumentResume,
                    
            }) {
                id
                    step
                    company {
                        id
                        companyName
                    }
                    companyId
                    foundedAt
                    termsAccepted
                    termsAcceptedDate
                    companyDocument
                    companyName
                    tradeName
                    companyActivity
                    companyPostalcode
                    companyAddress
                    companyAddressNumber
                    companyAddressComplement
                    companyNeighborhood
                    companyCity
                    companyState {
                        id
                        description
                    }
                    companyStateId
                    companyCountry {
                        id
                        description
                    }
                    companyCountryId
                    documentStatus {
                        id
                        description
                    }
                    documentStatusId
                    deleted
                    createdAt
                    updatedAt
                    status {
                        id
                        description
                    }
                    statusId
                    invalidReason
                    fullMotiveRefused
                    fullDocumentResume
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<CompanyKYC>`
        mutation CompanyKYCSave(
            $id: String,
                        $step: Int,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $foundedAt: String,
                        $termsAccepted: Boolean,
                        $termsAcceptedDate: String,
                        $companyDocument: String,
                        $companyName: String,
                        $tradeName: String,
                        $companyActivity: String,
                        $companyPostalcode: String,
                        $companyAddress: String,
                        $companyAddressNumber: String,
                        $companyAddressComplement: String,
                        $companyNeighborhood: String,
                        $companyCity: String,
                        $companyState: StateCreateInputs,
                    $companyStateId: String,
                        $companyCountry: CountryCreateInputs,
                    $companyCountryId: String,
                        $documentStatus: DocumentStatusCreateInputs,
                    $documentStatusId: String,
                        $status: KycStatusCreateInputs,
                    $statusId: String,
                        $invalidReason: String,
                        $fullMotiveRefused: String,
                        $fullDocumentResume: String,
                        
        )
        {
            companyKYCUpdateCms(data: {
                id: $id,
                    step: $step,
                    company: $company,
                    companyId: $companyId,
                    foundedAt: $foundedAt,
                    termsAccepted: $termsAccepted,
                    termsAcceptedDate: $termsAcceptedDate,
                    companyDocument: $companyDocument,
                    companyName: $companyName,
                    tradeName: $tradeName,
                    companyActivity: $companyActivity,
                    companyPostalcode: $companyPostalcode,
                    companyAddress: $companyAddress,
                    companyAddressNumber: $companyAddressNumber,
                    companyAddressComplement: $companyAddressComplement,
                    companyNeighborhood: $companyNeighborhood,
                    companyCity: $companyCity,
                    companyState: $companyState,
                    companyStateId: $companyStateId,
                    companyCountry: $companyCountry,
                    companyCountryId: $companyCountryId,
                    documentStatus: $documentStatus,
                    documentStatusId: $documentStatusId,
                    status: $status,
                    statusId: $statusId,
                    invalidReason: $invalidReason,
                    fullMotiveRefused: $fullMotiveRefused,
                    fullDocumentResume: $fullDocumentResume,
                    
            }) {
                id
                    step
                    company {
                        id
                        companyName
                    }
                    companyId
                    foundedAt
                    termsAccepted
                    termsAcceptedDate
                    companyDocument
                    companyName
                    tradeName
                    companyActivity
                    companyPostalcode
                    companyAddress
                    companyAddressNumber
                    companyAddressComplement
                    companyNeighborhood
                    companyCity
                    companyState {
                        id
                        description
                    }
                    companyStateId
                    companyCountry {
                        id
                        description
                    }
                    companyCountryId
                    documentStatus {
                        id
                        description
                    }
                    documentStatusId
                    deleted
                    createdAt
                    updatedAt
                    status {
                        id
                        description
                    }
                    statusId
                    invalidReason
                    fullMotiveRefused
                    fullDocumentResume
                    
            }
        }
        `;

        
                    const COMPANY_QUERY = `
                    query companyListCms($search: Search) {
                        companyListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyName
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const STATE_QUERY = `
                    query stateListCms($search: Search) {
                        stateListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const COUNTRY_QUERY = `
                    query countryListCms($search: Search) {
                        countryListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const DOCUMENTSTATUS_QUERY = `
                    query documentStatusListCms($search: Search) {
                        documentStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const KYCSTATUS_QUERY = `
                    query kycStatusListCms($search: Search) {
                        kycStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const CompanyKYCDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ companyKYCCms: CompanyKYC }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.companyKYCCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("CompanyKYC has been saved");
                    const model = entity?.data?.companyKYCUpdateCms || entity?.data?.companyKYCCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/companyKYC-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('CompanyKYC')} breadcrumbItems={[
                                { title: t("CompanyKYC"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('CompanyKYC')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('CompanyKYC')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Step")} name="step"
                            rules={[{ required: true, message: 'Please input the ' + t("Step") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "companyId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Company')}</>}>
                                <Form.Item noStyle id="company_id" name="companyId"
                                rules={[{ required: (true && !(dataSources.dependencies?.company?.companyName || databaseEntity?.company?.companyName)), message: 'Please input the Company!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                value={ dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyId = newValue.key;
                                            dataSources.dependencies["company"] = {
                                                id: newValue.key,
                                                companyName: newValue.value
                                            };
                                            if(dataSources.dependencies["company"]) {
                                                delete dataSources.dependencies["company"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyId: newValue.key });
                                            setUncheck({ ...uncheck, companyId: false });
                                        }
                                        else if (databaseEntity?.company) {
                                            dataSources.dependencies.companyId = undefined;
                                            dataSources.dependencies["company"] = {
                                                id: undefined,
                                                companyName: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["company"] = {
                                            id: undefined,
                                            companyName: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}>
                                    {(dataSources["Company"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyName}>
                                            {item.companyName}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CompanycompanyIdEditVisible: !modals.CompanycompanyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanycompanyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CompanycompanyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.company?.id || databaseEntity?.company?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CompanycompanyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyId: entity?.id,
                                                    company: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CompanyAddVisible: !modals.CompanyAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanyAddVisible"]}
                                    onHide={() => setModals({ ...modals, CompanyAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CompanyAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyId: entity?.id,
                                                        company: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.company?.id || databaseEntity?.company?.id) && !uncheck['companyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyId: "",
                                            company: {
                                                companyName: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("FoundedAt")} name="foundedAt"
                            rules={[{ required: false, message: 'Please input the ' + t("FoundedAt") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.foundedAt ? moment(databaseEntity?.foundedAt, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['foundedAt'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("TermsAccepted")} name="termsAccepted" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("TermsAcceptedDate")} name="termsAcceptedDate"
                            rules={[{ required: false, message: 'Please input the ' + t("TermsAcceptedDate") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.termsAcceptedDate ? moment(databaseEntity?.termsAcceptedDate, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['termsAcceptedDate'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("CompanyDocument")} name="companyDocument"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyDocument") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CompanyName")} name="companyName"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyName") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("TradeName")} name="tradeName"
                            rules={[{ required: false, message: 'Please input the ' + t("TradeName") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CompanyActivity")} name="companyActivity"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyActivity") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CompanyPostalcode")} name="companyPostalcode"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyPostalcode") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CompanyAddress")} name="companyAddress"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyAddress") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CompanyAddressNumber")} name="companyAddressNumber"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyAddressNumber") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CompanyAddressComplement")} name="companyAddressComplement"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyAddressComplement") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CompanyNeighborhood")} name="companyNeighborhood"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyNeighborhood") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CompanyCity")} name="companyCity"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyCity") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "companyStateId") && <Form.Item label={<> {t('CompanyState')}</>}>
                                <Form.Item noStyle id="companyState_id" name="companyStateId"
                                rules={[{ required: (false && !(dataSources.dependencies?.companyState?.description || databaseEntity?.companyState?.description)), message: 'Please input the State!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.companyState ? dataSources.dependencies?.companyState?.description : databaseEntity?.companyState?.description}
                                value={ dataSources.dependencies?.companyState ? dataSources.dependencies?.companyState?.description : databaseEntity?.companyState?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyStateId = newValue.key;
                                            dataSources.dependencies["companyState"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["companyState"]) {
                                                delete dataSources.dependencies["companyState"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyStateId: newValue.key });
                                            setUncheck({ ...uncheck, companyStateId: false });
                                        }
                                        else if (databaseEntity?.companyState) {
                                            dataSources.dependencies.companyStateId = undefined;
                                            dataSources.dependencies["companyState"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["companyState"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyStateId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "State", query: STATE_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "State", query: STATE_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["State"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, StatecompanyStateIdEditVisible: !modals.StatecompanyStateIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["StatecompanyStateIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, StatecompanyStateIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        State
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StateDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.companyState?.id || databaseEntity?.companyState?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, StatecompanyStateIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyStateId: entity?.id,
                                                    companyState: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, StateAddVisible: !modals.StateAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["StateAddVisible"]}
                                    onHide={() => setModals({ ...modals, StateAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            State
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StateDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, StateAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyStateId: entity?.id,
                                                        companyState: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.companyState?.id || databaseEntity?.companyState?.id) && !uncheck['companyStateId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyStateId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyStateId: "",
                                            companyState: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "companyCountryId") && <Form.Item label={<> {t('CompanyCountry')}</>}>
                                <Form.Item noStyle id="companyCountry_id" name="companyCountryId"
                                rules={[{ required: (false && !(dataSources.dependencies?.companyCountry?.description || databaseEntity?.companyCountry?.description)), message: 'Please input the Country!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.companyCountry ? dataSources.dependencies?.companyCountry?.description : databaseEntity?.companyCountry?.description}
                                value={ dataSources.dependencies?.companyCountry ? dataSources.dependencies?.companyCountry?.description : databaseEntity?.companyCountry?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyCountryId = newValue.key;
                                            dataSources.dependencies["companyCountry"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["companyCountry"]) {
                                                delete dataSources.dependencies["companyCountry"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyCountryId: newValue.key });
                                            setUncheck({ ...uncheck, companyCountryId: false });
                                        }
                                        else if (databaseEntity?.companyCountry) {
                                            dataSources.dependencies.companyCountryId = undefined;
                                            dataSources.dependencies["companyCountry"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["companyCountry"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyCountryId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Country", query: COUNTRY_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Country", query: COUNTRY_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["Country"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CountrycompanyCountryIdEditVisible: !modals.CountrycompanyCountryIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CountrycompanyCountryIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CountrycompanyCountryIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Country
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CountryDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.companyCountry?.id || databaseEntity?.companyCountry?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CountrycompanyCountryIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyCountryId: entity?.id,
                                                    companyCountry: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CountryAddVisible: !modals.CountryAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CountryAddVisible"]}
                                    onHide={() => setModals({ ...modals, CountryAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Country
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CountryDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CountryAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyCountryId: entity?.id,
                                                        companyCountry: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.companyCountry?.id || databaseEntity?.companyCountry?.id) && !uncheck['companyCountryId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyCountryId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyCountryId: "",
                                            companyCountry: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "documentStatusId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('DocumentStatus')}</>}>
                                <Form.Item noStyle id="documentStatus_id" name="documentStatusId"
                                rules={[{ required: (true && !(dataSources.dependencies?.documentStatus?.description || databaseEntity?.documentStatus?.description)), message: 'Please input the DocumentStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.documentStatus ? dataSources.dependencies?.documentStatus?.description : databaseEntity?.documentStatus?.description}
                                value={ dataSources.dependencies?.documentStatus ? dataSources.dependencies?.documentStatus?.description : databaseEntity?.documentStatus?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.documentStatusId = newValue.key;
                                            dataSources.dependencies["documentStatus"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["documentStatus"]) {
                                                delete dataSources.dependencies["documentStatus"].__typename;
                                            }                                            
                                            setSelects({ ...selects, documentStatusId: newValue.key });
                                            setUncheck({ ...uncheck, documentStatusId: false });
                                        }
                                        else if (databaseEntity?.documentStatus) {
                                            dataSources.dependencies.documentStatusId = undefined;
                                            dataSources.dependencies["documentStatus"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["documentStatus"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.documentStatusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "DocumentStatus", query: DOCUMENTSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "DocumentStatus", query: DOCUMENTSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["DocumentStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, DocumentStatusdocumentStatusIdEditVisible: !modals.DocumentStatusdocumentStatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["DocumentStatusdocumentStatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, DocumentStatusdocumentStatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        DocumentStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <DocumentStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.documentStatus?.id || databaseEntity?.documentStatus?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, DocumentStatusdocumentStatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    documentStatusId: entity?.id,
                                                    documentStatus: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, DocumentStatusAddVisible: !modals.DocumentStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["DocumentStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, DocumentStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            DocumentStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <DocumentStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, DocumentStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        documentStatusId: entity?.id,
                                                        documentStatus: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.documentStatus?.id || databaseEntity?.documentStatus?.id) && !uncheck['documentStatusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, documentStatusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            documentStatusId: "",
                                            documentStatus: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "statusId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Status')}</>}>
                                <Form.Item noStyle id="status_id" name="statusId"
                                rules={[{ required: (true && !(dataSources.dependencies?.status?.description || databaseEntity?.status?.description)), message: 'Please input the KycStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                value={ dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.statusId = newValue.key;
                                            dataSources.dependencies["status"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["status"]) {
                                                delete dataSources.dependencies["status"].__typename;
                                            }                                            
                                            setSelects({ ...selects, statusId: newValue.key });
                                            setUncheck({ ...uncheck, statusId: false });
                                        }
                                        else if (databaseEntity?.status) {
                                            dataSources.dependencies.statusId = undefined;
                                            dataSources.dependencies["status"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["status"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.statusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "KycStatus", query: KYCSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "KycStatus", query: KYCSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["KycStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, KycStatusstatusIdEditVisible: !modals.KycStatusstatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["KycStatusstatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, KycStatusstatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        KycStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <KycStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.status?.id || databaseEntity?.status?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, KycStatusstatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    statusId: entity?.id,
                                                    status: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, KycStatusAddVisible: !modals.KycStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["KycStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, KycStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            KycStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <KycStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, KycStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        statusId: entity?.id,
                                                        status: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.status?.id || databaseEntity?.status?.id) && !uncheck['statusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, statusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            statusId: "",
                                            status: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("InvalidReason")} name="invalidReason"
                            rules={[{ required: false, message: 'Please input the ' + t("InvalidReason") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("FullMotiveRefused")}
                    rules={[{ required: false, message: 'Please input the ' + t("fullMotiveRefused") + '!' }]}>
                    <ReactQuill modules={{
                      toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                      ],
                      clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                      }
                  }} theme="snow" value={descriptions['fullMotiveRefused'] || databaseEntity?.fullMotiveRefused ||''} onChange={(value) => {
                      setDescriptions({ ...descriptions, ['fullMotiveRefused']: value });
                  }} />
                </Form.Item><Form.Item label={t("FullDocumentResume")}
                    rules={[{ required: false, message: 'Please input the ' + t("fullDocumentResume") + '!' }]}>
                    <ReactQuill modules={{
                      toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                      ],
                      clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                      }
                  }} theme="snow" value={descriptions['fullDocumentResume'] || databaseEntity?.fullDocumentResume ||''} onChange={(value) => {
                      setDescriptions({ ...descriptions, ['fullDocumentResume']: value });
                  }} />
                </Form.Item>
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (CompanyKYCDetails);
  