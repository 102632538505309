//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { BankTransaction } from '../../../models/prisma/bankTransaction.model';
        import { GET_LIST, startedFilter } from './bankTransaction.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import BankTransactionTypeDetails from '../bankTransactionType/bankTransactionType.details';
import BankDetails from '../bank/bank.details';
import BlockchainTokenDetails from '../blockchainToken/blockchainToken.details';
import OfferDetails from '../offer/offer.details';
import InvestorDetails from '../investor/investor.details';
import CompanyDetails from '../company/company.details';
import BankTransactionOperationDetails from '../bankTransactionOperation/bankTransactionOperation.details';
import BankTransactionStatusDetails from '../bankTransactionStatus/bankTransactionStatus.details';
import BlockchainTaxDetails from '../blockchainTax/blockchainTax.details';
import PixKeyTypeDetails from '../pixKeyType/pixKeyType.details';
import BankAccountTypeDetails from '../bankAccountType/bankAccountType.details';
import StatementList from '../statement/statement.list';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            bankTransactionCms: BankTransaction
        }>`
        query BankTransactionCms($id: String)
        {
            bankTransactionCms(id: $id)
          {
            id
                    bankTransactionId
                    type {
                        id
                        description
                    }
                    pixKey
                    document
                    bank {
                        id
                        name
                    }
                    agencyNumber
                    agencyDigit
                    accountNumber
                    accountDigit
                    stableCoin {
                        id
                        name
                    }
                    stableCoinId
                    token {
                        id
                        name
                    }
                    tokenId
                    offer {
                        id
                        title
                    }
                    offerId
                    amount
                    investor {
                        id
                        companyId
                    }
                    investorId
                    company {
                        id
                        companyName
                    }
                    companyId
                    operation {
                        id
                        description
                    }
                    operationId
                    status {
                        id
                        description
                    }
                    statusId
                    tries
                    error
                    deleted
                    createdAt
                    typeId
                    updatedAt
                    tax {
                        id
                        hash
                    }
                    taxId
                    bankId
                    totalTax
                    pixKeyType {
                        id
                        title
                    }
                    pixKeyTypeId
                    bankAccountType {
                        id
                        title
                    }
                    bankAccountTypeId
                    appliedFee
                    transactionId
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<BankTransaction>`
        mutation BankTransactionSave(
            $id: String,
                        $bankTransactionId: String,
                        $type: BankTransactionTypeCreateInputs,
                    $pixKey: String,
                        $document: String,
                        $bank: BankCreateInputs,
                    $agencyNumber: String,
                        $agencyDigit: String,
                        $accountNumber: String,
                        $accountDigit: String,
                        $stableCoin: BlockchainTokenCreateInputs,
                    $stableCoinId: String,
                        $token: BlockchainTokenCreateInputs,
                    $tokenId: String,
                        $offer: OfferCreateInputs,
                    $offerId: String,
                        $amount: Float,
                        $investor: InvestorCreateInputs,
                    $investorId: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $operation: BankTransactionOperationCreateInputs,
                    $operationId: String,
                        $status: BankTransactionStatusCreateInputs,
                    $statusId: String,
                        $tries: Int,
                        $error: String,
                        $typeId: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $bankId: String,
                        $totalTax: Float,
                        $pixKeyType: PixKeyTypeCreateInputs,
                    $pixKeyTypeId: String,
                        $bankAccountType: BankAccountTypeCreateInputs,
                    $bankAccountTypeId: String,
                        $appliedFee: Float,
                        $transactionId: String,
                        
        )
        {
            bankTransactionCreateCms(data: {
                id: $id,
                    bankTransactionId: $bankTransactionId,
                    type: $type,
                    pixKey: $pixKey,
                    document: $document,
                    bank: $bank,
                    agencyNumber: $agencyNumber,
                    agencyDigit: $agencyDigit,
                    accountNumber: $accountNumber,
                    accountDigit: $accountDigit,
                    stableCoin: $stableCoin,
                    stableCoinId: $stableCoinId,
                    token: $token,
                    tokenId: $tokenId,
                    offer: $offer,
                    offerId: $offerId,
                    amount: $amount,
                    investor: $investor,
                    investorId: $investorId,
                    company: $company,
                    companyId: $companyId,
                    operation: $operation,
                    operationId: $operationId,
                    status: $status,
                    statusId: $statusId,
                    tries: $tries,
                    error: $error,
                    typeId: $typeId,
                    tax: $tax,
                    taxId: $taxId,
                    bankId: $bankId,
                    totalTax: $totalTax,
                    pixKeyType: $pixKeyType,
                    pixKeyTypeId: $pixKeyTypeId,
                    bankAccountType: $bankAccountType,
                    bankAccountTypeId: $bankAccountTypeId,
                    appliedFee: $appliedFee,
                    transactionId: $transactionId,
                    
            }) {
                id
                    bankTransactionId
                    type {
                        id
                        description
                    }
                    pixKey
                    document
                    bank {
                        id
                        name
                    }
                    agencyNumber
                    agencyDigit
                    accountNumber
                    accountDigit
                    stableCoin {
                        id
                        name
                    }
                    stableCoinId
                    token {
                        id
                        name
                    }
                    tokenId
                    offer {
                        id
                        title
                    }
                    offerId
                    amount
                    investor {
                        id
                        companyId
                    }
                    investorId
                    company {
                        id
                        companyName
                    }
                    companyId
                    operation {
                        id
                        description
                    }
                    operationId
                    status {
                        id
                        description
                    }
                    statusId
                    tries
                    error
                    deleted
                    createdAt
                    typeId
                    updatedAt
                    tax {
                        id
                        hash
                    }
                    taxId
                    bankId
                    totalTax
                    pixKeyType {
                        id
                        title
                    }
                    pixKeyTypeId
                    bankAccountType {
                        id
                        title
                    }
                    bankAccountTypeId
                    appliedFee
                    transactionId
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<BankTransaction>`
        mutation BankTransactionSave(
            $id: String,
                        $bankTransactionId: String,
                        $type: BankTransactionTypeCreateInputs,
                    $pixKey: String,
                        $document: String,
                        $bank: BankCreateInputs,
                    $agencyNumber: String,
                        $agencyDigit: String,
                        $accountNumber: String,
                        $accountDigit: String,
                        $stableCoin: BlockchainTokenCreateInputs,
                    $stableCoinId: String,
                        $token: BlockchainTokenCreateInputs,
                    $tokenId: String,
                        $offer: OfferCreateInputs,
                    $offerId: String,
                        $amount: Float,
                        $investor: InvestorCreateInputs,
                    $investorId: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $operation: BankTransactionOperationCreateInputs,
                    $operationId: String,
                        $status: BankTransactionStatusCreateInputs,
                    $statusId: String,
                        $tries: Int,
                        $error: String,
                        $typeId: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $bankId: String,
                        $totalTax: Float,
                        $pixKeyType: PixKeyTypeCreateInputs,
                    $pixKeyTypeId: String,
                        $bankAccountType: BankAccountTypeCreateInputs,
                    $bankAccountTypeId: String,
                        $appliedFee: Float,
                        $transactionId: String,
                        
        )
        {
            bankTransactionUpdateCms(data: {
                id: $id,
                    bankTransactionId: $bankTransactionId,
                    type: $type,
                    pixKey: $pixKey,
                    document: $document,
                    bank: $bank,
                    agencyNumber: $agencyNumber,
                    agencyDigit: $agencyDigit,
                    accountNumber: $accountNumber,
                    accountDigit: $accountDigit,
                    stableCoin: $stableCoin,
                    stableCoinId: $stableCoinId,
                    token: $token,
                    tokenId: $tokenId,
                    offer: $offer,
                    offerId: $offerId,
                    amount: $amount,
                    investor: $investor,
                    investorId: $investorId,
                    company: $company,
                    companyId: $companyId,
                    operation: $operation,
                    operationId: $operationId,
                    status: $status,
                    statusId: $statusId,
                    tries: $tries,
                    error: $error,
                    typeId: $typeId,
                    tax: $tax,
                    taxId: $taxId,
                    bankId: $bankId,
                    totalTax: $totalTax,
                    pixKeyType: $pixKeyType,
                    pixKeyTypeId: $pixKeyTypeId,
                    bankAccountType: $bankAccountType,
                    bankAccountTypeId: $bankAccountTypeId,
                    appliedFee: $appliedFee,
                    transactionId: $transactionId,
                    
            }) {
                id
                    bankTransactionId
                    type {
                        id
                        description
                    }
                    pixKey
                    document
                    bank {
                        id
                        name
                    }
                    agencyNumber
                    agencyDigit
                    accountNumber
                    accountDigit
                    stableCoin {
                        id
                        name
                    }
                    stableCoinId
                    token {
                        id
                        name
                    }
                    tokenId
                    offer {
                        id
                        title
                    }
                    offerId
                    amount
                    investor {
                        id
                        companyId
                    }
                    investorId
                    company {
                        id
                        companyName
                    }
                    companyId
                    operation {
                        id
                        description
                    }
                    operationId
                    status {
                        id
                        description
                    }
                    statusId
                    tries
                    error
                    deleted
                    createdAt
                    typeId
                    updatedAt
                    tax {
                        id
                        hash
                    }
                    taxId
                    bankId
                    totalTax
                    pixKeyType {
                        id
                        title
                    }
                    pixKeyTypeId
                    bankAccountType {
                        id
                        title
                    }
                    bankAccountTypeId
                    appliedFee
                    transactionId
                    
            }
        }
        `;

        
                    const BANKTRANSACTIONTYPE_QUERY = `
                    query bankTransactionTypeListCms($search: Search) {
                        bankTransactionTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BANK_QUERY = `
                    query bankListCms($search: Search) {
                        bankListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTOKEN_QUERY = `
                    query blockchainTokenListCms($search: Search) {
                        blockchainTokenListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const OFFER_QUERY = `
                    query offerListCms($search: Search) {
                        offerListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const INVESTOR_QUERY = `
                    query investorListCms($search: Search) {
                        investorListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyId
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const COMPANY_QUERY = `
                    query companyListCms($search: Search) {
                        companyListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyName
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BANKTRANSACTIONOPERATION_QUERY = `
                    query bankTransactionOperationListCms($search: Search) {
                        bankTransactionOperationListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BANKTRANSACTIONSTATUS_QUERY = `
                    query bankTransactionStatusListCms($search: Search) {
                        bankTransactionStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTAX_QUERY = `
                    query blockchainTaxListCms($search: Search) {
                        blockchainTaxListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    hash
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const PIXKEYTYPE_QUERY = `
                    query pixKeyTypeListCms($search: Search) {
                        pixKeyTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BANKACCOUNTTYPE_QUERY = `
                    query bankAccountTypeListCms($search: Search) {
                        bankAccountTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const BankTransactionDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ bankTransactionCms: BankTransaction }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.bankTransactionCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("BankTransaction has been saved");
                    const model = entity?.data?.bankTransactionUpdateCms || entity?.data?.bankTransactionCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/bankTransaction-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('BankTransaction')} breadcrumbItems={[
                                { title: t("BankTransaction"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('BankTransaction')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('BankTransaction')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("BankTransactionId")} name="bankTransactionId"
                            rules={[{ required: false, message: 'Please input the ' + t("BankTransactionId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "typeId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Type')}</>}>
                                <Form.Item noStyle id="type_id" name="typeId"
                                rules={[{ required: (true && !(dataSources.dependencies?.type?.description || databaseEntity?.type?.description)), message: 'Please input the BankTransactionType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.type ? dataSources.dependencies?.type?.description : databaseEntity?.type?.description}
                                value={ dataSources.dependencies?.type ? dataSources.dependencies?.type?.description : databaseEntity?.type?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.typeId = newValue.key;
                                            dataSources.dependencies["type"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["type"]) {
                                                delete dataSources.dependencies["type"].__typename;
                                            }                                            
                                            setSelects({ ...selects, typeId: newValue.key });
                                            setUncheck({ ...uncheck, typeId: false });
                                        }
                                        else if (databaseEntity?.type) {
                                            dataSources.dependencies.typeId = undefined;
                                            dataSources.dependencies["type"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["type"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.typeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BankTransactionType", query: BANKTRANSACTIONTYPE_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BankTransactionType", query: BANKTRANSACTIONTYPE_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["BankTransactionType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BankTransactionTypetypeIdEditVisible: !modals.BankTransactionTypetypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankTransactionTypetypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BankTransactionTypetypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BankTransactionType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankTransactionTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.type?.id || databaseEntity?.type?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BankTransactionTypetypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    typeId: entity?.id,
                                                    type: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BankTransactionTypeAddVisible: !modals.BankTransactionTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankTransactionTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, BankTransactionTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BankTransactionType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankTransactionTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BankTransactionTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        typeId: entity?.id,
                                                        type: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.type?.id || databaseEntity?.type?.id) && !uncheck['typeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, typeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            typeId: "",
                                            type: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("PixKey")} name="pixKey"
                            rules={[{ required: false, message: 'Please input the ' + t("PixKey") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Document")} name="document"
                            rules={[{ required: true, message: 'Please input the ' + t("Document") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "bankId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Bank')}</>}>
                                <Form.Item noStyle id="bank_id" name="bankId"
                                rules={[{ required: (true && !(dataSources.dependencies?.bank?.name || databaseEntity?.bank?.name)), message: 'Please input the Bank!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.bank ? dataSources.dependencies?.bank?.name : databaseEntity?.bank?.name}
                                value={ dataSources.dependencies?.bank ? dataSources.dependencies?.bank?.name : databaseEntity?.bank?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.bankId = newValue.key;
                                            dataSources.dependencies["bank"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["bank"]) {
                                                delete dataSources.dependencies["bank"].__typename;
                                            }                                            
                                            setSelects({ ...selects, bankId: newValue.key });
                                            setUncheck({ ...uncheck, bankId: false });
                                        }
                                        else if (databaseEntity?.bank) {
                                            dataSources.dependencies.bankId = undefined;
                                            dataSources.dependencies["bank"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["bank"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.bankId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Bank", query: BANK_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Bank", query: BANK_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["Bank"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BankbankIdEditVisible: !modals.BankbankIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankbankIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BankbankIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Bank
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.bank?.id || databaseEntity?.bank?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BankbankIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    bankId: entity?.id,
                                                    bank: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BankAddVisible: !modals.BankAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankAddVisible"]}
                                    onHide={() => setModals({ ...modals, BankAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Bank
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BankAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        bankId: entity?.id,
                                                        bank: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.bank?.id || databaseEntity?.bank?.id) && !uncheck['bankId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, bankId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            bankId: "",
                                            bank: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("AgencyNumber")} name="agencyNumber"
                            rules={[{ required: false, message: 'Please input the ' + t("AgencyNumber") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("AgencyDigit")} name="agencyDigit"
                            rules={[{ required: false, message: 'Please input the ' + t("AgencyDigit") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("AccountNumber")} name="accountNumber"
                            rules={[{ required: false, message: 'Please input the ' + t("AccountNumber") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("AccountDigit")} name="accountDigit"
                            rules={[{ required: false, message: 'Please input the ' + t("AccountDigit") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "stableCoinId") && <Form.Item label={<> {t('StableCoin')}</>}>
                                <Form.Item noStyle id="stableCoin_id" name="stableCoinId"
                                rules={[{ required: (false && !(dataSources.dependencies?.stableCoin?.name || databaseEntity?.stableCoin?.name)), message: 'Please input the BlockchainToken!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.stableCoin ? dataSources.dependencies?.stableCoin?.name : databaseEntity?.stableCoin?.name}
                                value={ dataSources.dependencies?.stableCoin ? dataSources.dependencies?.stableCoin?.name : databaseEntity?.stableCoin?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.stableCoinId = newValue.key;
                                            dataSources.dependencies["stableCoin"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["stableCoin"]) {
                                                delete dataSources.dependencies["stableCoin"].__typename;
                                            }                                            
                                            setSelects({ ...selects, stableCoinId: newValue.key });
                                            setUncheck({ ...uncheck, stableCoinId: false });
                                        }
                                        else if (databaseEntity?.stableCoin) {
                                            dataSources.dependencies.stableCoinId = undefined;
                                            dataSources.dependencies["stableCoin"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["stableCoin"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.stableCoinId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["BlockchainToken"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenstableCoinIdEditVisible: !modals.BlockchainTokenstableCoinIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenstableCoinIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenstableCoinIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.stableCoin?.id || databaseEntity?.stableCoin?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTokenstableCoinIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    stableCoinId: entity?.id,
                                                    stableCoin: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenAddVisible: !modals.BlockchainTokenAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTokenAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        stableCoinId: entity?.id,
                                                        stableCoin: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.stableCoin?.id || databaseEntity?.stableCoin?.id) && !uncheck['stableCoinId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, stableCoinId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            stableCoinId: "",
                                            stableCoin: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "tokenId") && <Form.Item label={<> {t('Token')}</>}>
                                <Form.Item noStyle id="token_id" name="tokenId"
                                rules={[{ required: (false && !(dataSources.dependencies?.token?.name || databaseEntity?.token?.name)), message: 'Please input the BlockchainToken!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.token ? dataSources.dependencies?.token?.name : databaseEntity?.token?.name}
                                value={ dataSources.dependencies?.token ? dataSources.dependencies?.token?.name : databaseEntity?.token?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.tokenId = newValue.key;
                                            dataSources.dependencies["token"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["token"]) {
                                                delete dataSources.dependencies["token"].__typename;
                                            }                                            
                                            setSelects({ ...selects, tokenId: newValue.key });
                                            setUncheck({ ...uncheck, tokenId: false });
                                        }
                                        else if (databaseEntity?.token) {
                                            dataSources.dependencies.tokenId = undefined;
                                            dataSources.dependencies["token"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["token"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.tokenId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["BlockchainToken"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTokentokenIdEditVisible: !modals.BlockchainTokentokenIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokentokenIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokentokenIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.token?.id || databaseEntity?.token?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTokentokenIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    tokenId: entity?.id,
                                                    token: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenAddVisible: !modals.BlockchainTokenAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTokenAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        tokenId: entity?.id,
                                                        token: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.token?.id || databaseEntity?.token?.id) && !uncheck['tokenId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, tokenId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            tokenId: "",
                                            token: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "offerId") && <Form.Item label={<> {t('Offer')}</>}>
                                <Form.Item noStyle id="offer_id" name="offerId"
                                rules={[{ required: (false && !(dataSources.dependencies?.offer?.title || databaseEntity?.offer?.title)), message: 'Please input the Offer!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.offer ? dataSources.dependencies?.offer?.title : databaseEntity?.offer?.title}
                                value={ dataSources.dependencies?.offer ? dataSources.dependencies?.offer?.title : databaseEntity?.offer?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.offerId = newValue.key;
                                            dataSources.dependencies["offer"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["offer"]) {
                                                delete dataSources.dependencies["offer"].__typename;
                                            }                                            
                                            setSelects({ ...selects, offerId: newValue.key });
                                            setUncheck({ ...uncheck, offerId: false });
                                        }
                                        else if (databaseEntity?.offer) {
                                            dataSources.dependencies.offerId = undefined;
                                            dataSources.dependencies["offer"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["offer"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.offerId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Offer", query: OFFER_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Offer", query: OFFER_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["Offer"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OfferofferIdEditVisible: !modals.OfferofferIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferofferIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OfferofferIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Offer
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.offer?.id || databaseEntity?.offer?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OfferofferIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    offerId: entity?.id,
                                                    offer: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OfferAddVisible: !modals.OfferAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferAddVisible"]}
                                    onHide={() => setModals({ ...modals, OfferAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Offer
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OfferAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        offerId: entity?.id,
                                                        offer: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.offer?.id || databaseEntity?.offer?.id) && !uncheck['offerId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, offerId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            offerId: "",
                                            offer: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Amount")} name="amount"
                            rules={[{ required: true, message: 'Please input the ' + t("Amount") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "investorId") && <Form.Item label={<> {t('Investor')}</>}>
                                <Form.Item noStyle id="investor_id" name="investorId"
                                rules={[{ required: (false && !(dataSources.dependencies?.investor?.companyId || databaseEntity?.investor?.companyId)), message: 'Please input the Investor!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.investor ? dataSources.dependencies?.investor?.companyId : databaseEntity?.investor?.companyId}
                                value={ dataSources.dependencies?.investor ? dataSources.dependencies?.investor?.companyId : databaseEntity?.investor?.companyId}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.investorId = newValue.key;
                                            dataSources.dependencies["investor"] = {
                                                id: newValue.key,
                                                companyId: newValue.value
                                            };
                                            if(dataSources.dependencies["investor"]) {
                                                delete dataSources.dependencies["investor"].__typename;
                                            }                                            
                                            setSelects({ ...selects, investorId: newValue.key });
                                            setUncheck({ ...uncheck, investorId: false });
                                        }
                                        else if (databaseEntity?.investor) {
                                            dataSources.dependencies.investorId = undefined;
                                            dataSources.dependencies["investor"] = {
                                                id: undefined,
                                                companyId: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["investor"] = {
                                            id: undefined,
                                            companyId: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.investorId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Investor", query: INVESTOR_QUERY, idField: "id", textField: "companyId" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Investor", query: INVESTOR_QUERY, idField: "id", textField: "companyId" });
                                    }}>
                                    {(dataSources["Investor"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyId}>
                                            {item.companyId}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, InvestorinvestorIdEditVisible: !modals.InvestorinvestorIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["InvestorinvestorIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, InvestorinvestorIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Investor
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InvestorDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.investor?.id || databaseEntity?.investor?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, InvestorinvestorIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    investorId: entity?.id,
                                                    investor: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, InvestorAddVisible: !modals.InvestorAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["InvestorAddVisible"]}
                                    onHide={() => setModals({ ...modals, InvestorAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Investor
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InvestorDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, InvestorAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        investorId: entity?.id,
                                                        investor: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.investor?.id || databaseEntity?.investor?.id) && !uncheck['investorId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, investorId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            investorId: "",
                                            investor: {
                                                companyId: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "companyId") && <Form.Item label={<> {t('Company')}</>}>
                                <Form.Item noStyle id="company_id" name="companyId"
                                rules={[{ required: (false && !(dataSources.dependencies?.company?.companyName || databaseEntity?.company?.companyName)), message: 'Please input the Company!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                value={ dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyId = newValue.key;
                                            dataSources.dependencies["company"] = {
                                                id: newValue.key,
                                                companyName: newValue.value
                                            };
                                            if(dataSources.dependencies["company"]) {
                                                delete dataSources.dependencies["company"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyId: newValue.key });
                                            setUncheck({ ...uncheck, companyId: false });
                                        }
                                        else if (databaseEntity?.company) {
                                            dataSources.dependencies.companyId = undefined;
                                            dataSources.dependencies["company"] = {
                                                id: undefined,
                                                companyName: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["company"] = {
                                            id: undefined,
                                            companyName: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}>
                                    {(dataSources["Company"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyName}>
                                            {item.companyName}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CompanycompanyIdEditVisible: !modals.CompanycompanyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanycompanyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CompanycompanyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.company?.id || databaseEntity?.company?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CompanycompanyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyId: entity?.id,
                                                    company: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CompanyAddVisible: !modals.CompanyAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanyAddVisible"]}
                                    onHide={() => setModals({ ...modals, CompanyAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CompanyAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyId: entity?.id,
                                                        company: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.company?.id || databaseEntity?.company?.id) && !uncheck['companyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyId: "",
                                            company: {
                                                companyName: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "operationId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Operation')}</>}>
                                <Form.Item noStyle id="operation_id" name="operationId"
                                rules={[{ required: (true && !(dataSources.dependencies?.operation?.description || databaseEntity?.operation?.description)), message: 'Please input the BankTransactionOperation!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.operation ? dataSources.dependencies?.operation?.description : databaseEntity?.operation?.description}
                                value={ dataSources.dependencies?.operation ? dataSources.dependencies?.operation?.description : databaseEntity?.operation?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.operationId = newValue.key;
                                            dataSources.dependencies["operation"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["operation"]) {
                                                delete dataSources.dependencies["operation"].__typename;
                                            }                                            
                                            setSelects({ ...selects, operationId: newValue.key });
                                            setUncheck({ ...uncheck, operationId: false });
                                        }
                                        else if (databaseEntity?.operation) {
                                            dataSources.dependencies.operationId = undefined;
                                            dataSources.dependencies["operation"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["operation"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.operationId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BankTransactionOperation", query: BANKTRANSACTIONOPERATION_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BankTransactionOperation", query: BANKTRANSACTIONOPERATION_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["BankTransactionOperation"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BankTransactionOperationoperationIdEditVisible: !modals.BankTransactionOperationoperationIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankTransactionOperationoperationIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BankTransactionOperationoperationIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BankTransactionOperation
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankTransactionOperationDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.operation?.id || databaseEntity?.operation?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BankTransactionOperationoperationIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    operationId: entity?.id,
                                                    operation: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BankTransactionOperationAddVisible: !modals.BankTransactionOperationAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankTransactionOperationAddVisible"]}
                                    onHide={() => setModals({ ...modals, BankTransactionOperationAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BankTransactionOperation
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankTransactionOperationDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BankTransactionOperationAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        operationId: entity?.id,
                                                        operation: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.operation?.id || databaseEntity?.operation?.id) && !uncheck['operationId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, operationId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            operationId: "",
                                            operation: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "statusId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Status')}</>}>
                                <Form.Item noStyle id="status_id" name="statusId"
                                rules={[{ required: (true && !(dataSources.dependencies?.status?.description || databaseEntity?.status?.description)), message: 'Please input the BankTransactionStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                value={ dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.statusId = newValue.key;
                                            dataSources.dependencies["status"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["status"]) {
                                                delete dataSources.dependencies["status"].__typename;
                                            }                                            
                                            setSelects({ ...selects, statusId: newValue.key });
                                            setUncheck({ ...uncheck, statusId: false });
                                        }
                                        else if (databaseEntity?.status) {
                                            dataSources.dependencies.statusId = undefined;
                                            dataSources.dependencies["status"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["status"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.statusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BankTransactionStatus", query: BANKTRANSACTIONSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BankTransactionStatus", query: BANKTRANSACTIONSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["BankTransactionStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BankTransactionStatusstatusIdEditVisible: !modals.BankTransactionStatusstatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankTransactionStatusstatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BankTransactionStatusstatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BankTransactionStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankTransactionStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.status?.id || databaseEntity?.status?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BankTransactionStatusstatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    statusId: entity?.id,
                                                    status: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BankTransactionStatusAddVisible: !modals.BankTransactionStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankTransactionStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, BankTransactionStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BankTransactionStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankTransactionStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BankTransactionStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        statusId: entity?.id,
                                                        status: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.status?.id || databaseEntity?.status?.id) && !uncheck['statusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, statusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            statusId: "",
                                            status: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Tries")} name="tries"
                            rules={[{ required: true, message: 'Please input the ' + t("Tries") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Error")} name="error"
                            rules={[{ required: false, message: 'Please input the ' + t("Error") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "taxId") && <Form.Item label={<> {t('Tax')}</>}>
                                <Form.Item noStyle id="tax_id" name="taxId"
                                rules={[{ required: (false && !(dataSources.dependencies?.tax?.hash || databaseEntity?.tax?.hash)), message: 'Please input the BlockchainTax!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                value={ dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.taxId = newValue.key;
                                            dataSources.dependencies["tax"] = {
                                                id: newValue.key,
                                                hash: newValue.value
                                            };
                                            if(dataSources.dependencies["tax"]) {
                                                delete dataSources.dependencies["tax"].__typename;
                                            }                                            
                                            setSelects({ ...selects, taxId: newValue.key });
                                            setUncheck({ ...uncheck, taxId: false });
                                        }
                                        else if (databaseEntity?.tax) {
                                            dataSources.dependencies.taxId = undefined;
                                            dataSources.dependencies["tax"] = {
                                                id: undefined,
                                                hash: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["tax"] = {
                                            id: undefined,
                                            hash: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.taxId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}>
                                    {(dataSources["BlockchainTax"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.hash}>
                                            {item.hash}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxtaxIdEditVisible: !modals.BlockchainTaxtaxIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxtaxIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    taxId: entity?.id,
                                                    tax: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxAddVisible: !modals.BlockchainTaxAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTaxAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        taxId: entity?.id,
                                                        tax: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id) && !uncheck['taxId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, taxId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            taxId: "",
                                            tax: {
                                                hash: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("TotalTax")} name="totalTax"
                            rules={[{ required: false, message: 'Please input the ' + t("TotalTax") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "pixKeyTypeId") && <Form.Item label={<> {t('PixKeyType')}</>}>
                                <Form.Item noStyle id="pixKeyType_id" name="pixKeyTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.pixKeyType?.title || databaseEntity?.pixKeyType?.title)), message: 'Please input the PixKeyType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.pixKeyType ? dataSources.dependencies?.pixKeyType?.title : databaseEntity?.pixKeyType?.title}
                                value={ dataSources.dependencies?.pixKeyType ? dataSources.dependencies?.pixKeyType?.title : databaseEntity?.pixKeyType?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.pixKeyTypeId = newValue.key;
                                            dataSources.dependencies["pixKeyType"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["pixKeyType"]) {
                                                delete dataSources.dependencies["pixKeyType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, pixKeyTypeId: newValue.key });
                                            setUncheck({ ...uncheck, pixKeyTypeId: false });
                                        }
                                        else if (databaseEntity?.pixKeyType) {
                                            dataSources.dependencies.pixKeyTypeId = undefined;
                                            dataSources.dependencies["pixKeyType"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["pixKeyType"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.pixKeyTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "PixKeyType", query: PIXKEYTYPE_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "PixKeyType", query: PIXKEYTYPE_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["PixKeyType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, PixKeyTypepixKeyTypeIdEditVisible: !modals.PixKeyTypepixKeyTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["PixKeyTypepixKeyTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, PixKeyTypepixKeyTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        PixKeyType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <PixKeyTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.pixKeyType?.id || databaseEntity?.pixKeyType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, PixKeyTypepixKeyTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    pixKeyTypeId: entity?.id,
                                                    pixKeyType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, PixKeyTypeAddVisible: !modals.PixKeyTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["PixKeyTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, PixKeyTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            PixKeyType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <PixKeyTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, PixKeyTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        pixKeyTypeId: entity?.id,
                                                        pixKeyType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.pixKeyType?.id || databaseEntity?.pixKeyType?.id) && !uncheck['pixKeyTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, pixKeyTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            pixKeyTypeId: "",
                                            pixKeyType: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "bankAccountTypeId") && <Form.Item label={<> {t('BankAccountType')}</>}>
                                <Form.Item noStyle id="bankAccountType_id" name="bankAccountTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.bankAccountType?.title || databaseEntity?.bankAccountType?.title)), message: 'Please input the BankAccountType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.bankAccountType ? dataSources.dependencies?.bankAccountType?.title : databaseEntity?.bankAccountType?.title}
                                value={ dataSources.dependencies?.bankAccountType ? dataSources.dependencies?.bankAccountType?.title : databaseEntity?.bankAccountType?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.bankAccountTypeId = newValue.key;
                                            dataSources.dependencies["bankAccountType"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["bankAccountType"]) {
                                                delete dataSources.dependencies["bankAccountType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, bankAccountTypeId: newValue.key });
                                            setUncheck({ ...uncheck, bankAccountTypeId: false });
                                        }
                                        else if (databaseEntity?.bankAccountType) {
                                            dataSources.dependencies.bankAccountTypeId = undefined;
                                            dataSources.dependencies["bankAccountType"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["bankAccountType"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.bankAccountTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BankAccountType", query: BANKACCOUNTTYPE_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BankAccountType", query: BANKACCOUNTTYPE_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["BankAccountType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BankAccountTypebankAccountTypeIdEditVisible: !modals.BankAccountTypebankAccountTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankAccountTypebankAccountTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BankAccountTypebankAccountTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BankAccountType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankAccountTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.bankAccountType?.id || databaseEntity?.bankAccountType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BankAccountTypebankAccountTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    bankAccountTypeId: entity?.id,
                                                    bankAccountType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BankAccountTypeAddVisible: !modals.BankAccountTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankAccountTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, BankAccountTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BankAccountType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankAccountTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BankAccountTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        bankAccountTypeId: entity?.id,
                                                        bankAccountType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.bankAccountType?.id || databaseEntity?.bankAccountType?.id) && !uncheck['bankAccountTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, bankAccountTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            bankAccountTypeId: "",
                                            bankAccountType: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("AppliedFee")} name="appliedFee"
                            rules={[{ required: false, message: 'Please input the ' + t("AppliedFee") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("TransactionId")} name="transactionId"
                            rules={[{ required: false, message: 'Please input the ' + t("TransactionId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`Statement`)}>
                            <StatementList relation={{model: "bank", field: "bankId", textField: "bankTransactionId", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.bankTransactionId}}></StatementList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (BankTransactionDetails);
  