import { AiFillCloseCircle, AiOutlineFileSearch } from 'react-icons/all';
import { FiArrowDown as ArrowDownIcon, FiArrowUp as ArrowUpIcon, FiDownload, FiFilter } from 'react-icons/fi';
import { AutoComplete, Button, DatePicker, Divider, Popover, Select } from 'antd';
import { Container, FilterItem, PopupContainer, PopupFilterItem, Search, SortOrder, Select as StyledSelect, Text } from './styled';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { api } from '../../services/api';
import { client } from '../../hooks/GraphQLProvider';
import gql from 'graphql-tag-ts';
import tableify from 'tableify';
import { withTranslation } from 'react-i18next';

const { RangePicker }: any = DatePicker;
const { Option } = Select;

const SORT_TYPES = {
  ASCENDING: "ascending",
  DESCENDING: "descending"
};

const FilterBar = (data: any) => {
  const [sortOrder, setSortOrder] = useState(SORT_TYPES.ASCENDING);
  const [showOptions, setShowOptions] = useState(false);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [dataSources, setDataSources] = useState<any>({});
  const [startedFilter, setStartedFilter] = useState<any>();

  if (!startedFilter && data.filter) {
    setStartedFilter(data.filter);
  }

  const toggleSortOrder = () => {
    const oppositeSort =
      sortOrder === SORT_TYPES.ASCENDING
        ? SORT_TYPES.DESCENDING
        : SORT_TYPES.ASCENDING;
    setSortOrder(oppositeSort);
    data.sort[sortOrder].onClick();
  };

  const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
    const { data } = await client.query({
      query: gql`${query}`,
      variables: { search: { search, type: 'contains', field: textField } }
    });
    if (data) {

      const obj: any = {};

      for (let item of data[model].edges) {
        obj[item.node[idField]] = item.node;
      }

      setDataSources({
        ...dataSources,
        [model]: data[model].edges,
        [`${model}Obj`]: obj
      });
    }
  };

  const download = (data: any) => {
    const refreshToken = localStorage.getItem('@Storage:token');

    let json = "{}";

    if (excelPeriod.length) {
      if (excelPeriod[0] && excelPeriod[1]) {
        json = `{"createdAt": {"gte": "${excelPeriod[0]}", "lte": "${excelPeriod[1]}"}}`;
      }
    }

    api({
      url: `/export?collection=${data.model}&filterJson=${json}`, //your url
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        authorization: `Bearer ${refreshToken}`
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.xls'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(e => {
      console.error(e);
    });
  }

  const hasChanged = JSON.stringify(startedFilter) !== JSON.stringify(data.filter);

  const filterContent = <PopupContainer>
    {data.period && <>
      <Divider orientation="left" plain>
        {data.t('Period')}
      </Divider>
      <div>
      <RangePicker onChange={(e: any[]) => { data.period.action(e?.[0], e?.[1]) }} />
      </div>
      
    </>}
    {data.search && <>
      <Divider orientation="left" plain>
        {data.t('Search Field')}

      </Divider>
      <PopupFilterItem>
        <StyledSelect defaultValue={data.search.default} onChange={data.search.onChangeField}>
          {data.search?.fields?.map((item: any) => (
            <Option value={item} key={item}>
              {item}
            </Option>
          ))}
        </StyledSelect>
      </PopupFilterItem>
    </>}

    {data.sort && <>
      <Divider orientation="left" plain>
        {data.t('Sort')}

      </Divider>
      <PopupFilterItem>
        <StyledSelect defaultValue={data.sort.default} onChange={data.sort.onChangeField}>
          {data.sort?.fields?.map((item: any) => (
            <Option value={item} key={item}>
              {item}
            </Option>
          ))}
        </StyledSelect>
        <div style={{ textAlign: 'right' }}>
          <PopupFilterItem onClick={toggleSortOrder} role="button">
            <SortOrder>
              <Text>{data.sort[sortOrder].label}</Text> {sortOrder === SORT_TYPES.ASCENDING ? (
                <ArrowDownIcon />
              ) : (
                <ArrowUpIcon />
              )}
            </SortOrder>
          </PopupFilterItem>
        </div>
      </PopupFilterItem>
    </>}

    {data.dropdownFilters &&
      data.dropdownFilters.map((drop: any) => (
        <React.Fragment key={drop.key}>
          <Divider orientation="left" plain>
            {data.t('Filter by')} {data.t(drop.key)}
          </Divider>
          <PopupFilterItem key={drop.key}>
            {/* <StyledSelect placeholder={"Company"} defaultValue={drop.items[0].key} onChange={drop.onChange}>
              {drop.items.map((item: any) => (
                <Option value={item.key} key={item.key}>
                  {item.label}
                </Option>
              ))}
            </StyledSelect> */}
            <AutoComplete style={{ width: '100%' }}
              onChange={(value) => {
                if (!value) {
                  drop.onChange({ key: undefined, value: undefined });
                }
              }}
              onSelect={(_value, option) => { drop.onChange(option) }}
              onSearch={search => {
                handleSearch({ search, model: drop.model, query: drop.query, idField: drop.idField, textField: drop.textField });
              }}
              onClick={() => {
                handleSearch({ search: "", model: drop.model, query: drop.query, idField: drop.idField, textField: drop.textField });
              }}>
              {dataSources[drop.model]?.map((item: any, index: number) => {
                return (
                  <AutoComplete.Option key={item.node[drop.idField]} value={item.node[drop.textField]}>
                    {item.node[drop.textField]}
                  </AutoComplete.Option>
                );
              })}
            </AutoComplete>
          </PopupFilterItem>
        </React.Fragment>
      ))}
    <Divider></Divider>
    <Button loading={data.loading} type="primary" style={{ float: 'right' }} onClick={data.find}>Search</Button>
    <div style={{ clear: 'both' }}></div>
  </PopupContainer>

  const [excelPeriod, setExcelPeriod] = useState<string[]>([]);

  const exporContent = <>
    <Divider orientation="left" plain>
      Period
    </Divider>
    <RangePicker onChange={(e: { toString: () => any; }[]) => { setExcelPeriod([e?.[0]?.toString() || "", e?.[1]?.toString() || ""]) }} />
    <Divider></Divider>
    <Button type="primary" style={{ float: 'right' }} onClick={() => { download(data) }}>{data.t('Export Excel')}</Button>
    <div style={{ clear: 'both' }}></div>
  </>

  return (
    <Container>

      {!!data.filter && <Popover placement="bottomRight" title={
        <div>{data.t('Filter Resume')} {hasChanged && <AiFillCloseCircle onClick={() => { data.reset(startedFilter) }} color={'red'} />}</div>
      } content={
        <div
          dangerouslySetInnerHTML={{
            __html: tableify(data.filter)
          }}></div>
      } trigger="click" style={{ width: '300px' }}>
        <FilterItem onClick={() => { setShowDownloadOptions(!showDownloadOptions) }} role="button">
          {showDownloadOptions ? (
            <AiOutlineFileSearch color={hasChanged ? 'green' : 'black'} />
          ) : (
            <AiOutlineFileSearch color={hasChanged ? 'green' : 'black'} />
          )}
        </FilterItem>
      </Popover>}
      {data.search && (
        <FilterItem>
          <Search
            placeholder={data.search.placeholder}
            onSearch={data.search.action}
            style={{ width: '100%' }}
            allowClear
            onClick={data.find}
          />
        </FilterItem>
      )}
      <Popover placement="bottomRight" content={filterContent} trigger="click">
        <FilterItem onClick={() => { setShowOptions(!showOptions) }} role="button">
          {showOptions ? (
            <FiFilter />
          ) : (
            <FiFilter />
          )}
        </FilterItem>
      </Popover>
      <Popover placement="bottomRight" content={exporContent} trigger="click" style={{ width: '300px' }}>
        <FilterItem onClick={() => { setShowDownloadOptions(!showDownloadOptions) }} role="button">
          {showDownloadOptions ? (
            <FiDownload />
          ) : (
            <FiDownload />
          )}
        </FilterItem>
      </Popover>
    </Container>
  );
};

FilterBar.SORT_TYPES = SORT_TYPES;

const SortPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
});

export const FilterBarContent = {
  loading: PropTypes.bool,
  filter: PropTypes.object,
  reset: PropTypes.func,
  period: PropTypes.shape({
    action: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired
  }),
  model: PropTypes.string.isRequired,
  find: PropTypes.func.isRequired,
  search: PropTypes.shape({
    action: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    onChangeField: PropTypes.func,
    default: PropTypes.string.isRequired
  }),
  sort: PropTypes.shape({
    onChangeField: PropTypes.func,
    fields: PropTypes.array.isRequired,
    default: PropTypes.string.isRequired,
    ascending: SortPropType.isRequired,
    descending: SortPropType.isRequired
  }),
  dropdownFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      onChange: PropTypes.func.isRequired,
      query: PropTypes.string.isRequired
    })
  )
}

FilterBar.propTypes = FilterBarContent;

FilterBar.defaultProps = {
  period: undefined,
  search: undefined,
  sort: undefined,
  dropdownFilters: undefined
};

export default withTranslation()(FilterBar);