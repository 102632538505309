//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { TokenRemunerationSchedule } from '../../../models/prisma/tokenRemunerationSchedule.model';
        import { GET_LIST, startedFilter } from './tokenRemunerationSchedule.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import BlockchainTokenDetails from '../blockchainToken/blockchainToken.details';
import BlockchainTaxDetails from '../blockchainTax/blockchainTax.details';
import TokenRemunerationScheduleStatusDetails from '../tokenRemunerationScheduleStatus/tokenRemunerationScheduleStatus.details';
import DividendList from '../dividend/dividend.list';
import CompanyDepositDetails from '../companyDeposit/companyDeposit.details';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            tokenRemunerationScheduleCms: TokenRemunerationSchedule
        }>`
        query TokenRemunerationScheduleCms($id: String)
        {
            tokenRemunerationScheduleCms(id: $id)
          {
            id
                    token {
                        id
                        name
                    }
                    date
                    description
                    interestRate
                    value
                    tax {
                        id
                        hash
                    }
                    paymentDate
                    createdAt
                    updatedAt
                    tokenId
                    blockchainTaxId
                    cashout
                    order
                    notification
                    status {
                        id
                        description
                    }
                    statusId
                    deleted
                    companyDeposit {
                        id
                        document
                    }
                    companyDepositId
                    error
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<TokenRemunerationSchedule>`
        mutation TokenRemunerationScheduleSave(
            $id: String,
                        $token: BlockchainTokenCreateInputs,
                    $date: String!,
                        $description: String,
                        $interestRate: Float,
                        $value: Float,
                        $tax: BlockchainTaxCreateInputs,
                    $paymentDate: String,
                        $tokenId: String,
                        $blockchainTaxId: String,
                        $cashout: Boolean,
                        $order: Int,
                        $notification: Boolean,
                        $status: TokenRemunerationScheduleStatusCreateInputs,
                    $statusId: String,
                        $companyDeposit: CompanyDepositCreateInputs,
                    $companyDepositId: String,
                        $error: String,
                        
        )
        {
            tokenRemunerationScheduleCreateCms(data: {
                id: $id,
                    token: $token,
                    date: $date,
                    description: $description,
                    interestRate: $interestRate,
                    value: $value,
                    tax: $tax,
                    paymentDate: $paymentDate,
                    tokenId: $tokenId,
                    blockchainTaxId: $blockchainTaxId,
                    cashout: $cashout,
                    order: $order,
                    notification: $notification,
                    status: $status,
                    statusId: $statusId,
                    companyDeposit: $companyDeposit,
                    companyDepositId: $companyDepositId,
                    error: $error,
                    
            }) {
                id
                    token {
                        id
                        name
                    }
                    date
                    description
                    interestRate
                    value
                    tax {
                        id
                        hash
                    }
                    paymentDate
                    createdAt
                    updatedAt
                    tokenId
                    blockchainTaxId
                    cashout
                    order
                    notification
                    status {
                        id
                        description
                    }
                    statusId
                    deleted
                    companyDeposit {
                        id
                        document
                    }
                    companyDepositId
                    error
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<TokenRemunerationSchedule>`
        mutation TokenRemunerationScheduleSave(
            $id: String,
                        $token: BlockchainTokenCreateInputs,
                    $date: String!,
                        $description: String,
                        $interestRate: Float,
                        $value: Float,
                        $tax: BlockchainTaxCreateInputs,
                    $paymentDate: String,
                        $tokenId: String,
                        $blockchainTaxId: String,
                        $cashout: Boolean,
                        $order: Int,
                        $notification: Boolean,
                        $status: TokenRemunerationScheduleStatusCreateInputs,
                    $statusId: String,
                        $companyDeposit: CompanyDepositCreateInputs,
                    $companyDepositId: String,
                        $error: String,
                        
        )
        {
            tokenRemunerationScheduleUpdateCms(data: {
                id: $id,
                    token: $token,
                    date: $date,
                    description: $description,
                    interestRate: $interestRate,
                    value: $value,
                    tax: $tax,
                    paymentDate: $paymentDate,
                    tokenId: $tokenId,
                    blockchainTaxId: $blockchainTaxId,
                    cashout: $cashout,
                    order: $order,
                    notification: $notification,
                    status: $status,
                    statusId: $statusId,
                    companyDeposit: $companyDeposit,
                    companyDepositId: $companyDepositId,
                    error: $error,
                    
            }) {
                id
                    token {
                        id
                        name
                    }
                    date
                    description
                    interestRate
                    value
                    tax {
                        id
                        hash
                    }
                    paymentDate
                    createdAt
                    updatedAt
                    tokenId
                    blockchainTaxId
                    cashout
                    order
                    notification
                    status {
                        id
                        description
                    }
                    statusId
                    deleted
                    companyDeposit {
                        id
                        document
                    }
                    companyDepositId
                    error
                    
            }
        }
        `;

        
                    const BLOCKCHAINTOKEN_QUERY = `
                    query blockchainTokenListCms($search: Search) {
                        blockchainTokenListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTAX_QUERY = `
                    query blockchainTaxListCms($search: Search) {
                        blockchainTaxListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    hash
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const TOKENREMUNERATIONSCHEDULESTATUS_QUERY = `
                    query tokenRemunerationScheduleStatusListCms($search: Search) {
                        tokenRemunerationScheduleStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const COMPANYDEPOSIT_QUERY = `
                    query companyDepositListCms($search: Search) {
                        companyDepositListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    document
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const TokenRemunerationScheduleDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ tokenRemunerationScheduleCms: TokenRemunerationSchedule }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.tokenRemunerationScheduleCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("TokenRemunerationSchedule has been saved");
                    const model = entity?.data?.tokenRemunerationScheduleUpdateCms || entity?.data?.tokenRemunerationScheduleCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/tokenRemunerationSchedule-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('TokenRemunerationSchedule')} breadcrumbItems={[
                                { title: t("TokenRemunerationSchedule"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('TokenRemunerationSchedule')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('TokenRemunerationSchedule')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        { (!props.relation || props.relation.field != "tokenId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Token')}</>}>
                                <Form.Item noStyle id="token_id" name="tokenId"
                                rules={[{ required: (true && !(dataSources.dependencies?.token?.name || databaseEntity?.token?.name)), message: 'Please input the BlockchainToken!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.token ? dataSources.dependencies?.token?.name : databaseEntity?.token?.name}
                                value={ dataSources.dependencies?.token ? dataSources.dependencies?.token?.name : databaseEntity?.token?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.tokenId = newValue.key;
                                            dataSources.dependencies["token"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["token"]) {
                                                delete dataSources.dependencies["token"].__typename;
                                            }                                            
                                            setSelects({ ...selects, tokenId: newValue.key });
                                            setUncheck({ ...uncheck, tokenId: false });
                                        }
                                        else if (databaseEntity?.token) {
                                            dataSources.dependencies.tokenId = undefined;
                                            dataSources.dependencies["token"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["token"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.tokenId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["BlockchainToken"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTokentokenIdEditVisible: !modals.BlockchainTokentokenIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokentokenIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokentokenIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.token?.id || databaseEntity?.token?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTokentokenIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    tokenId: entity?.id,
                                                    token: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenAddVisible: !modals.BlockchainTokenAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTokenAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        tokenId: entity?.id,
                                                        token: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.token?.id || databaseEntity?.token?.id) && !uncheck['tokenId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, tokenId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            tokenId: "",
                                            token: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Date")} name="date"
                            rules={[{ required: true, message: 'Please input the ' + t("Date") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.date ? moment(databaseEntity?.date, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['date'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("Description")} name="description"
                            rules={[{ required: false, message: 'Please input the ' + t("Description") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("InterestRate")} name="interestRate"
                            rules={[{ required: false, message: 'Please input the ' + t("InterestRate") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Value")} name="value"
                            rules={[{ required: false, message: 'Please input the ' + t("Value") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "blockchainTaxId") && <Form.Item label={<> {t('Tax')}</>}>
                                <Form.Item noStyle id="tax_id" name="blockchainTaxId"
                                rules={[{ required: (false && !(dataSources.dependencies?.tax?.hash || databaseEntity?.tax?.hash)), message: 'Please input the BlockchainTax!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                value={ dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.blockchainTaxId = newValue.key;
                                            dataSources.dependencies["tax"] = {
                                                id: newValue.key,
                                                hash: newValue.value
                                            };
                                            if(dataSources.dependencies["tax"]) {
                                                delete dataSources.dependencies["tax"].__typename;
                                            }                                            
                                            setSelects({ ...selects, blockchainTaxId: newValue.key });
                                            setUncheck({ ...uncheck, blockchainTaxId: false });
                                        }
                                        else if (databaseEntity?.tax) {
                                            dataSources.dependencies.blockchainTaxId = undefined;
                                            dataSources.dependencies["tax"] = {
                                                id: undefined,
                                                hash: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["tax"] = {
                                            id: undefined,
                                            hash: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.blockchainTaxId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}>
                                    {(dataSources["BlockchainTax"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.hash}>
                                            {item.hash}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxblockchainTaxIdEditVisible: !modals.BlockchainTaxblockchainTaxIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxblockchainTaxIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxblockchainTaxIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTaxblockchainTaxIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    blockchainTaxId: entity?.id,
                                                    tax: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxAddVisible: !modals.BlockchainTaxAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTaxAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        blockchainTaxId: entity?.id,
                                                        tax: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id) && !uncheck['blockchainTaxId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, blockchainTaxId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            blockchainTaxId: "",
                                            tax: {
                                                hash: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("PaymentDate")} name="paymentDate"
                            rules={[{ required: false, message: 'Please input the ' + t("PaymentDate") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.paymentDate ? moment(databaseEntity?.paymentDate, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['paymentDate'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("Cashout")} name="cashout" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("Order")} name="order"
                            rules={[{ required: true, message: 'Please input the ' + t("Order") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Notification")} name="notification" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "statusId") && <Form.Item label={<> {t('Status')}</>}>
                                <Form.Item noStyle id="status_id" name="statusId"
                                rules={[{ required: (false && !(dataSources.dependencies?.status?.description || databaseEntity?.status?.description)), message: 'Please input the TokenRemunerationScheduleStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                value={ dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.statusId = newValue.key;
                                            dataSources.dependencies["status"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["status"]) {
                                                delete dataSources.dependencies["status"].__typename;
                                            }                                            
                                            setSelects({ ...selects, statusId: newValue.key });
                                            setUncheck({ ...uncheck, statusId: false });
                                        }
                                        else if (databaseEntity?.status) {
                                            dataSources.dependencies.statusId = undefined;
                                            dataSources.dependencies["status"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["status"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.statusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "TokenRemunerationScheduleStatus", query: TOKENREMUNERATIONSCHEDULESTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "TokenRemunerationScheduleStatus", query: TOKENREMUNERATIONSCHEDULESTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["TokenRemunerationScheduleStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, TokenRemunerationScheduleStatusstatusIdEditVisible: !modals.TokenRemunerationScheduleStatusstatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenRemunerationScheduleStatusstatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, TokenRemunerationScheduleStatusstatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        TokenRemunerationScheduleStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenRemunerationScheduleStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.status?.id || databaseEntity?.status?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, TokenRemunerationScheduleStatusstatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    statusId: entity?.id,
                                                    status: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, TokenRemunerationScheduleStatusAddVisible: !modals.TokenRemunerationScheduleStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["TokenRemunerationScheduleStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, TokenRemunerationScheduleStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TokenRemunerationScheduleStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <TokenRemunerationScheduleStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, TokenRemunerationScheduleStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        statusId: entity?.id,
                                                        status: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.status?.id || databaseEntity?.status?.id) && !uncheck['statusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, statusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            statusId: "",
                                            status: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "companyDepositId") && <Form.Item label={<> {t('CompanyDeposit')}</>}>
                                <Form.Item noStyle id="companyDeposit_id" name="companyDepositId"
                                rules={[{ required: (false && !(dataSources.dependencies?.companyDeposit?.document || databaseEntity?.companyDeposit?.document)), message: 'Please input the CompanyDeposit!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.companyDeposit ? dataSources.dependencies?.companyDeposit?.document : databaseEntity?.companyDeposit?.document}
                                value={ dataSources.dependencies?.companyDeposit ? dataSources.dependencies?.companyDeposit?.document : databaseEntity?.companyDeposit?.document}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyDepositId = newValue.key;
                                            dataSources.dependencies["companyDeposit"] = {
                                                id: newValue.key,
                                                document: newValue.value
                                            };
                                            if(dataSources.dependencies["companyDeposit"]) {
                                                delete dataSources.dependencies["companyDeposit"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyDepositId: newValue.key });
                                            setUncheck({ ...uncheck, companyDepositId: false });
                                        }
                                        else if (databaseEntity?.companyDeposit) {
                                            dataSources.dependencies.companyDepositId = undefined;
                                            dataSources.dependencies["companyDeposit"] = {
                                                id: undefined,
                                                document: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["companyDeposit"] = {
                                            id: undefined,
                                            document: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyDepositId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CompanyDeposit", query: COMPANYDEPOSIT_QUERY, idField: "id", textField: "document" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CompanyDeposit", query: COMPANYDEPOSIT_QUERY, idField: "id", textField: "document" });
                                    }}>
                                    {(dataSources["CompanyDeposit"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.document}>
                                            {item.document}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CompanyDepositcompanyDepositIdEditVisible: !modals.CompanyDepositcompanyDepositIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanyDepositcompanyDepositIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CompanyDepositcompanyDepositIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CompanyDeposit
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDepositDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.companyDeposit?.id || databaseEntity?.companyDeposit?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CompanyDepositcompanyDepositIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyDepositId: entity?.id,
                                                    companyDeposit: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CompanyDepositAddVisible: !modals.CompanyDepositAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanyDepositAddVisible"]}
                                    onHide={() => setModals({ ...modals, CompanyDepositAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CompanyDeposit
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDepositDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CompanyDepositAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyDepositId: entity?.id,
                                                        companyDeposit: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.companyDeposit?.id || databaseEntity?.companyDeposit?.id) && !uncheck['companyDepositId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyDepositId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyDepositId: "",
                                            companyDeposit: {
                                                document: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Error")} name="error"
                            rules={[{ required: false, message: 'Please input the ' + t("Error") + '!' }]}>
                                <Input />
                            </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`Dividend`)}>
                            <DividendList relation={{model: "paymentRef", field: "remunerationId", textField: "description", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.description}}></DividendList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (TokenRemunerationScheduleDetails);
  