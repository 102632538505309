import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { api } from '../services/api';
import gql from 'graphql-tag-ts';
import { useMutation, useQuery } from '@apollo/client';
import { Agent } from '../models/prisma/agent.model';
import { buildAbilityFor } from '../casl/ability';
import { client } from './GraphQLProvider';

interface AuthState {
  token: string;
  user: Agent;
}

interface AuthContextData {
  user: Agent;
  signIn(email: string, password: string): Promise<AuthState | null>;
  signOut(): void;
  updateUser(user: Agent): void;
}

const ME = gql<{
  me: {
    id: string
  }
}>`
query Me
{
  agentMe {
   id
 }
}
`;

const AUTH = gql<{
  login: {
    accessToken: string,
    refreshToken: string,
    user: Agent
  }
}, {
  email: string,
  password: string
}>`
  mutation Login($email: String!, $password: String!)
  {
    agentLogin(data: {
      email: $email, 
      password: $password
    }){
      accessToken
      refreshToken
      agent {
        id
        email
        name
        role{
          description
          AgentRolePolicyMap {
            policy {
              description
              serviceMap {
                description
              }
              service
              canCreate
              canDelete
              canManage
              canRead
              canUpdate
            }
          }
        }
      }
    }
  }
`;

const Auth = createContext<AuthContextData>({} as AuthContextData);
const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Storage:token');
    const user = localStorage.getItem('@Storage:user');

    if (token && user) {
      return {
        token,
        user: JSON.parse(user),
      };
    }
    return {} as AuthState;
  });

  const [onLoginHandler] = useMutation<{
    agentLogin: {
      accessToken: string,
      refreshToken: string,
      agent: Agent
    }
  }>(AUTH);

  useEffect(() => {
    if (localStorage.getItem('@Storage:token')) {
      client.query({
        query: ME
      }).catch(() => {
        signOut();
      })
    }
  }, [])

  const signIn = async (email: string, password: string) => {

    const r = await onLoginHandler({
      variables: {
        email,
        password
      }
    });

    if (r.data?.agentLogin) {
      const { refreshToken, agent } = r.data.agentLogin;

      localStorage.setItem('@Storage:token', refreshToken);
      api.defaults.headers.authorization = `Bearer ${refreshToken}`;
      setData({ token: refreshToken, user: agent });
      updateUser(agent);

      return data;
    }

    return null;
  };

  const signOut = useCallback(() => {
    localStorage.removeItem('@Storage:token');
    localStorage.removeItem('@Storage:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: Agent) => {
      localStorage.setItem('@Storage:user', JSON.stringify(user));
      buildAbilityFor(user);

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <Auth.Provider value={{ user: data.user, signIn: signIn, signOut, updateUser }}>
      {children}
    </Auth.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(Auth);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
