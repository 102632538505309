//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { CustodyWallet } from '../../../models/prisma/custodyWallet.model';
        import { GET_LIST, startedFilter } from './custodyWallet.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import BlockchainDetails from '../blockchain/blockchain.details';
import CompanyDetails from '../company/company.details';
import CustodyUserDetails from '../custodyUser/custodyUser.details';
import CustodyWalletTypeDetails from '../custodyWalletType/custodyWalletType.details';
import CustodyWalletProfileDetails from '../custodyWalletProfile/custodyWalletProfile.details';
import CustodyWalletFrequencyDetails from '../custodyWalletFrequency/custodyWalletFrequency.details';
import CustodyApplicationDetails from '../custodyApplication/custodyApplication.details';
import CustodyBlockchainTransactionList from '../custodyBlockchainTransaction/custodyBlockchainTransaction.list';
import CustodyWalletAssetList from '../custodyWalletAsset/custodyWalletAsset.list';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            custodyWalletCms: CustodyWallet
        }>`
        query CustodyWalletCms($id: String)
        {
            custodyWalletCms(id: $id)
          {
            id
                    address
                    publicKey
                    network {
                        id
                        title
                    }
                    networkId
                    mnemonicSaved
                    company {
                        id
                        companyName
                    }
                    companyId
                    name
                    externalCode
                    startBlock
                    isMPC
                    deleted
                    createdAt
                    updatedAt
                    applicationWalletId
                    isExternal
                    depositLock
                    custodyUser {
                        id
                        companyId
                    }
                    custodyUserId
                    custodyWalletType {
                        id
                        title
                    }
                    custodyWalletTypeId
                    custodyWalletProfile {
                        id
                        title
                    }
                    custodyWalletProfileId
                    custodyWalletFrequency {
                        id
                        title
                    }
                    custodyWalletFrequencyId
                    addressIndex
                    accountIndex
                    applicationIndex
                    path
                    application {
                        id
                        companyId
                    }
                    applicationId
                    syncMonitor
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<CustodyWallet>`
        mutation CustodyWalletSave(
            $id: String,
                        $address: String,
                        $publicKey: String,
                        $network: BlockchainCreateInputs,
                    $networkId: String,
                        $mnemonicSaved: Boolean,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $name: String,
                        $externalCode: String,
                        $startBlock: Float,
                        $isMPC: Boolean,
                        $applicationWalletId: String,
                        $isExternal: Boolean,
                        $depositLock: Boolean,
                        $custodyUser: CustodyUserCreateInputs,
                    $custodyUserId: String,
                        $custodyWalletType: CustodyWalletTypeCreateInputs,
                    $custodyWalletTypeId: String,
                        $custodyWalletProfile: CustodyWalletProfileCreateInputs,
                    $custodyWalletProfileId: String,
                        $custodyWalletFrequency: CustodyWalletFrequencyCreateInputs,
                    $custodyWalletFrequencyId: String,
                        $addressIndex: Int,
                        $accountIndex: Int,
                        $applicationIndex: Int,
                        $path: String,
                        $application: CustodyApplicationCreateInputs,
                    $applicationId: String,
                        $syncMonitor: Boolean,
                        
        )
        {
            custodyWalletCreateCms(data: {
                id: $id,
                    address: $address,
                    publicKey: $publicKey,
                    network: $network,
                    networkId: $networkId,
                    mnemonicSaved: $mnemonicSaved,
                    company: $company,
                    companyId: $companyId,
                    name: $name,
                    externalCode: $externalCode,
                    startBlock: $startBlock,
                    isMPC: $isMPC,
                    applicationWalletId: $applicationWalletId,
                    isExternal: $isExternal,
                    depositLock: $depositLock,
                    custodyUser: $custodyUser,
                    custodyUserId: $custodyUserId,
                    custodyWalletType: $custodyWalletType,
                    custodyWalletTypeId: $custodyWalletTypeId,
                    custodyWalletProfile: $custodyWalletProfile,
                    custodyWalletProfileId: $custodyWalletProfileId,
                    custodyWalletFrequency: $custodyWalletFrequency,
                    custodyWalletFrequencyId: $custodyWalletFrequencyId,
                    addressIndex: $addressIndex,
                    accountIndex: $accountIndex,
                    applicationIndex: $applicationIndex,
                    path: $path,
                    application: $application,
                    applicationId: $applicationId,
                    syncMonitor: $syncMonitor,
                    
            }) {
                id
                    address
                    publicKey
                    network {
                        id
                        title
                    }
                    networkId
                    mnemonicSaved
                    company {
                        id
                        companyName
                    }
                    companyId
                    name
                    externalCode
                    startBlock
                    isMPC
                    deleted
                    createdAt
                    updatedAt
                    applicationWalletId
                    isExternal
                    depositLock
                    custodyUser {
                        id
                        companyId
                    }
                    custodyUserId
                    custodyWalletType {
                        id
                        title
                    }
                    custodyWalletTypeId
                    custodyWalletProfile {
                        id
                        title
                    }
                    custodyWalletProfileId
                    custodyWalletFrequency {
                        id
                        title
                    }
                    custodyWalletFrequencyId
                    addressIndex
                    accountIndex
                    applicationIndex
                    path
                    application {
                        id
                        companyId
                    }
                    applicationId
                    syncMonitor
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<CustodyWallet>`
        mutation CustodyWalletSave(
            $id: String,
                        $address: String,
                        $publicKey: String,
                        $network: BlockchainCreateInputs,
                    $networkId: String,
                        $mnemonicSaved: Boolean,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $name: String,
                        $externalCode: String,
                        $startBlock: Float,
                        $isMPC: Boolean,
                        $applicationWalletId: String,
                        $isExternal: Boolean,
                        $depositLock: Boolean,
                        $custodyUser: CustodyUserCreateInputs,
                    $custodyUserId: String,
                        $custodyWalletType: CustodyWalletTypeCreateInputs,
                    $custodyWalletTypeId: String,
                        $custodyWalletProfile: CustodyWalletProfileCreateInputs,
                    $custodyWalletProfileId: String,
                        $custodyWalletFrequency: CustodyWalletFrequencyCreateInputs,
                    $custodyWalletFrequencyId: String,
                        $addressIndex: Int,
                        $accountIndex: Int,
                        $applicationIndex: Int,
                        $path: String,
                        $application: CustodyApplicationCreateInputs,
                    $applicationId: String,
                        $syncMonitor: Boolean,
                        
        )
        {
            custodyWalletUpdateCms(data: {
                id: $id,
                    address: $address,
                    publicKey: $publicKey,
                    network: $network,
                    networkId: $networkId,
                    mnemonicSaved: $mnemonicSaved,
                    company: $company,
                    companyId: $companyId,
                    name: $name,
                    externalCode: $externalCode,
                    startBlock: $startBlock,
                    isMPC: $isMPC,
                    applicationWalletId: $applicationWalletId,
                    isExternal: $isExternal,
                    depositLock: $depositLock,
                    custodyUser: $custodyUser,
                    custodyUserId: $custodyUserId,
                    custodyWalletType: $custodyWalletType,
                    custodyWalletTypeId: $custodyWalletTypeId,
                    custodyWalletProfile: $custodyWalletProfile,
                    custodyWalletProfileId: $custodyWalletProfileId,
                    custodyWalletFrequency: $custodyWalletFrequency,
                    custodyWalletFrequencyId: $custodyWalletFrequencyId,
                    addressIndex: $addressIndex,
                    accountIndex: $accountIndex,
                    applicationIndex: $applicationIndex,
                    path: $path,
                    application: $application,
                    applicationId: $applicationId,
                    syncMonitor: $syncMonitor,
                    
            }) {
                id
                    address
                    publicKey
                    network {
                        id
                        title
                    }
                    networkId
                    mnemonicSaved
                    company {
                        id
                        companyName
                    }
                    companyId
                    name
                    externalCode
                    startBlock
                    isMPC
                    deleted
                    createdAt
                    updatedAt
                    applicationWalletId
                    isExternal
                    depositLock
                    custodyUser {
                        id
                        companyId
                    }
                    custodyUserId
                    custodyWalletType {
                        id
                        title
                    }
                    custodyWalletTypeId
                    custodyWalletProfile {
                        id
                        title
                    }
                    custodyWalletProfileId
                    custodyWalletFrequency {
                        id
                        title
                    }
                    custodyWalletFrequencyId
                    addressIndex
                    accountIndex
                    applicationIndex
                    path
                    application {
                        id
                        companyId
                    }
                    applicationId
                    syncMonitor
                    
            }
        }
        `;

        
                    const BLOCKCHAIN_QUERY = `
                    query blockchainListCms($search: Search) {
                        blockchainListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const COMPANY_QUERY = `
                    query companyListCms($search: Search) {
                        companyListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyName
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const CUSTODYUSER_QUERY = `
                    query custodyUserListCms($search: Search) {
                        custodyUserListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyId
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const CUSTODYWALLETTYPE_QUERY = `
                    query custodyWalletTypeListCms($search: Search) {
                        custodyWalletTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const CUSTODYWALLETPROFILE_QUERY = `
                    query custodyWalletProfileListCms($search: Search) {
                        custodyWalletProfileListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const CUSTODYWALLETFREQUENCY_QUERY = `
                    query custodyWalletFrequencyListCms($search: Search) {
                        custodyWalletFrequencyListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const CUSTODYAPPLICATION_QUERY = `
                    query custodyApplicationListCms($search: Search) {
                        custodyApplicationListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyId
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const CustodyWalletDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ custodyWalletCms: CustodyWallet }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.custodyWalletCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("CustodyWallet has been saved");
                    const model = entity?.data?.custodyWalletUpdateCms || entity?.data?.custodyWalletCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/custodyWallet-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('CustodyWallet')} breadcrumbItems={[
                                { title: t("CustodyWallet"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('CustodyWallet')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('CustodyWallet')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Address")} name="address"
                            rules={[{ required: true, message: 'Please input the ' + t("Address") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("PublicKey")} name="publicKey"
                            rules={[{ required: false, message: 'Please input the ' + t("PublicKey") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "networkId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Network')}</>}>
                                <Form.Item noStyle id="network_id" name="networkId"
                                rules={[{ required: (true && !(dataSources.dependencies?.network?.title || databaseEntity?.network?.title)), message: 'Please input the Blockchain!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.network ? dataSources.dependencies?.network?.title : databaseEntity?.network?.title}
                                value={ dataSources.dependencies?.network ? dataSources.dependencies?.network?.title : databaseEntity?.network?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.networkId = newValue.key;
                                            dataSources.dependencies["network"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["network"]) {
                                                delete dataSources.dependencies["network"].__typename;
                                            }                                            
                                            setSelects({ ...selects, networkId: newValue.key });
                                            setUncheck({ ...uncheck, networkId: false });
                                        }
                                        else if (databaseEntity?.network) {
                                            dataSources.dependencies.networkId = undefined;
                                            dataSources.dependencies["network"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["network"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.networkId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Blockchain", query: BLOCKCHAIN_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Blockchain", query: BLOCKCHAIN_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["Blockchain"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainnetworkIdEditVisible: !modals.BlockchainnetworkIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainnetworkIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainnetworkIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Blockchain
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.network?.id || databaseEntity?.network?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainnetworkIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    networkId: entity?.id,
                                                    network: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainAddVisible: !modals.BlockchainAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Blockchain
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        networkId: entity?.id,
                                                        network: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.network?.id || databaseEntity?.network?.id) && !uncheck['networkId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, networkId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            networkId: "",
                                            network: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("MnemonicSaved")} name="mnemonicSaved" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "companyId") && <Form.Item label={<> {t('Company')}</>}>
                                <Form.Item noStyle id="company_id" name="companyId"
                                rules={[{ required: (false && !(dataSources.dependencies?.company?.companyName || databaseEntity?.company?.companyName)), message: 'Please input the Company!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                value={ dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyId = newValue.key;
                                            dataSources.dependencies["company"] = {
                                                id: newValue.key,
                                                companyName: newValue.value
                                            };
                                            if(dataSources.dependencies["company"]) {
                                                delete dataSources.dependencies["company"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyId: newValue.key });
                                            setUncheck({ ...uncheck, companyId: false });
                                        }
                                        else if (databaseEntity?.company) {
                                            dataSources.dependencies.companyId = undefined;
                                            dataSources.dependencies["company"] = {
                                                id: undefined,
                                                companyName: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["company"] = {
                                            id: undefined,
                                            companyName: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}>
                                    {(dataSources["Company"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyName}>
                                            {item.companyName}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CompanycompanyIdEditVisible: !modals.CompanycompanyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanycompanyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CompanycompanyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.company?.id || databaseEntity?.company?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CompanycompanyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyId: entity?.id,
                                                    company: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CompanyAddVisible: !modals.CompanyAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanyAddVisible"]}
                                    onHide={() => setModals({ ...modals, CompanyAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CompanyAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyId: entity?.id,
                                                        company: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.company?.id || databaseEntity?.company?.id) && !uncheck['companyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyId: "",
                                            company: {
                                                companyName: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Name")} name="name"
                            rules={[{ required: false, message: 'Please input the ' + t("Name") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("ExternalCode")} name="externalCode"
                            rules={[{ required: false, message: 'Please input the ' + t("ExternalCode") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("StartBlock")} name="startBlock"
                            rules={[{ required: false, message: 'Please input the ' + t("StartBlock") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("IsMPC")} name="isMPC" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("ApplicationWalletId")} name="applicationWalletId"
                            rules={[{ required: false, message: 'Please input the ' + t("ApplicationWalletId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("IsExternal")} name="isExternal" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("DepositLock")} name="depositLock" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "custodyUserId") && <Form.Item label={<> {t('CustodyUser')}</>}>
                                <Form.Item noStyle id="custodyUser_id" name="custodyUserId"
                                rules={[{ required: (false && !(dataSources.dependencies?.custodyUser?.companyId || databaseEntity?.custodyUser?.companyId)), message: 'Please input the CustodyUser!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.custodyUser ? dataSources.dependencies?.custodyUser?.companyId : databaseEntity?.custodyUser?.companyId}
                                value={ dataSources.dependencies?.custodyUser ? dataSources.dependencies?.custodyUser?.companyId : databaseEntity?.custodyUser?.companyId}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.custodyUserId = newValue.key;
                                            dataSources.dependencies["custodyUser"] = {
                                                id: newValue.key,
                                                companyId: newValue.value
                                            };
                                            if(dataSources.dependencies["custodyUser"]) {
                                                delete dataSources.dependencies["custodyUser"].__typename;
                                            }                                            
                                            setSelects({ ...selects, custodyUserId: newValue.key });
                                            setUncheck({ ...uncheck, custodyUserId: false });
                                        }
                                        else if (databaseEntity?.custodyUser) {
                                            dataSources.dependencies.custodyUserId = undefined;
                                            dataSources.dependencies["custodyUser"] = {
                                                id: undefined,
                                                companyId: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["custodyUser"] = {
                                            id: undefined,
                                            companyId: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.custodyUserId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyUser", query: CUSTODYUSER_QUERY, idField: "id", textField: "companyId" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyUser", query: CUSTODYUSER_QUERY, idField: "id", textField: "companyId" });
                                    }}>
                                    {(dataSources["CustodyUser"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyId}>
                                            {item.companyId}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyUsercustodyUserIdEditVisible: !modals.CustodyUsercustodyUserIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyUsercustodyUserIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyUsercustodyUserIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyUser
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyUserDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.custodyUser?.id || databaseEntity?.custodyUser?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyUsercustodyUserIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    custodyUserId: entity?.id,
                                                    custodyUser: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyUserAddVisible: !modals.CustodyUserAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyUserAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyUserAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyUser
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyUserDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyUserAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        custodyUserId: entity?.id,
                                                        custodyUser: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.custodyUser?.id || databaseEntity?.custodyUser?.id) && !uncheck['custodyUserId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, custodyUserId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            custodyUserId: "",
                                            custodyUser: {
                                                companyId: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "custodyWalletTypeId") && <Form.Item label={<> {t('CustodyWalletType')}</>}>
                                <Form.Item noStyle id="custodyWalletType_id" name="custodyWalletTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.custodyWalletType?.title || databaseEntity?.custodyWalletType?.title)), message: 'Please input the CustodyWalletType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.custodyWalletType ? dataSources.dependencies?.custodyWalletType?.title : databaseEntity?.custodyWalletType?.title}
                                value={ dataSources.dependencies?.custodyWalletType ? dataSources.dependencies?.custodyWalletType?.title : databaseEntity?.custodyWalletType?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.custodyWalletTypeId = newValue.key;
                                            dataSources.dependencies["custodyWalletType"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["custodyWalletType"]) {
                                                delete dataSources.dependencies["custodyWalletType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, custodyWalletTypeId: newValue.key });
                                            setUncheck({ ...uncheck, custodyWalletTypeId: false });
                                        }
                                        else if (databaseEntity?.custodyWalletType) {
                                            dataSources.dependencies.custodyWalletTypeId = undefined;
                                            dataSources.dependencies["custodyWalletType"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["custodyWalletType"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.custodyWalletTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyWalletType", query: CUSTODYWALLETTYPE_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyWalletType", query: CUSTODYWALLETTYPE_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["CustodyWalletType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyWalletTypecustodyWalletTypeIdEditVisible: !modals.CustodyWalletTypecustodyWalletTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletTypecustodyWalletTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletTypecustodyWalletTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyWalletType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.custodyWalletType?.id || databaseEntity?.custodyWalletType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyWalletTypecustodyWalletTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    custodyWalletTypeId: entity?.id,
                                                    custodyWalletType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyWalletTypeAddVisible: !modals.CustodyWalletTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyWalletType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyWalletTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        custodyWalletTypeId: entity?.id,
                                                        custodyWalletType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.custodyWalletType?.id || databaseEntity?.custodyWalletType?.id) && !uncheck['custodyWalletTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, custodyWalletTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            custodyWalletTypeId: "",
                                            custodyWalletType: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "custodyWalletProfileId") && <Form.Item label={<> {t('CustodyWalletProfile')}</>}>
                                <Form.Item noStyle id="custodyWalletProfile_id" name="custodyWalletProfileId"
                                rules={[{ required: (false && !(dataSources.dependencies?.custodyWalletProfile?.title || databaseEntity?.custodyWalletProfile?.title)), message: 'Please input the CustodyWalletProfile!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.custodyWalletProfile ? dataSources.dependencies?.custodyWalletProfile?.title : databaseEntity?.custodyWalletProfile?.title}
                                value={ dataSources.dependencies?.custodyWalletProfile ? dataSources.dependencies?.custodyWalletProfile?.title : databaseEntity?.custodyWalletProfile?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.custodyWalletProfileId = newValue.key;
                                            dataSources.dependencies["custodyWalletProfile"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["custodyWalletProfile"]) {
                                                delete dataSources.dependencies["custodyWalletProfile"].__typename;
                                            }                                            
                                            setSelects({ ...selects, custodyWalletProfileId: newValue.key });
                                            setUncheck({ ...uncheck, custodyWalletProfileId: false });
                                        }
                                        else if (databaseEntity?.custodyWalletProfile) {
                                            dataSources.dependencies.custodyWalletProfileId = undefined;
                                            dataSources.dependencies["custodyWalletProfile"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["custodyWalletProfile"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.custodyWalletProfileId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyWalletProfile", query: CUSTODYWALLETPROFILE_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyWalletProfile", query: CUSTODYWALLETPROFILE_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["CustodyWalletProfile"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyWalletProfilecustodyWalletProfileIdEditVisible: !modals.CustodyWalletProfilecustodyWalletProfileIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletProfilecustodyWalletProfileIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletProfilecustodyWalletProfileIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyWalletProfile
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletProfileDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.custodyWalletProfile?.id || databaseEntity?.custodyWalletProfile?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyWalletProfilecustodyWalletProfileIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    custodyWalletProfileId: entity?.id,
                                                    custodyWalletProfile: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyWalletProfileAddVisible: !modals.CustodyWalletProfileAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletProfileAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletProfileAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyWalletProfile
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletProfileDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyWalletProfileAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        custodyWalletProfileId: entity?.id,
                                                        custodyWalletProfile: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.custodyWalletProfile?.id || databaseEntity?.custodyWalletProfile?.id) && !uncheck['custodyWalletProfileId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, custodyWalletProfileId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            custodyWalletProfileId: "",
                                            custodyWalletProfile: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("CustodyWalletProfileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("custodyWalletProfileId") + '!' }]}>
                    {!!databaseEntity?.custodyWalletProfileId && !dropUpload['custodyWalletProfileId'] && !files['custodyWalletProfileId'] &&
                        <>
                            <a title="custodyWalletProfileId" target="_blank"  onClick={() => { StorageService.forceDownload(databaseEntity?.custodyWalletProfileId, "private") }}>{databaseEntity?.custodyWalletProfileId}</a>
                            
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, custodyWalletProfileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.custodyWalletProfileId || dropUpload['custodyWalletProfileId'] || files['custodyWalletProfileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, custodyWalletProfileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item>{ (!props.relation || props.relation.field != "custodyWalletFrequencyId") && <Form.Item label={<> {t('CustodyWalletFrequency')}</>}>
                                <Form.Item noStyle id="custodyWalletFrequency_id" name="custodyWalletFrequencyId"
                                rules={[{ required: (false && !(dataSources.dependencies?.custodyWalletFrequency?.title || databaseEntity?.custodyWalletFrequency?.title)), message: 'Please input the CustodyWalletFrequency!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.custodyWalletFrequency ? dataSources.dependencies?.custodyWalletFrequency?.title : databaseEntity?.custodyWalletFrequency?.title}
                                value={ dataSources.dependencies?.custodyWalletFrequency ? dataSources.dependencies?.custodyWalletFrequency?.title : databaseEntity?.custodyWalletFrequency?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.custodyWalletFrequencyId = newValue.key;
                                            dataSources.dependencies["custodyWalletFrequency"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["custodyWalletFrequency"]) {
                                                delete dataSources.dependencies["custodyWalletFrequency"].__typename;
                                            }                                            
                                            setSelects({ ...selects, custodyWalletFrequencyId: newValue.key });
                                            setUncheck({ ...uncheck, custodyWalletFrequencyId: false });
                                        }
                                        else if (databaseEntity?.custodyWalletFrequency) {
                                            dataSources.dependencies.custodyWalletFrequencyId = undefined;
                                            dataSources.dependencies["custodyWalletFrequency"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["custodyWalletFrequency"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.custodyWalletFrequencyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyWalletFrequency", query: CUSTODYWALLETFREQUENCY_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyWalletFrequency", query: CUSTODYWALLETFREQUENCY_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["CustodyWalletFrequency"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyWalletFrequencycustodyWalletFrequencyIdEditVisible: !modals.CustodyWalletFrequencycustodyWalletFrequencyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletFrequencycustodyWalletFrequencyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletFrequencycustodyWalletFrequencyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyWalletFrequency
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletFrequencyDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.custodyWalletFrequency?.id || databaseEntity?.custodyWalletFrequency?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyWalletFrequencycustodyWalletFrequencyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    custodyWalletFrequencyId: entity?.id,
                                                    custodyWalletFrequency: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyWalletFrequencyAddVisible: !modals.CustodyWalletFrequencyAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyWalletFrequencyAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyWalletFrequencyAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyWalletFrequency
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyWalletFrequencyDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyWalletFrequencyAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        custodyWalletFrequencyId: entity?.id,
                                                        custodyWalletFrequency: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.custodyWalletFrequency?.id || databaseEntity?.custodyWalletFrequency?.id) && !uncheck['custodyWalletFrequencyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, custodyWalletFrequencyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            custodyWalletFrequencyId: "",
                                            custodyWalletFrequency: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("AddressIndex")} name="addressIndex"
                            rules={[{ required: false, message: 'Please input the ' + t("AddressIndex") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("AccountIndex")} name="accountIndex"
                            rules={[{ required: false, message: 'Please input the ' + t("AccountIndex") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("ApplicationIndex")} name="applicationIndex"
                            rules={[{ required: false, message: 'Please input the ' + t("ApplicationIndex") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Path")} name="path"
                            rules={[{ required: false, message: 'Please input the ' + t("Path") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "applicationId") && <Form.Item label={<> {t('Application')}</>}>
                                <Form.Item noStyle id="application_id" name="applicationId"
                                rules={[{ required: (false && !(dataSources.dependencies?.application?.companyId || databaseEntity?.application?.companyId)), message: 'Please input the CustodyApplication!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.application ? dataSources.dependencies?.application?.companyId : databaseEntity?.application?.companyId}
                                value={ dataSources.dependencies?.application ? dataSources.dependencies?.application?.companyId : databaseEntity?.application?.companyId}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.applicationId = newValue.key;
                                            dataSources.dependencies["application"] = {
                                                id: newValue.key,
                                                companyId: newValue.value
                                            };
                                            if(dataSources.dependencies["application"]) {
                                                delete dataSources.dependencies["application"].__typename;
                                            }                                            
                                            setSelects({ ...selects, applicationId: newValue.key });
                                            setUncheck({ ...uncheck, applicationId: false });
                                        }
                                        else if (databaseEntity?.application) {
                                            dataSources.dependencies.applicationId = undefined;
                                            dataSources.dependencies["application"] = {
                                                id: undefined,
                                                companyId: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["application"] = {
                                            id: undefined,
                                            companyId: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.applicationId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "CustodyApplication", query: CUSTODYAPPLICATION_QUERY, idField: "id", textField: "companyId" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "CustodyApplication", query: CUSTODYAPPLICATION_QUERY, idField: "id", textField: "companyId" });
                                    }}>
                                    {(dataSources["CustodyApplication"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyId}>
                                            {item.companyId}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CustodyApplicationapplicationIdEditVisible: !modals.CustodyApplicationapplicationIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyApplicationapplicationIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyApplicationapplicationIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        CustodyApplication
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyApplicationDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.application?.id || databaseEntity?.application?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CustodyApplicationapplicationIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    applicationId: entity?.id,
                                                    application: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CustodyApplicationAddVisible: !modals.CustodyApplicationAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CustodyApplicationAddVisible"]}
                                    onHide={() => setModals({ ...modals, CustodyApplicationAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            CustodyApplication
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CustodyApplicationDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CustodyApplicationAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        applicationId: entity?.id,
                                                        application: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.application?.id || databaseEntity?.application?.id) && !uncheck['applicationId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, applicationId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            applicationId: "",
                                            application: {
                                                companyId: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("SyncMonitor")} name="syncMonitor" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`CustodyBlockchainTransaction`)}>
                            <CustodyBlockchainTransactionList relation={{model: "wallet", field: "walletId", textField: "address", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.address}}></CustodyBlockchainTransactionList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={2} tab={t(`CustodyWalletAsset`)}>
                            <CustodyWalletAssetList relation={{model: "wallet", field: "walletId", textField: "address", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.address}}></CustodyWalletAssetList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (CustodyWalletDetails);
  