//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { BusinessTaxType } from '../../../models/prisma/businessTaxType.model';
        import { GET_LIST, startedFilter } from './businessTaxType.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import PublicFileDetails from '../publicFile/publicFile.details';
import BusinessTaxList from '../businessTax/businessTax.list';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            businessTaxTypeCms: BusinessTaxType
        }>`
        query BusinessTaxTypeCms($id: String)
        {
            businessTaxTypeCms(id: $id)
          {
            id
                    title
                    description
                    createdAt
                    updatedAt
                    PublicFile {
                        id
                        url
                    }
                    publicFileId
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<BusinessTaxType>`
        mutation BusinessTaxTypeSave(
            $id: String,
                        $title: String,
                        $description: String,
                        $PublicFile: PublicFileCreateInputs,
                    $publicFileId: String,
                        
        )
        {
            businessTaxTypeCreateCms(data: {
                id: $id,
                    title: $title,
                    description: $description,
                    PublicFile: $PublicFile,
                    publicFileId: $publicFileId,
                    
            }) {
                id
                    title
                    description
                    createdAt
                    updatedAt
                    PublicFile {
                        id
                        url
                    }
                    publicFileId
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<BusinessTaxType>`
        mutation BusinessTaxTypeSave(
            $id: String,
                        $title: String,
                        $description: String,
                        $PublicFile: PublicFileCreateInputs,
                    $publicFileId: String,
                        
        )
        {
            businessTaxTypeUpdateCms(data: {
                id: $id,
                    title: $title,
                    description: $description,
                    PublicFile: $PublicFile,
                    publicFileId: $publicFileId,
                    
            }) {
                id
                    title
                    description
                    createdAt
                    updatedAt
                    PublicFile {
                        id
                        url
                    }
                    publicFileId
                    
            }
        }
        `;

        
                    const PUBLICFILE_QUERY = `
                    query publicFileListCms($search: Search) {
                        publicFileListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    url
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const BusinessTaxTypeDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ businessTaxTypeCms: BusinessTaxType }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.businessTaxTypeCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("BusinessTaxType has been saved");
                    const model = entity?.data?.businessTaxTypeUpdateCms || entity?.data?.businessTaxTypeCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/businessTaxType-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('BusinessTaxType')} breadcrumbItems={[
                                { title: t("BusinessTaxType"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('BusinessTaxType')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('BusinessTaxType')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Title")} name="title"
                            rules={[{ required: false, message: 'Please input the ' + t("Title") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Description")} name="description"
                            rules={[{ required: true, message: 'Please input the ' + t("Description") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "publicFileId") && <Form.Item label={<> {t('PublicFile')}</>}>
                                <Form.Item noStyle id="PublicFile_id" name="publicFileId"
                                rules={[{ required: (false && !(dataSources.dependencies?.PublicFile?.url || databaseEntity?.PublicFile?.url)), message: 'Please input the PublicFile!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.PublicFile ? dataSources.dependencies?.PublicFile?.url : databaseEntity?.PublicFile?.url}
                                value={ dataSources.dependencies?.PublicFile ? dataSources.dependencies?.PublicFile?.url : databaseEntity?.PublicFile?.url}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.publicFileId = newValue.key;
                                            dataSources.dependencies["PublicFile"] = {
                                                id: newValue.key,
                                                url: newValue.value
                                            };
                                            if(dataSources.dependencies["PublicFile"]) {
                                                delete dataSources.dependencies["PublicFile"].__typename;
                                            }                                            
                                            setSelects({ ...selects, publicFileId: newValue.key });
                                            setUncheck({ ...uncheck, publicFileId: false });
                                        }
                                        else if (databaseEntity?.PublicFile) {
                                            dataSources.dependencies.publicFileId = undefined;
                                            dataSources.dependencies["PublicFile"] = {
                                                id: undefined,
                                                url: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["PublicFile"] = {
                                            id: undefined,
                                            url: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.publicFileId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "PublicFile", query: PUBLICFILE_QUERY, idField: "id", textField: "url" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "PublicFile", query: PUBLICFILE_QUERY, idField: "id", textField: "url" });
                                    }}>
                                    {(dataSources["PublicFile"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.url}>
                                            {item.url}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, PublicFilepublicFileIdEditVisible: !modals.PublicFilepublicFileIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["PublicFilepublicFileIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, PublicFilepublicFileIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        PublicFile
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <PublicFileDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.PublicFile?.id || databaseEntity?.PublicFile?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, PublicFilepublicFileIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    publicFileId: entity?.id,
                                                    PublicFile: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, PublicFileAddVisible: !modals.PublicFileAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["PublicFileAddVisible"]}
                                    onHide={() => setModals({ ...modals, PublicFileAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            PublicFile
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <PublicFileDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, PublicFileAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        publicFileId: entity?.id,
                                                        PublicFile: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.PublicFile?.id || databaseEntity?.PublicFile?.id) && !uncheck['publicFileId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, publicFileId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            publicFileId: "",
                                            PublicFile: {
                                                url: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("PublicFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("publicFileId") + '!' }]}>
                    {!!databaseEntity?.publicFileId && !dropUpload['publicFileId'] && !files['publicFileId'] &&
                        <>
                            <a title="publicFileId" target="_blank"  onClick={() => { StorageService.forceDownload(databaseEntity?.publicFileId, "private") }}>{databaseEntity?.publicFileId}</a>
                            
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, publicFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.publicFileId || dropUpload['publicFileId'] || files['publicFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, publicFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item>
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`BusinessTax`)}>
                            <BusinessTaxList relation={{model: "businessTaxType", field: "businessTaxTypeId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></BusinessTaxList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (BusinessTaxTypeDetails);
  