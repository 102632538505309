//// ------------------------------------------------------
//// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
//// ------------------------------------------------------

import { Card, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { CardBody, UncontrolledTooltip } from 'reactstrap';
import { client } from '../../../hooks/GraphQLProvider';
import React, { useState, useEffect } from 'react';
import { Button, Pagination } from 'antd';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import FilterBar from '../../../components/FilterBar/FilterBar';
import { Link } from "react-router-dom";
import { BiMessageEdit } from 'react-icons/all';
import { VirtualAsset } from '../../../models/prisma/virtualAsset.model';
import VirtualAssetDetails from './virtualAsset.details';
import gql from 'graphql-tag-ts';
import { useQuery, useSubscription } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";

const GET_LIST = gql<{
    virtualAssetListCms: {
        edges: {
            cursor: string,
            node: VirtualAsset
        }[],
        totalCount: number
    }
}>`
    query VirtualAssets($pagination: PaginationArgs, $search: Search, $orderBy: VirtualAssetOrder, $dateRange: VirtualAssetDateRange, $id: String,
                    $name: String,
                $symbol: String,
                $typeId: String,
                ) {
        virtualAssetListCms(search: $search, orderBy: $orderBy, dateRange: $dateRange, pagination: $pagination, id: $id,
                name: $name,
                symbol: $symbol,
                typeId: $typeId,
                ) {
            edges {
                node {
                    
                id
  
                name
  
                symbol
  type {
                        id
                        title
                    }
                    
                }
            }
            totalCount
        }
    }
`;

const DELETE = gql<VirtualAsset>`
    mutation DELETE($id: String!) {
        virtualAssetDeleteCms(data: { id: $id }) {
            id
        }
    }
`;

const startedFilter = {
    orderBy: {
        field: "createdAt",
        direction: "desc"
    },
    pagination: {
        skip: 0,
        take: 10
    }
};

const VirtualAssetList = (props: any) => {
    const { t } = useTranslation();
    const [modals, setModals] = useState<any>({});
    const [activePage, setActivePage] = useState<number>(1);
    const [field, setField] = useState<string>("id");
    const filterBar = {
        model: "virtualAsset",
        filter: {} as any,
        reset: (startedFilter:any) => {
            setFilter(startedFilter);
            setUpdateFilter(startedFilter);
        },
        find: () => {
            setUpdateFilter(filter);
        },
        period: {
            action: (start: Date, end: Date) => {
                if (start || end) {
                    setFilter({ ...filter, dateRange: { field: filterBar.period.field, start: start?.toString(), end: end?.toString() } })
                }
                else {
                    setFilter({ ...filter, dateRange: undefined });
                }
            },
            field: "createdAt"
        },
        search: {
            onChangeField: (value: any) => {
                setField(value);
                const newFilter = { ...filter, ...(value && filter.search ? { field, search: { ...filter.search, field } } : {}) };
                setFilter(newFilter);
            },
            action: (value: any) => {

                if(value) {
                    const newFilter = { ...filter, search: { ...filter.search, type: "contains", field, search: value } };
                    setFilter(newFilter);
                    setUpdateFilter(newFilter);
                }
                else {
                    const newFilter = { ...filter };
                    delete newFilter.search;
                    setFilter(newFilter);
                    setUpdateFilter(newFilter);
                }                
            },
            placeholder: t("Search unit or group..."),
            default: "id",
            fields: ["id","name","symbol","type","typeId","deleted","createdAt","updatedAt","createdBy","updatedBy","virtualWalletAsset","virtualAssetMap"]
        },
        sort: {
            onChangeField: (value: any) => {                
                setFilter({ ...filter, orderBy: { field: value, direction: filter.orderBy?.direction } })
            },
            default: "createdAt",
            defaultDirection: "desc",
            fields: ["id","name","symbol","type","typeId","deleted","createdAt","updatedAt","createdBy","updatedBy","virtualWalletAsset","virtualAssetMap"],
            ascending: {
                label: t("Oldest First"),
                onClick: () => {
                    setFilter({ ...filter, orderBy: { field: filter.orderBy?.field || filterBar.sort.default, direction: 'asc' } })
                }
            },
            descending: {
                label: t("Newest First"),
                onClick: () => {
                    setFilter({ ...filter, orderBy: { field: filter.orderBy?.field || filterBar.sort.default, direction: 'desc' } })
                }
            }
        },
        dropdownFilters: [
            
        ]
    }

    const deleteEntity = async (id: string) => {
        if (window.confirm("Do you want delete it?")) {
            const { data } = await client.mutate({
                mutation: DELETE,
                variables: {
                    id: id
                }
            });

            if (data) {
                refetch();
            }
        }
    }
    
    const [filter, setFilter] = useState<any>({...startedFilter, [props?.relation?.field]: props?.relation?.id});
    
    filterBar.filter = filter;
    const [updateFilter, setUpdateFilter] = useState<any>(filter);    

    const { data, loading, refetch } = useQuery<{
        virtualAssetListCms: {
            edges: {
                node: VirtualAsset
            }[],
            totalCount: number
        }
    }>(GET_LIST, {
        variables: updateFilter
    });

    const list = data?.virtualAssetListCms || { totalCount: 0, edges: [] };

    const onShowSizeChange = (current:any, pageSize:any) => {
        
        filter['pagination'].take=pageSize;
        changePage(current?current:activePage);
      
    }

    const changePage = (page: number) => {
        const filterUpdate = { ...filter, pagination: { ...filter?.pagination, skip: (page - 1) * (filter?.pagination?.take || 50) } };
        setFilter(filterUpdate);
        setUpdateFilter(filterUpdate);

        setActivePage(page);
    }

    const sort = (column: string) => {
        const filterUpdate = { ...filter, orderBy: { ...filter.orderBy, field: column, direction: filter.orderBy?.direction === 'desc' ? 'asc' : 'desc' } };
        setFilter(filterUpdate);
        setUpdateFilter(filterUpdate);
    }

    const getSortClass = (column: string) => {
        return column === filter.orderBy?.field ? `sorting_${filter.orderBy?.direction}` : '';
    }

    const webSocket = useSubscription(
        gql`
        subscription WebSocket {
            virtualAssetSnapshotCms {
                id
            }
        }`,
        {
            fetchPolicy: 'no-cache'
        }
    );

    useEffect(() => {
        refetch();
    }, [webSocket.data, refetch])

    return (
        <React.Fragment>
            <div className="page-content" style={props.relation ? {padding: 0} : {}}>
                <Container fluid style={props.relation ? {padding: 0} : {}}>
                    {!props.relation && <Breadcrumbs title={t("VirtualAsset")} breadcrumbItems={[
                        { title: t("VirtualAsset"), link: "#" },
                        { title: t("List"), link: "#" },
                    ]} /> }

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <Row>
                                                <Col sm={12} md={8}>
                                                    <h4 className="card-title">{t('VirtualAsset')} {t('List')}</h4>
                                                    <p className="card-title-desc">{t('Bellow is a responsive')} {t('VirtualAsset')} {t('List with principal data items')}.</p>
                                                </Col>
                                                <Col sm={12} md={4} className="aligner">
                                                    {!props.relation && <Link style={{width: '100%'}} to="/virtualAsset-details">
                                                        <Button style={{width: '100%'}} type="dashed" className="aligner-item">{t('Add New')}</Button>
                                                    </Link>}
                                                    {!!props.relation && <a onClick={() => { setModals({visibleAdd: true}) }}>
                                                        <Button style={{width: '100%'}} type="dashed" className="aligner-item">{t('Add New')}</Button>
                                                    </a>}
                                                    {!!props.relation && <Modal
                                                        size="lg"
                                                        show={modals[`visibleAdd`]}
                                                        onHide={() => setModals({ ...modals, [`visibleAdd`]: false })}
                                                        aria-labelledby="example-modal-sizes-title-lg">
                                                        <Modal.Header closeButton>
                                                            <Modal.Title id="example-modal-sizes-title-lg">
                                                                {t('VirtualAsset')}
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <VirtualAssetDetails isModal={true} {...props} modalOnClose={(entity:any) => { setModals({ ...modals, [`visibleAdd`]: false }) } } />
                                                        </Modal.Body>
                                                    </Modal> }
                                                </Col>
                                            </Row>                                            
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FilterBar loading={loading} {...filterBar} />
                                        </Col>
                                    </Row>
                                    { /*<Pagination
                                                showSizeChanger={true}
                                                
                                                onChange={onShowSizeChange}
                                                defaultCurrent={1}
                                                total={list.totalCount}
                                                    />*/ }
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive mb-0 table-noscroll" data-pattern="priority-columns">
                                            <Table responsive className="table table-bordered dataTable">
                                                <thead className="ordenable" data-test="datatable-head">
                                                    <tr>
                                                        
                                                        <th className={getSortClass('id')} onClick={() => sort('id')}>{t('Id')}</th>
    
                                                        <th className={getSortClass('name')} onClick={() => sort('name')}>{t('Name')}</th>
    
                                                        <th className={getSortClass('symbol')} onClick={() => sort('symbol')}>{t('Symbol')}</th>
    
                                                        <th className={getSortClass('type')} onClick={() => sort('type')}>{t('Type')}</th>
    
                                                        <th>
                                                            {t('Actions')}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list.edges?.map(({ node }, index: number) =>
                                                        (<tr key={index}>
                                                            
                                                            <td style={{maxWidth:200}}>
                                                                {node.id}
                                                            </td>
  
                                                            <td style={{maxWidth:200}}>
                                                                {node.name}
                                                            </td>
  
                                                            <td style={{maxWidth:200}}>
                                                                {node.symbol}
                                                            </td>
  
                                                            <td>
                                                                {node.type?.title}
                                                            </td>
  
                                                            <td>                                                                
                                                                <a onClick={() => { setModals({ ...modals, [`visible-${node.id}`]: !modals[`visible-${node.id}`] }) }} className="mr-3 text-primary" id="edit12">
                                                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                                                </a>
                                                                <Modal
                                                                    size="lg"
                                                                    show={modals[`visible-${node.id}`]}
                                                                    onHide={() => setModals({ ...modals, [`visible-${node.id}`]: false })}
                                                                    aria-labelledby="example-modal-sizes-title-lg">
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title id="example-modal-sizes-title-lg">
                                                                        VirtualAsset
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <VirtualAssetDetails isModal={true} {...props} match={{
                                                                            params: {
                                                                                guid: node.id                                                                                
                                                                            }
                                                                        }} 
                                                                        modalOnClose={(entity:any) => { setModals({ ...modals, [`visible-${node.id}`]: false }) } }
                                                                         />
                                                                    </Modal.Body>
                                                                </Modal>
                                                                {!props.relation && <Link to={`/virtualAsset-details/${encodeURIComponent(node.id)}`} className="mr-3 text-primary" id="edit13"><i className="mdi mdi-pencil font-size-18"></i></Link>}
                                                                
                                                                <a onClick={() => { deleteEntity(node.id) }} className="text-danger" id="delete13"><i className="mdi mdi-trash-can font-size-18"></i></a>
                                                               
                                                            </td>
                                                        </tr>)
                                                    )}
                                                </tbody>
                                                <thead className="ordenable" data-test="datatable-head">
                                                    <tr>
                                                        
                                                        <th className={getSortClass('id')} onClick={() => sort('id')}>{t('Id')}</th>
    
                                                        <th className={getSortClass('name')} onClick={() => sort('name')}>{t('Name')}</th>
    
                                                        <th className={getSortClass('symbol')} onClick={() => sort('symbol')}>{t('Symbol')}</th>
    
                                                        <th className={getSortClass('type')} onClick={() => sort('type')}>{t('Type')}</th>
    
                                                        <th>
                                                            {t('Actions')}
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </Table>
                                            {!!list.totalCount && <div style={{ textAlign: 'right' }}>Showing {(((activePage) * filter?.pagination?.take) - filter?.pagination?.take) || 1} to {(activePage * filter?.pagination?.take) < list.totalCount ? (activePage * filter?.pagination?.take) : list.totalCount} of {list.totalCount} entries</div>}
                                            <Pagination
                                            showSizeChanger={true}
    
                                            onChange={onShowSizeChange}
                                            defaultCurrent={1}
                                            total={list.totalCount}
                                            /> 
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default (VirtualAssetList);
export { GET_LIST, startedFilter };
  