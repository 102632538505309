//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { Statement } from '../../../models/prisma/statement.model';
        import { GET_LIST, startedFilter } from './statement.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import AccountDetails from '../account/account.details';
import InvestorDetails from '../investor/investor.details';
import CompanyDetails from '../company/company.details';
import BlockchainTaxDetails from '../blockchainTax/blockchainTax.details';
import StatementCategoryDetails from '../statementCategory/statementCategory.details';
import BlockchainTokenDetails from '../blockchainToken/blockchainToken.details';
import OfferDetails from '../offer/offer.details';
import BankTransactionDetails from '../bankTransaction/bankTransaction.details';
import StatementStatusDetails from '../statementStatus/statementStatus.details';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            statementCms: Statement
        }>`
        query StatementCms($id: String)
        {
            statementCms(id: $id)
          {
            id
                    symbol
                    symbolName
                    iconUrl
                    value
                    amount
                    prevBalance
                    nextBalance
                    account {
                        id
                        name
                    }
                    accountId
                    investor {
                        id
                        companyId
                    }
                    investorId
                    company {
                        id
                        companyName
                    }
                    companyId
                    description
                    operationId
                    operationType
                    tax {
                        id
                        hash
                    }
                    taxId
                    category {
                        id
                        title
                    }
                    categoryId
                    createdAt
                    updatedAt
                    token {
                        id
                        name
                    }
                    tokenId
                    offer {
                        id
                        title
                    }
                    offerId
                    bank {
                        id
                        bankTransactionId
                    }
                    bankId
                    status {
                        id
                        description
                    }
                    statusId
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<Statement>`
        mutation StatementSave(
            $id: String,
                        $symbol: String,
                        $symbolName: String,
                        $iconUrl: String,
                        $value: Float,
                        $amount: Float,
                        $prevBalance: Float,
                        $nextBalance: Float,
                        $account: AccountCreateInputs,
                    $accountId: String,
                        $investor: InvestorCreateInputs,
                    $investorId: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $description: String,
                        $operationId: String,
                        $operationType: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $category: StatementCategoryCreateInputs,
                    $categoryId: String,
                        $token: BlockchainTokenCreateInputs,
                    $tokenId: String,
                        $offer: OfferCreateInputs,
                    $offerId: String,
                        $bank: BankTransactionCreateInputs,
                    $bankId: String,
                        $status: StatementStatusCreateInputs,
                    $statusId: String,
                        
        )
        {
            statementCreateCms(data: {
                id: $id,
                    symbol: $symbol,
                    symbolName: $symbolName,
                    iconUrl: $iconUrl,
                    value: $value,
                    amount: $amount,
                    prevBalance: $prevBalance,
                    nextBalance: $nextBalance,
                    account: $account,
                    accountId: $accountId,
                    investor: $investor,
                    investorId: $investorId,
                    company: $company,
                    companyId: $companyId,
                    description: $description,
                    operationId: $operationId,
                    operationType: $operationType,
                    tax: $tax,
                    taxId: $taxId,
                    category: $category,
                    categoryId: $categoryId,
                    token: $token,
                    tokenId: $tokenId,
                    offer: $offer,
                    offerId: $offerId,
                    bank: $bank,
                    bankId: $bankId,
                    status: $status,
                    statusId: $statusId,
                    
            }) {
                id
                    symbol
                    symbolName
                    iconUrl
                    value
                    amount
                    prevBalance
                    nextBalance
                    account {
                        id
                        name
                    }
                    accountId
                    investor {
                        id
                        companyId
                    }
                    investorId
                    company {
                        id
                        companyName
                    }
                    companyId
                    description
                    operationId
                    operationType
                    tax {
                        id
                        hash
                    }
                    taxId
                    category {
                        id
                        title
                    }
                    categoryId
                    createdAt
                    updatedAt
                    token {
                        id
                        name
                    }
                    tokenId
                    offer {
                        id
                        title
                    }
                    offerId
                    bank {
                        id
                        bankTransactionId
                    }
                    bankId
                    status {
                        id
                        description
                    }
                    statusId
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<Statement>`
        mutation StatementSave(
            $id: String,
                        $symbol: String,
                        $symbolName: String,
                        $iconUrl: String,
                        $value: Float,
                        $amount: Float,
                        $prevBalance: Float,
                        $nextBalance: Float,
                        $account: AccountCreateInputs,
                    $accountId: String,
                        $investor: InvestorCreateInputs,
                    $investorId: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $description: String,
                        $operationId: String,
                        $operationType: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $category: StatementCategoryCreateInputs,
                    $categoryId: String,
                        $token: BlockchainTokenCreateInputs,
                    $tokenId: String,
                        $offer: OfferCreateInputs,
                    $offerId: String,
                        $bank: BankTransactionCreateInputs,
                    $bankId: String,
                        $status: StatementStatusCreateInputs,
                    $statusId: String,
                        
        )
        {
            statementUpdateCms(data: {
                id: $id,
                    symbol: $symbol,
                    symbolName: $symbolName,
                    iconUrl: $iconUrl,
                    value: $value,
                    amount: $amount,
                    prevBalance: $prevBalance,
                    nextBalance: $nextBalance,
                    account: $account,
                    accountId: $accountId,
                    investor: $investor,
                    investorId: $investorId,
                    company: $company,
                    companyId: $companyId,
                    description: $description,
                    operationId: $operationId,
                    operationType: $operationType,
                    tax: $tax,
                    taxId: $taxId,
                    category: $category,
                    categoryId: $categoryId,
                    token: $token,
                    tokenId: $tokenId,
                    offer: $offer,
                    offerId: $offerId,
                    bank: $bank,
                    bankId: $bankId,
                    status: $status,
                    statusId: $statusId,
                    
            }) {
                id
                    symbol
                    symbolName
                    iconUrl
                    value
                    amount
                    prevBalance
                    nextBalance
                    account {
                        id
                        name
                    }
                    accountId
                    investor {
                        id
                        companyId
                    }
                    investorId
                    company {
                        id
                        companyName
                    }
                    companyId
                    description
                    operationId
                    operationType
                    tax {
                        id
                        hash
                    }
                    taxId
                    category {
                        id
                        title
                    }
                    categoryId
                    createdAt
                    updatedAt
                    token {
                        id
                        name
                    }
                    tokenId
                    offer {
                        id
                        title
                    }
                    offerId
                    bank {
                        id
                        bankTransactionId
                    }
                    bankId
                    status {
                        id
                        description
                    }
                    statusId
                    
            }
        }
        `;

        
                    const ACCOUNT_QUERY = `
                    query accountListCms($search: Search) {
                        accountListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const INVESTOR_QUERY = `
                    query investorListCms($search: Search) {
                        investorListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyId
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const COMPANY_QUERY = `
                    query companyListCms($search: Search) {
                        companyListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyName
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTAX_QUERY = `
                    query blockchainTaxListCms($search: Search) {
                        blockchainTaxListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    hash
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const STATEMENTCATEGORY_QUERY = `
                    query statementCategoryListCms($search: Search) {
                        statementCategoryListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTOKEN_QUERY = `
                    query blockchainTokenListCms($search: Search) {
                        blockchainTokenListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const OFFER_QUERY = `
                    query offerListCms($search: Search) {
                        offerListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BANKTRANSACTION_QUERY = `
                    query bankTransactionListCms($search: Search) {
                        bankTransactionListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    bankTransactionId
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const STATEMENTSTATUS_QUERY = `
                    query statementStatusListCms($search: Search) {
                        statementStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const StatementDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ statementCms: Statement }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.statementCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("Statement has been saved");
                    const model = entity?.data?.statementUpdateCms || entity?.data?.statementCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/statement-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('Statement')} breadcrumbItems={[
                                { title: t("Statement"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('Statement')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('Statement')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Symbol")} name="symbol"
                            rules={[{ required: false, message: 'Please input the ' + t("Symbol") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("SymbolName")} name="symbolName"
                            rules={[{ required: false, message: 'Please input the ' + t("SymbolName") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("IconUrl")} name="iconUrl"
                            rules={[{ required: false, message: 'Please input the ' + t("IconUrl") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Value")} name="value"
                            rules={[{ required: true, message: 'Please input the ' + t("Value") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Amount")} name="amount"
                            rules={[{ required: true, message: 'Please input the ' + t("Amount") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("PrevBalance")} name="prevBalance"
                            rules={[{ required: true, message: 'Please input the ' + t("PrevBalance") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("NextBalance")} name="nextBalance"
                            rules={[{ required: true, message: 'Please input the ' + t("NextBalance") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "accountId") && <Form.Item label={<> {t('Account')}</>}>
                                <Form.Item noStyle id="account_id" name="accountId"
                                rules={[{ required: (false && !(dataSources.dependencies?.account?.name || databaseEntity?.account?.name)), message: 'Please input the Account!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.account ? dataSources.dependencies?.account?.name : databaseEntity?.account?.name}
                                value={ dataSources.dependencies?.account ? dataSources.dependencies?.account?.name : databaseEntity?.account?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.accountId = newValue.key;
                                            dataSources.dependencies["account"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["account"]) {
                                                delete dataSources.dependencies["account"].__typename;
                                            }                                            
                                            setSelects({ ...selects, accountId: newValue.key });
                                            setUncheck({ ...uncheck, accountId: false });
                                        }
                                        else if (databaseEntity?.account) {
                                            dataSources.dependencies.accountId = undefined;
                                            dataSources.dependencies["account"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["account"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.accountId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Account", query: ACCOUNT_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Account", query: ACCOUNT_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["Account"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, AccountaccountIdEditVisible: !modals.AccountaccountIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["AccountaccountIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, AccountaccountIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Account
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AccountDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.account?.id || databaseEntity?.account?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, AccountaccountIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    accountId: entity?.id,
                                                    account: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, AccountAddVisible: !modals.AccountAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["AccountAddVisible"]}
                                    onHide={() => setModals({ ...modals, AccountAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Account
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AccountDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, AccountAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        accountId: entity?.id,
                                                        account: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.account?.id || databaseEntity?.account?.id) && !uncheck['accountId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, accountId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            accountId: "",
                                            account: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "investorId") && <Form.Item label={<> {t('Investor')}</>}>
                                <Form.Item noStyle id="investor_id" name="investorId"
                                rules={[{ required: (false && !(dataSources.dependencies?.investor?.companyId || databaseEntity?.investor?.companyId)), message: 'Please input the Investor!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.investor ? dataSources.dependencies?.investor?.companyId : databaseEntity?.investor?.companyId}
                                value={ dataSources.dependencies?.investor ? dataSources.dependencies?.investor?.companyId : databaseEntity?.investor?.companyId}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.investorId = newValue.key;
                                            dataSources.dependencies["investor"] = {
                                                id: newValue.key,
                                                companyId: newValue.value
                                            };
                                            if(dataSources.dependencies["investor"]) {
                                                delete dataSources.dependencies["investor"].__typename;
                                            }                                            
                                            setSelects({ ...selects, investorId: newValue.key });
                                            setUncheck({ ...uncheck, investorId: false });
                                        }
                                        else if (databaseEntity?.investor) {
                                            dataSources.dependencies.investorId = undefined;
                                            dataSources.dependencies["investor"] = {
                                                id: undefined,
                                                companyId: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["investor"] = {
                                            id: undefined,
                                            companyId: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.investorId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Investor", query: INVESTOR_QUERY, idField: "id", textField: "companyId" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Investor", query: INVESTOR_QUERY, idField: "id", textField: "companyId" });
                                    }}>
                                    {(dataSources["Investor"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyId}>
                                            {item.companyId}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, InvestorinvestorIdEditVisible: !modals.InvestorinvestorIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["InvestorinvestorIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, InvestorinvestorIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Investor
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InvestorDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.investor?.id || databaseEntity?.investor?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, InvestorinvestorIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    investorId: entity?.id,
                                                    investor: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, InvestorAddVisible: !modals.InvestorAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["InvestorAddVisible"]}
                                    onHide={() => setModals({ ...modals, InvestorAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Investor
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InvestorDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, InvestorAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        investorId: entity?.id,
                                                        investor: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.investor?.id || databaseEntity?.investor?.id) && !uncheck['investorId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, investorId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            investorId: "",
                                            investor: {
                                                companyId: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "companyId") && <Form.Item label={<> {t('Company')}</>}>
                                <Form.Item noStyle id="company_id" name="companyId"
                                rules={[{ required: (false && !(dataSources.dependencies?.company?.companyName || databaseEntity?.company?.companyName)), message: 'Please input the Company!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                value={ dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyId = newValue.key;
                                            dataSources.dependencies["company"] = {
                                                id: newValue.key,
                                                companyName: newValue.value
                                            };
                                            if(dataSources.dependencies["company"]) {
                                                delete dataSources.dependencies["company"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyId: newValue.key });
                                            setUncheck({ ...uncheck, companyId: false });
                                        }
                                        else if (databaseEntity?.company) {
                                            dataSources.dependencies.companyId = undefined;
                                            dataSources.dependencies["company"] = {
                                                id: undefined,
                                                companyName: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["company"] = {
                                            id: undefined,
                                            companyName: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}>
                                    {(dataSources["Company"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyName}>
                                            {item.companyName}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CompanycompanyIdEditVisible: !modals.CompanycompanyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanycompanyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CompanycompanyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.company?.id || databaseEntity?.company?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CompanycompanyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyId: entity?.id,
                                                    company: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CompanyAddVisible: !modals.CompanyAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanyAddVisible"]}
                                    onHide={() => setModals({ ...modals, CompanyAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CompanyAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyId: entity?.id,
                                                        company: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.company?.id || databaseEntity?.company?.id) && !uncheck['companyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyId: "",
                                            company: {
                                                companyName: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Description")} name="description"
                            rules={[{ required: false, message: 'Please input the ' + t("Description") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("OperationId")} name="operationId"
                            rules={[{ required: false, message: 'Please input the ' + t("OperationId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("OperationType")} name="operationType"
                            rules={[{ required: false, message: 'Please input the ' + t("OperationType") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "taxId") && <Form.Item label={<> {t('Tax')}</>}>
                                <Form.Item noStyle id="tax_id" name="taxId"
                                rules={[{ required: (false && !(dataSources.dependencies?.tax?.hash || databaseEntity?.tax?.hash)), message: 'Please input the BlockchainTax!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                value={ dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.taxId = newValue.key;
                                            dataSources.dependencies["tax"] = {
                                                id: newValue.key,
                                                hash: newValue.value
                                            };
                                            if(dataSources.dependencies["tax"]) {
                                                delete dataSources.dependencies["tax"].__typename;
                                            }                                            
                                            setSelects({ ...selects, taxId: newValue.key });
                                            setUncheck({ ...uncheck, taxId: false });
                                        }
                                        else if (databaseEntity?.tax) {
                                            dataSources.dependencies.taxId = undefined;
                                            dataSources.dependencies["tax"] = {
                                                id: undefined,
                                                hash: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["tax"] = {
                                            id: undefined,
                                            hash: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.taxId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}>
                                    {(dataSources["BlockchainTax"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.hash}>
                                            {item.hash}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxtaxIdEditVisible: !modals.BlockchainTaxtaxIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxtaxIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    taxId: entity?.id,
                                                    tax: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxAddVisible: !modals.BlockchainTaxAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTaxAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        taxId: entity?.id,
                                                        tax: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id) && !uncheck['taxId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, taxId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            taxId: "",
                                            tax: {
                                                hash: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "categoryId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Category')}</>}>
                                <Form.Item noStyle id="category_id" name="categoryId"
                                rules={[{ required: (true && !(dataSources.dependencies?.category?.title || databaseEntity?.category?.title)), message: 'Please input the StatementCategory!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.category ? dataSources.dependencies?.category?.title : databaseEntity?.category?.title}
                                value={ dataSources.dependencies?.category ? dataSources.dependencies?.category?.title : databaseEntity?.category?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.categoryId = newValue.key;
                                            dataSources.dependencies["category"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["category"]) {
                                                delete dataSources.dependencies["category"].__typename;
                                            }                                            
                                            setSelects({ ...selects, categoryId: newValue.key });
                                            setUncheck({ ...uncheck, categoryId: false });
                                        }
                                        else if (databaseEntity?.category) {
                                            dataSources.dependencies.categoryId = undefined;
                                            dataSources.dependencies["category"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["category"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.categoryId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "StatementCategory", query: STATEMENTCATEGORY_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "StatementCategory", query: STATEMENTCATEGORY_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["StatementCategory"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, StatementCategorycategoryIdEditVisible: !modals.StatementCategorycategoryIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["StatementCategorycategoryIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, StatementCategorycategoryIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        StatementCategory
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StatementCategoryDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.category?.id || databaseEntity?.category?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, StatementCategorycategoryIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    categoryId: entity?.id,
                                                    category: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, StatementCategoryAddVisible: !modals.StatementCategoryAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["StatementCategoryAddVisible"]}
                                    onHide={() => setModals({ ...modals, StatementCategoryAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            StatementCategory
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StatementCategoryDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, StatementCategoryAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        categoryId: entity?.id,
                                                        category: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.category?.id || databaseEntity?.category?.id) && !uncheck['categoryId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, categoryId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            categoryId: "",
                                            category: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "tokenId") && <Form.Item label={<> {t('Token')}</>}>
                                <Form.Item noStyle id="token_id" name="tokenId"
                                rules={[{ required: (false && !(dataSources.dependencies?.token?.name || databaseEntity?.token?.name)), message: 'Please input the BlockchainToken!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.token ? dataSources.dependencies?.token?.name : databaseEntity?.token?.name}
                                value={ dataSources.dependencies?.token ? dataSources.dependencies?.token?.name : databaseEntity?.token?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.tokenId = newValue.key;
                                            dataSources.dependencies["token"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["token"]) {
                                                delete dataSources.dependencies["token"].__typename;
                                            }                                            
                                            setSelects({ ...selects, tokenId: newValue.key });
                                            setUncheck({ ...uncheck, tokenId: false });
                                        }
                                        else if (databaseEntity?.token) {
                                            dataSources.dependencies.tokenId = undefined;
                                            dataSources.dependencies["token"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["token"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.tokenId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["BlockchainToken"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTokentokenIdEditVisible: !modals.BlockchainTokentokenIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokentokenIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokentokenIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.token?.id || databaseEntity?.token?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTokentokenIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    tokenId: entity?.id,
                                                    token: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenAddVisible: !modals.BlockchainTokenAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTokenAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        tokenId: entity?.id,
                                                        token: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.token?.id || databaseEntity?.token?.id) && !uncheck['tokenId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, tokenId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            tokenId: "",
                                            token: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "offerId") && <Form.Item label={<> {t('Offer')}</>}>
                                <Form.Item noStyle id="offer_id" name="offerId"
                                rules={[{ required: (false && !(dataSources.dependencies?.offer?.title || databaseEntity?.offer?.title)), message: 'Please input the Offer!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.offer ? dataSources.dependencies?.offer?.title : databaseEntity?.offer?.title}
                                value={ dataSources.dependencies?.offer ? dataSources.dependencies?.offer?.title : databaseEntity?.offer?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.offerId = newValue.key;
                                            dataSources.dependencies["offer"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["offer"]) {
                                                delete dataSources.dependencies["offer"].__typename;
                                            }                                            
                                            setSelects({ ...selects, offerId: newValue.key });
                                            setUncheck({ ...uncheck, offerId: false });
                                        }
                                        else if (databaseEntity?.offer) {
                                            dataSources.dependencies.offerId = undefined;
                                            dataSources.dependencies["offer"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["offer"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.offerId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Offer", query: OFFER_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Offer", query: OFFER_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["Offer"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OfferofferIdEditVisible: !modals.OfferofferIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferofferIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OfferofferIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Offer
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.offer?.id || databaseEntity?.offer?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OfferofferIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    offerId: entity?.id,
                                                    offer: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OfferAddVisible: !modals.OfferAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferAddVisible"]}
                                    onHide={() => setModals({ ...modals, OfferAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Offer
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OfferAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        offerId: entity?.id,
                                                        offer: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.offer?.id || databaseEntity?.offer?.id) && !uncheck['offerId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, offerId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            offerId: "",
                                            offer: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "bankId") && <Form.Item label={<> {t('Bank')}</>}>
                                <Form.Item noStyle id="bank_id" name="bankId"
                                rules={[{ required: (false && !(dataSources.dependencies?.bank?.bankTransactionId || databaseEntity?.bank?.bankTransactionId)), message: 'Please input the BankTransaction!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.bank ? dataSources.dependencies?.bank?.bankTransactionId : databaseEntity?.bank?.bankTransactionId}
                                value={ dataSources.dependencies?.bank ? dataSources.dependencies?.bank?.bankTransactionId : databaseEntity?.bank?.bankTransactionId}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.bankId = newValue.key;
                                            dataSources.dependencies["bank"] = {
                                                id: newValue.key,
                                                bankTransactionId: newValue.value
                                            };
                                            if(dataSources.dependencies["bank"]) {
                                                delete dataSources.dependencies["bank"].__typename;
                                            }                                            
                                            setSelects({ ...selects, bankId: newValue.key });
                                            setUncheck({ ...uncheck, bankId: false });
                                        }
                                        else if (databaseEntity?.bank) {
                                            dataSources.dependencies.bankId = undefined;
                                            dataSources.dependencies["bank"] = {
                                                id: undefined,
                                                bankTransactionId: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["bank"] = {
                                            id: undefined,
                                            bankTransactionId: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.bankId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BankTransaction", query: BANKTRANSACTION_QUERY, idField: "id", textField: "bankTransactionId" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BankTransaction", query: BANKTRANSACTION_QUERY, idField: "id", textField: "bankTransactionId" });
                                    }}>
                                    {(dataSources["BankTransaction"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.bankTransactionId}>
                                            {item.bankTransactionId}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BankTransactionbankIdEditVisible: !modals.BankTransactionbankIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankTransactionbankIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BankTransactionbankIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BankTransaction
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankTransactionDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.bank?.id || databaseEntity?.bank?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BankTransactionbankIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    bankId: entity?.id,
                                                    bank: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BankTransactionAddVisible: !modals.BankTransactionAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankTransactionAddVisible"]}
                                    onHide={() => setModals({ ...modals, BankTransactionAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BankTransaction
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankTransactionDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BankTransactionAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        bankId: entity?.id,
                                                        bank: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.bank?.id || databaseEntity?.bank?.id) && !uncheck['bankId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, bankId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            bankId: "",
                                            bank: {
                                                bankTransactionId: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "statusId") && <Form.Item label={<> {t('Status')}</>}>
                                <Form.Item noStyle id="status_id" name="statusId"
                                rules={[{ required: (false && !(dataSources.dependencies?.status?.description || databaseEntity?.status?.description)), message: 'Please input the StatementStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                value={ dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.statusId = newValue.key;
                                            dataSources.dependencies["status"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["status"]) {
                                                delete dataSources.dependencies["status"].__typename;
                                            }                                            
                                            setSelects({ ...selects, statusId: newValue.key });
                                            setUncheck({ ...uncheck, statusId: false });
                                        }
                                        else if (databaseEntity?.status) {
                                            dataSources.dependencies.statusId = undefined;
                                            dataSources.dependencies["status"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["status"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.statusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "StatementStatus", query: STATEMENTSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "StatementStatus", query: STATEMENTSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["StatementStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, StatementStatusstatusIdEditVisible: !modals.StatementStatusstatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["StatementStatusstatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, StatementStatusstatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        StatementStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StatementStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.status?.id || databaseEntity?.status?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, StatementStatusstatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    statusId: entity?.id,
                                                    status: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, StatementStatusAddVisible: !modals.StatementStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["StatementStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, StatementStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            StatementStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StatementStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, StatementStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        statusId: entity?.id,
                                                        status: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.status?.id || databaseEntity?.status?.id) && !uncheck['statusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, statusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            statusId: "",
                                            status: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (StatementDetails);
  