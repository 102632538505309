//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { AccountKYC } from '../../../models/prisma/accountKYC.model';
        import { GET_LIST, startedFilter } from './accountKYC.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import StateDetails from '../state/state.details';
import CountryDetails from '../country/country.details';
import DocumentStatusDetails from '../documentStatus/documentStatus.details';
import DocumentTypeDetails from '../documentType/documentType.details';
import AccountDetails from '../account/account.details';
import KycStatusDetails from '../kycStatus/kycStatus.details';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            accountKYCCms: AccountKYC
        }>`
        query AccountKYCCms($id: String)
        {
            accountKYCCms(id: $id)
          {
            id
                    name
                    document
                    companyDocument
                    step
                    bornAt
                    state {
                        id
                        description
                    }
                    stateId
                    nationality
                    phoneNumber
                    profession
                    postalcode
                    address
                    addressNumber
                    addressComplement
                    neighborhood
                    city
                    country {
                        id
                        description
                    }
                    countryId
                    residenceProofImgPrivFileUrl
                    residenceProofImgPrivFileId
                    residenceProofStatus {
                        id
                        description
                    }
                    residenceProofStatusId
                    residenceProofDate
                    documentFrontImgPrivFileUrl
                    documentFrontImgPrivFileId
                    documentBackImgPrivFileUrl
                    documentBackImgPrivFileId
                    incomeTaxImgPrivFileUrl
                    incomeTaxImgPrivFileId
                    documentDate
                    invalidReason
                    intentionalReason
                    termsAccepted
                    termsAcceptedDate
                    documentType {
                        id
                        description
                    }
                    documentTypeId
                    documentStatus {
                        id
                        description
                    }
                    documentStatusId
                    fullDocumentResume
                    account {
                        id
                        name
                    }
                    accountId
                    deleted
                    createdAt
                    updatedAt
                    status {
                        id
                        description
                    }
                    statusId
                    fullMotiveRefused
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<AccountKYC>`
        mutation AccountKYCSave(
            $id: String,
                        $name: String,
                        $document: String,
                        $companyDocument: String,
                        $step: Int,
                        $bornAt: String,
                        $state: StateCreateInputs,
                    $stateId: String,
                        $nationality: String,
                        $phoneNumber: String,
                        $profession: String,
                        $postalcode: String,
                        $address: String,
                        $addressNumber: String,
                        $addressComplement: String,
                        $neighborhood: String,
                        $city: String,
                        $country: CountryCreateInputs,
                    $countryId: String,
                        $residenceProofImgPrivFileUrl: String,
                        $residenceProofImgPrivFileId: String,
                        $residenceProofStatus: DocumentStatusCreateInputs,
                    $residenceProofStatusId: String,
                        $residenceProofDate: String,
                        $documentFrontImgPrivFileUrl: String,
                        $documentFrontImgPrivFileId: String,
                        $documentBackImgPrivFileUrl: String,
                        $documentBackImgPrivFileId: String,
                        $incomeTaxImgPrivFileUrl: String,
                        $incomeTaxImgPrivFileId: String,
                        $documentDate: String,
                        $invalidReason: String,
                        $intentionalReason: String,
                        $termsAccepted: Boolean,
                        $termsAcceptedDate: String,
                        $documentType: DocumentTypeCreateInputs,
                    $documentTypeId: String,
                        $documentStatus: DocumentStatusCreateInputs,
                    $documentStatusId: String,
                        $fullDocumentResume: String,
                        $account: AccountCreateInputs,
                    $accountId: String,
                        $status: KycStatusCreateInputs,
                    $statusId: String,
                        $fullMotiveRefused: String,
                        
        )
        {
            accountKYCCreateCms(data: {
                id: $id,
                    name: $name,
                    document: $document,
                    companyDocument: $companyDocument,
                    step: $step,
                    bornAt: $bornAt,
                    state: $state,
                    stateId: $stateId,
                    nationality: $nationality,
                    phoneNumber: $phoneNumber,
                    profession: $profession,
                    postalcode: $postalcode,
                    address: $address,
                    addressNumber: $addressNumber,
                    addressComplement: $addressComplement,
                    neighborhood: $neighborhood,
                    city: $city,
                    country: $country,
                    countryId: $countryId,
                    residenceProofImgPrivFileUrl: $residenceProofImgPrivFileUrl,
                    residenceProofImgPrivFileId: $residenceProofImgPrivFileId,
                    residenceProofStatus: $residenceProofStatus,
                    residenceProofStatusId: $residenceProofStatusId,
                    residenceProofDate: $residenceProofDate,
                    documentFrontImgPrivFileUrl: $documentFrontImgPrivFileUrl,
                    documentFrontImgPrivFileId: $documentFrontImgPrivFileId,
                    documentBackImgPrivFileUrl: $documentBackImgPrivFileUrl,
                    documentBackImgPrivFileId: $documentBackImgPrivFileId,
                    incomeTaxImgPrivFileUrl: $incomeTaxImgPrivFileUrl,
                    incomeTaxImgPrivFileId: $incomeTaxImgPrivFileId,
                    documentDate: $documentDate,
                    invalidReason: $invalidReason,
                    intentionalReason: $intentionalReason,
                    termsAccepted: $termsAccepted,
                    termsAcceptedDate: $termsAcceptedDate,
                    documentType: $documentType,
                    documentTypeId: $documentTypeId,
                    documentStatus: $documentStatus,
                    documentStatusId: $documentStatusId,
                    fullDocumentResume: $fullDocumentResume,
                    account: $account,
                    accountId: $accountId,
                    status: $status,
                    statusId: $statusId,
                    fullMotiveRefused: $fullMotiveRefused,
                    
            }) {
                id
                    name
                    document
                    companyDocument
                    step
                    bornAt
                    state {
                        id
                        description
                    }
                    stateId
                    nationality
                    phoneNumber
                    profession
                    postalcode
                    address
                    addressNumber
                    addressComplement
                    neighborhood
                    city
                    country {
                        id
                        description
                    }
                    countryId
                    residenceProofImgPrivFileUrl
                    residenceProofImgPrivFileId
                    residenceProofStatus {
                        id
                        description
                    }
                    residenceProofStatusId
                    residenceProofDate
                    documentFrontImgPrivFileUrl
                    documentFrontImgPrivFileId
                    documentBackImgPrivFileUrl
                    documentBackImgPrivFileId
                    incomeTaxImgPrivFileUrl
                    incomeTaxImgPrivFileId
                    documentDate
                    invalidReason
                    intentionalReason
                    termsAccepted
                    termsAcceptedDate
                    documentType {
                        id
                        description
                    }
                    documentTypeId
                    documentStatus {
                        id
                        description
                    }
                    documentStatusId
                    fullDocumentResume
                    account {
                        id
                        name
                    }
                    accountId
                    deleted
                    createdAt
                    updatedAt
                    status {
                        id
                        description
                    }
                    statusId
                    fullMotiveRefused
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<AccountKYC>`
        mutation AccountKYCSave(
            $id: String,
                        $name: String,
                        $document: String,
                        $companyDocument: String,
                        $step: Int,
                        $bornAt: String,
                        $state: StateCreateInputs,
                    $stateId: String,
                        $nationality: String,
                        $phoneNumber: String,
                        $profession: String,
                        $postalcode: String,
                        $address: String,
                        $addressNumber: String,
                        $addressComplement: String,
                        $neighborhood: String,
                        $city: String,
                        $country: CountryCreateInputs,
                    $countryId: String,
                        $residenceProofImgPrivFileUrl: String,
                        $residenceProofImgPrivFileId: String,
                        $residenceProofStatus: DocumentStatusCreateInputs,
                    $residenceProofStatusId: String,
                        $residenceProofDate: String,
                        $documentFrontImgPrivFileUrl: String,
                        $documentFrontImgPrivFileId: String,
                        $documentBackImgPrivFileUrl: String,
                        $documentBackImgPrivFileId: String,
                        $incomeTaxImgPrivFileUrl: String,
                        $incomeTaxImgPrivFileId: String,
                        $documentDate: String,
                        $invalidReason: String,
                        $intentionalReason: String,
                        $termsAccepted: Boolean,
                        $termsAcceptedDate: String,
                        $documentType: DocumentTypeCreateInputs,
                    $documentTypeId: String,
                        $documentStatus: DocumentStatusCreateInputs,
                    $documentStatusId: String,
                        $fullDocumentResume: String,
                        $account: AccountCreateInputs,
                    $accountId: String,
                        $status: KycStatusCreateInputs,
                    $statusId: String,
                        $fullMotiveRefused: String,
                        
        )
        {
            accountKYCUpdateCms(data: {
                id: $id,
                    name: $name,
                    document: $document,
                    companyDocument: $companyDocument,
                    step: $step,
                    bornAt: $bornAt,
                    state: $state,
                    stateId: $stateId,
                    nationality: $nationality,
                    phoneNumber: $phoneNumber,
                    profession: $profession,
                    postalcode: $postalcode,
                    address: $address,
                    addressNumber: $addressNumber,
                    addressComplement: $addressComplement,
                    neighborhood: $neighborhood,
                    city: $city,
                    country: $country,
                    countryId: $countryId,
                    residenceProofImgPrivFileUrl: $residenceProofImgPrivFileUrl,
                    residenceProofImgPrivFileId: $residenceProofImgPrivFileId,
                    residenceProofStatus: $residenceProofStatus,
                    residenceProofStatusId: $residenceProofStatusId,
                    residenceProofDate: $residenceProofDate,
                    documentFrontImgPrivFileUrl: $documentFrontImgPrivFileUrl,
                    documentFrontImgPrivFileId: $documentFrontImgPrivFileId,
                    documentBackImgPrivFileUrl: $documentBackImgPrivFileUrl,
                    documentBackImgPrivFileId: $documentBackImgPrivFileId,
                    incomeTaxImgPrivFileUrl: $incomeTaxImgPrivFileUrl,
                    incomeTaxImgPrivFileId: $incomeTaxImgPrivFileId,
                    documentDate: $documentDate,
                    invalidReason: $invalidReason,
                    intentionalReason: $intentionalReason,
                    termsAccepted: $termsAccepted,
                    termsAcceptedDate: $termsAcceptedDate,
                    documentType: $documentType,
                    documentTypeId: $documentTypeId,
                    documentStatus: $documentStatus,
                    documentStatusId: $documentStatusId,
                    fullDocumentResume: $fullDocumentResume,
                    account: $account,
                    accountId: $accountId,
                    status: $status,
                    statusId: $statusId,
                    fullMotiveRefused: $fullMotiveRefused,
                    
            }) {
                id
                    name
                    document
                    companyDocument
                    step
                    bornAt
                    state {
                        id
                        description
                    }
                    stateId
                    nationality
                    phoneNumber
                    profession
                    postalcode
                    address
                    addressNumber
                    addressComplement
                    neighborhood
                    city
                    country {
                        id
                        description
                    }
                    countryId
                    residenceProofImgPrivFileUrl
                    residenceProofImgPrivFileId
                    residenceProofStatus {
                        id
                        description
                    }
                    residenceProofStatusId
                    residenceProofDate
                    documentFrontImgPrivFileUrl
                    documentFrontImgPrivFileId
                    documentBackImgPrivFileUrl
                    documentBackImgPrivFileId
                    incomeTaxImgPrivFileUrl
                    incomeTaxImgPrivFileId
                    documentDate
                    invalidReason
                    intentionalReason
                    termsAccepted
                    termsAcceptedDate
                    documentType {
                        id
                        description
                    }
                    documentTypeId
                    documentStatus {
                        id
                        description
                    }
                    documentStatusId
                    fullDocumentResume
                    account {
                        id
                        name
                    }
                    accountId
                    deleted
                    createdAt
                    updatedAt
                    status {
                        id
                        description
                    }
                    statusId
                    fullMotiveRefused
                    
            }
        }
        `;

        
                    const STATE_QUERY = `
                    query stateListCms($search: Search) {
                        stateListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const COUNTRY_QUERY = `
                    query countryListCms($search: Search) {
                        countryListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const DOCUMENTSTATUS_QUERY = `
                    query documentStatusListCms($search: Search) {
                        documentStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const DOCUMENTTYPE_QUERY = `
                    query documentTypeListCms($search: Search) {
                        documentTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const ACCOUNT_QUERY = `
                    query accountListCms($search: Search) {
                        accountListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const KYCSTATUS_QUERY = `
                    query kycStatusListCms($search: Search) {
                        kycStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const AccountKYCDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ accountKYCCms: AccountKYC }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.accountKYCCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("AccountKYC has been saved");
                    const model = entity?.data?.accountKYCUpdateCms || entity?.data?.accountKYCCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/accountKYC-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('AccountKYC')} breadcrumbItems={[
                                { title: t("AccountKYC"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('AccountKYC')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('AccountKYC')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Name")} name="name"
                            rules={[{ required: false, message: 'Please input the ' + t("Name") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Document")} name="document"
                            rules={[{ required: false, message: 'Please input the ' + t("Document") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("CompanyDocument")} name="companyDocument"
                            rules={[{ required: false, message: 'Please input the ' + t("CompanyDocument") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Step")} name="step"
                            rules={[{ required: true, message: 'Please input the ' + t("Step") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("BornAt")} name="bornAt"
                            rules={[{ required: false, message: 'Please input the ' + t("BornAt") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.bornAt ? moment(databaseEntity?.bornAt, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['bornAt'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            { (!props.relation || props.relation.field != "stateId") && <Form.Item label={<> {t('State')}</>}>
                                <Form.Item noStyle id="state_id" name="stateId"
                                rules={[{ required: (false && !(dataSources.dependencies?.state?.description || databaseEntity?.state?.description)), message: 'Please input the State!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.state ? dataSources.dependencies?.state?.description : databaseEntity?.state?.description}
                                value={ dataSources.dependencies?.state ? dataSources.dependencies?.state?.description : databaseEntity?.state?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.stateId = newValue.key;
                                            dataSources.dependencies["state"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["state"]) {
                                                delete dataSources.dependencies["state"].__typename;
                                            }                                            
                                            setSelects({ ...selects, stateId: newValue.key });
                                            setUncheck({ ...uncheck, stateId: false });
                                        }
                                        else if (databaseEntity?.state) {
                                            dataSources.dependencies.stateId = undefined;
                                            dataSources.dependencies["state"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["state"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.stateId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "State", query: STATE_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "State", query: STATE_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["State"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, StatestateIdEditVisible: !modals.StatestateIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["StatestateIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, StatestateIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        State
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StateDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.state?.id || databaseEntity?.state?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, StatestateIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    stateId: entity?.id,
                                                    state: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, StateAddVisible: !modals.StateAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["StateAddVisible"]}
                                    onHide={() => setModals({ ...modals, StateAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            State
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StateDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, StateAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        stateId: entity?.id,
                                                        state: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.state?.id || databaseEntity?.state?.id) && !uncheck['stateId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, stateId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            stateId: "",
                                            state: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Nationality")} name="nationality"
                            rules={[{ required: false, message: 'Please input the ' + t("Nationality") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("PhoneNumber")} name="phoneNumber"
                            rules={[{ required: false, message: 'Please input the ' + t("PhoneNumber") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Profession")} name="profession"
                            rules={[{ required: false, message: 'Please input the ' + t("Profession") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Postalcode")} name="postalcode"
                            rules={[{ required: false, message: 'Please input the ' + t("Postalcode") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Address")} name="address"
                            rules={[{ required: false, message: 'Please input the ' + t("Address") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("AddressNumber")} name="addressNumber"
                            rules={[{ required: false, message: 'Please input the ' + t("AddressNumber") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("AddressComplement")} name="addressComplement"
                            rules={[{ required: false, message: 'Please input the ' + t("AddressComplement") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Neighborhood")} name="neighborhood"
                            rules={[{ required: false, message: 'Please input the ' + t("Neighborhood") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("City")} name="city"
                            rules={[{ required: false, message: 'Please input the ' + t("City") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "countryId") && <Form.Item label={<> {t('Country')}</>}>
                                <Form.Item noStyle id="country_id" name="countryId"
                                rules={[{ required: (false && !(dataSources.dependencies?.country?.description || databaseEntity?.country?.description)), message: 'Please input the Country!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.country ? dataSources.dependencies?.country?.description : databaseEntity?.country?.description}
                                value={ dataSources.dependencies?.country ? dataSources.dependencies?.country?.description : databaseEntity?.country?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.countryId = newValue.key;
                                            dataSources.dependencies["country"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["country"]) {
                                                delete dataSources.dependencies["country"].__typename;
                                            }                                            
                                            setSelects({ ...selects, countryId: newValue.key });
                                            setUncheck({ ...uncheck, countryId: false });
                                        }
                                        else if (databaseEntity?.country) {
                                            dataSources.dependencies.countryId = undefined;
                                            dataSources.dependencies["country"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["country"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.countryId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Country", query: COUNTRY_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Country", query: COUNTRY_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["Country"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CountrycountryIdEditVisible: !modals.CountrycountryIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CountrycountryIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CountrycountryIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Country
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CountryDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.country?.id || databaseEntity?.country?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CountrycountryIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    countryId: entity?.id,
                                                    country: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CountryAddVisible: !modals.CountryAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CountryAddVisible"]}
                                    onHide={() => setModals({ ...modals, CountryAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Country
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CountryDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CountryAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        countryId: entity?.id,
                                                        country: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.country?.id || databaseEntity?.country?.id) && !uncheck['countryId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, countryId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            countryId: "",
                                            country: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("ResidenceProofImgPrivFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("residenceProofImgPrivFileId") + '!' }]}>
                    {!!databaseEntity?.residenceProofImgPrivFileId && !dropUpload['residenceProofImgPrivFileId'] && !files['residenceProofImgPrivFileId'] &&
                        <>
                            { !!download[databaseEntity?.residenceProofImgPrivFileId]  && <>
                        { !download[databaseEntity?.residenceProofImgPrivFileId + 'Error'] && <> 
                        
                            <a title="residenceProofImgPrivFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.residenceProofImgPrivFileId, "private") 
                            }}>
                            
                            <img alt="residenceProofImgPrivFileId" 
                            onError={() => { 
                                download[databaseEntity?.residenceProofImgPrivFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.residenceProofImgPrivFileId + 'Error']: true });
                            }} src={download[databaseEntity?.residenceProofImgPrivFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.residenceProofImgPrivFileId, "private") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, residenceProofImgPrivFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.residenceProofImgPrivFileId || dropUpload['residenceProofImgPrivFileId'] || files['residenceProofImgPrivFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, residenceProofImgPrivFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item>{ (!props.relation || props.relation.field != "residenceProofStatusId") && <Form.Item label={<> {t('ResidenceProofStatus')}</>}>
                                <Form.Item noStyle id="residenceProofStatus_id" name="residenceProofStatusId"
                                rules={[{ required: (false && !(dataSources.dependencies?.residenceProofStatus?.description || databaseEntity?.residenceProofStatus?.description)), message: 'Please input the DocumentStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.residenceProofStatus ? dataSources.dependencies?.residenceProofStatus?.description : databaseEntity?.residenceProofStatus?.description}
                                value={ dataSources.dependencies?.residenceProofStatus ? dataSources.dependencies?.residenceProofStatus?.description : databaseEntity?.residenceProofStatus?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.residenceProofStatusId = newValue.key;
                                            dataSources.dependencies["residenceProofStatus"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["residenceProofStatus"]) {
                                                delete dataSources.dependencies["residenceProofStatus"].__typename;
                                            }                                            
                                            setSelects({ ...selects, residenceProofStatusId: newValue.key });
                                            setUncheck({ ...uncheck, residenceProofStatusId: false });
                                        }
                                        else if (databaseEntity?.residenceProofStatus) {
                                            dataSources.dependencies.residenceProofStatusId = undefined;
                                            dataSources.dependencies["residenceProofStatus"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["residenceProofStatus"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.residenceProofStatusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "DocumentStatus", query: DOCUMENTSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "DocumentStatus", query: DOCUMENTSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["DocumentStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, DocumentStatusresidenceProofStatusIdEditVisible: !modals.DocumentStatusresidenceProofStatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["DocumentStatusresidenceProofStatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, DocumentStatusresidenceProofStatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        DocumentStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <DocumentStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.residenceProofStatus?.id || databaseEntity?.residenceProofStatus?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, DocumentStatusresidenceProofStatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    residenceProofStatusId: entity?.id,
                                                    residenceProofStatus: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, DocumentStatusAddVisible: !modals.DocumentStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["DocumentStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, DocumentStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            DocumentStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <DocumentStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, DocumentStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        residenceProofStatusId: entity?.id,
                                                        residenceProofStatus: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.residenceProofStatus?.id || databaseEntity?.residenceProofStatus?.id) && !uncheck['residenceProofStatusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, residenceProofStatusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            residenceProofStatusId: "",
                                            residenceProofStatus: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("ResidenceProofDate")} name="residenceProofDate"
                            rules={[{ required: false, message: 'Please input the ' + t("ResidenceProofDate") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.residenceProofDate ? moment(databaseEntity?.residenceProofDate, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['residenceProofDate'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("DocumentFrontImgPrivFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("documentFrontImgPrivFileId") + '!' }]}>
                    {!!databaseEntity?.documentFrontImgPrivFileId && !dropUpload['documentFrontImgPrivFileId'] && !files['documentFrontImgPrivFileId'] &&
                        <>
                            { !!download[databaseEntity?.documentFrontImgPrivFileId]  && <>
                        { !download[databaseEntity?.documentFrontImgPrivFileId + 'Error'] && <> 
                        
                            <a title="documentFrontImgPrivFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.documentFrontImgPrivFileId, "private") 
                            }}>
                            
                            <img alt="documentFrontImgPrivFileId" 
                            onError={() => { 
                                download[databaseEntity?.documentFrontImgPrivFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.documentFrontImgPrivFileId + 'Error']: true });
                            }} src={download[databaseEntity?.documentFrontImgPrivFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.documentFrontImgPrivFileId, "private") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, documentFrontImgPrivFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.documentFrontImgPrivFileId || dropUpload['documentFrontImgPrivFileId'] || files['documentFrontImgPrivFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, documentFrontImgPrivFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("DocumentBackImgPrivFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("documentBackImgPrivFileId") + '!' }]}>
                    {!!databaseEntity?.documentBackImgPrivFileId && !dropUpload['documentBackImgPrivFileId'] && !files['documentBackImgPrivFileId'] &&
                        <>
                            { !!download[databaseEntity?.documentBackImgPrivFileId]  && <>
                        { !download[databaseEntity?.documentBackImgPrivFileId + 'Error'] && <> 
                        
                            <a title="documentBackImgPrivFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.documentBackImgPrivFileId, "private") 
                            }}>
                            
                            <img alt="documentBackImgPrivFileId" 
                            onError={() => { 
                                download[databaseEntity?.documentBackImgPrivFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.documentBackImgPrivFileId + 'Error']: true });
                            }} src={download[databaseEntity?.documentBackImgPrivFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.documentBackImgPrivFileId, "private") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, documentBackImgPrivFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.documentBackImgPrivFileId || dropUpload['documentBackImgPrivFileId'] || files['documentBackImgPrivFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, documentBackImgPrivFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("IncomeTaxImgPrivFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("incomeTaxImgPrivFileId") + '!' }]}>
                    {!!databaseEntity?.incomeTaxImgPrivFileId && !dropUpload['incomeTaxImgPrivFileId'] && !files['incomeTaxImgPrivFileId'] &&
                        <>
                            { !!download[databaseEntity?.incomeTaxImgPrivFileId]  && <>
                        { !download[databaseEntity?.incomeTaxImgPrivFileId + 'Error'] && <> 
                        
                            <a title="incomeTaxImgPrivFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.incomeTaxImgPrivFileId, "private") 
                            }}>
                            
                            <img alt="incomeTaxImgPrivFileId" 
                            onError={() => { 
                                download[databaseEntity?.incomeTaxImgPrivFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.incomeTaxImgPrivFileId + 'Error']: true });
                            }} src={download[databaseEntity?.incomeTaxImgPrivFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.incomeTaxImgPrivFileId, "private") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, incomeTaxImgPrivFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.incomeTaxImgPrivFileId || dropUpload['incomeTaxImgPrivFileId'] || files['incomeTaxImgPrivFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, incomeTaxImgPrivFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("DocumentDate")} name="documentDate"
                            rules={[{ required: false, message: 'Please input the ' + t("DocumentDate") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.documentDate ? moment(databaseEntity?.documentDate, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['documentDate'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("InvalidReason")} name="invalidReason"
                            rules={[{ required: false, message: 'Please input the ' + t("InvalidReason") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("IntentionalReason")} name="intentionalReason"
                            rules={[{ required: false, message: 'Please input the ' + t("IntentionalReason") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("TermsAccepted")} name="termsAccepted" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("TermsAcceptedDate")} name="termsAcceptedDate"
                            rules={[{ required: false, message: 'Please input the ' + t("TermsAcceptedDate") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.termsAcceptedDate ? moment(databaseEntity?.termsAcceptedDate, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['termsAcceptedDate'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            { (!props.relation || props.relation.field != "documentTypeId") && <Form.Item label={<> {t('DocumentType')}</>}>
                                <Form.Item noStyle id="documentType_id" name="documentTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.documentType?.description || databaseEntity?.documentType?.description)), message: 'Please input the DocumentType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.documentType ? dataSources.dependencies?.documentType?.description : databaseEntity?.documentType?.description}
                                value={ dataSources.dependencies?.documentType ? dataSources.dependencies?.documentType?.description : databaseEntity?.documentType?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.documentTypeId = newValue.key;
                                            dataSources.dependencies["documentType"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["documentType"]) {
                                                delete dataSources.dependencies["documentType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, documentTypeId: newValue.key });
                                            setUncheck({ ...uncheck, documentTypeId: false });
                                        }
                                        else if (databaseEntity?.documentType) {
                                            dataSources.dependencies.documentTypeId = undefined;
                                            dataSources.dependencies["documentType"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["documentType"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.documentTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "DocumentType", query: DOCUMENTTYPE_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "DocumentType", query: DOCUMENTTYPE_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["DocumentType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, DocumentTypedocumentTypeIdEditVisible: !modals.DocumentTypedocumentTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["DocumentTypedocumentTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, DocumentTypedocumentTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        DocumentType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <DocumentTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.documentType?.id || databaseEntity?.documentType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, DocumentTypedocumentTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    documentTypeId: entity?.id,
                                                    documentType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, DocumentTypeAddVisible: !modals.DocumentTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["DocumentTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, DocumentTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            DocumentType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <DocumentTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, DocumentTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        documentTypeId: entity?.id,
                                                        documentType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.documentType?.id || databaseEntity?.documentType?.id) && !uncheck['documentTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, documentTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            documentTypeId: "",
                                            documentType: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "documentStatusId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('DocumentStatus')}</>}>
                                <Form.Item noStyle id="documentStatus_id" name="documentStatusId"
                                rules={[{ required: (true && !(dataSources.dependencies?.documentStatus?.description || databaseEntity?.documentStatus?.description)), message: 'Please input the DocumentStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.documentStatus ? dataSources.dependencies?.documentStatus?.description : databaseEntity?.documentStatus?.description}
                                value={ dataSources.dependencies?.documentStatus ? dataSources.dependencies?.documentStatus?.description : databaseEntity?.documentStatus?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.documentStatusId = newValue.key;
                                            dataSources.dependencies["documentStatus"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["documentStatus"]) {
                                                delete dataSources.dependencies["documentStatus"].__typename;
                                            }                                            
                                            setSelects({ ...selects, documentStatusId: newValue.key });
                                            setUncheck({ ...uncheck, documentStatusId: false });
                                        }
                                        else if (databaseEntity?.documentStatus) {
                                            dataSources.dependencies.documentStatusId = undefined;
                                            dataSources.dependencies["documentStatus"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["documentStatus"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.documentStatusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "DocumentStatus", query: DOCUMENTSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "DocumentStatus", query: DOCUMENTSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["DocumentStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, DocumentStatusdocumentStatusIdEditVisible: !modals.DocumentStatusdocumentStatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["DocumentStatusdocumentStatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, DocumentStatusdocumentStatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        DocumentStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <DocumentStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.documentStatus?.id || databaseEntity?.documentStatus?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, DocumentStatusdocumentStatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    documentStatusId: entity?.id,
                                                    documentStatus: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, DocumentStatusAddVisible: !modals.DocumentStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["DocumentStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, DocumentStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            DocumentStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <DocumentStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, DocumentStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        documentStatusId: entity?.id,
                                                        documentStatus: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.documentStatus?.id || databaseEntity?.documentStatus?.id) && !uncheck['documentStatusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, documentStatusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            documentStatusId: "",
                                            documentStatus: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("FullDocumentResume")}
                    rules={[{ required: false, message: 'Please input the ' + t("fullDocumentResume") + '!' }]}>
                    <ReactQuill modules={{
                      toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                      ],
                      clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                      }
                  }} theme="snow" value={descriptions['fullDocumentResume'] || databaseEntity?.fullDocumentResume ||''} onChange={(value) => {
                      setDescriptions({ ...descriptions, ['fullDocumentResume']: value });
                  }} />
                </Form.Item>{ (!props.relation || props.relation.field != "accountId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Account')}</>}>
                                <Form.Item noStyle id="account_id" name="accountId"
                                rules={[{ required: (true && !(dataSources.dependencies?.account?.name || databaseEntity?.account?.name)), message: 'Please input the Account!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.account ? dataSources.dependencies?.account?.name : databaseEntity?.account?.name}
                                value={ dataSources.dependencies?.account ? dataSources.dependencies?.account?.name : databaseEntity?.account?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.accountId = newValue.key;
                                            dataSources.dependencies["account"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["account"]) {
                                                delete dataSources.dependencies["account"].__typename;
                                            }                                            
                                            setSelects({ ...selects, accountId: newValue.key });
                                            setUncheck({ ...uncheck, accountId: false });
                                        }
                                        else if (databaseEntity?.account) {
                                            dataSources.dependencies.accountId = undefined;
                                            dataSources.dependencies["account"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["account"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.accountId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Account", query: ACCOUNT_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Account", query: ACCOUNT_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["Account"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, AccountaccountIdEditVisible: !modals.AccountaccountIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["AccountaccountIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, AccountaccountIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Account
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AccountDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.account?.id || databaseEntity?.account?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, AccountaccountIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    accountId: entity?.id,
                                                    account: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, AccountAddVisible: !modals.AccountAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["AccountAddVisible"]}
                                    onHide={() => setModals({ ...modals, AccountAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Account
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AccountDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, AccountAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        accountId: entity?.id,
                                                        account: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.account?.id || databaseEntity?.account?.id) && !uncheck['accountId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, accountId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            accountId: "",
                                            account: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "statusId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Status')}</>}>
                                <Form.Item noStyle id="status_id" name="statusId"
                                rules={[{ required: (true && !(dataSources.dependencies?.status?.description || databaseEntity?.status?.description)), message: 'Please input the KycStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                value={ dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.statusId = newValue.key;
                                            dataSources.dependencies["status"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["status"]) {
                                                delete dataSources.dependencies["status"].__typename;
                                            }                                            
                                            setSelects({ ...selects, statusId: newValue.key });
                                            setUncheck({ ...uncheck, statusId: false });
                                        }
                                        else if (databaseEntity?.status) {
                                            dataSources.dependencies.statusId = undefined;
                                            dataSources.dependencies["status"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["status"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.statusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "KycStatus", query: KYCSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "KycStatus", query: KYCSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["KycStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, KycStatusstatusIdEditVisible: !modals.KycStatusstatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["KycStatusstatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, KycStatusstatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        KycStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <KycStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.status?.id || databaseEntity?.status?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, KycStatusstatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    statusId: entity?.id,
                                                    status: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, KycStatusAddVisible: !modals.KycStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["KycStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, KycStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            KycStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <KycStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, KycStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        statusId: entity?.id,
                                                        status: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.status?.id || databaseEntity?.status?.id) && !uncheck['statusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, statusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            statusId: "",
                                            status: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("FullMotiveRefused")}
                    rules={[{ required: false, message: 'Please input the ' + t("fullMotiveRefused") + '!' }]}>
                    <ReactQuill modules={{
                      toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                      ],
                      clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                      }
                  }} theme="snow" value={descriptions['fullMotiveRefused'] || databaseEntity?.fullMotiveRefused ||''} onChange={(value) => {
                      setDescriptions({ ...descriptions, ['fullMotiveRefused']: value });
                  }} />
                </Form.Item>
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (AccountKYCDetails);
  