//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { Offer } from '../../../models/prisma/offer.model';
        import { GET_LIST, startedFilter } from './offer.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import OfferStageTypeDetails from '../offerStageType/offerStageType.details';
import OfferModalityDetails from '../offerModality/offerModality.details';
import BlockchainTokenDetails from '../blockchainToken/blockchainToken.details';
import CompanyDetails from '../company/company.details';
import AccountDetails from '../account/account.details';
import WalletDetails from '../wallet/wallet.details';
import OfferStatusDetails from '../offerStatus/offerStatus.details';
import ApprovalManagerOfferMapList from '../approvalManagerOfferMap/approvalManagerOfferMap.list';
import InvestorDepositList from '../investorDeposit/investorDeposit.list';
import CompanyDepositList from '../companyDeposit/companyDeposit.list';
import WalletAssetList from '../walletAsset/walletAsset.list';
import OfferDeployVarsMapList from '../offerDeployVarsMap/offerDeployVarsMap.list';
import OfferContractInfoList from '../offerContractInfo/offerContractInfo.list';
import OfferNotificationList from '../offerNotification/offerNotification.list';
import OfferAreaMapList from '../offerAreaMap/offerAreaMap.list';
import OfferInfoList from '../offerInfo/offerInfo.list';
import OfferStageList from '../offerStage/offerStage.list';
import OfferImageList from '../offerImage/offerImage.list';
import OfferFileList from '../offerFile/offerFile.list';
import OfferRuleMapList from '../offerRuleMap/offerRuleMap.list';
import OfferConstMapList from '../offerConstMap/offerConstMap.list';
import BlockchainTransactionList from '../blockchainTransaction/blockchainTransaction.list';
import BankAccountList from '../bankAccount/bankAccount.list';
import BankTransactionList from '../bankTransaction/bankTransaction.list';
import PartnerOfferMapList from '../partnerOfferMap/partnerOfferMap.list';
import StatementList from '../statement/statement.list';
import OfferCompaniesList from '../offerCompanies/offerCompanies.list';
import OfferTermsMapList from '../offerTermsMap/offerTermsMap.list';
import OfferInvestList from '../offerInvest/offerInvest.list';
import OfferSucessTypeDetails from '../offerSucessType/offerSucessType.details';
import DocumentSignerContractList from '../documentSignerContract/documentSignerContract.list';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            offerCms: Offer
        }>`
        query OfferCms($id: String)
        {
            offerCms(id: $id)
          {
            id
                    title
                    slug
                    promotion
                    order
                    isPublic
                    isHidden
                    isBlockedBeforeDate
                    hasCountdown
                    hasKyc
                    hasCvm
                    secondaryMarketUrl
                    fullDescription
                    startDate
                    closeDate
                    stageType {
                        id
                        description
                    }
                    stageTypeId
                    estimatedProfitability
                    minTarget
                    maxTarget
                    actualTarget
                    minTicket
                    maxTicket
                    investorReturn
                    trancheFinancing
                    unitTokenPrice
                    tokensAmount
                    modalityId
                    modality {
                        id
                        description
                    }
                    token {
                        id
                        name
                    }
                    tokenId
                    currencyToken {
                        id
                        name
                    }
                    currencyTokenId
                    address
                    company {
                        id
                        companyName
                    }
                    companyId
                    account {
                        id
                        name
                    }
                    accountId
                    wallet {
                        id
                        address
                    }
                    walletId
                    abi
                    logoImgPubFileUrl
                    logoImgPubFileId
                    imgPubFileUrl
                    imgPubFileId
                    whitePaperPubFileUrl
                    whitePaperPubFileId
                    isDirectSale
                    deleted
                    createdAt
                    updatedAt
                    status {
                        id
                        description
                    }
                    statusId
                    countInvestor
                    error
                    preReleaseDate
                    lastStatus
                    stepContract
                    stepRelease
                    transactionHashDeploy
                    successFee
                    sucessType {
                        id
                        description
                    }
                    sucessTypeId
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<Offer>`
        mutation OfferSave(
            $id: String,
                        $title: String,
                        $slug: String,
                        $promotion: Boolean,
                        $order: Int,
                        $isPublic: Boolean,
                        $isHidden: Boolean,
                        $isBlockedBeforeDate: Boolean,
                        $hasCountdown: Boolean,
                        $hasKyc: Boolean,
                        $hasCvm: Boolean,
                        $secondaryMarketUrl: String,
                        $fullDescription: String,
                        $startDate: String,
                        $closeDate: String,
                        $stageType: OfferStageTypeCreateInputs,
                    $stageTypeId: String,
                        $estimatedProfitability: Float,
                        $minTarget: Float,
                        $maxTarget: Float,
                        $actualTarget: Float,
                        $minTicket: Float,
                        $maxTicket: Float,
                        $investorReturn: String,
                        $trancheFinancing: Float,
                        $unitTokenPrice: Float,
                        $tokensAmount: Float,
                        $modalityId: String,
                        $modality: OfferModalityCreateInputs,
                    $token: BlockchainTokenCreateInputs,
                    $tokenId: String,
                        $currencyToken: BlockchainTokenCreateInputs,
                    $currencyTokenId: String,
                        $address: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $account: AccountCreateInputs,
                    $accountId: String,
                        $wallet: WalletCreateInputs,
                    $walletId: String,
                        $abi: String,
                        $logoImgPubFileUrl: String,
                        $logoImgPubFileId: String,
                        $imgPubFileUrl: String,
                        $imgPubFileId: String,
                        $whitePaperPubFileUrl: String,
                        $whitePaperPubFileId: String,
                        $isDirectSale: Boolean,
                        $status: OfferStatusCreateInputs,
                    $statusId: String,
                        $countInvestor: Int,
                        $error: String,
                        $preReleaseDate: String,
                        $lastStatus: String,
                        $stepContract: Int,
                        $stepRelease: Int,
                        $transactionHashDeploy: String,
                        $successFee: Float,
                        $sucessType: OfferSucessTypeCreateInputs,
                    $sucessTypeId: String,
                        
        )
        {
            offerCreateCms(data: {
                id: $id,
                    title: $title,
                    slug: $slug,
                    promotion: $promotion,
                    order: $order,
                    isPublic: $isPublic,
                    isHidden: $isHidden,
                    isBlockedBeforeDate: $isBlockedBeforeDate,
                    hasCountdown: $hasCountdown,
                    hasKyc: $hasKyc,
                    hasCvm: $hasCvm,
                    secondaryMarketUrl: $secondaryMarketUrl,
                    fullDescription: $fullDescription,
                    startDate: $startDate,
                    closeDate: $closeDate,
                    stageType: $stageType,
                    stageTypeId: $stageTypeId,
                    estimatedProfitability: $estimatedProfitability,
                    minTarget: $minTarget,
                    maxTarget: $maxTarget,
                    actualTarget: $actualTarget,
                    minTicket: $minTicket,
                    maxTicket: $maxTicket,
                    investorReturn: $investorReturn,
                    trancheFinancing: $trancheFinancing,
                    unitTokenPrice: $unitTokenPrice,
                    tokensAmount: $tokensAmount,
                    modalityId: $modalityId,
                    modality: $modality,
                    token: $token,
                    tokenId: $tokenId,
                    currencyToken: $currencyToken,
                    currencyTokenId: $currencyTokenId,
                    address: $address,
                    company: $company,
                    companyId: $companyId,
                    account: $account,
                    accountId: $accountId,
                    wallet: $wallet,
                    walletId: $walletId,
                    abi: $abi,
                    logoImgPubFileUrl: $logoImgPubFileUrl,
                    logoImgPubFileId: $logoImgPubFileId,
                    imgPubFileUrl: $imgPubFileUrl,
                    imgPubFileId: $imgPubFileId,
                    whitePaperPubFileUrl: $whitePaperPubFileUrl,
                    whitePaperPubFileId: $whitePaperPubFileId,
                    isDirectSale: $isDirectSale,
                    status: $status,
                    statusId: $statusId,
                    countInvestor: $countInvestor,
                    error: $error,
                    preReleaseDate: $preReleaseDate,
                    lastStatus: $lastStatus,
                    stepContract: $stepContract,
                    stepRelease: $stepRelease,
                    transactionHashDeploy: $transactionHashDeploy,
                    successFee: $successFee,
                    sucessType: $sucessType,
                    sucessTypeId: $sucessTypeId,
                    
            }) {
                id
                    title
                    slug
                    promotion
                    order
                    isPublic
                    isHidden
                    isBlockedBeforeDate
                    hasCountdown
                    hasKyc
                    hasCvm
                    secondaryMarketUrl
                    fullDescription
                    startDate
                    closeDate
                    stageType {
                        id
                        description
                    }
                    stageTypeId
                    estimatedProfitability
                    minTarget
                    maxTarget
                    actualTarget
                    minTicket
                    maxTicket
                    investorReturn
                    trancheFinancing
                    unitTokenPrice
                    tokensAmount
                    modalityId
                    modality {
                        id
                        description
                    }
                    token {
                        id
                        name
                    }
                    tokenId
                    currencyToken {
                        id
                        name
                    }
                    currencyTokenId
                    address
                    company {
                        id
                        companyName
                    }
                    companyId
                    account {
                        id
                        name
                    }
                    accountId
                    wallet {
                        id
                        address
                    }
                    walletId
                    abi
                    logoImgPubFileUrl
                    logoImgPubFileId
                    imgPubFileUrl
                    imgPubFileId
                    whitePaperPubFileUrl
                    whitePaperPubFileId
                    isDirectSale
                    deleted
                    createdAt
                    updatedAt
                    status {
                        id
                        description
                    }
                    statusId
                    countInvestor
                    error
                    preReleaseDate
                    lastStatus
                    stepContract
                    stepRelease
                    transactionHashDeploy
                    successFee
                    sucessType {
                        id
                        description
                    }
                    sucessTypeId
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<Offer>`
        mutation OfferSave(
            $id: String,
                        $title: String,
                        $slug: String,
                        $promotion: Boolean,
                        $order: Int,
                        $isPublic: Boolean,
                        $isHidden: Boolean,
                        $isBlockedBeforeDate: Boolean,
                        $hasCountdown: Boolean,
                        $hasKyc: Boolean,
                        $hasCvm: Boolean,
                        $secondaryMarketUrl: String,
                        $fullDescription: String,
                        $startDate: String,
                        $closeDate: String,
                        $stageType: OfferStageTypeCreateInputs,
                    $stageTypeId: String,
                        $estimatedProfitability: Float,
                        $minTarget: Float,
                        $maxTarget: Float,
                        $actualTarget: Float,
                        $minTicket: Float,
                        $maxTicket: Float,
                        $investorReturn: String,
                        $trancheFinancing: Float,
                        $unitTokenPrice: Float,
                        $tokensAmount: Float,
                        $modalityId: String,
                        $modality: OfferModalityCreateInputs,
                    $token: BlockchainTokenCreateInputs,
                    $tokenId: String,
                        $currencyToken: BlockchainTokenCreateInputs,
                    $currencyTokenId: String,
                        $address: String,
                        $company: CompanyCreateInputs,
                    $companyId: String,
                        $account: AccountCreateInputs,
                    $accountId: String,
                        $wallet: WalletCreateInputs,
                    $walletId: String,
                        $abi: String,
                        $logoImgPubFileUrl: String,
                        $logoImgPubFileId: String,
                        $imgPubFileUrl: String,
                        $imgPubFileId: String,
                        $whitePaperPubFileUrl: String,
                        $whitePaperPubFileId: String,
                        $isDirectSale: Boolean,
                        $status: OfferStatusCreateInputs,
                    $statusId: String,
                        $countInvestor: Int,
                        $error: String,
                        $preReleaseDate: String,
                        $lastStatus: String,
                        $stepContract: Int,
                        $stepRelease: Int,
                        $transactionHashDeploy: String,
                        $successFee: Float,
                        $sucessType: OfferSucessTypeCreateInputs,
                    $sucessTypeId: String,
                        
        )
        {
            offerUpdateCms(data: {
                id: $id,
                    title: $title,
                    slug: $slug,
                    promotion: $promotion,
                    order: $order,
                    isPublic: $isPublic,
                    isHidden: $isHidden,
                    isBlockedBeforeDate: $isBlockedBeforeDate,
                    hasCountdown: $hasCountdown,
                    hasKyc: $hasKyc,
                    hasCvm: $hasCvm,
                    secondaryMarketUrl: $secondaryMarketUrl,
                    fullDescription: $fullDescription,
                    startDate: $startDate,
                    closeDate: $closeDate,
                    stageType: $stageType,
                    stageTypeId: $stageTypeId,
                    estimatedProfitability: $estimatedProfitability,
                    minTarget: $minTarget,
                    maxTarget: $maxTarget,
                    actualTarget: $actualTarget,
                    minTicket: $minTicket,
                    maxTicket: $maxTicket,
                    investorReturn: $investorReturn,
                    trancheFinancing: $trancheFinancing,
                    unitTokenPrice: $unitTokenPrice,
                    tokensAmount: $tokensAmount,
                    modalityId: $modalityId,
                    modality: $modality,
                    token: $token,
                    tokenId: $tokenId,
                    currencyToken: $currencyToken,
                    currencyTokenId: $currencyTokenId,
                    address: $address,
                    company: $company,
                    companyId: $companyId,
                    account: $account,
                    accountId: $accountId,
                    wallet: $wallet,
                    walletId: $walletId,
                    abi: $abi,
                    logoImgPubFileUrl: $logoImgPubFileUrl,
                    logoImgPubFileId: $logoImgPubFileId,
                    imgPubFileUrl: $imgPubFileUrl,
                    imgPubFileId: $imgPubFileId,
                    whitePaperPubFileUrl: $whitePaperPubFileUrl,
                    whitePaperPubFileId: $whitePaperPubFileId,
                    isDirectSale: $isDirectSale,
                    status: $status,
                    statusId: $statusId,
                    countInvestor: $countInvestor,
                    error: $error,
                    preReleaseDate: $preReleaseDate,
                    lastStatus: $lastStatus,
                    stepContract: $stepContract,
                    stepRelease: $stepRelease,
                    transactionHashDeploy: $transactionHashDeploy,
                    successFee: $successFee,
                    sucessType: $sucessType,
                    sucessTypeId: $sucessTypeId,
                    
            }) {
                id
                    title
                    slug
                    promotion
                    order
                    isPublic
                    isHidden
                    isBlockedBeforeDate
                    hasCountdown
                    hasKyc
                    hasCvm
                    secondaryMarketUrl
                    fullDescription
                    startDate
                    closeDate
                    stageType {
                        id
                        description
                    }
                    stageTypeId
                    estimatedProfitability
                    minTarget
                    maxTarget
                    actualTarget
                    minTicket
                    maxTicket
                    investorReturn
                    trancheFinancing
                    unitTokenPrice
                    tokensAmount
                    modalityId
                    modality {
                        id
                        description
                    }
                    token {
                        id
                        name
                    }
                    tokenId
                    currencyToken {
                        id
                        name
                    }
                    currencyTokenId
                    address
                    company {
                        id
                        companyName
                    }
                    companyId
                    account {
                        id
                        name
                    }
                    accountId
                    wallet {
                        id
                        address
                    }
                    walletId
                    abi
                    logoImgPubFileUrl
                    logoImgPubFileId
                    imgPubFileUrl
                    imgPubFileId
                    whitePaperPubFileUrl
                    whitePaperPubFileId
                    isDirectSale
                    deleted
                    createdAt
                    updatedAt
                    status {
                        id
                        description
                    }
                    statusId
                    countInvestor
                    error
                    preReleaseDate
                    lastStatus
                    stepContract
                    stepRelease
                    transactionHashDeploy
                    successFee
                    sucessType {
                        id
                        description
                    }
                    sucessTypeId
                    
            }
        }
        `;

        
                    const OFFERSTAGETYPE_QUERY = `
                    query offerStageTypeListCms($search: Search) {
                        offerStageTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const OFFERMODALITY_QUERY = `
                    query offerModalityListCms($search: Search) {
                        offerModalityListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTOKEN_QUERY = `
                    query blockchainTokenListCms($search: Search) {
                        blockchainTokenListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const COMPANY_QUERY = `
                    query companyListCms($search: Search) {
                        companyListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyName
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const ACCOUNT_QUERY = `
                    query accountListCms($search: Search) {
                        accountListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const WALLET_QUERY = `
                    query walletListCms($search: Search) {
                        walletListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    address
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const OFFERSTATUS_QUERY = `
                    query offerStatusListCms($search: Search) {
                        offerStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const OFFERSUCESSTYPE_QUERY = `
                    query offerSucessTypeListCms($search: Search) {
                        offerSucessTypeListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const OfferDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ offerCms: Offer }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.offerCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("Offer has been saved");
                    const model = entity?.data?.offerUpdateCms || entity?.data?.offerCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/offer-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('Offer')} breadcrumbItems={[
                                { title: t("Offer"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('Offer')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('Offer')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Title")} name="title"
                            rules={[{ required: true, message: 'Please input the ' + t("Title") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Slug")} name="slug"
                            rules={[{ required: true, message: 'Please input the ' + t("Slug") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Promotion")} name="promotion" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("Order")} name="order"
                            rules={[{ required: true, message: 'Please input the ' + t("Order") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("IsPublic")} name="isPublic" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("IsHidden")} name="isHidden" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("IsBlockedBeforeDate")} name="isBlockedBeforeDate" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("HasCountdown")} name="hasCountdown" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("HasKyc")} name="hasKyc" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("HasCvm")} name="hasCvm" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("SecondaryMarketUrl")} name="secondaryMarketUrl"
                            rules={[{ required: false, message: 'Please input the ' + t("SecondaryMarketUrl") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("FullDescription")}
                    rules={[{ required: false, message: 'Please input the ' + t("fullDescription") + '!' }]}>
                    <ReactQuill modules={{
                      toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                      ],
                      clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                      }
                  }} theme="snow" value={descriptions['fullDescription'] || databaseEntity?.fullDescription ||''} onChange={(value) => {
                      setDescriptions({ ...descriptions, ['fullDescription']: value });
                  }} />
                </Form.Item><Form.Item label={t("StartDate")} name="startDate"
                            rules={[{ required: false, message: 'Please input the ' + t("StartDate") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.startDate ? moment(databaseEntity?.startDate, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['startDate'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("CloseDate")} name="closeDate"
                            rules={[{ required: false, message: 'Please input the ' + t("CloseDate") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.closeDate ? moment(databaseEntity?.closeDate, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['closeDate'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            { (!props.relation || props.relation.field != "stageTypeId") && <Form.Item label={<> {t('StageType')}</>}>
                                <Form.Item noStyle id="stageType_id" name="stageTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.stageType?.description || databaseEntity?.stageType?.description)), message: 'Please input the OfferStageType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.stageType ? dataSources.dependencies?.stageType?.description : databaseEntity?.stageType?.description}
                                value={ dataSources.dependencies?.stageType ? dataSources.dependencies?.stageType?.description : databaseEntity?.stageType?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.stageTypeId = newValue.key;
                                            dataSources.dependencies["stageType"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["stageType"]) {
                                                delete dataSources.dependencies["stageType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, stageTypeId: newValue.key });
                                            setUncheck({ ...uncheck, stageTypeId: false });
                                        }
                                        else if (databaseEntity?.stageType) {
                                            dataSources.dependencies.stageTypeId = undefined;
                                            dataSources.dependencies["stageType"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["stageType"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.stageTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "OfferStageType", query: OFFERSTAGETYPE_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "OfferStageType", query: OFFERSTAGETYPE_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["OfferStageType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OfferStageTypestageTypeIdEditVisible: !modals.OfferStageTypestageTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferStageTypestageTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OfferStageTypestageTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        OfferStageType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferStageTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.stageType?.id || databaseEntity?.stageType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OfferStageTypestageTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    stageTypeId: entity?.id,
                                                    stageType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OfferStageTypeAddVisible: !modals.OfferStageTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferStageTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, OfferStageTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            OfferStageType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferStageTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OfferStageTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        stageTypeId: entity?.id,
                                                        stageType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.stageType?.id || databaseEntity?.stageType?.id) && !uncheck['stageTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, stageTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            stageTypeId: "",
                                            stageType: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("EstimatedProfitability")} name="estimatedProfitability"
                            rules={[{ required: false, message: 'Please input the ' + t("EstimatedProfitability") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("MinTarget")} name="minTarget"
                            rules={[{ required: false, message: 'Please input the ' + t("MinTarget") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("MaxTarget")} name="maxTarget"
                            rules={[{ required: false, message: 'Please input the ' + t("MaxTarget") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("ActualTarget")} name="actualTarget"
                            rules={[{ required: false, message: 'Please input the ' + t("ActualTarget") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("MinTicket")} name="minTicket"
                            rules={[{ required: false, message: 'Please input the ' + t("MinTicket") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("MaxTicket")} name="maxTicket"
                            rules={[{ required: false, message: 'Please input the ' + t("MaxTicket") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("InvestorReturn")} name="investorReturn"
                            rules={[{ required: false, message: 'Please input the ' + t("InvestorReturn") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("TrancheFinancing")} name="trancheFinancing"
                            rules={[{ required: false, message: 'Please input the ' + t("TrancheFinancing") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("UnitTokenPrice")} name="unitTokenPrice"
                            rules={[{ required: false, message: 'Please input the ' + t("UnitTokenPrice") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("TokensAmount")} name="tokensAmount"
                            rules={[{ required: false, message: 'Please input the ' + t("TokensAmount") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "modalityId") && <Form.Item label={<> {t('Modality')}</>}>
                                <Form.Item noStyle id="modality_id" name="modalityId"
                                rules={[{ required: (false && !(dataSources.dependencies?.modality?.description || databaseEntity?.modality?.description)), message: 'Please input the OfferModality!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.modality ? dataSources.dependencies?.modality?.description : databaseEntity?.modality?.description}
                                value={ dataSources.dependencies?.modality ? dataSources.dependencies?.modality?.description : databaseEntity?.modality?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.modalityId = newValue.key;
                                            dataSources.dependencies["modality"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["modality"]) {
                                                delete dataSources.dependencies["modality"].__typename;
                                            }                                            
                                            setSelects({ ...selects, modalityId: newValue.key });
                                            setUncheck({ ...uncheck, modalityId: false });
                                        }
                                        else if (databaseEntity?.modality) {
                                            dataSources.dependencies.modalityId = undefined;
                                            dataSources.dependencies["modality"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["modality"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.modalityId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "OfferModality", query: OFFERMODALITY_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "OfferModality", query: OFFERMODALITY_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["OfferModality"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OfferModalitymodalityIdEditVisible: !modals.OfferModalitymodalityIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferModalitymodalityIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OfferModalitymodalityIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        OfferModality
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferModalityDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.modality?.id || databaseEntity?.modality?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OfferModalitymodalityIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    modalityId: entity?.id,
                                                    modality: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OfferModalityAddVisible: !modals.OfferModalityAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferModalityAddVisible"]}
                                    onHide={() => setModals({ ...modals, OfferModalityAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            OfferModality
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferModalityDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OfferModalityAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        modalityId: entity?.id,
                                                        modality: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.modality?.id || databaseEntity?.modality?.id) && !uncheck['modalityId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, modalityId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            modalityId: "",
                                            modality: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "tokenId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Token')}</>}>
                                <Form.Item noStyle id="token_id" name="tokenId"
                                rules={[{ required: (true && !(dataSources.dependencies?.token?.name || databaseEntity?.token?.name)), message: 'Please input the BlockchainToken!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.token ? dataSources.dependencies?.token?.name : databaseEntity?.token?.name}
                                value={ dataSources.dependencies?.token ? dataSources.dependencies?.token?.name : databaseEntity?.token?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.tokenId = newValue.key;
                                            dataSources.dependencies["token"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["token"]) {
                                                delete dataSources.dependencies["token"].__typename;
                                            }                                            
                                            setSelects({ ...selects, tokenId: newValue.key });
                                            setUncheck({ ...uncheck, tokenId: false });
                                        }
                                        else if (databaseEntity?.token) {
                                            dataSources.dependencies.tokenId = undefined;
                                            dataSources.dependencies["token"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["token"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.tokenId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["BlockchainToken"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTokentokenIdEditVisible: !modals.BlockchainTokentokenIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokentokenIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokentokenIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.token?.id || databaseEntity?.token?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTokentokenIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    tokenId: entity?.id,
                                                    token: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenAddVisible: !modals.BlockchainTokenAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTokenAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        tokenId: entity?.id,
                                                        token: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.token?.id || databaseEntity?.token?.id) && !uncheck['tokenId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, tokenId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            tokenId: "",
                                            token: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "currencyTokenId") && <Form.Item label={<> {t('CurrencyToken')}</>}>
                                <Form.Item noStyle id="currencyToken_id" name="currencyTokenId"
                                rules={[{ required: (false && !(dataSources.dependencies?.currencyToken?.name || databaseEntity?.currencyToken?.name)), message: 'Please input the BlockchainToken!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.currencyToken ? dataSources.dependencies?.currencyToken?.name : databaseEntity?.currencyToken?.name}
                                value={ dataSources.dependencies?.currencyToken ? dataSources.dependencies?.currencyToken?.name : databaseEntity?.currencyToken?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.currencyTokenId = newValue.key;
                                            dataSources.dependencies["currencyToken"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["currencyToken"]) {
                                                delete dataSources.dependencies["currencyToken"].__typename;
                                            }                                            
                                            setSelects({ ...selects, currencyTokenId: newValue.key });
                                            setUncheck({ ...uncheck, currencyTokenId: false });
                                        }
                                        else if (databaseEntity?.currencyToken) {
                                            dataSources.dependencies.currencyTokenId = undefined;
                                            dataSources.dependencies["currencyToken"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["currencyToken"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.currencyTokenId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["BlockchainToken"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTokencurrencyTokenIdEditVisible: !modals.BlockchainTokencurrencyTokenIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokencurrencyTokenIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokencurrencyTokenIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.currencyToken?.id || databaseEntity?.currencyToken?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTokencurrencyTokenIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    currencyTokenId: entity?.id,
                                                    currencyToken: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenAddVisible: !modals.BlockchainTokenAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTokenAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        currencyTokenId: entity?.id,
                                                        currencyToken: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.currencyToken?.id || databaseEntity?.currencyToken?.id) && !uncheck['currencyTokenId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, currencyTokenId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            currencyTokenId: "",
                                            currencyToken: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Address")} name="address"
                            rules={[{ required: false, message: 'Please input the ' + t("Address") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "companyId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Company')}</>}>
                                <Form.Item noStyle id="company_id" name="companyId"
                                rules={[{ required: (true && !(dataSources.dependencies?.company?.companyName || databaseEntity?.company?.companyName)), message: 'Please input the Company!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                value={ dataSources.dependencies?.company ? dataSources.dependencies?.company?.companyName : databaseEntity?.company?.companyName}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.companyId = newValue.key;
                                            dataSources.dependencies["company"] = {
                                                id: newValue.key,
                                                companyName: newValue.value
                                            };
                                            if(dataSources.dependencies["company"]) {
                                                delete dataSources.dependencies["company"].__typename;
                                            }                                            
                                            setSelects({ ...selects, companyId: newValue.key });
                                            setUncheck({ ...uncheck, companyId: false });
                                        }
                                        else if (databaseEntity?.company) {
                                            dataSources.dependencies.companyId = undefined;
                                            dataSources.dependencies["company"] = {
                                                id: undefined,
                                                companyName: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["company"] = {
                                            id: undefined,
                                            companyName: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.companyId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Company", query: COMPANY_QUERY, idField: "id", textField: "companyName" });
                                    }}>
                                    {(dataSources["Company"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyName}>
                                            {item.companyName}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, CompanycompanyIdEditVisible: !modals.CompanycompanyIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanycompanyIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, CompanycompanyIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.company?.id || databaseEntity?.company?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, CompanycompanyIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    companyId: entity?.id,
                                                    company: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, CompanyAddVisible: !modals.CompanyAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["CompanyAddVisible"]}
                                    onHide={() => setModals({ ...modals, CompanyAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Company
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <CompanyDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, CompanyAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        companyId: entity?.id,
                                                        company: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.company?.id || databaseEntity?.company?.id) && !uncheck['companyId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, companyId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            companyId: "",
                                            company: {
                                                companyName: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "accountId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Account')}</>}>
                                <Form.Item noStyle id="account_id" name="accountId"
                                rules={[{ required: (true && !(dataSources.dependencies?.account?.name || databaseEntity?.account?.name)), message: 'Please input the Account!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.account ? dataSources.dependencies?.account?.name : databaseEntity?.account?.name}
                                value={ dataSources.dependencies?.account ? dataSources.dependencies?.account?.name : databaseEntity?.account?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.accountId = newValue.key;
                                            dataSources.dependencies["account"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["account"]) {
                                                delete dataSources.dependencies["account"].__typename;
                                            }                                            
                                            setSelects({ ...selects, accountId: newValue.key });
                                            setUncheck({ ...uncheck, accountId: false });
                                        }
                                        else if (databaseEntity?.account) {
                                            dataSources.dependencies.accountId = undefined;
                                            dataSources.dependencies["account"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["account"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.accountId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Account", query: ACCOUNT_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Account", query: ACCOUNT_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["Account"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, AccountaccountIdEditVisible: !modals.AccountaccountIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["AccountaccountIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, AccountaccountIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Account
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AccountDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.account?.id || databaseEntity?.account?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, AccountaccountIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    accountId: entity?.id,
                                                    account: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, AccountAddVisible: !modals.AccountAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["AccountAddVisible"]}
                                    onHide={() => setModals({ ...modals, AccountAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Account
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AccountDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, AccountAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        accountId: entity?.id,
                                                        account: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.account?.id || databaseEntity?.account?.id) && !uncheck['accountId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, accountId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            accountId: "",
                                            account: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "walletId") && <Form.Item label={<> {t('Wallet')}</>}>
                                <Form.Item noStyle id="wallet_id" name="walletId"
                                rules={[{ required: (false && !(dataSources.dependencies?.wallet?.address || databaseEntity?.wallet?.address)), message: 'Please input the Wallet!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                value={ dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.walletId = newValue.key;
                                            dataSources.dependencies["wallet"] = {
                                                id: newValue.key,
                                                address: newValue.value
                                            };
                                            if(dataSources.dependencies["wallet"]) {
                                                delete dataSources.dependencies["wallet"].__typename;
                                            }                                            
                                            setSelects({ ...selects, walletId: newValue.key });
                                            setUncheck({ ...uncheck, walletId: false });
                                        }
                                        else if (databaseEntity?.wallet) {
                                            dataSources.dependencies.walletId = undefined;
                                            dataSources.dependencies["wallet"] = {
                                                id: undefined,
                                                address: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["wallet"] = {
                                            id: undefined,
                                            address: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.walletId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Wallet", query: WALLET_QUERY, idField: "id", textField: "address" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Wallet", query: WALLET_QUERY, idField: "id", textField: "address" });
                                    }}>
                                    {(dataSources["Wallet"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.address}>
                                            {item.address}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, WalletwalletIdEditVisible: !modals.WalletwalletIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["WalletwalletIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, WalletwalletIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Wallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <WalletDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, WalletwalletIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    walletId: entity?.id,
                                                    wallet: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, WalletAddVisible: !modals.WalletAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["WalletAddVisible"]}
                                    onHide={() => setModals({ ...modals, WalletAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Wallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <WalletDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, WalletAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        walletId: entity?.id,
                                                        wallet: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id) && !uncheck['walletId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, walletId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            walletId: "",
                                            wallet: {
                                                address: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Abi")} name="abi"
                            rules={[{ required: false, message: 'Please input the ' + t("Abi") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("LogoImgPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("logoImgPubFileId") + '!' }]}>
                    {!!databaseEntity?.logoImgPubFileId && !dropUpload['logoImgPubFileId'] && !files['logoImgPubFileId'] &&
                        <>
                            { !!download[databaseEntity?.logoImgPubFileId]  && <>
                        { !download[databaseEntity?.logoImgPubFileId + 'Error'] && <> 
                        
                            <a title="logoImgPubFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.logoImgPubFileId, "public") 
                            }}>
                            
                            <img alt="logoImgPubFileId" 
                            onError={() => { 
                                download[databaseEntity?.logoImgPubFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.logoImgPubFileId + 'Error']: true });
                            }} src={download[databaseEntity?.logoImgPubFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.logoImgPubFileId, "public") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, logoImgPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.logoImgPubFileId || dropUpload['logoImgPubFileId'] || files['logoImgPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, logoImgPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("ImgPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("imgPubFileId") + '!' }]}>
                    {!!databaseEntity?.imgPubFileId && !dropUpload['imgPubFileId'] && !files['imgPubFileId'] &&
                        <>
                            { !!download[databaseEntity?.imgPubFileId]  && <>
                        { !download[databaseEntity?.imgPubFileId + 'Error'] && <> 
                        
                            <a title="imgPubFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.imgPubFileId, "public") 
                            }}>
                            
                            <img alt="imgPubFileId" 
                            onError={() => { 
                                download[databaseEntity?.imgPubFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.imgPubFileId + 'Error']: true });
                            }} src={download[databaseEntity?.imgPubFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.imgPubFileId, "public") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, imgPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.imgPubFileId || dropUpload['imgPubFileId'] || files['imgPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, imgPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("WhitePaperPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("whitePaperPubFileId") + '!' }]}>
                    {!!databaseEntity?.whitePaperPubFileId && !dropUpload['whitePaperPubFileId'] && !files['whitePaperPubFileId'] &&
                        <>
                            <a title="whitePaperPubFileId" target="_blank"  onClick={() => { StorageService.forceDownload(databaseEntity?.whitePaperPubFileId, "public") }}>{databaseEntity?.whitePaperPubFileId}</a>
                            
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, whitePaperPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.whitePaperPubFileId || dropUpload['whitePaperPubFileId'] || files['whitePaperPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, whitePaperPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("IsDirectSale")} name="isDirectSale" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "statusId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Status')}</>}>
                                <Form.Item noStyle id="status_id" name="statusId"
                                rules={[{ required: (true && !(dataSources.dependencies?.status?.description || databaseEntity?.status?.description)), message: 'Please input the OfferStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                value={ dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.statusId = newValue.key;
                                            dataSources.dependencies["status"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["status"]) {
                                                delete dataSources.dependencies["status"].__typename;
                                            }                                            
                                            setSelects({ ...selects, statusId: newValue.key });
                                            setUncheck({ ...uncheck, statusId: false });
                                        }
                                        else if (databaseEntity?.status) {
                                            dataSources.dependencies.statusId = undefined;
                                            dataSources.dependencies["status"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["status"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.statusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "OfferStatus", query: OFFERSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "OfferStatus", query: OFFERSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["OfferStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OfferStatusstatusIdEditVisible: !modals.OfferStatusstatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferStatusstatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OfferStatusstatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        OfferStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.status?.id || databaseEntity?.status?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OfferStatusstatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    statusId: entity?.id,
                                                    status: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OfferStatusAddVisible: !modals.OfferStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, OfferStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            OfferStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OfferStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        statusId: entity?.id,
                                                        status: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.status?.id || databaseEntity?.status?.id) && !uncheck['statusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, statusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            statusId: "",
                                            status: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("CountInvestor")} name="countInvestor"
                            rules={[{ required: false, message: 'Please input the ' + t("CountInvestor") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Error")} name="error"
                            rules={[{ required: false, message: 'Please input the ' + t("Error") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("PreReleaseDate")} name="preReleaseDate"
                            rules={[{ required: false, message: 'Please input the ' + t("PreReleaseDate") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.preReleaseDate ? moment(databaseEntity?.preReleaseDate, 'YYYY-MM-DD') : undefined} 
                                onChange={(date, dateString) => {
                                    console.log("date (moment):", date);
                                    console.log("dateString:", dateString);
                                    if (date === null) {

                                        dataSources.noDate = dataSources.noDate || {};
                                        dataSources.noDate['preReleaseDate'] = 'no_data';
                                    setDataSources(dataSources);
                                    console.log(" dataSources " ,  dataSources.noDate)
                                    }
                                
                                }}/>
                            }
                        </Form.Item>
                            <Form.Item label={t("LastStatus")} name="lastStatus"
                            rules={[{ required: false, message: 'Please input the ' + t("LastStatus") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("StepContract")} name="stepContract"
                            rules={[{ required: false, message: 'Please input the ' + t("StepContract") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("StepRelease")} name="stepRelease"
                            rules={[{ required: false, message: 'Please input the ' + t("StepRelease") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("TransactionHashDeploy")} name="transactionHashDeploy"
                            rules={[{ required: false, message: 'Please input the ' + t("TransactionHashDeploy") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("SuccessFee")} name="successFee"
                            rules={[{ required: false, message: 'Please input the ' + t("SuccessFee") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "sucessTypeId") && <Form.Item label={<> {t('SucessType')}</>}>
                                <Form.Item noStyle id="sucessType_id" name="sucessTypeId"
                                rules={[{ required: (false && !(dataSources.dependencies?.sucessType?.description || databaseEntity?.sucessType?.description)), message: 'Please input the OfferSucessType!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.sucessType ? dataSources.dependencies?.sucessType?.description : databaseEntity?.sucessType?.description}
                                value={ dataSources.dependencies?.sucessType ? dataSources.dependencies?.sucessType?.description : databaseEntity?.sucessType?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.sucessTypeId = newValue.key;
                                            dataSources.dependencies["sucessType"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["sucessType"]) {
                                                delete dataSources.dependencies["sucessType"].__typename;
                                            }                                            
                                            setSelects({ ...selects, sucessTypeId: newValue.key });
                                            setUncheck({ ...uncheck, sucessTypeId: false });
                                        }
                                        else if (databaseEntity?.sucessType) {
                                            dataSources.dependencies.sucessTypeId = undefined;
                                            dataSources.dependencies["sucessType"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["sucessType"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.sucessTypeId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "OfferSucessType", query: OFFERSUCESSTYPE_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "OfferSucessType", query: OFFERSUCESSTYPE_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["OfferSucessType"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OfferSucessTypesucessTypeIdEditVisible: !modals.OfferSucessTypesucessTypeIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferSucessTypesucessTypeIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OfferSucessTypesucessTypeIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        OfferSucessType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferSucessTypeDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.sucessType?.id || databaseEntity?.sucessType?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OfferSucessTypesucessTypeIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    sucessTypeId: entity?.id,
                                                    sucessType: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OfferSucessTypeAddVisible: !modals.OfferSucessTypeAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferSucessTypeAddVisible"]}
                                    onHide={() => setModals({ ...modals, OfferSucessTypeAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            OfferSucessType
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferSucessTypeDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OfferSucessTypeAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        sucessTypeId: entity?.id,
                                                        sucessType: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.sucessType?.id || databaseEntity?.sucessType?.id) && !uncheck['sucessTypeId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, sucessTypeId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            sucessTypeId: "",
                                            sucessType: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`ApprovalManagerOfferMap`)}>
                            <ApprovalManagerOfferMapList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></ApprovalManagerOfferMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={2} tab={t(`InvestorDeposit`)}>
                            <InvestorDepositList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></InvestorDepositList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={3} tab={t(`CompanyDeposit`)}>
                            <CompanyDepositList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></CompanyDepositList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={4} tab={t(`WalletAsset`)}>
                            <WalletAssetList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></WalletAssetList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={5} tab={t(`OfferDeployVarsMap`)}>
                            <OfferDeployVarsMapList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferDeployVarsMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={6} tab={t(`OfferContractInfo`)}>
                            <OfferContractInfoList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferContractInfoList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={7} tab={t(`OfferNotification`)}>
                            <OfferNotificationList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferNotificationList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={8} tab={t(`OfferAreaMap`)}>
                            <OfferAreaMapList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferAreaMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={9} tab={t(`OfferInfo`)}>
                            <OfferInfoList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferInfoList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={10} tab={t(`OfferStage`)}>
                            <OfferStageList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferStageList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={11} tab={t(`OfferImage`)}>
                            <OfferImageList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferImageList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={12} tab={t(`OfferFile`)}>
                            <OfferFileList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferFileList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={13} tab={t(`OfferRuleMap`)}>
                            <OfferRuleMapList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferRuleMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={14} tab={t(`OfferConstMap`)}>
                            <OfferConstMapList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferConstMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={15} tab={t(`BlockchainTransaction`)}>
                            <BlockchainTransactionList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></BlockchainTransactionList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={16} tab={t(`BankAccount`)}>
                            <BankAccountList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></BankAccountList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={17} tab={t(`BankTransaction`)}>
                            <BankTransactionList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></BankTransactionList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={18} tab={t(`PartnerOfferMap`)}>
                            <PartnerOfferMapList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></PartnerOfferMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={19} tab={t(`Statement`)}>
                            <StatementList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></StatementList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={20} tab={t(`OfferCompanies`)}>
                            <OfferCompaniesList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferCompaniesList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={21} tab={t(`OfferTermsMap`)}>
                            <OfferTermsMapList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferTermsMapList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={22} tab={t(`OfferInvest`)}>
                            <OfferInvestList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OfferInvestList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={23} tab={t(`DocumentSignerContract`)}>
                            <DocumentSignerContractList relation={{model: "offer", field: "offerId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></DocumentSignerContractList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (OfferDetails);
  