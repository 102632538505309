import axios from 'axios';
const debug = process.env.REACT_APP_LOCAL_URL;
const sandbox = "https://tokenize-api-sandbox.liqi.com.br";
const production = "https://tokenize-api.liqi.com.br";

let fixedUrl = window.location.hostname.indexOf('sandbox') > -1 ? sandbox : production;
const remote = process.env.REACT_APP_URL || fixedUrl;
let urlBase = window.location.hostname.indexOf('localhost') > -1 ? debug : remote;

const api = axios.create({
  baseURL: urlBase,
});

api.interceptors.request.use(function (config) {
  const token = localStorage?.getItem('@Storage:token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export { api, urlBase };
