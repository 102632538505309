//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { Blockchain } from '../../../models/prisma/blockchain.model';
        import { GET_LIST, startedFilter } from './blockchain.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import PublicFileDetails from '../publicFile/publicFile.details';
import AlgorithmWalletDetails from '../algorithmWallet/algorithmWallet.details';
import ApprovalManagerList from '../approvalManager/approvalManager.list';
import WalletList from '../wallet/wallet.list';
import BlockchainTokenList from '../blockchainToken/blockchainToken.list';
import BlockchainTaxList from '../blockchainTax/blockchainTax.list';
import ApiKeyWalletList from '../apiKeyWallet/apiKeyWallet.list';
import BlockchainInfoList from '../blockchainInfo/blockchainInfo.list';
import CustodyNetworkList from '../custodyNetwork/custodyNetwork.list';
import OracleAddressList from '../oracleAddress/oracleAddress.list';
import CustodyWalletList from '../custodyWallet/custodyWallet.list';
import AirdropCampaignList from '../airdropCampaign/airdropCampaign.list';
import CustodyCurrenciesList from '../custodyCurrencies/custodyCurrencies.list';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            blockchainCms: Blockchain
        }>`
        query BlockchainCms($id: String)
        {
            blockchainCms(id: $id)
          {
            id
                    title
                    description
                    provider
                    infoProvider
                    symbol
                    isMain
                    isTestnet
                    isEvm
                    isCustody
                    isSmartContract
                    subnet
                    fullDescription
                    iconImgPubFileUrl
                    iconImgPubFileId
                    iconImgPubFile {
                        id
                        url
                    }
                    deleted
                    createdAt
                    updatedAt
                    addressUrl
                    tokenamountUrl
                    contractUrl
                    transactionUrl
                    tokenUrl
                    chainId
                    algorithmWallet {
                        id
                        title
                    }
                    algorithmWalletId
                    isEnable
                    networkId
                    networkMonitor
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<Blockchain>`
        mutation BlockchainSave(
            $id: String,
                        $title: String,
                        $description: String,
                        $provider: String,
                        $infoProvider: String,
                        $symbol: String,
                        $isMain: Boolean,
                        $isTestnet: Boolean,
                        $isEvm: Boolean,
                        $isCustody: Boolean,
                        $isSmartContract: Boolean,
                        $subnet: String,
                        $fullDescription: String,
                        $iconImgPubFileUrl: String,
                        $iconImgPubFileId: String,
                        $iconImgPubFile: PublicFileCreateInputs,
                    $addressUrl: String,
                        $tokenamountUrl: String,
                        $contractUrl: String,
                        $transactionUrl: String,
                        $tokenUrl: String,
                        $chainId: String,
                        $algorithmWallet: AlgorithmWalletCreateInputs,
                    $algorithmWalletId: String,
                        $isEnable: Boolean,
                        $networkId: Int,
                        $networkMonitor: String,
                        
        )
        {
            blockchainCreateCms(data: {
                id: $id,
                    title: $title,
                    description: $description,
                    provider: $provider,
                    infoProvider: $infoProvider,
                    symbol: $symbol,
                    isMain: $isMain,
                    isTestnet: $isTestnet,
                    isEvm: $isEvm,
                    isCustody: $isCustody,
                    isSmartContract: $isSmartContract,
                    subnet: $subnet,
                    fullDescription: $fullDescription,
                    iconImgPubFileUrl: $iconImgPubFileUrl,
                    iconImgPubFileId: $iconImgPubFileId,
                    iconImgPubFile: $iconImgPubFile,
                    addressUrl: $addressUrl,
                    tokenamountUrl: $tokenamountUrl,
                    contractUrl: $contractUrl,
                    transactionUrl: $transactionUrl,
                    tokenUrl: $tokenUrl,
                    chainId: $chainId,
                    algorithmWallet: $algorithmWallet,
                    algorithmWalletId: $algorithmWalletId,
                    isEnable: $isEnable,
                    networkId: $networkId,
                    networkMonitor: $networkMonitor,
                    
            }) {
                id
                    title
                    description
                    provider
                    infoProvider
                    symbol
                    isMain
                    isTestnet
                    isEvm
                    isCustody
                    isSmartContract
                    subnet
                    fullDescription
                    iconImgPubFileUrl
                    iconImgPubFileId
                    iconImgPubFile {
                        id
                        url
                    }
                    deleted
                    createdAt
                    updatedAt
                    addressUrl
                    tokenamountUrl
                    contractUrl
                    transactionUrl
                    tokenUrl
                    chainId
                    algorithmWallet {
                        id
                        title
                    }
                    algorithmWalletId
                    isEnable
                    networkId
                    networkMonitor
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<Blockchain>`
        mutation BlockchainSave(
            $id: String,
                        $title: String,
                        $description: String,
                        $provider: String,
                        $infoProvider: String,
                        $symbol: String,
                        $isMain: Boolean,
                        $isTestnet: Boolean,
                        $isEvm: Boolean,
                        $isCustody: Boolean,
                        $isSmartContract: Boolean,
                        $subnet: String,
                        $fullDescription: String,
                        $iconImgPubFileUrl: String,
                        $iconImgPubFileId: String,
                        $iconImgPubFile: PublicFileCreateInputs,
                    $addressUrl: String,
                        $tokenamountUrl: String,
                        $contractUrl: String,
                        $transactionUrl: String,
                        $tokenUrl: String,
                        $chainId: String,
                        $algorithmWallet: AlgorithmWalletCreateInputs,
                    $algorithmWalletId: String,
                        $isEnable: Boolean,
                        $networkId: Int,
                        $networkMonitor: String,
                        
        )
        {
            blockchainUpdateCms(data: {
                id: $id,
                    title: $title,
                    description: $description,
                    provider: $provider,
                    infoProvider: $infoProvider,
                    symbol: $symbol,
                    isMain: $isMain,
                    isTestnet: $isTestnet,
                    isEvm: $isEvm,
                    isCustody: $isCustody,
                    isSmartContract: $isSmartContract,
                    subnet: $subnet,
                    fullDescription: $fullDescription,
                    iconImgPubFileUrl: $iconImgPubFileUrl,
                    iconImgPubFileId: $iconImgPubFileId,
                    iconImgPubFile: $iconImgPubFile,
                    addressUrl: $addressUrl,
                    tokenamountUrl: $tokenamountUrl,
                    contractUrl: $contractUrl,
                    transactionUrl: $transactionUrl,
                    tokenUrl: $tokenUrl,
                    chainId: $chainId,
                    algorithmWallet: $algorithmWallet,
                    algorithmWalletId: $algorithmWalletId,
                    isEnable: $isEnable,
                    networkId: $networkId,
                    networkMonitor: $networkMonitor,
                    
            }) {
                id
                    title
                    description
                    provider
                    infoProvider
                    symbol
                    isMain
                    isTestnet
                    isEvm
                    isCustody
                    isSmartContract
                    subnet
                    fullDescription
                    iconImgPubFileUrl
                    iconImgPubFileId
                    iconImgPubFile {
                        id
                        url
                    }
                    deleted
                    createdAt
                    updatedAt
                    addressUrl
                    tokenamountUrl
                    contractUrl
                    transactionUrl
                    tokenUrl
                    chainId
                    algorithmWallet {
                        id
                        title
                    }
                    algorithmWalletId
                    isEnable
                    networkId
                    networkMonitor
                    
            }
        }
        `;

        
                    const PUBLICFILE_QUERY = `
                    query publicFileListCms($search: Search) {
                        publicFileListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    url
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const ALGORITHMWALLET_QUERY = `
                    query algorithmWalletListCms($search: Search) {
                        algorithmWalletListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const BlockchainDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ blockchainCms: Blockchain }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.blockchainCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("Blockchain has been saved");
                    const model = entity?.data?.blockchainUpdateCms || entity?.data?.blockchainCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/blockchain-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('Blockchain')} breadcrumbItems={[
                                { title: t("Blockchain"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('Blockchain')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('Blockchain')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Title")} name="title"
                            rules={[{ required: false, message: 'Please input the ' + t("Title") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Description")} name="description"
                            rules={[{ required: true, message: 'Please input the ' + t("Description") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Provider")} name="provider"
                            rules={[{ required: true, message: 'Please input the ' + t("Provider") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("InfoProvider")} name="infoProvider"
                            rules={[{ required: true, message: 'Please input the ' + t("InfoProvider") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("Symbol")} name="symbol"
                            rules={[{ required: true, message: 'Please input the ' + t("Symbol") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("IsMain")} name="isMain" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("IsTestnet")} name="isTestnet" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("IsEvm")} name="isEvm" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("IsCustody")} name="isCustody" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("IsSmartContract")} name="isSmartContract" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("Subnet")} name="subnet"
                            rules={[{ required: false, message: 'Please input the ' + t("Subnet") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("FullDescription")}
                    rules={[{ required: false, message: 'Please input the ' + t("fullDescription") + '!' }]}>
                    <ReactQuill modules={{
                      toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link', 'image', 'video'],
                          ['clean']
                      ],
                      clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                      }
                  }} theme="snow" value={descriptions['fullDescription'] || databaseEntity?.fullDescription ||''} onChange={(value) => {
                      setDescriptions({ ...descriptions, ['fullDescription']: value });
                  }} />
                </Form.Item><Form.Item label={t("IconImgPubFileId")}
                    rules={[{ required: false, message: 'Please input the ' + t("iconImgPubFileId") + '!' }]}>
                    {!!databaseEntity?.iconImgPubFileId && !dropUpload['iconImgPubFileId'] && !files['iconImgPubFileId'] &&
                        <>
                            { !!download[databaseEntity?.iconImgPubFileId]  && <>
                        { !download[databaseEntity?.iconImgPubFileId + 'Error'] && <> 
                        
                            <a title="iconImgPubFileId" onClick={() => { 
                                StorageService.forceDownload(databaseEntity?.iconImgPubFileId, "public") 
                            }}>
                            
                            <img alt="iconImgPubFileId" 
                            onError={() => { 
                                download[databaseEntity?.iconImgPubFileId + 'Error'] = true;
                                setDownload({...download, [databaseEntity?.iconImgPubFileId + 'Error']: true });
                            }} src={download[databaseEntity?.iconImgPubFileId]} /> 
                            <br/> 
                            </a>    
                        </> }                   
                        &nbsp; <a onClick={() => { StorageService.forceDownload(databaseEntity?.iconImgPubFileId, "public") }}>Baixar</a></>  }
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setDropUpload({ ...dropUpload, iconImgPubFileId: true });

                            }} className="mr-3 text-primary" id="edit12">
                                <TiDelete style={{ height: 20, width: 20 }} />
                            </a>
                        </>
                    }
                    {(!databaseEntity?.iconImgPubFileId || dropUpload['iconImgPubFileId'] || files['iconImgPubFileId']) && <Input type="file" onChange={(e) => {
                        let file = e?.target?.files?.length ? e?.target?.files[0] : null;
                        if (file) {
                            setFiles({ ...files, iconImgPubFileId: file });
                            setDropUpload({});
                        }
                    }} />}
                </Form.Item><Form.Item label={t("AddressUrl")} name="addressUrl"
                            rules={[{ required: false, message: 'Please input the ' + t("AddressUrl") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("TokenamountUrl")} name="tokenamountUrl"
                            rules={[{ required: false, message: 'Please input the ' + t("TokenamountUrl") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("ContractUrl")} name="contractUrl"
                            rules={[{ required: false, message: 'Please input the ' + t("ContractUrl") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("TransactionUrl")} name="transactionUrl"
                            rules={[{ required: false, message: 'Please input the ' + t("TransactionUrl") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("TokenUrl")} name="tokenUrl"
                            rules={[{ required: false, message: 'Please input the ' + t("TokenUrl") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("ChainId")} name="chainId"
                            rules={[{ required: false, message: 'Please input the ' + t("ChainId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "algorithmWalletId") && <Form.Item label={<> {t('AlgorithmWallet')}</>}>
                                <Form.Item noStyle id="algorithmWallet_id" name="algorithmWalletId"
                                rules={[{ required: (false && !(dataSources.dependencies?.algorithmWallet?.title || databaseEntity?.algorithmWallet?.title)), message: 'Please input the AlgorithmWallet!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.algorithmWallet ? dataSources.dependencies?.algorithmWallet?.title : databaseEntity?.algorithmWallet?.title}
                                value={ dataSources.dependencies?.algorithmWallet ? dataSources.dependencies?.algorithmWallet?.title : databaseEntity?.algorithmWallet?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.algorithmWalletId = newValue.key;
                                            dataSources.dependencies["algorithmWallet"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["algorithmWallet"]) {
                                                delete dataSources.dependencies["algorithmWallet"].__typename;
                                            }                                            
                                            setSelects({ ...selects, algorithmWalletId: newValue.key });
                                            setUncheck({ ...uncheck, algorithmWalletId: false });
                                        }
                                        else if (databaseEntity?.algorithmWallet) {
                                            dataSources.dependencies.algorithmWalletId = undefined;
                                            dataSources.dependencies["algorithmWallet"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["algorithmWallet"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.algorithmWalletId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "AlgorithmWallet", query: ALGORITHMWALLET_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "AlgorithmWallet", query: ALGORITHMWALLET_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["AlgorithmWallet"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, AlgorithmWalletalgorithmWalletIdEditVisible: !modals.AlgorithmWalletalgorithmWalletIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["AlgorithmWalletalgorithmWalletIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, AlgorithmWalletalgorithmWalletIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        AlgorithmWallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AlgorithmWalletDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.algorithmWallet?.id || databaseEntity?.algorithmWallet?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, AlgorithmWalletalgorithmWalletIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    algorithmWalletId: entity?.id,
                                                    algorithmWallet: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, AlgorithmWalletAddVisible: !modals.AlgorithmWalletAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["AlgorithmWalletAddVisible"]}
                                    onHide={() => setModals({ ...modals, AlgorithmWalletAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            AlgorithmWallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AlgorithmWalletDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, AlgorithmWalletAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        algorithmWalletId: entity?.id,
                                                        algorithmWallet: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.algorithmWallet?.id || databaseEntity?.algorithmWallet?.id) && !uncheck['algorithmWalletId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, algorithmWalletId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            algorithmWalletId: "",
                                            algorithmWallet: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("IsEnable")} name="isEnable" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            <Form.Item label={t("NetworkId")} name="networkId"
                            rules={[{ required: false, message: 'Please input the ' + t("NetworkId") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("NetworkMonitor")} name="networkMonitor"
                            rules={[{ required: false, message: 'Please input the ' + t("NetworkMonitor") + '!' }]}>
                                <Input />
                            </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`ApprovalManager`)}>
                            <ApprovalManagerList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></ApprovalManagerList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={2} tab={t(`Wallet`)}>
                            <WalletList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></WalletList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={3} tab={t(`BlockchainToken`)}>
                            <BlockchainTokenList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></BlockchainTokenList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={4} tab={t(`BlockchainTax`)}>
                            <BlockchainTaxList relation={{model: "network", field: "blockchainId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></BlockchainTaxList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={5} tab={t(`ApiKeyWallet`)}>
                            <ApiKeyWalletList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></ApiKeyWalletList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={6} tab={t(`BlockchainInfo`)}>
                            <BlockchainInfoList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></BlockchainInfoList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={7} tab={t(`CustodyNetwork`)}>
                            <CustodyNetworkList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></CustodyNetworkList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={8} tab={t(`OracleAddress`)}>
                            <OracleAddressList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></OracleAddressList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={9} tab={t(`CustodyWallet`)}>
                            <CustodyWalletList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></CustodyWalletList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={10} tab={t(`AirdropCampaign`)}>
                            <AirdropCampaignList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></AirdropCampaignList>
                        </TabPane> }
                        
                        { !!databaseEntity?.id && <TabPane key={11} tab={t(`CustodyCurrencies`)}>
                            <CustodyCurrenciesList relation={{model: "network", field: "networkId", textField: "title", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.title}}></CustodyCurrenciesList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (BlockchainDetails);
  