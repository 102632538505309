//// ------------------------------------------------------
//// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
//// ------------------------------------------------------

import ApprovalManagerCategoryDetails from '../../pages/prisma/approvalManagerCategory/approvalManagerCategory.details';
import ApprovalManagerCategoryList from '../../pages/prisma/approvalManagerCategory/approvalManagerCategory.list';
import ApprovalManagerDetails from '../../pages/prisma/approvalManager/approvalManager.details';
import ApprovalManagerList from '../../pages/prisma/approvalManager/approvalManager.list';
import ApprovalManagerUserDetails from '../../pages/prisma/approvalManagerUser/approvalManagerUser.details';
import ApprovalManagerUserList from '../../pages/prisma/approvalManagerUser/approvalManagerUser.list';
import ApprovalManagerTokenMapDetails from '../../pages/prisma/approvalManagerTokenMap/approvalManagerTokenMap.details';
import ApprovalManagerTokenMapList from '../../pages/prisma/approvalManagerTokenMap/approvalManagerTokenMap.list';
import ApprovalManagerOfferMapDetails from '../../pages/prisma/approvalManagerOfferMap/approvalManagerOfferMap.details';
import ApprovalManagerOfferMapList from '../../pages/prisma/approvalManagerOfferMap/approvalManagerOfferMap.list';
import AgentDetails from '../../pages/prisma/agent/agent.details';
import AgentList from '../../pages/prisma/agent/agent.list';
import AgentRoleDetails from '../../pages/prisma/agentRole/agentRole.details';
import AgentRoleList from '../../pages/prisma/agentRole/agentRole.list';
import AgentRolePolicyMapDetails from '../../pages/prisma/agentRolePolicyMap/agentRolePolicyMap.details';
import AgentRolePolicyMapList from '../../pages/prisma/agentRolePolicyMap/agentRolePolicyMap.list';
import UserDetails from '../../pages/prisma/user/user.details';
import UserList from '../../pages/prisma/user/user.list';
import NotificationRuleMapDetails from '../../pages/prisma/notificationRuleMap/notificationRuleMap.details';
import NotificationRuleMapList from '../../pages/prisma/notificationRuleMap/notificationRuleMap.list';
import NotificationRuleDetails from '../../pages/prisma/notificationRule/notificationRule.details';
import NotificationRuleList from '../../pages/prisma/notificationRule/notificationRule.list';
import NotificationRuleCategoryDetails from '../../pages/prisma/notificationRuleCategory/notificationRuleCategory.details';
import NotificationRuleCategoryList from '../../pages/prisma/notificationRuleCategory/notificationRuleCategory.list';
import NotificationDetails from '../../pages/prisma/notification/notification.details';
import NotificationList from '../../pages/prisma/notification/notification.list';
import PartnerTypeDetails from '../../pages/prisma/partnerType/partnerType.details';
import PartnerTypeList from '../../pages/prisma/partnerType/partnerType.list';
import PartnerTypeMapDetails from '../../pages/prisma/partnerTypeMap/partnerTypeMap.details';
import PartnerTypeMapList from '../../pages/prisma/partnerTypeMap/partnerTypeMap.list';
import PartnerDetails from '../../pages/prisma/partner/partner.details';
import PartnerList from '../../pages/prisma/partner/partner.list';
import CustodyAccessTokenLogDetails from '../../pages/prisma/custodyAccessTokenLog/custodyAccessTokenLog.details';
import CustodyAccessTokenLogList from '../../pages/prisma/custodyAccessTokenLog/custodyAccessTokenLog.list';
import CustodyNetworkDetails from '../../pages/prisma/custodyNetwork/custodyNetwork.details';
import CustodyNetworkList from '../../pages/prisma/custodyNetwork/custodyNetwork.list';
import CompanyDetails from '../../pages/prisma/company/company.details';
import CompanyList from '../../pages/prisma/company/company.list';
import CompanyBankAccountDetails from '../../pages/prisma/companyBankAccount/companyBankAccount.details';
import CompanyBankAccountList from '../../pages/prisma/companyBankAccount/companyBankAccount.list';
import CompanyProfileDetails from '../../pages/prisma/companyProfile/companyProfile.details';
import CompanyProfileList from '../../pages/prisma/companyProfile/companyProfile.list';
import CompanyTypeDetails from '../../pages/prisma/companyType/companyType.details';
import CompanyTypeList from '../../pages/prisma/companyType/companyType.list';
import PartnerCompanyMapDetails from '../../pages/prisma/partnerCompanyMap/partnerCompanyMap.details';
import PartnerCompanyMapList from '../../pages/prisma/partnerCompanyMap/partnerCompanyMap.list';
import CompanyPartnerMapDetails from '../../pages/prisma/companyPartnerMap/companyPartnerMap.details';
import CompanyPartnerMapList from '../../pages/prisma/companyPartnerMap/companyPartnerMap.list';
import InvestorTypeDetails from '../../pages/prisma/investorType/investorType.details';
import InvestorTypeList from '../../pages/prisma/investorType/investorType.list';
import InvestorDetails from '../../pages/prisma/investor/investor.details';
import InvestorList from '../../pages/prisma/investor/investor.list';
import AccountDetails from '../../pages/prisma/account/account.details';
import AccountList from '../../pages/prisma/account/account.list';
import ProfileAccountDetails from '../../pages/prisma/profileAccount/profileAccount.details';
import ProfileAccountList from '../../pages/prisma/profileAccount/profileAccount.list';
import InvestorDepositDetails from '../../pages/prisma/investorDeposit/investorDeposit.details';
import InvestorDepositList from '../../pages/prisma/investorDeposit/investorDeposit.list';
import OfferInvestDetails from '../../pages/prisma/offerInvest/offerInvest.details';
import OfferInvestList from '../../pages/prisma/offerInvest/offerInvest.list';
import OfferInvestStatusDetails from '../../pages/prisma/offerInvestStatus/offerInvestStatus.details';
import OfferInvestStatusList from '../../pages/prisma/offerInvestStatus/offerInvestStatus.list';
import CompanyDepositDetails from '../../pages/prisma/companyDeposit/companyDeposit.details';
import CompanyDepositList from '../../pages/prisma/companyDeposit/companyDeposit.list';
import CompanyDepositStatusDetails from '../../pages/prisma/companyDepositStatus/companyDepositStatus.details';
import CompanyDepositStatusList from '../../pages/prisma/companyDepositStatus/companyDepositStatus.list';
import InvestorDepositStatusDetails from '../../pages/prisma/investorDepositStatus/investorDepositStatus.details';
import InvestorDepositStatusList from '../../pages/prisma/investorDepositStatus/investorDepositStatus.list';
import TwoFARecoveryDetails from '../../pages/prisma/twoFARecovery/twoFARecovery.details';
import TwoFARecoveryList from '../../pages/prisma/twoFARecovery/twoFARecovery.list';
import TwoFARecoveryStatusDetails from '../../pages/prisma/twoFARecoveryStatus/twoFARecoveryStatus.details';
import TwoFARecoveryStatusList from '../../pages/prisma/twoFARecoveryStatus/twoFARecoveryStatus.list';
import AccountKYCDetails from '../../pages/prisma/accountKYC/accountKYC.details';
import AccountKYCList from '../../pages/prisma/accountKYC/accountKYC.list';
import KycStatusDetails from '../../pages/prisma/kycStatus/kycStatus.details';
import KycStatusList from '../../pages/prisma/kycStatus/kycStatus.list';
import CompanyKYCDetails from '../../pages/prisma/companyKYC/companyKYC.details';
import CompanyKYCList from '../../pages/prisma/companyKYC/companyKYC.list';
import CompanyBeginFundraisingDetails from '../../pages/prisma/companyBeginFundraising/companyBeginFundraising.details';
import CompanyBeginFundraisingList from '../../pages/prisma/companyBeginFundraising/companyBeginFundraising.list';
import FundraisingStatusDetails from '../../pages/prisma/fundraisingStatus/fundraisingStatus.details';
import FundraisingStatusList from '../../pages/prisma/fundraisingStatus/fundraisingStatus.list';
import PublicFileDetails from '../../pages/prisma/publicFile/publicFile.details';
import PublicFileList from '../../pages/prisma/publicFile/publicFile.list';
import PrivateFileDetails from '../../pages/prisma/privateFile/privateFile.details';
import PrivateFileList from '../../pages/prisma/privateFile/privateFile.list';
import LeadDetails from '../../pages/prisma/lead/lead.details';
import LeadList from '../../pages/prisma/lead/lead.list';
import LeadTypeDetails from '../../pages/prisma/leadType/leadType.details';
import LeadTypeList from '../../pages/prisma/leadType/leadType.list';
import LeadTokenTypeDetails from '../../pages/prisma/leadTokenType/leadTokenType.details';
import LeadTokenTypeList from '../../pages/prisma/leadTokenType/leadTokenType.list';
import LeadStatusDetails from '../../pages/prisma/leadStatus/leadStatus.details';
import LeadStatusList from '../../pages/prisma/leadStatus/leadStatus.list';
import WalletDetails from '../../pages/prisma/wallet/wallet.details';
import WalletList from '../../pages/prisma/wallet/wallet.list';
import WalletTypeDetails from '../../pages/prisma/walletType/walletType.details';
import WalletTypeList from '../../pages/prisma/walletType/walletType.list';
import WalletAssetDetails from '../../pages/prisma/walletAsset/walletAsset.details';
import WalletAssetList from '../../pages/prisma/walletAsset/walletAsset.list';
import WalletAssetStatusDetails from '../../pages/prisma/walletAssetStatus/walletAssetStatus.details';
import WalletAssetStatusList from '../../pages/prisma/walletAssetStatus/walletAssetStatus.list';
import BlockchainDetails from '../../pages/prisma/blockchain/blockchain.details';
import BlockchainList from '../../pages/prisma/blockchain/blockchain.list';
import AlgorithmWalletDetails from '../../pages/prisma/algorithmWallet/algorithmWallet.details';
import AlgorithmWalletList from '../../pages/prisma/algorithmWallet/algorithmWallet.list';
import OfferDetails from '../../pages/prisma/offer/offer.details';
import OfferList from '../../pages/prisma/offer/offer.list';
import OfferSucessTypeDetails from '../../pages/prisma/offerSucessType/offerSucessType.details';
import OfferSucessTypeList from '../../pages/prisma/offerSucessType/offerSucessType.list';
import PartnerOfferMapDetails from '../../pages/prisma/partnerOfferMap/partnerOfferMap.details';
import PartnerOfferMapList from '../../pages/prisma/partnerOfferMap/partnerOfferMap.list';
import OfferDeployVarsMapDetails from '../../pages/prisma/offerDeployVarsMap/offerDeployVarsMap.details';
import OfferDeployVarsMapList from '../../pages/prisma/offerDeployVarsMap/offerDeployVarsMap.list';
import OfferContractInfoDetails from '../../pages/prisma/offerContractInfo/offerContractInfo.details';
import OfferContractInfoList from '../../pages/prisma/offerContractInfo/offerContractInfo.list';
import OfferNotificationDetails from '../../pages/prisma/offerNotification/offerNotification.details';
import OfferNotificationList from '../../pages/prisma/offerNotification/offerNotification.list';
import OfferAreaMapDetails from '../../pages/prisma/offerAreaMap/offerAreaMap.details';
import OfferAreaMapList from '../../pages/prisma/offerAreaMap/offerAreaMap.list';
import OfferInfoDetails from '../../pages/prisma/offerInfo/offerInfo.details';
import OfferInfoList from '../../pages/prisma/offerInfo/offerInfo.list';
import OfferStageDetails from '../../pages/prisma/offerStage/offerStage.details';
import OfferStageList from '../../pages/prisma/offerStage/offerStage.list';
import OfferStageTypeDetails from '../../pages/prisma/offerStageType/offerStageType.details';
import OfferStageTypeList from '../../pages/prisma/offerStageType/offerStageType.list';
import OfferKeyDetails from '../../pages/prisma/offerKey/offerKey.details';
import OfferKeyList from '../../pages/prisma/offerKey/offerKey.list';
import OfferImageDetails from '../../pages/prisma/offerImage/offerImage.details';
import OfferImageList from '../../pages/prisma/offerImage/offerImage.list';
import OfferFileDetails from '../../pages/prisma/offerFile/offerFile.details';
import OfferFileList from '../../pages/prisma/offerFile/offerFile.list';
import OfferCompaniesDetails from '../../pages/prisma/offerCompanies/offerCompanies.details';
import OfferCompaniesList from '../../pages/prisma/offerCompanies/offerCompanies.list';
import OfferCompaniesTypeMapDetails from '../../pages/prisma/offerCompaniesTypeMap/offerCompaniesTypeMap.details';
import OfferCompaniesTypeMapList from '../../pages/prisma/offerCompaniesTypeMap/offerCompaniesTypeMap.list';
import OfferCompaniesTypeDetails from '../../pages/prisma/offerCompaniesType/offerCompaniesType.details';
import OfferCompaniesTypeList from '../../pages/prisma/offerCompaniesType/offerCompaniesType.list';
import OfferCompaniesTeamDetails from '../../pages/prisma/offerCompaniesTeam/offerCompaniesTeam.details';
import OfferCompaniesTeamList from '../../pages/prisma/offerCompaniesTeam/offerCompaniesTeam.list';
import OfferAreaDetails from '../../pages/prisma/offerArea/offerArea.details';
import OfferAreaList from '../../pages/prisma/offerArea/offerArea.list';
import OfferStatusDetails from '../../pages/prisma/offerStatus/offerStatus.details';
import OfferStatusList from '../../pages/prisma/offerStatus/offerStatus.list';
import OfferModalityDetails from '../../pages/prisma/offerModality/offerModality.details';
import OfferModalityList from '../../pages/prisma/offerModality/offerModality.list';
import OfferTermsDetails from '../../pages/prisma/offerTerms/offerTerms.details';
import OfferTermsList from '../../pages/prisma/offerTerms/offerTerms.list';
import OfferTermsMapDetails from '../../pages/prisma/offerTermsMap/offerTermsMap.details';
import OfferTermsMapList from '../../pages/prisma/offerTermsMap/offerTermsMap.list';
import OfferTermsTypeDetails from '../../pages/prisma/offerTermsType/offerTermsType.details';
import OfferTermsTypeList from '../../pages/prisma/offerTermsType/offerTermsType.list';
import BlockchainTokenDetails from '../../pages/prisma/blockchainToken/blockchainToken.details';
import BlockchainTokenList from '../../pages/prisma/blockchainToken/blockchainToken.list';
import VirtualWalletAssetDetails from '../../pages/prisma/virtualWalletAsset/virtualWalletAsset.details';
import VirtualWalletAssetList from '../../pages/prisma/virtualWalletAsset/virtualWalletAsset.list';
import VirtualAssetDetails from '../../pages/prisma/virtualAsset/virtualAsset.details';
import VirtualAssetList from '../../pages/prisma/virtualAsset/virtualAsset.list';
import VirtualAssetTypeDetails from '../../pages/prisma/virtualAssetType/virtualAssetType.details';
import VirtualAssetTypeList from '../../pages/prisma/virtualAssetType/virtualAssetType.list';
import VirtualAssetMapDetails from '../../pages/prisma/virtualAssetMap/virtualAssetMap.details';
import VirtualAssetMapList from '../../pages/prisma/virtualAssetMap/virtualAssetMap.list';
import CompanyContactsTokenMapDetails from '../../pages/prisma/companyContactsTokenMap/companyContactsTokenMap.details';
import CompanyContactsTokenMapList from '../../pages/prisma/companyContactsTokenMap/companyContactsTokenMap.list';
import CompanyContactDetails from '../../pages/prisma/companyContact/companyContact.details';
import CompanyContactList from '../../pages/prisma/companyContact/companyContact.list';
import TokenRateTypeDetails from '../../pages/prisma/tokenRateType/tokenRateType.details';
import TokenRateTypeList from '../../pages/prisma/tokenRateType/tokenRateType.list';
import TokenConstVarsDetails from '../../pages/prisma/tokenConstVars/tokenConstVars.details';
import TokenConstVarsList from '../../pages/prisma/tokenConstVars/tokenConstVars.list';
import TokenConstVarsTypeDetails from '../../pages/prisma/tokenConstVarsType/tokenConstVarsType.details';
import TokenConstVarsTypeList from '../../pages/prisma/tokenConstVarsType/tokenConstVarsType.list';
import BlockchainTokenFileDetails from '../../pages/prisma/blockchainTokenFile/blockchainTokenFile.details';
import BlockchainTokenFileList from '../../pages/prisma/blockchainTokenFile/blockchainTokenFile.list';
import NftFileDetails from '../../pages/prisma/nftFile/nftFile.details';
import NftFileList from '../../pages/prisma/nftFile/nftFile.list';
import BlockchainTokenFileTypeDetails from '../../pages/prisma/blockchainTokenFileType/blockchainTokenFileType.details';
import BlockchainTokenFileTypeList from '../../pages/prisma/blockchainTokenFileType/blockchainTokenFileType.list';
import NftFileTypeDetails from '../../pages/prisma/nftFileType/nftFileType.details';
import NftFileTypeList from '../../pages/prisma/nftFileType/nftFileType.list';
import BlockchainTokenRateTypeDetails from '../../pages/prisma/blockchainTokenRateType/blockchainTokenRateType.details';
import BlockchainTokenRateTypeList from '../../pages/prisma/blockchainTokenRateType/blockchainTokenRateType.list';
import TokenContractInfoDetails from '../../pages/prisma/tokenContractInfo/tokenContractInfo.details';
import TokenContractInfoList from '../../pages/prisma/tokenContractInfo/tokenContractInfo.list';
import TokenInfoDetails from '../../pages/prisma/tokenInfo/tokenInfo.details';
import TokenInfoList from '../../pages/prisma/tokenInfo/tokenInfo.list';
import TokenRemunerationScheduleDetails from '../../pages/prisma/tokenRemunerationSchedule/tokenRemunerationSchedule.details';
import TokenRemunerationScheduleList from '../../pages/prisma/tokenRemunerationSchedule/tokenRemunerationSchedule.list';
import TokenRemunerationScheduleStatusDetails from '../../pages/prisma/tokenRemunerationScheduleStatus/tokenRemunerationScheduleStatus.details';
import TokenRemunerationScheduleStatusList from '../../pages/prisma/tokenRemunerationScheduleStatus/tokenRemunerationScheduleStatus.list';
import TokenRemunerationTypeDetails from '../../pages/prisma/tokenRemunerationType/tokenRemunerationType.details';
import TokenRemunerationTypeList from '../../pages/prisma/tokenRemunerationType/tokenRemunerationType.list';
import TokenSettlementTypeDetails from '../../pages/prisma/tokenSettlementType/tokenSettlementType.details';
import TokenSettlementTypeList from '../../pages/prisma/tokenSettlementType/tokenSettlementType.list';
import TokenCategoryDetails from '../../pages/prisma/tokenCategory/tokenCategory.details';
import TokenCategoryList from '../../pages/prisma/tokenCategory/tokenCategory.list';
import TokenKindDetails from '../../pages/prisma/tokenKind/tokenKind.details';
import TokenKindList from '../../pages/prisma/tokenKind/tokenKind.list';
import TokenResourceMapDetails from '../../pages/prisma/tokenResourceMap/tokenResourceMap.details';
import TokenResourceMapList from '../../pages/prisma/tokenResourceMap/tokenResourceMap.list';
import TokenResourceDetails from '../../pages/prisma/tokenResource/tokenResource.details';
import TokenResourceList from '../../pages/prisma/tokenResource/tokenResource.list';
import TokenSubCategoryDetails from '../../pages/prisma/tokenSubCategory/tokenSubCategory.details';
import TokenSubCategoryList from '../../pages/prisma/tokenSubCategory/tokenSubCategory.list';
import TokenInfoCategoryDetails from '../../pages/prisma/tokenInfoCategory/tokenInfoCategory.details';
import TokenInfoCategoryList from '../../pages/prisma/tokenInfoCategory/tokenInfoCategory.list';
import TokenNewsDetails from '../../pages/prisma/tokenNews/tokenNews.details';
import TokenNewsList from '../../pages/prisma/tokenNews/tokenNews.list';
import BlockchainTokenTypeDetails from '../../pages/prisma/blockchainTokenType/blockchainTokenType.details';
import BlockchainTokenTypeList from '../../pages/prisma/blockchainTokenType/blockchainTokenType.list';
import BlockchainTokenRuleMapDetails from '../../pages/prisma/blockchainTokenRuleMap/blockchainTokenRuleMap.details';
import BlockchainTokenRuleMapList from '../../pages/prisma/blockchainTokenRuleMap/blockchainTokenRuleMap.list';
import BlockchainTokenConstMapDetails from '../../pages/prisma/blockchainTokenConstMap/blockchainTokenConstMap.details';
import BlockchainTokenConstMapList from '../../pages/prisma/blockchainTokenConstMap/blockchainTokenConstMap.list';
import TokenDeployVarsMapDetails from '../../pages/prisma/tokenDeployVarsMap/tokenDeployVarsMap.details';
import TokenDeployVarsMapList from '../../pages/prisma/tokenDeployVarsMap/tokenDeployVarsMap.list';
import OfferRuleMapDetails from '../../pages/prisma/offerRuleMap/offerRuleMap.details';
import OfferRuleMapList from '../../pages/prisma/offerRuleMap/offerRuleMap.list';
import OfferConstMapDetails from '../../pages/prisma/offerConstMap/offerConstMap.details';
import OfferConstMapList from '../../pages/prisma/offerConstMap/offerConstMap.list';
import TokenRuleDetails from '../../pages/prisma/tokenRule/tokenRule.details';
import TokenRuleList from '../../pages/prisma/tokenRule/tokenRule.list';
import TokenConstDetails from '../../pages/prisma/tokenConst/tokenConst.details';
import TokenConstList from '../../pages/prisma/tokenConst/tokenConst.list';
import OfferRuleDetails from '../../pages/prisma/offerRule/offerRule.details';
import OfferRuleList from '../../pages/prisma/offerRule/offerRule.list';
import OfferConstDetails from '../../pages/prisma/offerConst/offerConst.details';
import OfferConstList from '../../pages/prisma/offerConst/offerConst.list';
import ConstTypeDetails from '../../pages/prisma/constType/constType.details';
import ConstTypeList from '../../pages/prisma/constType/constType.list';
import TokenStatusDetails from '../../pages/prisma/tokenStatus/tokenStatus.details';
import TokenStatusList from '../../pages/prisma/tokenStatus/tokenStatus.list';
import DocumentStatusDetails from '../../pages/prisma/documentStatus/documentStatus.details';
import DocumentStatusList from '../../pages/prisma/documentStatus/documentStatus.list';
import DocumentTypeDetails from '../../pages/prisma/documentType/documentType.details';
import DocumentTypeList from '../../pages/prisma/documentType/documentType.list';
import BlockchainTransactionDetails from '../../pages/prisma/blockchainTransaction/blockchainTransaction.details';
import BlockchainTransactionList from '../../pages/prisma/blockchainTransaction/blockchainTransaction.list';
import BlockchainTaxDetails from '../../pages/prisma/blockchainTax/blockchainTax.details';
import BlockchainTaxList from '../../pages/prisma/blockchainTax/blockchainTax.list';
import BlockchainTransactionOperationDetails from '../../pages/prisma/blockchainTransactionOperation/blockchainTransactionOperation.details';
import BlockchainTransactionOperationList from '../../pages/prisma/blockchainTransactionOperation/blockchainTransactionOperation.list';
import BlockchainTransactionStatusDetails from '../../pages/prisma/blockchainTransactionStatus/blockchainTransactionStatus.details';
import BlockchainTransactionStatusList from '../../pages/prisma/blockchainTransactionStatus/blockchainTransactionStatus.list';
import BankDetails from '../../pages/prisma/bank/bank.details';
import BankList from '../../pages/prisma/bank/bank.list';
import BankAccountDetails from '../../pages/prisma/bankAccount/bankAccount.details';
import BankAccountList from '../../pages/prisma/bankAccount/bankAccount.list';
import BankAccountStatusDetails from '../../pages/prisma/bankAccountStatus/bankAccountStatus.details';
import BankAccountStatusList from '../../pages/prisma/bankAccountStatus/bankAccountStatus.list';
import PixKeyTypeDetails from '../../pages/prisma/pixKeyType/pixKeyType.details';
import PixKeyTypeList from '../../pages/prisma/pixKeyType/pixKeyType.list';
import BankTransactionDetails from '../../pages/prisma/bankTransaction/bankTransaction.details';
import BankTransactionList from '../../pages/prisma/bankTransaction/bankTransaction.list';
import BankTransactionOperationDetails from '../../pages/prisma/bankTransactionOperation/bankTransactionOperation.details';
import BankTransactionOperationList from '../../pages/prisma/bankTransactionOperation/bankTransactionOperation.list';
import BankAccountTypeDetails from '../../pages/prisma/bankAccountType/bankAccountType.details';
import BankAccountTypeList from '../../pages/prisma/bankAccountType/bankAccountType.list';
import AirdropCampaignDetails from '../../pages/prisma/airdropCampaign/airdropCampaign.details';
import AirdropCampaignList from '../../pages/prisma/airdropCampaign/airdropCampaign.list';
import CampaignDetails from '../../pages/prisma/campaign/campaign.details';
import CampaignList from '../../pages/prisma/campaign/campaign.list';
import CampaignStatusDetails from '../../pages/prisma/campaignStatus/campaignStatus.details';
import CampaignStatusList from '../../pages/prisma/campaignStatus/campaignStatus.list';
import CampaignTypeDetails from '../../pages/prisma/campaignType/campaignType.details';
import CampaignTypeList from '../../pages/prisma/campaignType/campaignType.list';
import BankTransactionStatusDetails from '../../pages/prisma/bankTransactionStatus/bankTransactionStatus.details';
import BankTransactionStatusList from '../../pages/prisma/bankTransactionStatus/bankTransactionStatus.list';
import BankTransactionTypeDetails from '../../pages/prisma/bankTransactionType/bankTransactionType.details';
import BankTransactionTypeList from '../../pages/prisma/bankTransactionType/bankTransactionType.list';
import CountryDetails from '../../pages/prisma/country/country.details';
import CountryList from '../../pages/prisma/country/country.list';
import UserRoleDetails from '../../pages/prisma/userRole/userRole.details';
import UserRoleList from '../../pages/prisma/userRole/userRole.list';
import UserRoleMapDetails from '../../pages/prisma/userRoleMap/userRoleMap.details';
import UserRoleMapList from '../../pages/prisma/userRoleMap/userRoleMap.list';
import UserRoleTokenCategoryMapDetails from '../../pages/prisma/userRoleTokenCategoryMap/userRoleTokenCategoryMap.details';
import UserRoleTokenCategoryMapList from '../../pages/prisma/userRoleTokenCategoryMap/userRoleTokenCategoryMap.list';
import UserRoleLeadTypeMapDetails from '../../pages/prisma/userRoleLeadTypeMap/userRoleLeadTypeMap.details';
import UserRoleLeadTypeMapList from '../../pages/prisma/userRoleLeadTypeMap/userRoleLeadTypeMap.list';
import AccessIpDetails from '../../pages/prisma/accessIp/accessIp.details';
import AccessIpList from '../../pages/prisma/accessIp/accessIp.list';
import TypeSupplyTokenDetails from '../../pages/prisma/typeSupplyToken/typeSupplyToken.details';
import TypeSupplyTokenList from '../../pages/prisma/typeSupplyToken/typeSupplyToken.list';
import PolicyDetails from '../../pages/prisma/policy/policy.details';
import PolicyList from '../../pages/prisma/policy/policy.list';
import PolicyServiceDetails from '../../pages/prisma/policyService/policyService.details';
import PolicyServiceList from '../../pages/prisma/policyService/policyService.list';
import PolicyStatusDetails from '../../pages/prisma/policyStatus/policyStatus.details';
import PolicyStatusList from '../../pages/prisma/policyStatus/policyStatus.list';
import ReportDetails from '../../pages/prisma/report/report.details';
import ReportList from '../../pages/prisma/report/report.list';
import LogDetails from '../../pages/prisma/log/log.details';
import LogList from '../../pages/prisma/log/log.list';
import StateDetails from '../../pages/prisma/state/state.details';
import StateList from '../../pages/prisma/state/state.list';
import VaultDetails from '../../pages/prisma/vault/vault.details';
import VaultList from '../../pages/prisma/vault/vault.list';
import UtmDetails from '../../pages/prisma/utm/utm.details';
import UtmList from '../../pages/prisma/utm/utm.list';
import BankReconciliationDetails from '../../pages/prisma/bankReconciliation/bankReconciliation.details';
import BankReconciliationList from '../../pages/prisma/bankReconciliation/bankReconciliation.list';
import BankReconciliationStatusDetails from '../../pages/prisma/bankReconciliationStatus/bankReconciliationStatus.details';
import BankReconciliationStatusList from '../../pages/prisma/bankReconciliationStatus/bankReconciliationStatus.list';
import BlockchainInfoDetails from '../../pages/prisma/blockchainInfo/blockchainInfo.details';
import BlockchainInfoList from '../../pages/prisma/blockchainInfo/blockchainInfo.list';
import LiqiFileDetails from '../../pages/prisma/liqiFile/liqiFile.details';
import LiqiFileList from '../../pages/prisma/liqiFile/liqiFile.list';
import UserPushDetails from '../../pages/prisma/userPush/userPush.details';
import UserPushList from '../../pages/prisma/userPush/userPush.list';
import ScheduleCommunicationDetails from '../../pages/prisma/scheduleCommunication/scheduleCommunication.details';
import ScheduleCommunicationList from '../../pages/prisma/scheduleCommunication/scheduleCommunication.list';
import ScheduleCommunicationFieldsDetails from '../../pages/prisma/scheduleCommunicationFields/scheduleCommunicationFields.details';
import ScheduleCommunicationFieldsList from '../../pages/prisma/scheduleCommunicationFields/scheduleCommunicationFields.list';
import ScheduleCommunicationEmailsDetails from '../../pages/prisma/scheduleCommunicationEmails/scheduleCommunicationEmails.details';
import ScheduleCommunicationEmailsList from '../../pages/prisma/scheduleCommunicationEmails/scheduleCommunicationEmails.list';
import ExternalWalletDetails from '../../pages/prisma/externalWallet/externalWallet.details';
import ExternalWalletList from '../../pages/prisma/externalWallet/externalWallet.list';
import StatementDetails from '../../pages/prisma/statement/statement.details';
import StatementList from '../../pages/prisma/statement/statement.list';
import StatementStatusDetails from '../../pages/prisma/statementStatus/statementStatus.details';
import StatementStatusList from '../../pages/prisma/statementStatus/statementStatus.list';
import StatementCategoryDetails from '../../pages/prisma/statementCategory/statementCategory.details';
import StatementCategoryList from '../../pages/prisma/statementCategory/statementCategory.list';
import StatementOperationDetails from '../../pages/prisma/statementOperation/statementOperation.details';
import StatementOperationList from '../../pages/prisma/statementOperation/statementOperation.list';
import DividendDetails from '../../pages/prisma/dividend/dividend.details';
import DividendList from '../../pages/prisma/dividend/dividend.list';
import DividendCategoryDetails from '../../pages/prisma/dividendCategory/dividendCategory.details';
import DividendCategoryList from '../../pages/prisma/dividendCategory/dividendCategory.list';
import ApiKeyWalletDetails from '../../pages/prisma/apiKeyWallet/apiKeyWallet.details';
import ApiKeyWalletList from '../../pages/prisma/apiKeyWallet/apiKeyWallet.list';
import ApiKeyVaultDetails from '../../pages/prisma/apiKeyVault/apiKeyVault.details';
import ApiKeyVaultList from '../../pages/prisma/apiKeyVault/apiKeyVault.list';
import CompanyInviteDetails from '../../pages/prisma/companyInvite/companyInvite.details';
import CompanyInviteList from '../../pages/prisma/companyInvite/companyInvite.list';
import BlockchainTokenUserMapDetails from '../../pages/prisma/blockchainTokenUserMap/blockchainTokenUserMap.details';
import BlockchainTokenUserMapList from '../../pages/prisma/blockchainTokenUserMap/blockchainTokenUserMap.list';
import LiqiWitnessDetails from '../../pages/prisma/liqiWitness/liqiWitness.details';
import LiqiWitnessList from '../../pages/prisma/liqiWitness/liqiWitness.list';
import DocumentSignerContractDetails from '../../pages/prisma/documentSignerContract/documentSignerContract.details';
import DocumentSignerContractList from '../../pages/prisma/documentSignerContract/documentSignerContract.list';
import DocumentSignerContractStatusDetails from '../../pages/prisma/documentSignerContractStatus/documentSignerContractStatus.details';
import DocumentSignerContractStatusList from '../../pages/prisma/documentSignerContractStatus/documentSignerContractStatus.list';
import FaqDetails from '../../pages/prisma/faq/faq.details';
import FaqList from '../../pages/prisma/faq/faq.list';
import FaqItemDetails from '../../pages/prisma/faqItem/faqItem.details';
import FaqItemList from '../../pages/prisma/faqItem/faqItem.list';
import OracleIndexTypeDetails from '../../pages/prisma/oracleIndexType/oracleIndexType.details';
import OracleIndexTypeList from '../../pages/prisma/oracleIndexType/oracleIndexType.list';
import OracleDataDetails from '../../pages/prisma/oracleData/oracleData.details';
import OracleDataList from '../../pages/prisma/oracleData/oracleData.list';
import OracleAddressDetails from '../../pages/prisma/oracleAddress/oracleAddress.details';
import OracleAddressList from '../../pages/prisma/oracleAddress/oracleAddress.list';
import OracleRegisterDetails from '../../pages/prisma/oracleRegister/oracleRegister.details';
import OracleRegisterList from '../../pages/prisma/oracleRegister/oracleRegister.list';
import CustodyUserDetails from '../../pages/prisma/custodyUser/custodyUser.details';
import CustodyUserList from '../../pages/prisma/custodyUser/custodyUser.list';
import CustodyWalletTypeDetails from '../../pages/prisma/custodyWalletType/custodyWalletType.details';
import CustodyWalletTypeList from '../../pages/prisma/custodyWalletType/custodyWalletType.list';
import CustodyWalletProfileDetails from '../../pages/prisma/custodyWalletProfile/custodyWalletProfile.details';
import CustodyWalletProfileList from '../../pages/prisma/custodyWalletProfile/custodyWalletProfile.list';
import CustodyWalletFrequencyDetails from '../../pages/prisma/custodyWalletFrequency/custodyWalletFrequency.details';
import CustodyWalletFrequencyList from '../../pages/prisma/custodyWalletFrequency/custodyWalletFrequency.list';
import CustodyWalletDetails from '../../pages/prisma/custodyWallet/custodyWallet.details';
import CustodyWalletList from '../../pages/prisma/custodyWallet/custodyWallet.list';
import CustodyVaultDetails from '../../pages/prisma/custodyVault/custodyVault.details';
import CustodyVaultList from '../../pages/prisma/custodyVault/custodyVault.list';
import CustodySeedDetails from '../../pages/prisma/custodySeed/custodySeed.details';
import CustodySeedList from '../../pages/prisma/custodySeed/custodySeed.list';
import CustodyApplicationDetails from '../../pages/prisma/custodyApplication/custodyApplication.details';
import CustodyApplicationList from '../../pages/prisma/custodyApplication/custodyApplication.list';
import CustodyBlockchainTransactionDetails from '../../pages/prisma/custodyBlockchainTransaction/custodyBlockchainTransaction.details';
import CustodyBlockchainTransactionList from '../../pages/prisma/custodyBlockchainTransaction/custodyBlockchainTransaction.list';
import CustodyTransactionTypeDetails from '../../pages/prisma/custodyTransactionType/custodyTransactionType.details';
import CustodyTransactionTypeList from '../../pages/prisma/custodyTransactionType/custodyTransactionType.list';
import CustodyWebHookTypeDetails from '../../pages/prisma/custodyWebHookType/custodyWebHookType.details';
import CustodyWebHookTypeList from '../../pages/prisma/custodyWebHookType/custodyWebHookType.list';
import CustodyWebHookUrlDetails from '../../pages/prisma/custodyWebHookUrl/custodyWebHookUrl.details';
import CustodyWebHookUrlList from '../../pages/prisma/custodyWebHookUrl/custodyWebHookUrl.list';
import CustodyCurrenciesDetails from '../../pages/prisma/custodyCurrencies/custodyCurrencies.details';
import CustodyCurrenciesList from '../../pages/prisma/custodyCurrencies/custodyCurrencies.list';
import CustodyCurrenciesCompanyMapDetails from '../../pages/prisma/custodyCurrenciesCompanyMap/custodyCurrenciesCompanyMap.details';
import CustodyCurrenciesCompanyMapList from '../../pages/prisma/custodyCurrenciesCompanyMap/custodyCurrenciesCompanyMap.list';
import CustodyWalletAssetDetails from '../../pages/prisma/custodyWalletAsset/custodyWalletAsset.details';
import CustodyWalletAssetList from '../../pages/prisma/custodyWalletAsset/custodyWalletAsset.list';
import BusinessTaxDetails from '../../pages/prisma/businessTax/businessTax.details';
import BusinessTaxList from '../../pages/prisma/businessTax/businessTax.list';
import BusinessTaxCompanyDetails from '../../pages/prisma/businessTaxCompany/businessTaxCompany.details';
import BusinessTaxCompanyList from '../../pages/prisma/businessTaxCompany/businessTaxCompany.list';
import BusinessTaxTypeDetails from '../../pages/prisma/businessTaxType/businessTaxType.details';
import BusinessTaxTypeList from '../../pages/prisma/businessTaxType/businessTaxType.list';
import WebHookMessageDetails from '../../pages/prisma/webHookMessage/webHookMessage.details';
import WebHookMessageList from '../../pages/prisma/webHookMessage/webHookMessage.list';

const prismaRoutes = [
    { path: '/approvalManagerCategory-list', component: ApprovalManagerCategoryList },
    { path: '/approvalManagerCategory-details/:guid?', component: ApprovalManagerCategoryDetails },
    { path: '/approvalManager-list', component: ApprovalManagerList },
    { path: '/approvalManager-details/:guid?', component: ApprovalManagerDetails },
    { path: '/approvalManagerUser-list', component: ApprovalManagerUserList },
    { path: '/approvalManagerUser-details/:guid?', component: ApprovalManagerUserDetails },
    { path: '/approvalManagerTokenMap-list', component: ApprovalManagerTokenMapList },
    { path: '/approvalManagerTokenMap-details/:guid?', component: ApprovalManagerTokenMapDetails },
    { path: '/approvalManagerOfferMap-list', component: ApprovalManagerOfferMapList },
    { path: '/approvalManagerOfferMap-details/:guid?', component: ApprovalManagerOfferMapDetails },
    { path: '/agent-list', component: AgentList },
    { path: '/agent-details/:guid?', component: AgentDetails },
    { path: '/agentRole-list', component: AgentRoleList },
    { path: '/agentRole-details/:guid?', component: AgentRoleDetails },
    { path: '/agentRolePolicyMap-list', component: AgentRolePolicyMapList },
    { path: '/agentRolePolicyMap-details/:guid?', component: AgentRolePolicyMapDetails },
    { path: '/user-list', component: UserList },
    { path: '/user-details/:guid?', component: UserDetails },
    { path: '/notificationRuleMap-list', component: NotificationRuleMapList },
    { path: '/notificationRuleMap-details/:guid?', component: NotificationRuleMapDetails },
    { path: '/notificationRule-list', component: NotificationRuleList },
    { path: '/notificationRule-details/:guid?', component: NotificationRuleDetails },
    { path: '/notificationRuleCategory-list', component: NotificationRuleCategoryList },
    { path: '/notificationRuleCategory-details/:guid?', component: NotificationRuleCategoryDetails },
    { path: '/notification-list', component: NotificationList },
    { path: '/notification-details/:guid?', component: NotificationDetails },
    { path: '/partnerType-list', component: PartnerTypeList },
    { path: '/partnerType-details/:guid?', component: PartnerTypeDetails },
    { path: '/partnerTypeMap-list', component: PartnerTypeMapList },
    { path: '/partnerTypeMap-details/:guid?', component: PartnerTypeMapDetails },
    { path: '/partner-list', component: PartnerList },
    { path: '/partner-details/:guid?', component: PartnerDetails },
    { path: '/custodyAccessTokenLog-list', component: CustodyAccessTokenLogList },
    { path: '/custodyAccessTokenLog-details/:guid?', component: CustodyAccessTokenLogDetails },
    { path: '/custodyNetwork-list', component: CustodyNetworkList },
    { path: '/custodyNetwork-details/:guid?', component: CustodyNetworkDetails },
    { path: '/company-list', component: CompanyList },
    { path: '/company-details/:guid?', component: CompanyDetails },
    { path: '/companyBankAccount-list', component: CompanyBankAccountList },
    { path: '/companyBankAccount-details/:guid?', component: CompanyBankAccountDetails },
    { path: '/companyProfile-list', component: CompanyProfileList },
    { path: '/companyProfile-details/:guid?', component: CompanyProfileDetails },
    { path: '/companyType-list', component: CompanyTypeList },
    { path: '/companyType-details/:guid?', component: CompanyTypeDetails },
    { path: '/partnerCompanyMap-list', component: PartnerCompanyMapList },
    { path: '/partnerCompanyMap-details/:guid?', component: PartnerCompanyMapDetails },
    { path: '/companyPartnerMap-list', component: CompanyPartnerMapList },
    { path: '/companyPartnerMap-details/:guid?', component: CompanyPartnerMapDetails },
    { path: '/investorType-list', component: InvestorTypeList },
    { path: '/investorType-details/:guid?', component: InvestorTypeDetails },
    { path: '/investor-list', component: InvestorList },
    { path: '/investor-details/:guid?', component: InvestorDetails },
    { path: '/account-list', component: AccountList },
    { path: '/account-details/:guid?', component: AccountDetails },
    { path: '/profileAccount-list', component: ProfileAccountList },
    { path: '/profileAccount-details/:guid?', component: ProfileAccountDetails },
    { path: '/investorDeposit-list', component: InvestorDepositList },
    { path: '/investorDeposit-details/:guid?', component: InvestorDepositDetails },
    { path: '/offerInvest-list', component: OfferInvestList },
    { path: '/offerInvest-details/:guid?', component: OfferInvestDetails },
    { path: '/offerInvestStatus-list', component: OfferInvestStatusList },
    { path: '/offerInvestStatus-details/:guid?', component: OfferInvestStatusDetails },
    { path: '/companyDeposit-list', component: CompanyDepositList },
    { path: '/companyDeposit-details/:guid?', component: CompanyDepositDetails },
    { path: '/companyDepositStatus-list', component: CompanyDepositStatusList },
    { path: '/companyDepositStatus-details/:guid?', component: CompanyDepositStatusDetails },
    { path: '/investorDepositStatus-list', component: InvestorDepositStatusList },
    { path: '/investorDepositStatus-details/:guid?', component: InvestorDepositStatusDetails },
    { path: '/twoFARecovery-list', component: TwoFARecoveryList },
    { path: '/twoFARecovery-details/:guid?', component: TwoFARecoveryDetails },
    { path: '/twoFARecoveryStatus-list', component: TwoFARecoveryStatusList },
    { path: '/twoFARecoveryStatus-details/:guid?', component: TwoFARecoveryStatusDetails },
    { path: '/accountKYC-list', component: AccountKYCList },
    { path: '/accountKYC-details/:guid?', component: AccountKYCDetails },
    { path: '/kycStatus-list', component: KycStatusList },
    { path: '/kycStatus-details/:guid?', component: KycStatusDetails },
    { path: '/companyKYC-list', component: CompanyKYCList },
    { path: '/companyKYC-details/:guid?', component: CompanyKYCDetails },
    { path: '/companyBeginFundraising-list', component: CompanyBeginFundraisingList },
    { path: '/companyBeginFundraising-details/:guid?', component: CompanyBeginFundraisingDetails },
    { path: '/fundraisingStatus-list', component: FundraisingStatusList },
    { path: '/fundraisingStatus-details/:guid?', component: FundraisingStatusDetails },
    { path: '/publicFile-list', component: PublicFileList },
    { path: '/publicFile-details/:guid?', component: PublicFileDetails },
    { path: '/privateFile-list', component: PrivateFileList },
    { path: '/privateFile-details/:guid?', component: PrivateFileDetails },
    { path: '/lead-list', component: LeadList },
    { path: '/lead-details/:guid?', component: LeadDetails },
    { path: '/leadType-list', component: LeadTypeList },
    { path: '/leadType-details/:guid?', component: LeadTypeDetails },
    { path: '/leadTokenType-list', component: LeadTokenTypeList },
    { path: '/leadTokenType-details/:guid?', component: LeadTokenTypeDetails },
    { path: '/leadStatus-list', component: LeadStatusList },
    { path: '/leadStatus-details/:guid?', component: LeadStatusDetails },
    { path: '/wallet-list', component: WalletList },
    { path: '/wallet-details/:guid?', component: WalletDetails },
    { path: '/walletType-list', component: WalletTypeList },
    { path: '/walletType-details/:guid?', component: WalletTypeDetails },
    { path: '/walletAsset-list', component: WalletAssetList },
    { path: '/walletAsset-details/:guid?', component: WalletAssetDetails },
    { path: '/walletAssetStatus-list', component: WalletAssetStatusList },
    { path: '/walletAssetStatus-details/:guid?', component: WalletAssetStatusDetails },
    { path: '/blockchain-list', component: BlockchainList },
    { path: '/blockchain-details/:guid?', component: BlockchainDetails },
    { path: '/algorithmWallet-list', component: AlgorithmWalletList },
    { path: '/algorithmWallet-details/:guid?', component: AlgorithmWalletDetails },
    { path: '/offer-list', component: OfferList },
    { path: '/offer-details/:guid?', component: OfferDetails },
    { path: '/offerSucessType-list', component: OfferSucessTypeList },
    { path: '/offerSucessType-details/:guid?', component: OfferSucessTypeDetails },
    { path: '/partnerOfferMap-list', component: PartnerOfferMapList },
    { path: '/partnerOfferMap-details/:guid?', component: PartnerOfferMapDetails },
    { path: '/offerDeployVarsMap-list', component: OfferDeployVarsMapList },
    { path: '/offerDeployVarsMap-details/:guid?', component: OfferDeployVarsMapDetails },
    { path: '/offerContractInfo-list', component: OfferContractInfoList },
    { path: '/offerContractInfo-details/:guid?', component: OfferContractInfoDetails },
    { path: '/offerNotification-list', component: OfferNotificationList },
    { path: '/offerNotification-details/:guid?', component: OfferNotificationDetails },
    { path: '/offerAreaMap-list', component: OfferAreaMapList },
    { path: '/offerAreaMap-details/:guid?', component: OfferAreaMapDetails },
    { path: '/offerInfo-list', component: OfferInfoList },
    { path: '/offerInfo-details/:guid?', component: OfferInfoDetails },
    { path: '/offerStage-list', component: OfferStageList },
    { path: '/offerStage-details/:guid?', component: OfferStageDetails },
    { path: '/offerStageType-list', component: OfferStageTypeList },
    { path: '/offerStageType-details/:guid?', component: OfferStageTypeDetails },
    { path: '/offerKey-list', component: OfferKeyList },
    { path: '/offerKey-details/:guid?', component: OfferKeyDetails },
    { path: '/offerImage-list', component: OfferImageList },
    { path: '/offerImage-details/:guid?', component: OfferImageDetails },
    { path: '/offerFile-list', component: OfferFileList },
    { path: '/offerFile-details/:guid?', component: OfferFileDetails },
    { path: '/offerCompanies-list', component: OfferCompaniesList },
    { path: '/offerCompanies-details/:guid?', component: OfferCompaniesDetails },
    { path: '/offerCompaniesTypeMap-list', component: OfferCompaniesTypeMapList },
    { path: '/offerCompaniesTypeMap-details/:guid?', component: OfferCompaniesTypeMapDetails },
    { path: '/offerCompaniesType-list', component: OfferCompaniesTypeList },
    { path: '/offerCompaniesType-details/:guid?', component: OfferCompaniesTypeDetails },
    { path: '/offerCompaniesTeam-list', component: OfferCompaniesTeamList },
    { path: '/offerCompaniesTeam-details/:guid?', component: OfferCompaniesTeamDetails },
    { path: '/offerArea-list', component: OfferAreaList },
    { path: '/offerArea-details/:guid?', component: OfferAreaDetails },
    { path: '/offerStatus-list', component: OfferStatusList },
    { path: '/offerStatus-details/:guid?', component: OfferStatusDetails },
    { path: '/offerModality-list', component: OfferModalityList },
    { path: '/offerModality-details/:guid?', component: OfferModalityDetails },
    { path: '/offerTerms-list', component: OfferTermsList },
    { path: '/offerTerms-details/:guid?', component: OfferTermsDetails },
    { path: '/offerTermsMap-list', component: OfferTermsMapList },
    { path: '/offerTermsMap-details/:guid?', component: OfferTermsMapDetails },
    { path: '/offerTermsType-list', component: OfferTermsTypeList },
    { path: '/offerTermsType-details/:guid?', component: OfferTermsTypeDetails },
    { path: '/blockchainToken-list', component: BlockchainTokenList },
    { path: '/blockchainToken-details/:guid?', component: BlockchainTokenDetails },
    { path: '/virtualWalletAsset-list', component: VirtualWalletAssetList },
    { path: '/virtualWalletAsset-details/:guid?', component: VirtualWalletAssetDetails },
    { path: '/virtualAsset-list', component: VirtualAssetList },
    { path: '/virtualAsset-details/:guid?', component: VirtualAssetDetails },
    { path: '/virtualAssetType-list', component: VirtualAssetTypeList },
    { path: '/virtualAssetType-details/:guid?', component: VirtualAssetTypeDetails },
    { path: '/virtualAssetMap-list', component: VirtualAssetMapList },
    { path: '/virtualAssetMap-details/:guid?', component: VirtualAssetMapDetails },
    { path: '/companyContactsTokenMap-list', component: CompanyContactsTokenMapList },
    { path: '/companyContactsTokenMap-details/:guid?', component: CompanyContactsTokenMapDetails },
    { path: '/companyContact-list', component: CompanyContactList },
    { path: '/companyContact-details/:guid?', component: CompanyContactDetails },
    { path: '/tokenRateType-list', component: TokenRateTypeList },
    { path: '/tokenRateType-details/:guid?', component: TokenRateTypeDetails },
    { path: '/tokenConstVars-list', component: TokenConstVarsList },
    { path: '/tokenConstVars-details/:guid?', component: TokenConstVarsDetails },
    { path: '/tokenConstVarsType-list', component: TokenConstVarsTypeList },
    { path: '/tokenConstVarsType-details/:guid?', component: TokenConstVarsTypeDetails },
    { path: '/blockchainTokenFile-list', component: BlockchainTokenFileList },
    { path: '/blockchainTokenFile-details/:guid?', component: BlockchainTokenFileDetails },
    { path: '/nftFile-list', component: NftFileList },
    { path: '/nftFile-details/:guid?', component: NftFileDetails },
    { path: '/blockchainTokenFileType-list', component: BlockchainTokenFileTypeList },
    { path: '/blockchainTokenFileType-details/:guid?', component: BlockchainTokenFileTypeDetails },
    { path: '/nftFileType-list', component: NftFileTypeList },
    { path: '/nftFileType-details/:guid?', component: NftFileTypeDetails },
    { path: '/blockchainTokenRateType-list', component: BlockchainTokenRateTypeList },
    { path: '/blockchainTokenRateType-details/:guid?', component: BlockchainTokenRateTypeDetails },
    { path: '/tokenContractInfo-list', component: TokenContractInfoList },
    { path: '/tokenContractInfo-details/:guid?', component: TokenContractInfoDetails },
    { path: '/tokenInfo-list', component: TokenInfoList },
    { path: '/tokenInfo-details/:guid?', component: TokenInfoDetails },
    { path: '/tokenRemunerationSchedule-list', component: TokenRemunerationScheduleList },
    { path: '/tokenRemunerationSchedule-details/:guid?', component: TokenRemunerationScheduleDetails },
    { path: '/tokenRemunerationScheduleStatus-list', component: TokenRemunerationScheduleStatusList },
    { path: '/tokenRemunerationScheduleStatus-details/:guid?', component: TokenRemunerationScheduleStatusDetails },
    { path: '/tokenRemunerationType-list', component: TokenRemunerationTypeList },
    { path: '/tokenRemunerationType-details/:guid?', component: TokenRemunerationTypeDetails },
    { path: '/tokenSettlementType-list', component: TokenSettlementTypeList },
    { path: '/tokenSettlementType-details/:guid?', component: TokenSettlementTypeDetails },
    { path: '/tokenCategory-list', component: TokenCategoryList },
    { path: '/tokenCategory-details/:guid?', component: TokenCategoryDetails },
    { path: '/tokenKind-list', component: TokenKindList },
    { path: '/tokenKind-details/:guid?', component: TokenKindDetails },
    { path: '/tokenResourceMap-list', component: TokenResourceMapList },
    { path: '/tokenResourceMap-details/:guid?', component: TokenResourceMapDetails },
    { path: '/tokenResource-list', component: TokenResourceList },
    { path: '/tokenResource-details/:guid?', component: TokenResourceDetails },
    { path: '/tokenSubCategory-list', component: TokenSubCategoryList },
    { path: '/tokenSubCategory-details/:guid?', component: TokenSubCategoryDetails },
    { path: '/tokenInfoCategory-list', component: TokenInfoCategoryList },
    { path: '/tokenInfoCategory-details/:guid?', component: TokenInfoCategoryDetails },
    { path: '/tokenNews-list', component: TokenNewsList },
    { path: '/tokenNews-details/:guid?', component: TokenNewsDetails },
    { path: '/blockchainTokenType-list', component: BlockchainTokenTypeList },
    { path: '/blockchainTokenType-details/:guid?', component: BlockchainTokenTypeDetails },
    { path: '/blockchainTokenRuleMap-list', component: BlockchainTokenRuleMapList },
    { path: '/blockchainTokenRuleMap-details/:guid?', component: BlockchainTokenRuleMapDetails },
    { path: '/blockchainTokenConstMap-list', component: BlockchainTokenConstMapList },
    { path: '/blockchainTokenConstMap-details/:guid?', component: BlockchainTokenConstMapDetails },
    { path: '/tokenDeployVarsMap-list', component: TokenDeployVarsMapList },
    { path: '/tokenDeployVarsMap-details/:guid?', component: TokenDeployVarsMapDetails },
    { path: '/offerRuleMap-list', component: OfferRuleMapList },
    { path: '/offerRuleMap-details/:guid?', component: OfferRuleMapDetails },
    { path: '/offerConstMap-list', component: OfferConstMapList },
    { path: '/offerConstMap-details/:guid?', component: OfferConstMapDetails },
    { path: '/tokenRule-list', component: TokenRuleList },
    { path: '/tokenRule-details/:guid?', component: TokenRuleDetails },
    { path: '/tokenConst-list', component: TokenConstList },
    { path: '/tokenConst-details/:guid?', component: TokenConstDetails },
    { path: '/offerRule-list', component: OfferRuleList },
    { path: '/offerRule-details/:guid?', component: OfferRuleDetails },
    { path: '/offerConst-list', component: OfferConstList },
    { path: '/offerConst-details/:guid?', component: OfferConstDetails },
    { path: '/constType-list', component: ConstTypeList },
    { path: '/constType-details/:guid?', component: ConstTypeDetails },
    { path: '/tokenStatus-list', component: TokenStatusList },
    { path: '/tokenStatus-details/:guid?', component: TokenStatusDetails },
    { path: '/documentStatus-list', component: DocumentStatusList },
    { path: '/documentStatus-details/:guid?', component: DocumentStatusDetails },
    { path: '/documentType-list', component: DocumentTypeList },
    { path: '/documentType-details/:guid?', component: DocumentTypeDetails },
    { path: '/blockchainTransaction-list', component: BlockchainTransactionList },
    { path: '/blockchainTransaction-details/:guid?', component: BlockchainTransactionDetails },
    { path: '/blockchainTax-list', component: BlockchainTaxList },
    { path: '/blockchainTax-details/:guid?', component: BlockchainTaxDetails },
    { path: '/blockchainTransactionOperation-list', component: BlockchainTransactionOperationList },
    { path: '/blockchainTransactionOperation-details/:guid?', component: BlockchainTransactionOperationDetails },
    { path: '/blockchainTransactionStatus-list', component: BlockchainTransactionStatusList },
    { path: '/blockchainTransactionStatus-details/:guid?', component: BlockchainTransactionStatusDetails },
    { path: '/bank-list', component: BankList },
    { path: '/bank-details/:guid?', component: BankDetails },
    { path: '/bankAccount-list', component: BankAccountList },
    { path: '/bankAccount-details/:guid?', component: BankAccountDetails },
    { path: '/bankAccountStatus-list', component: BankAccountStatusList },
    { path: '/bankAccountStatus-details/:guid?', component: BankAccountStatusDetails },
    { path: '/pixKeyType-list', component: PixKeyTypeList },
    { path: '/pixKeyType-details/:guid?', component: PixKeyTypeDetails },
    { path: '/bankTransaction-list', component: BankTransactionList },
    { path: '/bankTransaction-details/:guid?', component: BankTransactionDetails },
    { path: '/bankTransactionOperation-list', component: BankTransactionOperationList },
    { path: '/bankTransactionOperation-details/:guid?', component: BankTransactionOperationDetails },
    { path: '/bankAccountType-list', component: BankAccountTypeList },
    { path: '/bankAccountType-details/:guid?', component: BankAccountTypeDetails },
    { path: '/airdropCampaign-list', component: AirdropCampaignList },
    { path: '/airdropCampaign-details/:guid?', component: AirdropCampaignDetails },
    { path: '/campaign-list', component: CampaignList },
    { path: '/campaign-details/:guid?', component: CampaignDetails },
    { path: '/campaignStatus-list', component: CampaignStatusList },
    { path: '/campaignStatus-details/:guid?', component: CampaignStatusDetails },
    { path: '/campaignType-list', component: CampaignTypeList },
    { path: '/campaignType-details/:guid?', component: CampaignTypeDetails },
    { path: '/bankTransactionStatus-list', component: BankTransactionStatusList },
    { path: '/bankTransactionStatus-details/:guid?', component: BankTransactionStatusDetails },
    { path: '/bankTransactionType-list', component: BankTransactionTypeList },
    { path: '/bankTransactionType-details/:guid?', component: BankTransactionTypeDetails },
    { path: '/country-list', component: CountryList },
    { path: '/country-details/:guid?', component: CountryDetails },
    { path: '/userRole-list', component: UserRoleList },
    { path: '/userRole-details/:guid?', component: UserRoleDetails },
    { path: '/userRoleMap-list', component: UserRoleMapList },
    { path: '/userRoleMap-details/:guid?', component: UserRoleMapDetails },
    { path: '/userRoleTokenCategoryMap-list', component: UserRoleTokenCategoryMapList },
    { path: '/userRoleTokenCategoryMap-details/:guid?', component: UserRoleTokenCategoryMapDetails },
    { path: '/userRoleLeadTypeMap-list', component: UserRoleLeadTypeMapList },
    { path: '/userRoleLeadTypeMap-details/:guid?', component: UserRoleLeadTypeMapDetails },
    { path: '/accessIp-list', component: AccessIpList },
    { path: '/accessIp-details/:guid?', component: AccessIpDetails },
    { path: '/typeSupplyToken-list', component: TypeSupplyTokenList },
    { path: '/typeSupplyToken-details/:guid?', component: TypeSupplyTokenDetails },
    { path: '/policy-list', component: PolicyList },
    { path: '/policy-details/:guid?', component: PolicyDetails },
    { path: '/policyService-list', component: PolicyServiceList },
    { path: '/policyService-details/:guid?', component: PolicyServiceDetails },
    { path: '/policyStatus-list', component: PolicyStatusList },
    { path: '/policyStatus-details/:guid?', component: PolicyStatusDetails },
    { path: '/report-list', component: ReportList },
    { path: '/report-details/:guid?', component: ReportDetails },
    { path: '/log-list', component: LogList },
    { path: '/log-details/:guid?', component: LogDetails },
    { path: '/state-list', component: StateList },
    { path: '/state-details/:guid?', component: StateDetails },
    { path: '/vault-list', component: VaultList },
    { path: '/vault-details/:guid?', component: VaultDetails },
    { path: '/utm-list', component: UtmList },
    { path: '/utm-details/:guid?', component: UtmDetails },
    { path: '/bankReconciliation-list', component: BankReconciliationList },
    { path: '/bankReconciliation-details/:guid?', component: BankReconciliationDetails },
    { path: '/bankReconciliationStatus-list', component: BankReconciliationStatusList },
    { path: '/bankReconciliationStatus-details/:guid?', component: BankReconciliationStatusDetails },
    { path: '/blockchainInfo-list', component: BlockchainInfoList },
    { path: '/blockchainInfo-details/:guid?', component: BlockchainInfoDetails },
    { path: '/liqiFile-list', component: LiqiFileList },
    { path: '/liqiFile-details/:guid?', component: LiqiFileDetails },
    { path: '/userPush-list', component: UserPushList },
    { path: '/userPush-details/:guid?', component: UserPushDetails },
    { path: '/scheduleCommunication-list', component: ScheduleCommunicationList },
    { path: '/scheduleCommunication-details/:guid?', component: ScheduleCommunicationDetails },
    { path: '/scheduleCommunicationFields-list', component: ScheduleCommunicationFieldsList },
    { path: '/scheduleCommunicationFields-details/:guid?', component: ScheduleCommunicationFieldsDetails },
    { path: '/scheduleCommunicationEmails-list', component: ScheduleCommunicationEmailsList },
    { path: '/scheduleCommunicationEmails-details/:guid?', component: ScheduleCommunicationEmailsDetails },
    { path: '/externalWallet-list', component: ExternalWalletList },
    { path: '/externalWallet-details/:guid?', component: ExternalWalletDetails },
    { path: '/statement-list', component: StatementList },
    { path: '/statement-details/:guid?', component: StatementDetails },
    { path: '/statementStatus-list', component: StatementStatusList },
    { path: '/statementStatus-details/:guid?', component: StatementStatusDetails },
    { path: '/statementCategory-list', component: StatementCategoryList },
    { path: '/statementCategory-details/:guid?', component: StatementCategoryDetails },
    { path: '/statementOperation-list', component: StatementOperationList },
    { path: '/statementOperation-details/:guid?', component: StatementOperationDetails },
    { path: '/dividend-list', component: DividendList },
    { path: '/dividend-details/:guid?', component: DividendDetails },
    { path: '/dividendCategory-list', component: DividendCategoryList },
    { path: '/dividendCategory-details/:guid?', component: DividendCategoryDetails },
    { path: '/apiKeyWallet-list', component: ApiKeyWalletList },
    { path: '/apiKeyWallet-details/:guid?', component: ApiKeyWalletDetails },
    { path: '/apiKeyVault-list', component: ApiKeyVaultList },
    { path: '/apiKeyVault-details/:guid?', component: ApiKeyVaultDetails },
    { path: '/companyInvite-list', component: CompanyInviteList },
    { path: '/companyInvite-details/:guid?', component: CompanyInviteDetails },
    { path: '/blockchainTokenUserMap-list', component: BlockchainTokenUserMapList },
    { path: '/blockchainTokenUserMap-details/:guid?', component: BlockchainTokenUserMapDetails },
    { path: '/liqiWitness-list', component: LiqiWitnessList },
    { path: '/liqiWitness-details/:guid?', component: LiqiWitnessDetails },
    { path: '/documentSignerContract-list', component: DocumentSignerContractList },
    { path: '/documentSignerContract-details/:guid?', component: DocumentSignerContractDetails },
    { path: '/documentSignerContractStatus-list', component: DocumentSignerContractStatusList },
    { path: '/documentSignerContractStatus-details/:guid?', component: DocumentSignerContractStatusDetails },
    { path: '/faq-list', component: FaqList },
    { path: '/faq-details/:guid?', component: FaqDetails },
    { path: '/faqItem-list', component: FaqItemList },
    { path: '/faqItem-details/:guid?', component: FaqItemDetails },
    { path: '/oracleIndexType-list', component: OracleIndexTypeList },
    { path: '/oracleIndexType-details/:guid?', component: OracleIndexTypeDetails },
    { path: '/oracleData-list', component: OracleDataList },
    { path: '/oracleData-details/:guid?', component: OracleDataDetails },
    { path: '/oracleAddress-list', component: OracleAddressList },
    { path: '/oracleAddress-details/:guid?', component: OracleAddressDetails },
    { path: '/oracleRegister-list', component: OracleRegisterList },
    { path: '/oracleRegister-details/:guid?', component: OracleRegisterDetails },
    { path: '/custodyUser-list', component: CustodyUserList },
    { path: '/custodyUser-details/:guid?', component: CustodyUserDetails },
    { path: '/custodyWalletType-list', component: CustodyWalletTypeList },
    { path: '/custodyWalletType-details/:guid?', component: CustodyWalletTypeDetails },
    { path: '/custodyWalletProfile-list', component: CustodyWalletProfileList },
    { path: '/custodyWalletProfile-details/:guid?', component: CustodyWalletProfileDetails },
    { path: '/custodyWalletFrequency-list', component: CustodyWalletFrequencyList },
    { path: '/custodyWalletFrequency-details/:guid?', component: CustodyWalletFrequencyDetails },
    { path: '/custodyWallet-list', component: CustodyWalletList },
    { path: '/custodyWallet-details/:guid?', component: CustodyWalletDetails },
    { path: '/custodyVault-list', component: CustodyVaultList },
    { path: '/custodyVault-details/:guid?', component: CustodyVaultDetails },
    { path: '/custodySeed-list', component: CustodySeedList },
    { path: '/custodySeed-details/:guid?', component: CustodySeedDetails },
    { path: '/custodyApplication-list', component: CustodyApplicationList },
    { path: '/custodyApplication-details/:guid?', component: CustodyApplicationDetails },
    { path: '/custodyBlockchainTransaction-list', component: CustodyBlockchainTransactionList },
    { path: '/custodyBlockchainTransaction-details/:guid?', component: CustodyBlockchainTransactionDetails },
    { path: '/custodyTransactionType-list', component: CustodyTransactionTypeList },
    { path: '/custodyTransactionType-details/:guid?', component: CustodyTransactionTypeDetails },
    { path: '/custodyWebHookType-list', component: CustodyWebHookTypeList },
    { path: '/custodyWebHookType-details/:guid?', component: CustodyWebHookTypeDetails },
    { path: '/custodyWebHookUrl-list', component: CustodyWebHookUrlList },
    { path: '/custodyWebHookUrl-details/:guid?', component: CustodyWebHookUrlDetails },
    { path: '/custodyCurrencies-list', component: CustodyCurrenciesList },
    { path: '/custodyCurrencies-details/:guid?', component: CustodyCurrenciesDetails },
    { path: '/custodyCurrenciesCompanyMap-list', component: CustodyCurrenciesCompanyMapList },
    { path: '/custodyCurrenciesCompanyMap-details/:guid?', component: CustodyCurrenciesCompanyMapDetails },
    { path: '/custodyWalletAsset-list', component: CustodyWalletAssetList },
    { path: '/custodyWalletAsset-details/:guid?', component: CustodyWalletAssetDetails },
    { path: '/businessTax-list', component: BusinessTaxList },
    { path: '/businessTax-details/:guid?', component: BusinessTaxDetails },
    { path: '/businessTaxCompany-list', component: BusinessTaxCompanyList },
    { path: '/businessTaxCompany-details/:guid?', component: BusinessTaxCompanyDetails },
    { path: '/businessTaxType-list', component: BusinessTaxTypeList },
    { path: '/businessTaxType-details/:guid?', component: BusinessTaxTypeDetails },
    { path: '/webHookMessage-list', component: WebHookMessageList },
    { path: '/webHookMessage-details/:guid?', component: WebHookMessageDetails },
];

export { prismaRoutes };
