//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { InvestorDeposit } from '../../../models/prisma/investorDeposit.model';
        import { GET_LIST, startedFilter } from './investorDeposit.list';
        import { useTranslation } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        import WalletDetails from '../wallet/wallet.details';
import InvestorDetails from '../investor/investor.details';
import OfferDetails from '../offer/offer.details';
import InvestorDepositStatusDetails from '../investorDepositStatus/investorDepositStatus.details';
import BankDetails from '../bank/bank.details';
import BlockchainTokenDetails from '../blockchainToken/blockchainToken.details';
import BlockchainTaxDetails from '../blockchainTax/blockchainTax.details';
import BankReconciliationList from '../bankReconciliation/bankReconciliation.list';

        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            investorDepositCms: InvestorDeposit
        }>`
        query InvestorDepositCms($id: String)
        {
            investorDepositCms(id: $id)
          {
            id
                    document
                    wallet {
                        id
                        address
                    }
                    walletId
                    investor {
                        id
                        companyId
                    }
                    investorId
                    offer {
                        id
                        title
                    }
                    offerId
                    value
                    assetAmount
                    rate
                    status {
                        id
                        description
                    }
                    statusId
                    fromBank {
                        id
                        name
                    }
                    bankId
                    fromAgencyNumber
                    fromAccountNumber
                    bankTransactionId
                    confirmedValue
                    confirmedAssetAmount
                    confirmedRate
                    tries
                    error
                    currencyToken {
                        id
                        name
                    }
                    currencyTokenId
                    tax {
                        id
                        hash
                    }
                    taxId
                    cashout
                    deleted
                    createdAt
                    updatedAt
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<InvestorDeposit>`
        mutation InvestorDepositSave(
            $id: String,
                        $document: String,
                        $wallet: WalletCreateInputs,
                    $walletId: String,
                        $investor: InvestorCreateInputs,
                    $investorId: String,
                        $offer: OfferCreateInputs,
                    $offerId: String,
                        $value: Float,
                        $assetAmount: Float,
                        $rate: Float,
                        $status: InvestorDepositStatusCreateInputs,
                    $statusId: String,
                        $fromBank: BankCreateInputs,
                    $bankId: String,
                        $fromAgencyNumber: String,
                        $fromAccountNumber: String,
                        $bankTransactionId: String,
                        $confirmedValue: Float,
                        $confirmedAssetAmount: Float,
                        $confirmedRate: Float,
                        $tries: Int,
                        $error: String,
                        $currencyToken: BlockchainTokenCreateInputs,
                    $currencyTokenId: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $cashout: Boolean,
                        
        )
        {
            investorDepositCreateCms(data: {
                id: $id,
                    document: $document,
                    wallet: $wallet,
                    walletId: $walletId,
                    investor: $investor,
                    investorId: $investorId,
                    offer: $offer,
                    offerId: $offerId,
                    value: $value,
                    assetAmount: $assetAmount,
                    rate: $rate,
                    status: $status,
                    statusId: $statusId,
                    fromBank: $fromBank,
                    bankId: $bankId,
                    fromAgencyNumber: $fromAgencyNumber,
                    fromAccountNumber: $fromAccountNumber,
                    bankTransactionId: $bankTransactionId,
                    confirmedValue: $confirmedValue,
                    confirmedAssetAmount: $confirmedAssetAmount,
                    confirmedRate: $confirmedRate,
                    tries: $tries,
                    error: $error,
                    currencyToken: $currencyToken,
                    currencyTokenId: $currencyTokenId,
                    tax: $tax,
                    taxId: $taxId,
                    cashout: $cashout,
                    
            }) {
                id
                    document
                    wallet {
                        id
                        address
                    }
                    walletId
                    investor {
                        id
                        companyId
                    }
                    investorId
                    offer {
                        id
                        title
                    }
                    offerId
                    value
                    assetAmount
                    rate
                    status {
                        id
                        description
                    }
                    statusId
                    fromBank {
                        id
                        name
                    }
                    bankId
                    fromAgencyNumber
                    fromAccountNumber
                    bankTransactionId
                    confirmedValue
                    confirmedAssetAmount
                    confirmedRate
                    tries
                    error
                    currencyToken {
                        id
                        name
                    }
                    currencyTokenId
                    tax {
                        id
                        hash
                    }
                    taxId
                    cashout
                    deleted
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<InvestorDeposit>`
        mutation InvestorDepositSave(
            $id: String,
                        $document: String,
                        $wallet: WalletCreateInputs,
                    $walletId: String,
                        $investor: InvestorCreateInputs,
                    $investorId: String,
                        $offer: OfferCreateInputs,
                    $offerId: String,
                        $value: Float,
                        $assetAmount: Float,
                        $rate: Float,
                        $status: InvestorDepositStatusCreateInputs,
                    $statusId: String,
                        $fromBank: BankCreateInputs,
                    $bankId: String,
                        $fromAgencyNumber: String,
                        $fromAccountNumber: String,
                        $bankTransactionId: String,
                        $confirmedValue: Float,
                        $confirmedAssetAmount: Float,
                        $confirmedRate: Float,
                        $tries: Int,
                        $error: String,
                        $currencyToken: BlockchainTokenCreateInputs,
                    $currencyTokenId: String,
                        $tax: BlockchainTaxCreateInputs,
                    $taxId: String,
                        $cashout: Boolean,
                        
        )
        {
            investorDepositUpdateCms(data: {
                id: $id,
                    document: $document,
                    wallet: $wallet,
                    walletId: $walletId,
                    investor: $investor,
                    investorId: $investorId,
                    offer: $offer,
                    offerId: $offerId,
                    value: $value,
                    assetAmount: $assetAmount,
                    rate: $rate,
                    status: $status,
                    statusId: $statusId,
                    fromBank: $fromBank,
                    bankId: $bankId,
                    fromAgencyNumber: $fromAgencyNumber,
                    fromAccountNumber: $fromAccountNumber,
                    bankTransactionId: $bankTransactionId,
                    confirmedValue: $confirmedValue,
                    confirmedAssetAmount: $confirmedAssetAmount,
                    confirmedRate: $confirmedRate,
                    tries: $tries,
                    error: $error,
                    currencyToken: $currencyToken,
                    currencyTokenId: $currencyTokenId,
                    tax: $tax,
                    taxId: $taxId,
                    cashout: $cashout,
                    
            }) {
                id
                    document
                    wallet {
                        id
                        address
                    }
                    walletId
                    investor {
                        id
                        companyId
                    }
                    investorId
                    offer {
                        id
                        title
                    }
                    offerId
                    value
                    assetAmount
                    rate
                    status {
                        id
                        description
                    }
                    statusId
                    fromBank {
                        id
                        name
                    }
                    bankId
                    fromAgencyNumber
                    fromAccountNumber
                    bankTransactionId
                    confirmedValue
                    confirmedAssetAmount
                    confirmedRate
                    tries
                    error
                    currencyToken {
                        id
                        name
                    }
                    currencyTokenId
                    tax {
                        id
                        hash
                    }
                    taxId
                    cashout
                    deleted
                    createdAt
                    updatedAt
                    
            }
        }
        `;

        
                    const WALLET_QUERY = `
                    query walletListCms($search: Search) {
                        walletListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    address
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const INVESTOR_QUERY = `
                    query investorListCms($search: Search) {
                        investorListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    companyId
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const OFFER_QUERY = `
                    query offerListCms($search: Search) {
                        offerListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const INVESTORDEPOSITSTATUS_QUERY = `
                    query investorDepositStatusListCms($search: Search) {
                        investorDepositStatusListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    description
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BANK_QUERY = `
                    query bankListCms($search: Search) {
                        bankListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTOKEN_QUERY = `
                    query blockchainTokenListCms($search: Search) {
                        blockchainTokenListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    
                    const BLOCKCHAINTAX_QUERY = `
                    query blockchainTaxListCms($search: Search) {
                        blockchainTaxListCms(search: $search, pagination: {take: 30}) {
                            edges {
                                node {
                                    id
                                    hash
                                }
                            }
                            totalCount
                        }
                    }
                    `;
                    

        const InvestorDepositDetails = (props: any) => {
            const { t } = useTranslation();
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({used: false} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error: any) {
                    toastr.error(error.message || error.stack);
                }
            };

            const { data, loading } = useQuery<{ investorDepositCms: InvestorDeposit }>(QUERY, {
                variables: { id: decodeURIComponent(props.match?.params?.guid) },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.investorDepositCms || {} as any;            

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            async function downloadFile (download: any, url: string) {
                
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                download[url] = false;
                setDownload({ ...download, [url]: false });

                let result = await StorageService.download(url);

                if (!result.error) {
                    download[url] = result.data;
                    setDownload({ ...download, used: true, [url]: result.data });
                }

                return undefined;
            }

            useEffect(() => {
                (async () => {
                    let index = 0;               
                    for (const key in databaseEntity) {                           
                        if (Object.hasOwnProperty.call(databaseEntity, key)) {
                            const element = databaseEntity[key];
                            
                            if(key.toLowerCase().endsWith("fileid")) {
                                downloadFile(download, element);
                                index++;
                            }

                            
                        }                            
                    }
                })();
            }, [databaseEntity]);

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        if(entity[key]) {
                            delete entity[key].__typename;
                        }                        
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // get datasource dates value null
                if (dataSources.noDate) {
                    for(const key in dataSources.noDate) {
                        if(typeof dataSources.noDate[key] === 'string') {
                           console.log(key, dataSources.noDate[key])
                           entity[key] = dataSources.noDate[key];
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("InvestorDeposit has been saved");
                    const model = entity?.data?.investorDepositUpdateCms || entity?.data?.investorDepositCreateCms;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/investorDeposit-details/' + encodeURIComponent(model.id));
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error:any) => {
                            toastr.error(error.message || error.stack);
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={t('InvestorDeposit')} breadcrumbItems={[
                                { title: t("InvestorDeposit"), link: "#" },
                                { title: t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{t('InvestorDeposit')} {t('Details')}</h4>
                                                    <p className="card-title-desc">{t('Create/Edit')} {t('InvestorDeposit')} {t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={t("Document")} name="document"
                            rules={[{ required: true, message: 'Please input the ' + t("Document") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "walletId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Wallet')}</>}>
                                <Form.Item noStyle id="wallet_id" name="walletId"
                                rules={[{ required: (true && !(dataSources.dependencies?.wallet?.address || databaseEntity?.wallet?.address)), message: 'Please input the Wallet!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                value={ dataSources.dependencies?.wallet ? dataSources.dependencies?.wallet?.address : databaseEntity?.wallet?.address}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.walletId = newValue.key;
                                            dataSources.dependencies["wallet"] = {
                                                id: newValue.key,
                                                address: newValue.value
                                            };
                                            if(dataSources.dependencies["wallet"]) {
                                                delete dataSources.dependencies["wallet"].__typename;
                                            }                                            
                                            setSelects({ ...selects, walletId: newValue.key });
                                            setUncheck({ ...uncheck, walletId: false });
                                        }
                                        else if (databaseEntity?.wallet) {
                                            dataSources.dependencies.walletId = undefined;
                                            dataSources.dependencies["wallet"] = {
                                                id: undefined,
                                                address: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["wallet"] = {
                                            id: undefined,
                                            address: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.walletId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Wallet", query: WALLET_QUERY, idField: "id", textField: "address" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Wallet", query: WALLET_QUERY, idField: "id", textField: "address" });
                                    }}>
                                    {(dataSources["Wallet"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.address}>
                                            {item.address}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, WalletwalletIdEditVisible: !modals.WalletwalletIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["WalletwalletIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, WalletwalletIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Wallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <WalletDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, WalletwalletIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    walletId: entity?.id,
                                                    wallet: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, WalletAddVisible: !modals.WalletAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["WalletAddVisible"]}
                                    onHide={() => setModals({ ...modals, WalletAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Wallet
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <WalletDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, WalletAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        walletId: entity?.id,
                                                        wallet: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.wallet?.id || databaseEntity?.wallet?.id) && !uncheck['walletId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, walletId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            walletId: "",
                                            wallet: {
                                                address: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "investorId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Investor')}</>}>
                                <Form.Item noStyle id="investor_id" name="investorId"
                                rules={[{ required: (true && !(dataSources.dependencies?.investor?.companyId || databaseEntity?.investor?.companyId)), message: 'Please input the Investor!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.investor ? dataSources.dependencies?.investor?.companyId : databaseEntity?.investor?.companyId}
                                value={ dataSources.dependencies?.investor ? dataSources.dependencies?.investor?.companyId : databaseEntity?.investor?.companyId}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.investorId = newValue.key;
                                            dataSources.dependencies["investor"] = {
                                                id: newValue.key,
                                                companyId: newValue.value
                                            };
                                            if(dataSources.dependencies["investor"]) {
                                                delete dataSources.dependencies["investor"].__typename;
                                            }                                            
                                            setSelects({ ...selects, investorId: newValue.key });
                                            setUncheck({ ...uncheck, investorId: false });
                                        }
                                        else if (databaseEntity?.investor) {
                                            dataSources.dependencies.investorId = undefined;
                                            dataSources.dependencies["investor"] = {
                                                id: undefined,
                                                companyId: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["investor"] = {
                                            id: undefined,
                                            companyId: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.investorId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Investor", query: INVESTOR_QUERY, idField: "id", textField: "companyId" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Investor", query: INVESTOR_QUERY, idField: "id", textField: "companyId" });
                                    }}>
                                    {(dataSources["Investor"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.companyId}>
                                            {item.companyId}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, InvestorinvestorIdEditVisible: !modals.InvestorinvestorIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["InvestorinvestorIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, InvestorinvestorIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Investor
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InvestorDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.investor?.id || databaseEntity?.investor?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, InvestorinvestorIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    investorId: entity?.id,
                                                    investor: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, InvestorAddVisible: !modals.InvestorAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["InvestorAddVisible"]}
                                    onHide={() => setModals({ ...modals, InvestorAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Investor
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InvestorDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, InvestorAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        investorId: entity?.id,
                                                        investor: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.investor?.id || databaseEntity?.investor?.id) && !uncheck['investorId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, investorId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            investorId: "",
                                            investor: {
                                                companyId: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "offerId") && <Form.Item label={<> {t('Offer')}</>}>
                                <Form.Item noStyle id="offer_id" name="offerId"
                                rules={[{ required: (false && !(dataSources.dependencies?.offer?.title || databaseEntity?.offer?.title)), message: 'Please input the Offer!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.offer ? dataSources.dependencies?.offer?.title : databaseEntity?.offer?.title}
                                value={ dataSources.dependencies?.offer ? dataSources.dependencies?.offer?.title : databaseEntity?.offer?.title}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.offerId = newValue.key;
                                            dataSources.dependencies["offer"] = {
                                                id: newValue.key,
                                                title: newValue.value
                                            };
                                            if(dataSources.dependencies["offer"]) {
                                                delete dataSources.dependencies["offer"].__typename;
                                            }                                            
                                            setSelects({ ...selects, offerId: newValue.key });
                                            setUncheck({ ...uncheck, offerId: false });
                                        }
                                        else if (databaseEntity?.offer) {
                                            dataSources.dependencies.offerId = undefined;
                                            dataSources.dependencies["offer"] = {
                                                id: undefined,
                                                title: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["offer"] = {
                                            id: undefined,
                                            title: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.offerId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Offer", query: OFFER_QUERY, idField: "id", textField: "title" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Offer", query: OFFER_QUERY, idField: "id", textField: "title" });
                                    }}>
                                    {(dataSources["Offer"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.title}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, OfferofferIdEditVisible: !modals.OfferofferIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferofferIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, OfferofferIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Offer
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.offer?.id || databaseEntity?.offer?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, OfferofferIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    offerId: entity?.id,
                                                    offer: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, OfferAddVisible: !modals.OfferAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["OfferAddVisible"]}
                                    onHide={() => setModals({ ...modals, OfferAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Offer
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OfferDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, OfferAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        offerId: entity?.id,
                                                        offer: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.offer?.id || databaseEntity?.offer?.id) && !uncheck['offerId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, offerId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            offerId: "",
                                            offer: {
                                                title: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Value")} name="value"
                            rules={[{ required: true, message: 'Please input the ' + t("Value") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("AssetAmount")} name="assetAmount"
                            rules={[{ required: true, message: 'Please input the ' + t("AssetAmount") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Rate")} name="rate"
                            rules={[{ required: true, message: 'Please input the ' + t("Rate") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            { (!props.relation || props.relation.field != "statusId") && <Form.Item label={<><span style={{color: 'red'}}> * </span> {t('Status')}</>}>
                                <Form.Item noStyle id="status_id" name="statusId"
                                rules={[{ required: (true && !(dataSources.dependencies?.status?.description || databaseEntity?.status?.description)), message: 'Please input the InvestorDepositStatus!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                value={ dataSources.dependencies?.status ? dataSources.dependencies?.status?.description : databaseEntity?.status?.description}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.statusId = newValue.key;
                                            dataSources.dependencies["status"] = {
                                                id: newValue.key,
                                                description: newValue.value
                                            };
                                            if(dataSources.dependencies["status"]) {
                                                delete dataSources.dependencies["status"].__typename;
                                            }                                            
                                            setSelects({ ...selects, statusId: newValue.key });
                                            setUncheck({ ...uncheck, statusId: false });
                                        }
                                        else if (databaseEntity?.status) {
                                            dataSources.dependencies.statusId = undefined;
                                            dataSources.dependencies["status"] = {
                                                id: undefined,
                                                description: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["status"] = {
                                            id: undefined,
                                            description: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.statusId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "InvestorDepositStatus", query: INVESTORDEPOSITSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "InvestorDepositStatus", query: INVESTORDEPOSITSTATUS_QUERY, idField: "id", textField: "description" });
                                    }}>
                                    {(dataSources["InvestorDepositStatus"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.description}>
                                            {item.description}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, InvestorDepositStatusstatusIdEditVisible: !modals.InvestorDepositStatusstatusIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["InvestorDepositStatusstatusIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, InvestorDepositStatusstatusIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        InvestorDepositStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InvestorDepositStatusDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.status?.id || databaseEntity?.status?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, InvestorDepositStatusstatusIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    statusId: entity?.id,
                                                    status: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, InvestorDepositStatusAddVisible: !modals.InvestorDepositStatusAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["InvestorDepositStatusAddVisible"]}
                                    onHide={() => setModals({ ...modals, InvestorDepositStatusAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            InvestorDepositStatus
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InvestorDepositStatusDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, InvestorDepositStatusAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        statusId: entity?.id,
                                                        status: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.status?.id || databaseEntity?.status?.id) && !uncheck['statusId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, statusId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            statusId: "",
                                            status: {
                                                description: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "bankId") && <Form.Item label={<> {t('FromBank')}</>}>
                                <Form.Item noStyle id="fromBank_id" name="bankId"
                                rules={[{ required: (false && !(dataSources.dependencies?.fromBank?.name || databaseEntity?.fromBank?.name)), message: 'Please input the Bank!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.fromBank ? dataSources.dependencies?.fromBank?.name : databaseEntity?.fromBank?.name}
                                value={ dataSources.dependencies?.fromBank ? dataSources.dependencies?.fromBank?.name : databaseEntity?.fromBank?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.bankId = newValue.key;
                                            dataSources.dependencies["fromBank"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["fromBank"]) {
                                                delete dataSources.dependencies["fromBank"].__typename;
                                            }                                            
                                            setSelects({ ...selects, bankId: newValue.key });
                                            setUncheck({ ...uncheck, bankId: false });
                                        }
                                        else if (databaseEntity?.fromBank) {
                                            dataSources.dependencies.bankId = undefined;
                                            dataSources.dependencies["fromBank"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["fromBank"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.bankId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "Bank", query: BANK_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "Bank", query: BANK_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["Bank"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BankbankIdEditVisible: !modals.BankbankIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankbankIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BankbankIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        Bank
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.fromBank?.id || databaseEntity?.fromBank?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BankbankIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    bankId: entity?.id,
                                                    fromBank: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BankAddVisible: !modals.BankAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BankAddVisible"]}
                                    onHide={() => setModals({ ...modals, BankAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Bank
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BankDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BankAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        bankId: entity?.id,
                                                        fromBank: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.fromBank?.id || databaseEntity?.fromBank?.id) && !uncheck['bankId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, bankId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            bankId: "",
                                            fromBank: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("FromAgencyNumber")} name="fromAgencyNumber"
                            rules={[{ required: false, message: 'Please input the ' + t("FromAgencyNumber") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("FromAccountNumber")} name="fromAccountNumber"
                            rules={[{ required: false, message: 'Please input the ' + t("FromAccountNumber") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("BankTransactionId")} name="bankTransactionId"
                            rules={[{ required: false, message: 'Please input the ' + t("BankTransactionId") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("ConfirmedValue")} name="confirmedValue"
                            rules={[{ required: true, message: 'Please input the ' + t("ConfirmedValue") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("ConfirmedAssetAmount")} name="confirmedAssetAmount"
                            rules={[{ required: true, message: 'Please input the ' + t("ConfirmedAssetAmount") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("ConfirmedRate")} name="confirmedRate"
                            rules={[{ required: true, message: 'Please input the ' + t("ConfirmedRate") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Tries")} name="tries"
                            rules={[{ required: true, message: 'Please input the ' + t("Tries") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            <Form.Item label={t("Error")} name="error"
                            rules={[{ required: false, message: 'Please input the ' + t("Error") + '!' }]}>
                                <Input />
                            </Form.Item>
                            { (!props.relation || props.relation.field != "currencyTokenId") && <Form.Item label={<> {t('CurrencyToken')}</>}>
                                <Form.Item noStyle id="currencyToken_id" name="currencyTokenId"
                                rules={[{ required: (false && !(dataSources.dependencies?.currencyToken?.name || databaseEntity?.currencyToken?.name)), message: 'Please input the BlockchainToken!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.currencyToken ? dataSources.dependencies?.currencyToken?.name : databaseEntity?.currencyToken?.name}
                                value={ dataSources.dependencies?.currencyToken ? dataSources.dependencies?.currencyToken?.name : databaseEntity?.currencyToken?.name}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.currencyTokenId = newValue.key;
                                            dataSources.dependencies["currencyToken"] = {
                                                id: newValue.key,
                                                name: newValue.value
                                            };
                                            if(dataSources.dependencies["currencyToken"]) {
                                                delete dataSources.dependencies["currencyToken"].__typename;
                                            }                                            
                                            setSelects({ ...selects, currencyTokenId: newValue.key });
                                            setUncheck({ ...uncheck, currencyTokenId: false });
                                        }
                                        else if (databaseEntity?.currencyToken) {
                                            dataSources.dependencies.currencyTokenId = undefined;
                                            dataSources.dependencies["currencyToken"] = {
                                                id: undefined,
                                                name: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["currencyToken"] = {
                                            id: undefined,
                                            name: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.currencyTokenId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainToken", query: BLOCKCHAINTOKEN_QUERY, idField: "id", textField: "name" });
                                    }}>
                                    {(dataSources["BlockchainToken"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTokencurrencyTokenIdEditVisible: !modals.BlockchainTokencurrencyTokenIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokencurrencyTokenIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokencurrencyTokenIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.currencyToken?.id || databaseEntity?.currencyToken?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTokencurrencyTokenIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    currencyTokenId: entity?.id,
                                                    currencyToken: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTokenAddVisible: !modals.BlockchainTokenAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTokenAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTokenAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainToken
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTokenDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTokenAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        currencyTokenId: entity?.id,
                                                        currencyToken: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.currencyToken?.id || databaseEntity?.currencyToken?.id) && !uncheck['currencyTokenId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, currencyTokenId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            currencyTokenId: "",
                                            currencyToken: {
                                                name: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            { (!props.relation || props.relation.field != "taxId") && <Form.Item label={<> {t('Tax')}</>}>
                                <Form.Item noStyle id="tax_id" name="taxId"
                                rules={[{ required: (false && !(dataSources.dependencies?.tax?.hash || databaseEntity?.tax?.hash)), message: 'Please input the BlockchainTax!' }]}
                                valuePropName="aria-label">
                                {(!props.match?.params?.guid || (props.match?.params?.guid && data)) && <AutoComplete style={{ maxWidth: 260 }}
                                aria-label={dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                value={ dataSources.dependencies?.tax ? dataSources.dependencies?.tax?.hash : databaseEntity?.tax?.hash}
                                    dropdownMatchSelectWidth={252}
                                    onSelect={(event, newValue) => {
                                        dataSources.dependencies = dataSources.dependencies || {};

                                        if (newValue) {
                                            dataSources.dependencies.taxId = newValue.key;
                                            dataSources.dependencies["tax"] = {
                                                id: newValue.key,
                                                hash: newValue.value
                                            };
                                            if(dataSources.dependencies["tax"]) {
                                                delete dataSources.dependencies["tax"].__typename;
                                            }                                            
                                            setSelects({ ...selects, taxId: newValue.key });
                                            setUncheck({ ...uncheck, taxId: false });
                                        }
                                        else if (databaseEntity?.tax) {
                                            dataSources.dependencies.taxId = undefined;
                                            dataSources.dependencies["tax"] = {
                                                id: undefined,
                                                hash: undefined
                                            }
                                        }
                                        setDataSources(dataSources);
                                    }}
                                    onChange={(value: string) => {
                                        dataSources.dependencies = dataSources.dependencies || {};
                                        dataSources.dependencies["tax"] = {
                                            id: undefined,
                                            hash: value
                                        }

                                        if(!value) {
                                            dataSources.dependencies.taxId = undefined;
                                        }

                                        setDataSources({...dataSources});
                                    }}
                                    onSearch={(value: string) => {
                                        handleSearch({ search: value, model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}
                                    onClick={() => {
                                        handleSearch({ search: "", model: "BlockchainTax", query: BLOCKCHAINTAX_QUERY, idField: "id", textField: "hash" });
                                    }}>
                                    {(dataSources["BlockchainTax"] || []).map((item: any) => (
                                        <Option key={item.id} value={item.hash}>
                                            {item.hash}
                                        </Option>
                                    ))}
                                </AutoComplete>}
                            </Form.Item>
                            <Tooltip title="">
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxtaxIdEditVisible: !modals.BlockchainTaxtaxIdEditVisible }) }} className="mr-3 text-primary" id="edit12" style={{marginLeft: 20}}>
                                    <BiMessageEdit style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxtaxIdEditVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                        BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                        match={{
                                            params: {
                                                guid: dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id
                                            }
                                        }}
                                        modalOnClose={(entity:any) => {
                                            setModals({ ...modals, BlockchainTaxtaxIdEditVisible: false });
                                            const {__typename, createdAt, updatedAt, ...result} = entity;
                                            setDataSources({
                                                ...dataSources,
                                                dependencies: {
                                                    ...dataSources.dependencies,
                                                    taxId: entity?.id,
                                                    tax: result
                                                }
                                            });
                                        }
                                    } />
                                    </Modal.Body>
                                </Modal>
                                <a onClick={() => { setModals({ ...modals, BlockchainTaxAddVisible: !modals.BlockchainTaxAddVisible }) }} className="mr-3 text-primary" id="edit12">
                                    <RiChatNewLine style={{height: 20, width: 20}} />
                                </a>
                                <Modal
                                    size="lg"
                                    show={modals["BlockchainTaxAddVisible"]}
                                    onHide={() => setModals({ ...modals, BlockchainTaxAddVisible: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            BlockchainTax
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BlockchainTaxDetails isModal={true}
                                            modalOnClose={(entity:any) => {
                                                setModals({ ...modals, BlockchainTaxAddVisible: false });
                                                const {__typename, createdAt, updatedAt, ...result} = entity;
                                                setDataSources({
                                                    ...dataSources,
                                                    dependencies: {
                                                        ...dataSources.dependencies,
                                                        taxId: entity?.id,
                                                        tax: result
                                                    }
                                                });
                                            }
                                        } />
                                    </Modal.Body>
                                </Modal>
                                {(dataSources.dependencies?.tax?.id || databaseEntity?.tax?.id) && !uncheck['taxId'] && <a onClick={() => {
                                    setUncheck({ ...uncheck, taxId: true });

                                    setDataSources({
                                        ...dataSources,
                                        dependencies: {
                                            ...dataSources.dependencies,
                                            taxId: "",
                                            tax: {
                                                hash: ""
                                            }
                                        }
                                    });

                                }} className="mr-3 text-primary" id="edit12">
                                    <FiDelete style={{ height: 20, width: 20 }} />
                                </a>}
                            </Tooltip>
                        </Form.Item> }
                            <Form.Item label={t("Cashout")} name="cashout" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                        { !!databaseEntity?.id && <TabPane key={1} tab={t(`BankReconciliation`)}>
                            <BankReconciliationList relation={{model: "investorDeposit", field: "investorDepositId", textField: "document", idField: "id", id: props.match?.params?.guid, text: databaseEntity?.document}}></BankReconciliationList>
                        </TabPane> }
                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default (InvestorDepositDetails);
  